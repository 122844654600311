
import { computed, defineComponent, ref } from 'vue';
import { ComponentName } from '@/products/api/configuration/components/ComponentName';
import { setupComponent } from '@/products/composables/setupComponent';
import { ClassificationItem } from '@/common/services/swagger/index.defs';
import { DataType } from '@/products/api/configuration/application/DataType';
import { ClassificationCommand } from '@/products/api/runtime/CommandExecutor';
import { IConfiguratorOptions } from '@/products/api/configuration/components/IConfiguratorOptions';
import { ISearchCategoriesOptions } from '@/products/api/configuration/components/ISearchCategoriesOptions';

import InjectStyles from '@/common/components/InjectStyles.vue';
import LinkSecondary from '@/common/components/LinkSecondary.vue';
import CategoriesList from '@/common/components/CategoriesList.vue';
import Card from '@/common/components/Card.vue';
import Message from '@/common/components/Message.vue';
import Arrow from '@/common/components/Arrow.vue';

export default defineComponent({
  name: ComponentName.searchCategories,

  components: {
    InjectStyles,
    LinkSecondary,
    Card,
    CategoriesList,
    Message,
    Arrow,
  },

  props: {
    instanceId: String,
  },

  setup(props) {
    const root = ref<HTMLElement>();
    const trimList = ref(true);

    const { isReady, isWebComponent, componentName, instance, store, t } = setupComponent(
      root,
      props.instanceId,
      [DataType.Classifications],
    );

    const componentOptions = computed(
      () => store.value?.options.components?.searchCategories ?? ({} as ISearchCategoriesOptions),
    );

    const configuratorOptions = computed(
      () => store.value?.options.components?.configurators as IConfiguratorOptions,
    );

    const categories = computed(() => store.value?.data.classifications?.items ?? []);

    const visible = computed(
      () =>
        componentOptions.value.visible &&
        store.value?.options.application?.agmListCode == undefined,
    );

    const categoriesTrimmed = computed(() =>
      trimList.value
        ? categories.value?.slice(0, componentOptions.value.maxCategoriesVisible)
        : categories.value,
    );

    const showMoreBtn = computed(
      () =>
        categories.value &&
        categories.value.length > (componentOptions.value.maxCategoriesVisible ?? 0),
    );

    const setCategory = async ({ cid }: ClassificationItem) => {
      if (instance.value && cid) {
        try {
          await instance.value.execute(new ClassificationCommand({ cid, clearSearch: false }));
        } catch (error) {
          // Ignore Error
        }
      }
    };

    const showAllCategories = () => {
      trimList.value = !trimList.value;
    };

    return {
      root,
      isReady,
      isWebComponent,
      componentName,
      componentOptions,
      trimList,
      store,
      visible,
      categories,
      categoriesTrimmed,
      showMoreBtn,
      configuratorOptions,
      t,
      setCategory,
      showAllCategories,
    };
  },
});
