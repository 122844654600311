
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    highlighted: Boolean,
    removable: Boolean,
    removeTitle: String,
  },

  emits: ['remove'],
});
