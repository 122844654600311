
import { computed, defineComponent, ref } from 'vue';
import { ComponentName } from '@/products/api/configuration/components/ComponentName';
import { setupComponent } from '@/products/composables/setupComponent';

export default defineComponent({
  name: ComponentName.searchNoResultsView,

  props: {
    instanceId: String,
  },

  setup(props) {
    const root = ref<HTMLElement>();

    const { componentName, isReady, store } = setupComponent(root, props.instanceId);

    const productsItems = computed(() => store.value?.data.products?.items);

    return {
      root,
      componentName,
      store,
      isReady,
      productsItems,
    };
  },
});
