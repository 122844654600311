
import { computed, defineComponent, onMounted, PropType, reactive, ref } from 'vue';
import {
  FilterItem,
  FilterValue,
  FilterValueSearchResult,
  ImageItem,
  PrmRelationship,
  PrmRelationshipTypeEnum,
  PrmSearchResult,
  Table,
  TableRow,
} from '../services/swagger/index.defs';

import { GetPrmListCommand, GetPrmFiltersCommand } from '@/products/api/runtime/CommandExecutor';

import Tooltip from './Tooltip.vue';
import PisLink from './PisLink.vue';
import Loader from './Loader.vue';
import Icon from './Icon.vue';
import Pagination from './Pagination.vue';
import ImageGallery from './ImageGallery.vue';
import FormInputClearable from '@/common/components/FormInputClearable.vue';
import Dropdown from '@/common/components/Dropdown.vue';
import Arrow from '@/common/components/Arrow.vue';
import Badge from '@/common/components/Badge.vue';
import Btn from '@/common/components/Btn.vue';
import Card from '@/common/components/Card.vue';
import Message from '@/common/components/Message.vue';
import Spinner from '@/common/components/Spinner.vue';
import ScrollableList from '@/common/components/ScrollableList.vue';
import LinkSecondary from '@/common/components/LinkSecondary.vue';
import FiltersNavigator from '@/products/components/common/FiltersNavigatorModal.vue';
import Clickable from '@/common/components/Clickable.vue';
import { EmitFunc, TranslateFunc } from '@/products/composables/setupComponent';
import _debounce from 'lodash/debounce';
import { Instance } from '@/products/api/Instance';
import { setupPRMFiltersStorage } from '@/common/composables/prmFiltersStorage';
import VRuntimeTemplate from 'vue3-runtime-template';
import Highlighter from 'vue-highlight-words';
export default defineComponent({
  name: 'PrmList',

  components: {
    Arrow,
    Btn,
    Card,
    Badge,
    LinkSecondary,
    Message,
    ScrollableList,
    Tooltip,
    PisLink,
    Icon,
    Pagination,
    ImageGallery,
    FormInputClearable,
    FiltersNavigator,
    Loader,
    Spinner,
    Dropdown,
    Clickable,
    VRuntimeTemplate,
    Highlighter,
  },

  props: {
    relationship: { type: Object as () => PrmRelationship, required: true },
    instance: { type: Object as () => Instance | undefined, required: true },
    productId: { type: String, required: true },
    t: { type: Function as PropType<TranslateFunc>, required: true },
    cid: { type: String, required: false },
    emit: { type: Function as PropType<EmitFunc>, required: true },
  },

  setup(props) {
    const areFiltersDirty = ref(false);
    const allFiltersModalShown = ref(false);

    const prm = computed(() => props.relationship as PrmRelationship);
    const table = computed(() => prm.value.table as Table);
    const isFilteringEnabled = ref(false);
    const isSearchEnabled = ref(false);
    const filteringOptions = computed(
      () => props.instance?.store.options.components?.details?.relationships,
    );

    const {
      filter,
      filtersAll,
      filtersPromoted,
      filtersAdditional,
      filtersModel,
      filtersFetched,
      filtersLoading,
      filtersOpen,
      saveSettings,
      setFilters,
      setFilterValues,
      resetFiltersApplied,
      getFiltersModelAsFilterSettings,
    } = setupPRMFiltersStorage(props.productId, props.relationship);

    const page = ref(1);
    const pageSize = ref(filteringOptions.value?.pageSize || 0);
    const totalCount = ref(table.value.rowsTotalCount || 0);
    const loadedRows = ref<TableRow[]>([]);
    const isSearched = ref(false);
    const productsImages = ref<ImageItem[]>([]);
    const productsImagesLoading = ref(false);
    const isLoading = ref(false);
    const isAutocompleteVisible = ref(false);
    const isAutocompleteLoading = ref(false);
    const isAutocompleteDisabled = ref(false);

    const rows = computed(() => {
      if (isSearched.value === true) {
        return loadedRows.value;
      }
      return props.relationship.table?.rows || [];
    });

    const pageCount = computed(() => {
      return Math.ceil(totalCount.value / pageSize.value);
    });

    const autocomplete = reactive({
      visibleMatchCount: 0,
      totalCount: 0,
      matches: [],
    });

    const load = async () => {
      isLoading.value = true;
      const command = new GetPrmListCommand({
        search: {
          cid: props.instance?.router.routeData.cid,
          productId: props.productId,
          searchText: filter.value || undefined,
          relationshipType: props.relationship.type,
          relationshipCode: props.relationship.code,
          filterSettings: getFiltersModelAsFilterSettings(),
        },
        pager: {
          page: page.value,
          pageSize: pageSize.value,
        },
      });
      try {
        const result = (await props.instance?.execute(command)) as PrmSearchResult;
        if (result && result.items) {
          loadedRows.value = result.items[0].table?.rows || [];
          totalCount.value = result.items[0].table?.rowsTotalCount || 0;
          setFilters(result.items[0].filters || []);
        } else {
          loadedRows.value = [];
          totalCount.value = 0;
          setFilters([]);
        }
        const attributeCodes: Record<string, boolean> = {};
        // Merge filter attributes used so far, to refresh them
        filtersFetched.forEach(
          (value: FilterValue[], attributeCode: string) => (attributeCodes[attributeCode] = true),
        );
        filtersLoading.forEach((attributeCode: string) => (attributeCodes[attributeCode] = true));
        await onFetchFilterValues(Object.keys(attributeCodes));
        isSearched.value = true;
        isLoading.value = false;
      } catch (error) {
        // Ignore Error
      }
    };

    const loadAutocomplete = async () => {
      let matches = [];
      let totalCount = 0;
      let visibleMatchCount = 0;
      if (filter.value && isAutocompleteDisabled.value !== true) {
        isAutocompleteLoading.value = true;
        const command = new GetPrmListCommand({
          search: {
            cid: props.instance?.router.routeData.cid,
            productId: props.productId,
            searchText: filter.value || undefined,
            relationshipType: props.relationship.type,
            relationshipCode: props.relationship.code,
            filterSettings: getFiltersModelAsFilterSettings(),
          },
          pager: {
            page: 1,
            pageSize: 5,
          },
        });
        try {
          const result = (await props.instance?.execute(command)) as PrmSearchResult;
          if (result && result.items) {
            const table = result.items[0].table;
            const rows = table?.rows;
            const columns = table?.columns;
            const identifier: string | undefined = (columns || [])[0].dataField;
            const descriptionField: string | undefined = (columns || [])[1].dataField;
            if (rows && identifier && descriptionField) {
              const values = rows.map((a) => a.values);
              matches = values.map((a) => {
                return {
                  text: a[identifier][0].text,
                  description: a[descriptionField]?.length ? a[descriptionField][0].text : null,
                  type: a['Type']?.length ? a['Type'][0].text : null,
                } as never;
              });

              totalCount = result.items[0].table?.rowsTotalCount || 0;
              visibleMatchCount = autocomplete.matches.length;
            }
          }
        } catch (error) {
          // Ignore Error
        }
      }
      Object.assign(autocomplete, { matches, totalCount, visibleMatchCount });
      isAutocompleteVisible.value = autocomplete.matches.length > 0;
      isAutocompleteLoading.value = false;
    };

    const onFetchFilterValues = async (attributeCodes: string[]) => {
      attributeCodes.forEach((attributeCode) => filtersLoading.add(attributeCode));
      try {
        const res = (await props.instance?.execute(
          new GetPrmFiltersCommand({
            cid: props.instance?.router.routeData.cid,
            attributeCodes,
            productId: props.productId,
            searchText: filter.value || undefined,
            relationshipType: props.relationship.type,
            relationshipCode: props.relationship.code,
            filterSettings: getFiltersModelAsFilterSettings(),
          }),
        )) as FilterValueSearchResult;
        setFilterValues(res.items || []);
      } catch (error) {
        // Ignore error
      }
    };

    const onPageChange = async (p: number) => {
      page.value = p;
      await load();
    };

    const search = _debounce(async (event?: KeyboardEvent) => {
      if (event?.key == 'Enter') {
        return;
      }
      page.value = 1;
      await loadAutocomplete();
      if (!filter.value) {
        await load(); // Load all data after searchText has been cleared
      }
    }, 600);

    const searchNow = async () => {
      isAutocompleteVisible.value = false;
      allFiltersModalShown.value = false;
      await load();
    };

    const onSearch = async (item: unknown) => {
      filter.value = item as string;
      await searchNow();
    };

    const trimFilters = (filters: FilterItem[]) =>
      filters.slice(0, filteringOptions.value?.filteringMaxAttributesVisible) ?? [];

    const filtersToDisplay = computed<FilterItem[]>(() =>
      trimFilters(filtersPromoted.value.length ? filtersPromoted.value : filtersAdditional.value),
    );

    const toggleFilter = async ({ attributeCode }: FilterItem) => {
      if (!attributeCode || !props.instance) return;
      if (filtersOpen.has(attributeCode)) {
        filtersOpen.delete(attributeCode);
      } else {
        if (!filtersFetched.has(attributeCode)) {
          await onFetchFilterValues([attributeCode]);
        }
        filtersOpen.add(attributeCode);
      }
    };

    const isFilterOpen = ({ attributeCode }: FilterItem) => filtersOpen.has(attributeCode);
    const isFilterLoading = ({ attributeCode }: FilterItem) => filtersLoading.has(attributeCode);

    const showAllFilters = () => {
      allFiltersModalShown.value = false;
      setTimeout(() => {
        allFiltersModalShown.value = true;
      }, 1);
    };

    const clearAndCloseFilters = async () => {
      resetFiltersApplied();
      if (filtersOpen) {
        filtersOpen.clear();
      }
      await load();
    };

    const onModalFilterValueChange = ({ attributeCode, filterValueName, value }) => {
      filtersModel.value[attributeCode][filterValueName] = value;
    };

    const onFilterValueChange = () => {
      areFiltersDirty.value = true;
    };

    onMounted(async () => {
      if (props.relationship) {
        if (props.relationship.images) {
          productsImagesLoading.value = true;
          try {
            const result = await (props.instance as Instance)?.httpService?.loadImagesById(
              props.relationship.images,
            );
            if (result?.items) {
              productsImages.value = result.items;
            }
          } catch (error) {
            // Ignore error
          }
          productsImagesLoading.value = false;
        }
        if (props.relationship.filters) {
          setFilters(props.relationship.filters);
        }
      }
      if (getFiltersModelAsFilterSettings().length || filter.value) {
        isFilteringEnabled.value = true;
        isSearchEnabled.value = true;
        await load();
      } else {
        isFilteringEnabled.value =
          totalCount.value >= (filteringOptions.value?.filteringMinItems || 0) &&
          [
            PrmRelationshipTypeEnum.Accessories,
            PrmRelationshipTypeEnum.KitContents,
            PrmRelationshipTypeEnum.SparePartUsedOnList,
          ].includes(props.relationship.type || '');
        isSearchEnabled.value = totalCount.value >= (filteringOptions.value?.searchMinItems || 0);
      }
    });

    const actionTemplate = computed(() => {
      const options = props.instance?.store.options.components?.details?.relationships;
      if (options?.actionTemplates) {
        if (options.actionTemplates[props.relationship.type]) {
          return options.actionTemplates[props.relationship.type];
        }

        if (options.actionTemplates['All']) {
          return options.actionTemplates['All'];
        }
      }

      return undefined;
    });

    const highlightTerms = computed(() => {
      if (!filter.value) {
        return [];
      }

      const arr = filter.value
        .trim()
        .toLowerCase()
        .split(/[._-\s/]+/);

      arr.push(filter.value.trim());

      return arr;
    });

    return {
      prm,
      table,
      rows,
      filter,
      page,
      pageCount,
      totalCount,
      productsImages,
      productsImagesLoading,
      isLoading,
      isAutocompleteVisible,
      isAutocompleteLoading,
      autocomplete,
      search,
      filtersAll,
      filtersPromoted,
      filtersAdditional,
      filtersToDisplay,
      filtersOpen,
      filtersFetched,
      areFiltersDirty,
      filtersModel,
      isSearchEnabled,
      filteringOptions,
      isFilteringEnabled,
      allFiltersModalShown,
      actionTemplate,
      highlightTerms,
      onSearch,
      searchNow,
      toggleFilter,
      saveSettings,
      onPageChange,
      isFilterOpen,
      showAllFilters,
      isFilterLoading,
      onFetchFilterValues,
      onFilterValueChange,
      clearAndCloseFilters,
      onModalFilterValueChange,
    };
  },
});
