
import { Instance } from '@/catalogs/api/Instance';
import PIS from '@/common/api/PIS';
import Btn from '@/common/components/Btn.vue';
import FormInputClearable from '@/common/components/FormInputClearable.vue';
import FormSelectFilterable, { SelectItem } from '@/common/components/FormSelectFilterable.vue';
import FormTextAreaClearable from '@/common/components/FormTextAreaClearable.vue';
import Modal from '@/common/components/Modal.vue';
import { ValidationType, Validators } from '@/common/helpers/validation';
import { computed, defineComponent, onMounted, PropType, ref } from 'vue';
import {
  AddNotificationCommand,
  CatalogCopyCommand,
  CatalogCopyCommandArgs,
  CatalogCopyToNewCommand,
  CatalogCopyToNewCommandArgs,
  CatalogGetOptionsCommand,
  GetAllCatalogsCommand,
} from '@/catalogs/api/runtime/CommandExecutor';
import {
  CatalogItem,
  CatalogOptionsResult,
  CatalogTypeEnum,
} from '@/common/services/swagger/index.defs';
import { TranslateFunc } from '@/catalogs/composables/setupComponent';
import { NotificationType } from '@/common/api/runtime/INotification';
import { CatalogCopyActionData } from '@/catalogs/api/runtime/CatalogActionData';
import CatalogOptions from '@/catalogs/components/common/CatalogOptions.vue';

export default defineComponent({
  components: {
    Btn,
    Modal,
    FormInputClearable,
    FormSelectFilterable,
    FormTextAreaClearable,
    CatalogOptions,
  },
  props: {
    t: { type: Function as PropType<TranslateFunc>, required: true },
    instanceId: String,
    data: { type: Object as PropType<CatalogCopyActionData>, required: true },
  },
  emits: ['update:show'],
  setup(props, { emit }) {
    const newCatalogValue = 'NEW_CATALOG';
    const instance = PIS.Catalogs.getInstance(props.instanceId) as Instance;
    const show = ref(false);
    const catalogType: typeof CatalogTypeEnum = CatalogTypeEnum;
    const copyTo = ref<string | null>(null);
    const code = ref('');
    const description = ref('');
    const type = ref(CatalogTypeEnum.Personal);
    const allCatalogs = ref<CatalogItem[]>([]);
    const catalogOptions = ref<CatalogOptionsResult['items']>([]);
    const copyToOptions = computed<SelectItem[]>(() =>
      [
        { value: newCatalogValue, text: props.t('CreateNewCatalog'), type: 'fixed' } as SelectItem,
        { type: 'divider' } as SelectItem,
      ].concat(
        allCatalogs.value.reduce((result: SelectItem[], current: CatalogItem) => {
          if (current.code !== props.data.catalog.code && current.editable) {
            return result.concat([{ value: current.code, text: current.code, type: 'standard' }]);
          }
          return result;
        }, []),
      ),
    );
    const isFormValid = computed<boolean>(
      () =>
        copyTo.value !== null &&
        (copyTo.value !== newCatalogValue ||
          (copyTo.value === newCatalogValue &&
            !!code.value &&
            !!type.value &&
            !!description.value)),
    );
    const validators: Record<string, Validators> = {
      code: { [ValidationType.required]: {} },
      description: { [ValidationType.required]: {} },
    };
    const onCopyToUpdate = (newCopyToValue: string) => (copyTo.value = newCopyToValue);
    const onCodeUpdate = (newCodeValue: string) => (code.value = newCodeValue);
    const onDescriptionUpdate = (newDescriptionValue: string) =>
      (description.value = newDescriptionValue);
    const onTypeUpdated = (newType: CatalogTypeEnum) => (type.value = newType);
    const close = () => emit('update:show', false);
    const onSave = async () => {
      if (!isFormValid.value) {
        return;
      }
      try {
        if (copyTo.value === newCatalogValue) {
          const copyToNewArgs: CatalogCopyToNewCommandArgs = {
            onSuccessMessage: props.t('CatalogProductCopySuccess'),
            sourceCatalogId: props.data.catalog.id,
            code: code.value,
            type: type.value,
            description: description.value,
          };
          await instance.execute(new CatalogCopyToNewCommand(copyToNewArgs));
        } else {
          const copyArgs: CatalogCopyCommandArgs = {
            onSuccessMessage: props.t('CatalogProductCopySuccess'),
            sourceCatalogId: props.data.catalog.id,
            targetCatalogId: '',
          };
          const found: CatalogItem | undefined = allCatalogs.value.find(
            (destination: CatalogItem) => destination.code === copyTo.value,
          );
          if (!found) {
            return;
          }
          copyArgs.targetCatalogId = found.id;
          await instance.execute(new CatalogCopyCommand(copyArgs));
        }
      } catch (error) {
        // Ignore error
      }
      close();
    };

    const onAllCatalogsLoad = async () => {
      try {
        allCatalogs.value = (await instance.execute(new GetAllCatalogsCommand())) as CatalogItem[];
      } catch (error) {
        // Ignore error
      }
    };

    onMounted(async () => {
      try {
        onAllCatalogsLoad();
        const catalogOptionsResult: CatalogOptionsResult | undefined = (await instance.execute(
          new CatalogGetOptionsCommand(),
        )) as CatalogOptionsResult;
        if (!catalogOptionsResult) {
          throw new Error(props.t('CatalogOptionsLoadingError'));
        }
        catalogOptions.value = catalogOptionsResult.items;
        show.value = true;
      } catch (error) {
        await instance.execute(
          new AddNotificationCommand({ type: NotificationType.danger, message: error.message }),
        );
      }
    });

    return {
      newCatalogValue,
      show,
      code,
      type,
      copyTo,
      validators,
      catalogType,
      description,
      isFormValid,
      copyToOptions,
      catalogOptions,
      close,
      onSave,
      onCopyToUpdate,
      onCodeUpdate,
      onTypeUpdated,
      onDescriptionUpdate,
    };
  },
});
