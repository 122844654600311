import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "fw-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ItemConfiguredOption = _resolveComponent("ItemConfiguredOption")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.t('ConfiguredOptions')), 1),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.qty.configurationIds, (configurationId) => {
      return (_openBlock(), _createElementBlock("div", {
        key: configurationId.id
      }, [
        _createVNode(_component_ItemConfiguredOption, {
          "configuration-id": configurationId.id,
          "product-id": configurationId.productId,
          "instance-id": _ctx.instanceId,
          catalog: _ctx.catalog,
          qty: _ctx.qty,
          t: _ctx.t
        }, null, 8, ["configuration-id", "product-id", "instance-id", "catalog", "qty", "t"])
      ]))
    }), 128))
  ], 64))
}