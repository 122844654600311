import { reactive, Ref, ref } from 'vue';
import {
  FilterAttributeValue,
  FilterItem,
  FilterSettings,
  FilterValue,
  FilterValueItem,
  PrmRelationship,
} from '@/common/services/swagger/index.defs';

export interface FilterSettingsNamed extends FilterSettings {
  attributeName?: string;
}

export type PRMFiltersStorageData = {
  productId: string;
  relationshipCode: string;
  filter: Ref<string | undefined>;
  filtersAll: Ref<FilterItem[]>;
  filtersPromoted: Ref<FilterItem[]>;
  filtersAdditional: Ref<FilterItem[]>;
  filtersModel: Ref<Record<string, Record<string, boolean>>>;
  filtersLoading: Set<string>;
  filtersFetched: Map<string, FilterValue[]>;
  filtersOpen: Set<string>;
  saveSettings: () => void;
  setFilters: (filterItems: FilterItem[]) => void;
  setFilterValues: (filterValueItems: FilterValueItem[]) => void;
  resetFiltersApplied: () => void;
  getFiltersModelAsFilterSettings: () => FilterSettings[];
};

let saved: Record<
  string,
  {
    filter: string | undefined;
    filterModel: Record<string, Record<string, boolean>>;
    filtersFetched: Map<string, FilterValue[]>;
  }
> = {};

let savedStorageData: PRMFiltersStorageData[] = [];

const getStorageData = (
  productId: string,
  relationship: PrmRelationship,
): PRMFiltersStorageData => {
  const storageData: PRMFiltersStorageData = {
    productId,
    relationshipCode: relationship.code,
    filter: ref(undefined),
    filtersAll: ref([]),
    filtersPromoted: ref([]),
    filtersAdditional: ref([]),
    filtersModel: ref<Record<string, Record<string, boolean>>>({}),
    filtersLoading: reactive<Set<string>>(new Set()),
    filtersFetched: reactive<Map<string, FilterValue[]>>(new Map()),
    filtersOpen: reactive<Set<string>>(new Set()),
    saveSettings: (): void => {
      savedStorageData.forEach((savedData: PRMFiltersStorageData) => {
        saved[`${savedData.productId}${savedData.relationshipCode}`] = {
          filter: savedData.filter.value,
          filterModel: { ...savedData.filtersModel.value },
          filtersFetched: new Map(savedData.filtersFetched),
        };
      });
    },
    setFilters: (filterValueItems: FilterValueItem[]) => {
      storageData.filtersAll.value = [];
      storageData.filtersPromoted.value = [];
      storageData.filtersAdditional.value = [];
      filterValueItems.forEach((filterValueItem: FilterValueItem) => {
        const filterItem: FilterItem = {
          attributeCode: filterValueItem.attributeCode,
          attributeName: filterValueItem.attributeName,
          hitCount: filterValueItem.hitCount,
          isPromoted: filterValueItem.isPromoted,
        };
        storageData.filtersAll.value.push(filterItem);
        if (filterItem.isPromoted) {
          storageData.filtersPromoted.value.push(filterItem);
        } else {
          storageData.filtersAdditional.value.push(filterItem);
        }
        if (!storageData.filtersModel.value[filterItem.attributeCode]) {
          storageData.filtersModel.value[filterItem.attributeCode] = {};
        }
      });
    },
    setFilterValues: (filterValueItems: FilterValueItem[]) =>
      filterValueItems.forEach((item: FilterValueItem) => {
        storageData.filtersLoading.delete(item.attributeCode);
        storageData.filtersFetched.set(item.attributeCode, (item.values ?? []) as FilterValue[]);
      }),
    getFiltersModelAsFilterSettings: () =>
      Object.keys(storageData.filtersModel.value).reduce(
        (filterSettings: FilterSettings[], filtersModelKey: string) => {
          const filtersModelAttribute = storageData.filtersModel.value[filtersModelKey];
          const filtersFetchedByAttribute: FilterValue[] | undefined =
            storageData.filtersFetched.get(filtersModelKey);
          const values: FilterAttributeValue[] = Object.keys(filtersModelAttribute).reduce(
            (all: FilterAttributeValue[], filtersModelAttributeKey: string) => {
              if (filtersModelAttribute[filtersModelAttributeKey]) {
                const [name, code] = filtersModelAttributeKey.split(':');
                const filterAttributeValue: FilterAttributeValue = { name };
                if (filtersFetchedByAttribute) {
                  const filterFetched: FilterValue | undefined = filtersFetchedByAttribute.find(
                    (filterValue: FilterValue) =>
                      filterValue.name === name && filterValue.code === code,
                  );
                  if (filterFetched && filterFetched.code) {
                    filterAttributeValue.code = filterFetched.code;
                  }
                }
                all.push(filterAttributeValue);
              }
              return all;
            },
            [],
          );
          if (values?.length) {
            filterSettings.push({ attributeCode: filtersModelKey, values });
          }
          return filterSettings;
        },
        [],
      ),
    resetFiltersApplied: (): void => {
      storageData.filtersOpen.clear();
      storageData.filtersFetched.clear();
      Object.keys(storageData.filtersModel.value).forEach(
        (attributeCode) => (storageData.filtersModel.value[attributeCode] = {}),
      );
    },
  };
  savedStorageData.push(storageData);
  return storageData;
};

export const setupPRMFiltersStorage = (
  productId: string,
  relationship: PrmRelationship,
): PRMFiltersStorageData => {
  const storageData = getStorageData(productId, relationship);
  const savedSettings = saved[`${productId}${relationship.code}`];
  if (savedSettings) {
    storageData.filter.value = savedSettings.filter;
    storageData.filtersModel.value = savedSettings.filterModel;
    savedSettings.filtersFetched.forEach((values: FilterValue[], key: string) =>
      storageData.filtersFetched.set(key, values),
    );
  } else {
    storageData.resetFiltersApplied();
  }
  return storageData;
};

export const clearSavedData = (): void => {
  saved = {};
  savedStorageData = [];
};
