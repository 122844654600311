import {
  TranslationQuery,
  AppSettings,
  TreeTypeEnum,
  AliasSettings,
  Customer,
  ApplicationFilters,
  Criteria,
  AppSpecificSettings,
  ClientInfo,
  TranslationItem,
  IList,
  List,
  IListResult,
  ListResultDto,
  IPagedResult,
  PagedResultDto,
  Dictionary,
  IDictionary,
  IRequestOptions,
  IRequestConfig,
  getConfigs,
  axios,
  basePath
} from './index.defs';

export class TranslationsService {
  /** Generate by swagger-axios-codegen */
  // @ts-nocheck
  /* eslint-disable */

  /**
   * Retrieve translations
   */
  static translations(
    params: {
      /** requestBody */
      body?: TranslationQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TranslationItem[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/v1/Translations';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}
