import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, renderSlot as _renderSlot, toDisplayString as _toDisplayString, withCtx as _withCtx, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "position-absolute top-50 start-50 translate-middle"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Badge = _resolveComponent("Badge")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.link ? 'a' : 'button'), {
    class: _normalizeClass(["btn position-relative", [
      _ctx.lg && 'btn-lg',
      _ctx.disabled && 'disabled',
      _ctx.primary ? 'btn-primary' : 'btn-outline-primary',
    ]]),
    "aria-disabled": _ctx.disabled,
    href: _ctx.link && _ctx.href ? _ctx.href : null,
    type: _ctx.link ? null : 'submit',
    disabled: _ctx.disabled
  }, {
    default: _withCtx(() => [
      (_ctx.isLoading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createTextVNode("  "),
            _createVNode(_component_Spinner, { sm: "" }),
            _createTextVNode("  ")
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass(["d-flex align-items-center", [_ctx.isLoading && 'invisible', _ctx.vertical && 'flex-column py-2']])
      }, [
        (_ctx.icon)
          ? (_openBlock(), _createBlock(_component_Icon, {
              key: 0,
              class: _normalizeClass(["me-1", [_ctx.vertical && 'text-body']]),
              glyph: _ctx.icon,
              lg: _ctx.vertical
            }, null, 8, ["glyph", "class", "lg"]))
          : _createCommentVNode("", true),
        _renderSlot(_ctx.$slots, "default"),
        (_ctx.number)
          ? (_openBlock(), _createBlock(_component_Badge, {
              key: 1,
              class: "mt-1"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.number), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ], 2)
    ]),
    _: 3
  }, 8, ["aria-disabled", "class", "href", "type", "disabled"]))
}