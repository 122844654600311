import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "list-group list-group-flush" }
const _hoisted_2 = { class: "form-check mb-0 lh-sm" }
const _hoisted_3 = ["checked", "onInput"]
const _hoisted_4 = { class: "form-check-label user-select-none pis-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.partTypes, (partType) => {
      return (_openBlock(), _createElementBlock("label", {
        key: partType.type,
        class: "list-group-item list-group-item-action"
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("input", {
            type: "radio",
            class: "form-check-input me-2 flex-shrink-0",
            checked: _ctx.currentPartType == partType.type,
            onInput: ($event: any) => (_ctx.isChecked($event) && _ctx.$emit('click-item', partType))
          }, null, 40, _hoisted_3),
          _createElementVNode("span", _hoisted_4, _toDisplayString(partType.name), 1)
        ])
      ]))
    }), 128))
  ]))
}