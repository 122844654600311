import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
    return (_openBlock(), _createBlock(_resolveDynamicComponent('style'), {
      innerHTML: item.css ?? `@import url('${item.url}')`,
      type: "text/css",
      key: index
    }, null, 8, ["innerHTML"]))
  }), 128))
}