
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Clickable',

  props: {
    disabled: { type: Boolean, default: false },
  },
});
