import { CatalogTypeEnum } from '@/common/services/swagger/index.defs';
import { AddToCatalogCommand } from './CommandExecutor';
import { IInstance } from '@/common/api/runtime/IInstance';
import { IHelpers } from './IHelpers';

export const getHelpers = (instance: IInstance): IHelpers => ({
  addToCatalog: async (
    productId: string,
    catalogType: CatalogTypeEnum,
    uom?: string,
    catalogId?: string,
  ): Promise<unknown> =>
    instance.execute(new AddToCatalogCommand({ productId, catalogType, uom, catalogId })),
});
