import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createBlock(_component_Icon, {
    glyph: "arrow-right",
    class: "pis-arrow",
    style: _normalizeStyle({ transform: `rotate(${_ctx.angle}deg)` }),
    sm: _ctx.sm
  }, null, 8, ["style", "sm"]))
}