export enum ComponentName {
  common = 'common',
  instance = 'pis-classifications-instance',
  tree = 'pis-classifications-tree',
  layout = 'pis-classifications-layout',
  loadingIndicator = 'pis-classifications-loading-indicator',
  searchForm = 'pis-classifications-search-form',
  selection = 'pis-classifications-selection',
  treeTypeTabs = 'pis-classifications-tree-type-tabs',
  note = 'pis-classifications-note',
  actions = 'pis-classifications-actions',
  notifications = 'pis-classifications-notifications',
}
