//import { DetailDataTypeEnum, SearchDataTypeEnum } from '@/common/services/swagger/index.defs';

import { CatalogProductSearchDataTypeEnum } from '@/common/services/swagger/index.defs';

export enum InternalDataTypeEnum {
  'Catalogs' = 'Catalogs',
  'Products' = 'Products',
  'ProductPreview' = 'ProductPreview',
  'Translations' = 'Translations',
  'Notifications' = 'Notifications',
}

export const DataType = { ...CatalogProductSearchDataTypeEnum, ...InternalDataTypeEnum };
export type DataType = InternalDataTypeEnum | CatalogProductSearchDataTypeEnum;
