import {
  AutocompleteQuery,
  AppSettings,
  TreeTypeEnum,
  AliasSettings,
  Customer,
  ApplicationFilters,
  Criteria,
  AppSpecificSettings,
  ClientInfo,
  PagerSettings,
  SearchSettings,
  PartType,
  PartTypeEnum,
  FilterSettings,
  FilterAttributeValue,
  SearchTypeEnum,
  AutocompleteSearchResult,
  AutocompleteItem,
  Image,
  AutocompleteCategoryItem,
  BreadcrumbItem,
  CompareQuery,
  CompareSearchSettings,
  AttributeMergeMethodEnum,
  CompareResult,
  CompareClassificationItem,
  ClassificationItem,
  ClassificationAdditionalInfo,
  ClassificationAdditionalInfoTypeEnum,
  CompareProductDetail,
  ProductItem,
  ProductReplacement,
  Link,
  LinkTypeEnum,
  ProductAdditionalInfo,
  ProductAdditionalInfoTypeEnum,
  ProductItemClassifications,
  AttributeGroup,
  CountQuery,
  CountSearchResult,
  DetailQuery,
  ProductDetailSettings,
  DetailDataTypeEnum,
  ProductDetail,
  ProductDetailSearchResult,
  AttributeGroupSearchResult,
  ProductClassificationsSearchResult,
  PrmSearchResult,
  PrmRelationship,
  PrmRelationshipTypeEnum,
  Table,
  TableColumn,
  TableRow,
  UserField,
  FilterValueItem,
  FilterValue,
  InteractiveGuidesSearchResult,
  InteractiveGuide,
  SecurityLevelEnum,
  InteractiveGuideTypeEnum,
  RelatedLinksSearchResult,
  RelatedLink,
  RelatedProductsSearchResult,
  RelatedProduct,
  FiltersQuery,
  FilterSearchSettings,
  FilterValueSearchResult,
  ListQuery,
  ListSearchSettings,
  ProductListSearchResult,
  ProductListItem,
  PdfQuery,
  ProductSearchSettings,
  PrintResult,
  PreviewQuery,
  ProductPreviewSettings,
  ProductPreview,
  PrmFiltersQuery,
  PrmFilterSettings,
  PrmQuery,
  PrmSearchSettings,
  SearchQuery,
  SearchDataTypeEnum,
  SortSettings,
  SortFieldEnum,
  SortDirectionEnum,
  SearchResult,
  ClassificationsSearchResult,
  ProductSearchResult,
  FilterSearchResult,
  FilterItem,
  BreadcrumbsSearchResult,
  ConfiguratorItemSearchResult,
  ConfiguratorItem,
  MasterProductSearchResult,
  MasterProduct,
  PartTypesSearchResult,
  IList,
  List,
  IListResult,
  ListResultDto,
  IPagedResult,
  PagedResultDto,
  Dictionary,
  IDictionary,
  IRequestOptions,
  IRequestConfig,
  getConfigs,
  axios,
  basePath
} from './index.defs';

export class ProductsService {
  /** Generate by swagger-axios-codegen */
  // @ts-nocheck
  /* eslint-disable */

  /**
   * Autocomplete products
   */
  static autocomplete(
    params: {
      /** requestBody */
      body?: AutocompleteQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AutocompleteSearchResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/v1/Products/Autocomplete';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Compare products
   */
  static compare(
    params: {
      /** requestBody */
      body?: CompareQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CompareResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/v1/Products/Compare';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Retrieve a count
   */
  static count(
    params: {
      /** requestBody */
      body?: CountQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CountSearchResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/v1/Products/Count';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Retrieve product details
   */
  static detail(
    params: {
      /** requestBody */
      body?: DetailQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProductDetail> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/v1/Products/Detail';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Retrieve filters
   */
  static filters(
    params: {
      /** requestBody */
      body?: FiltersQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FilterValueSearchResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/v1/Products/Filters';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * List products
   */
  static list(
    params: {
      /** requestBody */
      body?: ListQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProductListSearchResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/v1/Products/List';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Download PDF file
   */
  static download(
    params: {
      /** Guid of requested file */
      guid?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/v1/Products/Pdf/Download';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { guid: params['guid'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Generate PDF file
   */
  static generate(
    params: {
      /** requestBody */
      body?: PdfQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PrintResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/v1/Products/Pdf/Generate';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Retrieve a product preview
   */
  static preview(
    params: {
      /** requestBody */
      body?: PreviewQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProductPreview> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/v1/Products/Preview';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Retrieve relationship filters
   */
  static relationshipFilters(
    params: {
      /** requestBody */
      body?: PrmFiltersQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FilterValueSearchResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/v1/Products/RelationshipFilters';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Retrieve relationships
   */
  static relationships(
    params: {
      /** requestBody */
      body?: PrmQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PrmSearchResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/v1/Products/Relationships';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Search products
   */
  static search(
    params: {
      /** requestBody */
      body?: SearchQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SearchResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/v1/Products/Search';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}
