import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, vShow as _vShow, normalizeClass as _normalizeClass, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["part"]
const _hoisted_2 = { class: "mb-3 card px-3 py-1 bg-light rounded-1" }
const _hoisted_3 = { class: "small" }
const _hoisted_4 = { class: "d-inline" }
const _hoisted_5 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InjectStyles = _resolveComponent("InjectStyles")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    ref: "root",
    class: _normalizeClass(["pis-component", _ctx.componentName]),
    part: _ctx.componentName,
    style: {"z-index":"1"}
  }, [
    (_ctx.isReady)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.isWebComponent)
            ? (_openBlock(), _createBlock(_component_InjectStyles, {
                key: 0,
                items: _ctx.store?.options.styles
              }, null, 8, ["items"]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("span", _hoisted_3, [
              _createElementVNode("strong", _hoisted_4, _toDisplayString(_ctx.t('NoteTitle')), 1),
              _createTextVNode("   "),
              _createElementVNode("span", {
                innerHTML: _ctx.t('NoteBody')
              }, null, 8, _hoisted_5)
            ])
          ])
        ], 64))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1)), [
    [_vShow, _ctx.componentOptions.visible]
  ])
}