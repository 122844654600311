import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "pis-replacements"
}
const _hoisted_2 = {
  key: 1,
  class: "fw-bold"
}
const _hoisted_3 = { class: "fw-bold" }
const _hoisted_4 = { class: "fw-bold text-nowrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!
  const _component_VRuntimeTemplate = _resolveComponent("VRuntimeTemplate")!
  const _component_PisLink = _resolveComponent("PisLink")!

  return (_ctx.replacements && _ctx.replacements.length && _ctx.visible)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.replacements, (repl, replIndex) => {
          return (_openBlock(), _createElementBlock("div", {
            key: replIndex,
            class: _normalizeClass({ 'd-flex': _ctx.showIcon })
          }, [
            (_ctx.showIcon)
              ? (_openBlock(), _createBlock(_component_icon, {
                  key: 0,
                  class: "text-muted me-2",
                  glyph: "info",
                  lg: ""
                }))
              : _createCommentVNode("", true),
            (repl.link && repl.link.type === _ctx.LinkTypeEnum.TechnicalInformation)
              ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
                  _createVNode(_component_VRuntimeTemplate, {
                    template: _ctx.getLinkTemplate(repl),
                    "template-props": { navigate: _ctx.navigate }
                  }, null, 8, ["template", "template-props"])
                ]))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                  _createElementVNode("span", _hoisted_3, _toDisplayString(repl.label), 1),
                  _createTextVNode("  "),
                  (repl.link)
                    ? (_openBlock(), _createBlock(_component_PisLink, {
                        key: 0,
                        instance: _ctx.instance,
                        link: repl.link,
                        "clear-search": true
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", _hoisted_4, _toDisplayString(repl.link.text), 1)
                        ]),
                        _: 2
                      }, 1032, ["instance", "link"]))
                    : _createCommentVNode("", true)
                ], 64))
          ], 2))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}