import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["part"]
const _hoisted_2 = { class: "mb-3" }
const _hoisted_3 = { class: "nav nav-tabs" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { key: 1 }
const _hoisted_6 = {
  key: 2,
  class: "badge rounded-pill bg-secondary text-body ms-1 small d-none d-sm-inline"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InjectStyles = _resolveComponent("InjectStyles")!
  const _component_Icon = _resolveComponent("Icon")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    ref: "root",
    class: _normalizeClass(["pis-component position-relative", _ctx.componentName]),
    part: _ctx.componentName
  }, [
    (_ctx.isReady)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.isWebComponent)
            ? (_openBlock(), _createBlock(_component_InjectStyles, {
                key: 0,
                items: _ctx.store?.options.styles
              }, null, 8, ["items"]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("ul", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.allowedModes, (mode) => {
                return (_openBlock(), _createElementBlock("li", {
                  class: "nav-item",
                  style: {"max-width":"33%"},
                  key: mode
                }, [
                  _createElementVNode("a", {
                    class: _normalizeClass(["nav-link mx-1 text-nowrap pis-text-ellipsis", _ctx.routeView === mode ? 'active' : '']),
                    href: "#",
                    onClick: _withModifiers(($event: any) => (_ctx.switchToView(mode)), ["prevent"])
                  }, [
                    (_ctx.narrowerThan('md') && mode === 'Favorites')
                      ? (_openBlock(), _createBlock(_component_Icon, {
                          key: 0,
                          class: "px-2",
                          glyph: "star",
                          sm: ""
                        }))
                      : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.t(_ctx.translationMap[mode])), 1)),
                    (_ctx.componentOptions?.countsVisible && _ctx.getCount(mode) > 0)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.getCount(mode)), 1))
                      : _createCommentVNode("", true)
                  ], 10, _hoisted_4)
                ]))
              }), 128))
            ])
          ])
        ], 64))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1)), [
    [_vShow, _ctx.visible]
  ])
}