import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["part"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InjectStyles = _resolveComponent("InjectStyles")!
  const _component_AliasFilters = _resolveComponent("AliasFilters")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "root",
    class: _normalizeClass(["pis-component", _ctx.componentName]),
    part: _ctx.componentName
  }, [
    (_ctx.isReady && _ctx.visible)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.isWebComponent)
            ? (_openBlock(), _createBlock(_component_InjectStyles, {
                key: 0,
                items: _ctx.store?.options.styles
              }, null, 8, ["items"]))
            : _createCommentVNode("", true),
          _createVNode(_component_Card, {
            class: "mb-4",
            icon: _ctx.componentOptions.icon && 'tree-view',
            header: _ctx.t('AliasFilters'),
            "use-markup": _ctx.componentOptions.card
          }, {
            list: _withCtx(() => [
              _createVNode(_component_AliasFilters)
            ]),
            _: 1
          }, 8, ["icon", "header", "use-markup"])
        ], 64))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}