import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Removable = _resolveComponent("Removable")!

  return (_openBlock(), _createBlock(_component_Removable, {
    "iteration-key": "cid",
    "naming-key": "name",
    items: _ctx.categories,
    onClickItem: _cache[0] || (_cache[0] = (item) =>_ctx.$emit('click-item', item)),
    onRemoveItem: _cache[1] || (_cache[1] = (item) =>_ctx.$emit('remove-item', item))
  }, null, 8, ["items"]))
}