import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex justify-content-start" }
const _hoisted_2 = { class: "list-unstyled mt-3" }
const _hoisted_3 = { class: "w-75" }
const _hoisted_4 = ["value"]
const _hoisted_5 = { class: "btn-group d-flex justify-content-between align-items-center" }
const _hoisted_6 = { class: "w-75" }
const _hoisted_7 = { class: "list-unstyled ms-4 mt-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Btn = _resolveComponent("Btn")!
  const _component_FormInputClearable = _resolveComponent("FormInputClearable")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    show: _ctx.show,
    title: _ctx.t('EditProductAliases'),
    "onUpdate:show": _ctx.onModalShowUpdate,
    closeOnOuterClick: false,
    lg: ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("form", {
        onSubmit: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"]))
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("strong", null, _toDisplayString(_ctx.t('Product')) + " " + _toDisplayString(_ctx.attrValue(_ctx.data.alias, '#DisplayName')), 1)
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("ul", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.aliases, (alias, index) => {
              return (_openBlock(), _createElementBlock("li", {
                key: index,
                class: "btn-group d-flex justify-content-between align-items-center mb-2"
              }, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("input", {
                    type: "text",
                    class: "form-control",
                    value: alias.value,
                    readonly: ""
                  }, null, 8, _hoisted_4)
                ]),
                _createVNode(_component_Btn, {
                  onClick: _withModifiers(($event: any) => (_ctx.onDeleteAlias(alias)), ["prevent"]),
                  secondary: ""
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t('DeleteAlias')), 1)
                  ]),
                  _: 2
                }, 1032, ["onClick"])
              ]))
            }), 128)),
            _createElementVNode("li", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_FormInputClearable, {
                  modelValue: _ctx.toBeAddedAlias,
                  "onUpdate:modelValue": _ctx.onCurrentAliasUpdate
                }, null, 8, ["modelValue", "onUpdate:modelValue"])
              ]),
              _createVNode(_component_Btn, {
                onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.onAddAlias(_ctx.toBeAddedAlias)), ["prevent"])),
                disabled: !_ctx.isFormValid,
                primary: ""
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t('AddAlias')), 1)
                ]),
                _: 1
              }, 8, ["disabled"])
            ])
          ])
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("strong", null, _toDisplayString(_ctx.currentMessage), 1),
          _createElementVNode("ul", _hoisted_7, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentErrors, (error, index) => {
              return (_openBlock(), _createElementBlock("li", {
                key: index,
                class: "d-flex justify-content-start align-items-center text-danger"
              }, [
                _createElementVNode("span", null, _toDisplayString(error.message), 1)
              ]))
            }), 128)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentWarnings, (warning, index) => {
              return (_openBlock(), _createElementBlock("li", {
                key: index,
                class: "d-flex justify-content-start align-items-center text-warning"
              }, [
                _createElementVNode("span", null, _toDisplayString(warning.message), 1)
              ]))
            }), 128))
          ])
        ])
      ], 32)
    ]),
    _: 1
  }, 8, ["show", "title", "onUpdate:show"]))
}