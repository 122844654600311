
import { defineComponent } from 'vue';
import { Table } from '@/common/services/swagger/index.defs';

export default defineComponent({
  name: 'AttributeValueTable',

  props: {
    table: { type: Object as () => Table, required: true },
  },
});
