
import { PropType, defineComponent, onMounted, ref, watch } from 'vue';
import { Instance } from '@/catalogs/api/Instance';
import {
  AddNotificationCommand,
  CatalogGetOptionsCommand,
} from '@/catalogs/api/runtime/CommandExecutor';
import { TranslateFunc } from '@/catalogs/composables/setupComponent';
import PIS from '@/common/api/PIS';
import { NotificationType } from '@/common/api/runtime/INotification';
import {
  CatalogOption,
  CatalogTypeEnum,
  CatalogOptionsResult,
} from '@/common/services/swagger/index.defs';

export default defineComponent({
  props: {
    t: { type: Function as PropType<TranslateFunc>, required: true },
    instanceId: String,
    editable: { type: Boolean, default: true },
    type: { type: String as PropType<CatalogTypeEnum>, default: CatalogTypeEnum.Personal },
  },
  emits: ['on-type-updated'],
  setup(props, { emit }) {
    const instance = PIS.Catalogs.getInstance(props.instanceId) as Instance;
    const catalogType: typeof CatalogTypeEnum = CatalogTypeEnum;
    const catalogOptions = ref<CatalogOption[]>([]);
    const ownerType = ref<CatalogTypeEnum>(props.type);
    watch(
      () => ownerType.value,
      () => emit('on-type-updated', ownerType.value),
    );
    onMounted(async () => {
      try {
        const catalogOptionsResult: CatalogOptionsResult | undefined = (await instance.execute(
          new CatalogGetOptionsCommand(),
        )) as CatalogOptionsResult;
        if (!catalogOptionsResult) {
          throw new Error(props.t('CatalogOptionsLoadingError'));
        }
        catalogOptions.value = catalogOptionsResult.items || [];
      } catch (error) {
        await instance.execute(
          new AddNotificationCommand({ type: NotificationType.danger, message: error.message }),
        );
      }
    });

    return {
      ownerType,
      catalogType,
      catalogOptions,
    };
  },
});
