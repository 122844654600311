
import { defineComponent, nextTick, onBeforeUpdate, ref, watch } from 'vue';
import { onClickOutside } from '@vueuse/core';
import _throttle from 'lodash/throttle';
import { getPathFromEvent } from '../helpers/dom';

export type InsetPosition = 0 | '100%' | 'auto';

export type Inset = {
  top: InsetPosition;
  right: InsetPosition;
  bottom: InsetPosition;
  left: InsetPosition;
};

const INSET_DEFAULTS: Inset = {
  top: '100%',
  right: 'auto',
  bottom: 'auto',
  left: 0,
};

export default defineComponent({
  props: {
    open: Boolean,
    items: Array,
    width: String,
    minWidth: String,
    maxHeight: { type: String, default: '400px' },
  },

  emits: ['update:open'],

  setup(props, { emit }) {
    const createInset = ({ top, right, bottom, left }: Inset) =>
      [top, right, bottom, left].join(' ');

    const wrapperEl = ref<HTMLElement>();
    const menuEl = ref<HTMLElement>();
    const itemElements = ref<HTMLElement[]>([]);
    const currentFocusIndex = ref<number>(0);
    const inset = ref(createInset(INSET_DEFAULTS));

    const close = () => emit('update:open', false);

    const focusItem = (index) => {
      itemElements.value[index]?.focus();
    };

    // List of events that will be attached to activator element
    const eventHandlers = {
      keyup(event: KeyboardEvent) {
        if (props.open) {
          if (['ArrowDown', 'ArrowUp'].includes(event.key)) {
            event.stopPropagation();
            focusItem(currentFocusIndex.value);
          } else if (event.key === 'Escape') {
            close();
          }
        }
      },

      focus() {
        currentFocusIndex.value = 0;
      },
    };

    const onItemEnter = (event: KeyboardEvent) => {
      const clickable: HTMLElement | undefined = getPathFromEvent<KeyboardEvent, HTMLElement>(
        event
      )[0].querySelector('button, a') as HTMLElement;
      if (clickable) {
        clickable.click();
        close();
      }
    };

    const onItemArrowDown = (event) => {
      event.preventDefault();
      if (currentFocusIndex.value < itemElements.value.length - 1) {
        currentFocusIndex.value++;
        focusItem(currentFocusIndex.value);
      }
    };

    const onItemArrowUp = (event) => {
      event.preventDefault();
      if (currentFocusIndex.value > 0) {
        currentFocusIndex.value--;
        focusItem(currentFocusIndex.value);
      }
    };

    const detectPosition = _throttle(() => {
      nextTick(() => {
        if (wrapperEl.value && menuEl.value) {
          const { bottom, right, width: wrapperWidth } = wrapperEl.value.getBoundingClientRect();
          const { height, width } = menuEl.value.getBoundingClientRect();
          const newInset = { ...INSET_DEFAULTS };

          // Too low
          if (bottom + height >= document.documentElement.clientHeight) {
            newInset.bottom = '100%';
            newInset.top = 'auto';
          }

          // Too far right
          if (right + width - wrapperWidth >= document.documentElement.clientWidth) {
            newInset.right = 0;
            newInset.left = 'auto';
          }

          inset.value = createInset(newInset);
        }
      });
    }, 200);

    watch(
      () => props.open,
      (state) => {
        if (state) {
          detectPosition();
          document.addEventListener('scroll', detectPosition);
        } else {
          document.removeEventListener('scroll', detectPosition);
        }
      }
    );

    onBeforeUpdate(() => {
      itemElements.value = [];
    });

    onClickOutside(wrapperEl, close);

    return {
      wrapperEl,
      menuEl,
      itemElements,
      eventHandlers,
      inset,
      close,
      onItemEnter,
      onItemArrowDown,
      onItemArrowUp,
    };
  },
});
