
import { defineComponent, ref } from 'vue';
import { ComponentName } from '@/catalogs/api/configuration/components/ComponentName';
import { setupComponent } from '@/catalogs/composables/setupComponent';

import InjectStyles from '@/common/components/InjectStyles.vue';
import Notifications from '@/common/components/Notifications.vue';

export default defineComponent({
  name: ComponentName.notifications,

  components: {
    InjectStyles,
    Notifications,
  },

  props: {
    instanceId: String,
  },

  setup(props) {
    const root = ref<HTMLElement>();

    const { componentName, isReady, isWebComponent, instance, store, routeData } = setupComponent(
      root,
      props.instanceId,
    );

    return {
      root,
      componentName,
      isReady,
      isWebComponent,
      store,
      routeData,
      instance,
    };
  },
});
