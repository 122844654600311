import {
  ClassificationsBrowserAppSettings,
  ClassificationsBrowserTreeViewEnum,
} from '@/common/services/swagger/index.defs';
import { IComponentOptions } from '../runtime/IComponentOptions';
import { IOptions } from '../runtime/IOptions';

export class DefaultOptions implements IOptions {
  baseUrl = process.env.VUE_APP_API_PATH;

  application: ClassificationsBrowserAppSettings = {
    appCode: '',
    langCode: 'en',
  };

  styles = [
    {
      url: `${process.env.VUE_APP_STYLE_PATH}/pis-products-theme.css?${process.env.VUE_APP_BUILD}`,
    },
    { url: `${process.env.VUE_APP_STYLE_PATH}/pis-products.css?${process.env.VUE_APP_BUILD}` },
  ];

  components: IComponentOptions = {
    searchForm: {
      visible: true,
      useAutocomplete: true,
    },
    searchToolbar: {
      applyVisible: true,
      cancelVisible: true,
      favoritesVisible: true,
      productLineTreeVisible: true,
      visible: true,
    },
    treeTypeTabs: {
      visible: true,
      countsVisible: true,
      defaultDisplayMode: ClassificationsBrowserTreeViewEnum.Offering,
      displayModes: [
        ClassificationsBrowserTreeViewEnum.Favorites,
        ClassificationsBrowserTreeViewEnum.Offering,
        ClassificationsBrowserTreeViewEnum.ProductLine,
      ],
    },

    tree: {
      visible: true,
      scrollHeight: 600,
      mobileScrollHeight: 300,
    },
    selection: {
      visible: true,
      scrollHeight: 600,
      mobileScrollHeight: 300,
    },
    note: {
      visible: true,
    },
    actions: {
      visible: true,
      applyVisible: true,
      cancelVisible: true,
    },
  };
  allowMobileView = true;
  cacheTranslations = true;
}
