
import { Instance } from '@/catalogs/api/Instance';
import PIS from '@/common/api/PIS';
import Btn from '@/common/components/Btn.vue';
import FormTextAreaClearable from '@/common/components/FormTextAreaClearable.vue';
import Modal from '@/common/components/Modal.vue';
import { ValidationType, Validators } from '@/common/helpers/validation';
import { computed, defineComponent, onMounted, PropType, ref } from 'vue';
import {
  CatalogUpdateCommand,
  CatalogMakeDefaultCommand,
  CatalogUnMakeDefaultCommand,
  AddNotificationCommand,
  CatalogGetOptionsCommand,
} from '@/catalogs/api/runtime/CommandExecutor';
import { CatalogOptionsResult, CatalogTypeEnum } from '@/common/services/swagger/index.defs';
import { TranslateFunc } from '@/catalogs/composables/setupComponent';
import { NotificationType } from '@/common/api/runtime/INotification';
import { CatalogEditActionData } from '@/catalogs/api/runtime/CatalogActionData';
import CatalogOptions from '@/catalogs/components/common/CatalogOptions.vue';

export default defineComponent({
  components: {
    Btn,
    Modal,
    CatalogOptions,
    FormTextAreaClearable,
  },
  props: {
    t: { type: Function as PropType<TranslateFunc>, required: true },
    instanceId: String,
    data: { type: Object as PropType<CatalogEditActionData>, required: true },
  },
  emits: ['update:show'],
  setup(props, { emit }) {
    const show = ref(false);
    const catalogType: typeof CatalogTypeEnum = CatalogTypeEnum;
    const instance = PIS.Catalogs.getInstance(props.instanceId) as Instance;
    const catalog = ref(props.data.catalog);
    const description = ref<string>(catalog.value.description);
    const catalogOptions = ref<CatalogOptionsResult['items']>([]);

    const isFormValid = computed<boolean>(() => !!catalog.value.code);
    const validators: Record<string, Validators> = {
      description: { [ValidationType.required]: {} },
    };
    const close = () => emit('update:show', false);
    const onDescriptionUpdate = (newDescriptionValue: string) =>
      (description.value = newDescriptionValue);
    const onSave = async () => {
      if (!isFormValid.value) {
        return;
      }
      try {
        await instance.execute(
          new CatalogUpdateCommand({
            catalogId: catalog.value.id,
            description: description.value,
            onSuccessMessage: props.t('CatalogEditSuccess'),
          }),
        );
      } catch (error) {
        // Ignore Error
      }
      close();
    };
    const onMakeDefault = async () => {
      if (!catalog.value) {
        return;
      }
      try {
        await instance.execute(
          new CatalogMakeDefaultCommand({
            catalogId: catalog.value.id,
            onSuccessMessage: props.t('CatalogSetAsDefaultSuccess'),
          }),
        );
      } catch (error) {
        // Ignore Error
      }
      close();
    };
    const onUnMakeDefault = async () => {
      if (!catalog.value) {
        return;
      }
      try {
        await instance.execute(
          new CatalogUnMakeDefaultCommand({
            catalogId: catalog.value.id,
            onSuccessMessage: props.t('CatalogUnsetAsDefaultSuccess'),
          }),
        );
      } catch (error) {
        // Ignore Error
      }
      close();
    };

    onMounted(async () => {
      try {
        const catalogOptionsResult: CatalogOptionsResult | undefined = (await instance.execute(
          new CatalogGetOptionsCommand(),
        )) as CatalogOptionsResult;
        if (!catalogOptionsResult) {
          throw new Error(props.t('CatalogOptionsLoadingError'));
        }
        catalogOptions.value = catalogOptionsResult.items;
        show.value = true;
      } catch (error) {
        await instance.execute(
          new AddNotificationCommand({ type: NotificationType.danger, message: error.message }),
        );
      }
    });

    return {
      show,
      catalog,
      validators,
      description,
      catalogType,
      isFormValid,
      catalogOptions,
      close,
      onSave,
      onMakeDefault,
      onUnMakeDefault,
      onDescriptionUpdate,
    };
  },
});
