
import {
  CatalogDeleteCommand,
  CatalogActionCompleted,
} from '@/catalogs/api/runtime/CommandExecutor';
import { defineComponent, computed, ref } from 'vue';
import Confirmation from '@/common/components/Confirmation.vue';
import { ComponentName } from '@/catalogs/api/configuration/components/ComponentName';
import CatalogEditModal from '@/catalogs/components/catalogs/CatalogEditModal.vue';
import CatalogCopyModal from '@/catalogs/components/catalogs/CatalogCopyModal.vue';
import CatalogImportModal from '@/catalogs/components/catalogs/CatalogImportModal.vue';
import CatalogExportModal from '@/catalogs/components/catalogs/CatalogExportModal.vue';
import CatalogHistoryModal from '@/catalogs/components/catalogs/CatalogHistoryModal.vue';
import CatalogCreateNewModal from '@/catalogs/components/catalogs/CatalogCreateNewModal.vue';
import CatalogActionErrors from '@/catalogs/components/common/CatalogActionErrors.vue';
import { setupComponent } from '@/catalogs/composables/setupComponent';
import InjectStyles from '@/common/components/InjectStyles.vue';
import { CatalogAction } from '@/catalogs/api/runtime/CatalogAction';
import { Catalog } from '@/common/services/swagger/index.defs';

// Used in template
/* eslint-disable */
import {
  CatalogEditActionData,
  CatalogCopyActionData,
  CatalogDeleteActionData,
  CatalogImportActionData,
  CatalogExportActionData,
  CatalogErrorsActionData,
} from '@/catalogs/api/runtime/CatalogActionData';
/* eslint-enable */

export default defineComponent({
  name: ComponentName.catalogsActionModals,
  components: {
    InjectStyles,
    Confirmation,
    CatalogEditModal,
    CatalogCopyModal,
    CatalogImportModal,
    CatalogExportModal,
    CatalogHistoryModal,
    CatalogActionErrors,
    CatalogCreateNewModal,
  },
  props: {
    instanceId: String,
  },
  setup(props) {
    const root = ref<HTMLElement>();
    const { componentName, instance, isReady, isWebComponent, store, t } = setupComponent(
      root,
      props.instanceId,
    );
    const actions = computed(() => instance.value?.store.data.actions);
    const currentActionName = computed(() => actions.value?.current);
    const currentActionData = computed(() => actions.value?.data);
    const onActionCompleted = async () => {
      try {
        instance.value?.execute(new CatalogActionCompleted());
      } catch (error) {
        // Ignore error
      }
    };

    const onCatalogDelete = async () => {
      const currentActions = actions.value;
      if (!currentActions?.current || currentActions.current !== CatalogAction.delete) {
        return;
      }
      const catalogForDeletion: Catalog = (currentActions.data as CatalogDeleteActionData)?.catalog;
      if (!catalogForDeletion) {
        return;
      }
      try {
        await instance.value?.execute(
          new CatalogDeleteCommand({
            catalogId: catalogForDeletion.id,
            onSuccessMessage: t('CatalogDeleteSuccess'),
          }),
        );
      } catch (error) {
        // Ignore Error
      }
      await onActionCompleted();
    };

    return {
      root,
      store,
      isReady,
      componentName,
      CatalogAction,
      isWebComponent,
      currentActionName,
      currentActionData,
      t,
      onCatalogDelete,
      onActionCompleted,
    };
  },
});
