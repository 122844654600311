
import { defineComponent, ref } from 'vue';
import { ICategoryGroup } from './ICategoryGroup';
import Dropdown from '@/common/components/Dropdown.vue';
import Clickable from '@/common/components/Clickable.vue';

import { CompareClassificationItem } from '@/common/services/swagger/index.defs';

export default defineComponent({
  components: { Dropdown, Clickable },
  props: {
    cat: {
      type: Object as () => ICategoryGroup,
      required: true,
    },
    selectedCid: {
      type: String,
      required: false,
    },
  },
  emits: ['select'],
  setup() {
    const open = ref(false);
    const getName = (item: CompareClassificationItem) => {
      const names = item.breadcrumb?.map((a) => a.name);
      return names?.join(' > ');
    };
    return { open, getName };
  },
});
