import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Clickable = _resolveComponent("Clickable")!

  return (_openBlock(), _createBlock(_component_Clickable, { class: "d-flex align-items-center small text-decoration-none" }, {
    default: _withCtx(() => [
      (_ctx.icon)
        ? (_openBlock(), _createBlock(_component_Icon, {
            key: 0,
            glyph: _ctx.icon,
            class: "me-2"
          }, null, 8, ["glyph"]))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }))
}