import ClassificationsInstance from '@/classifications/components/ClassificationsInstance.vue';
import ClassificationsLayout from '@/classifications/components/ClassificationsLayout.vue';
import ClassificationsTree from '@/classifications/components/browser/ClassificationsTree.vue';
import ClassificationsSearchForm from '@/classifications/components/browser/ClassificationsSearchForm.vue';
import ClassificationsSelection from '@/classifications/components/browser/ClassificationsSelection.vue';
import ClassificationsTreeTabs from '@/classifications/components/browser/ClassificationsTreeTabs.vue';
import ClassificationNote from '@/classifications/components/browser/ClassificationNote.vue';
import ClassificationActions from '@/classifications/components/browser/ClassificationActions.vue';
import ClassificationsNotifications from './common/ClassificationsNotifications.vue';

export default [
  ClassificationsInstance,
  ClassificationsLayout,
  ClassificationsTree,
  ClassificationsSearchForm,
  ClassificationsSelection,
  ClassificationsTreeTabs,
  ClassificationNote,
  ClassificationActions,
  ClassificationsNotifications,
];
