
import { computed, defineComponent, ref, PropType } from 'vue';
import {
  ClassificationNode,
  ClassificationNodesSearchResult,
} from '@/common/services/swagger/index.defs';
import {
  AddFavoriteCategoryCommand,
  ClassificationCommand,
  GetClassificationsNodesCommand,
  RemoveFavoriteCategoryCommand,
} from '@/products/api/runtime/CommandExecutor';
import { IFavoriteCategoriesOptions } from '@/products/api/configuration/components/IFavoriteCategoriesOptions';
import { AppSettings, TreeTypeEnum } from '@/common/services/swagger/index.defs';
import _sortBy from 'lodash/sortBy';
import { TranslateFunc } from '@/products/composables/setupComponent';
import { Instance } from '@/products/api/Instance';

import Modal from '@/common/components/Modal.vue';
import LinkSecondary from '@/common/components/LinkSecondary.vue';
import FavoriteCategoriesList from '@/common/components/FavoriteCategoriesList.vue';
import Card from '@/common/components/Card.vue';
import Message from '@/common/components/Message.vue';
import Arrow from '@/common/components/Arrow.vue';
import Icon from '@/common/components/Icon.vue';
import PIS from '../api/PIS';
import { isEmptyCID } from '../helpers/isEmptyCID';

export default defineComponent({
  components: {
    LinkSecondary,
    Card,
    FavoriteCategoriesList,
    Message,
    Arrow,
    Icon,
    Modal,
  },

  props: {
    isMobile: Boolean,
    instanceId: String,
    t: { type: Function as PropType<TranslateFunc>, required: true },
    mobileShow: Boolean,
  },

  setup(props) {
    const root = ref<HTMLElement>();
    const trimList = ref(true);
    const instance = PIS.Products.getInstance(props.instanceId) as Instance;

    const appOptions = computed(() => instance.store?.options.application ?? ({} as AppSettings));

    const componentOptions = computed(
      () =>
        instance.store?.options.components?.favoriteCategories ??
        ({} as IFavoriteCategoriesOptions),
    );

    const cids = computed(() =>
      appOptions.value.treeType === TreeTypeEnum.Products
        ? componentOptions.value.productCids
        : componentOptions.value.partCids,
    );

    const categories = computed(() =>
      appOptions.value.treeType === TreeTypeEnum.Products
        ? instance.store?.data.favoriteProductCategories?.items ?? []
        : instance.store?.data.favoritePartCategories?.items ?? [],
    );

    const visible = computed(() => instance.store?.options.components?.favoriteCategories?.visible);

    const categoriesTrimmed = computed(() =>
      trimList.value
        ? categories.value?.slice(0, componentOptions.value.maxCategoriesVisible)
        : categories.value,
    );

    const showMoreBtn = computed(
      () =>
        categories.value &&
        categories.value.length > (componentOptions.value.maxCategoriesVisible ?? 0),
    );

    const setCategory = async ({ cid }: ClassificationNode) => {
      if (instance && cid) {
        try {
          await instance.execute(new ClassificationCommand({ cid, clearSearch: true }));
        } catch (error) {
          // Ignore Error
        }
      }
    };

    const removeCategory = async ({ cid }: ClassificationNode) => {
      if (instance && cid) {
        try {
          await instance.execute(new RemoveFavoriteCategoryCommand({ cid }));
        } catch (error) {
          // Ignore Error
        }
      }
    };

    const showAllCategories = () => {
      trimList.value = !trimList.value;
    };

    const showAddBtn = computed(
      () =>
        instance.router.routeData?.cid &&
        !isEmptyCID(instance.router.routeData?.cid) &&
        ((cids.value && cids.value.indexOf(instance.router.routeData.cid) < 0) || !cids.value),
    );

    const showRemoveBtn = computed(
      () =>
        cids.value &&
        instance.router.routeData?.cid &&
        cids.value.indexOf(instance.router.routeData.cid) >= 0,
    );

    const addCurrentCategory = async () => {
      if (instance && instance.router.routeData.cid) {
        try {
          await instance.execute(
            new AddFavoriteCategoryCommand({
              cid: instance.router.routeData.cid,
            }),
          );
          await updateCategories();
        } catch (error) {
          // Ignore Error
        }
      }
    };

    const removeCurrentCategory = async () => {
      if (instance && instance.router.routeData?.cid) {
        try {
          await instance.execute(
            new RemoveFavoriteCategoryCommand({ cid: instance.router.routeData.cid }),
          );
        } catch (error) {
          // Ignore Error
        }
      }
    };

    const updateCategories = async () => {
      if (cids.value) {
        const command = new GetClassificationsNodesCommand({
          cids: cids.value,
        });
        try {
          const result = (await instance.execute(command)) as ClassificationNodesSearchResult;
          if (result) {
            if (result.items) result.items = _sortBy(result.items, (x) => x.name);
            if (appOptions.value.treeType === TreeTypeEnum.Products && instance.store?.data)
              instance.store.data.favoriteProductCategories = result;
            else if (appOptions.value.treeType === TreeTypeEnum.Parts && instance.store?.data)
              instance.store.data.favoritePartCategories = result;
          }
        } catch (error) {
          return;
        }
      } else {
        if (
          appOptions.value.treeType === TreeTypeEnum.Products &&
          instance.store?.data?.favoriteProductCategories?.items
        )
          instance.store.data.favoriteProductCategories.items = [];
        else if (
          appOptions.value.treeType === TreeTypeEnum.Parts &&
          instance.store?.data?.favoritePartCategories?.items
        )
          instance.store.data.favoritePartCategories.items = [];
      }
    };

    updateCategories();

    return {
      root,
      componentOptions,
      trimList,
      visible,
      categories,
      categoriesTrimmed,
      showMoreBtn,
      setCategory,
      removeCategory,
      showAllCategories,
      addCurrentCategory,
      removeCurrentCategory,
      showAddBtn,
      showRemoveBtn,
    };
  },
});
