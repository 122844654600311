import {
  AppSettings,
  serviceOptions,
  TranslationItem,
  ClassificationsBrowserAppSettings,
  ClassificationsBrowserNode,
  ClassificationsBrowserValidateNode,
  ClassificationsBrowserAutocompleteResult,
} from '@/common/services/swagger/index.defs';
import axios from 'axios';
import { reactive } from 'vue';
import { DataType } from '../configuration/application/DataType';
import { Instance } from '../Instance';
import { IErrorEvent, ILoadEvent } from './ILoadEvent';
import { IRouteData } from './IRouteData';
import { StoreService } from './StoreService';
import { IHttpService } from '@/common/api/runtime/IHttpService';
import { InstanceType } from '@/common/api/runtime/IInstance';
import { NotificationType } from '@/common/api/runtime/INotification';
import { TranslationsService } from '@/common/services/swagger/TranslationsService';
import { ClassificationsBrowserService } from '@/common/services/swagger/ClassificationsBrowserService';

export const SESSION_TRANSLATIONS_KEY = 'pis-classifications-translations';

export class HttpService implements IHttpService {
  instanceType: InstanceType = 'Classifications';
  private storeService: StoreService;
  private axiosInstance;
  constructor(private instance: Instance, baseURL?: string, accessToken?: string) {
    this.axiosInstance = axios.create({
      baseURL,
      timeout: 60000,
    });

    this.axiosInstance.interceptors.request.use(
      (config) => {
        if (config && config.headers) {
          const currentAccessToken = instance.store?.options?.accessToken;
          config.headers['Authorization'] = `Bearer ${currentAccessToken || accessToken}`;

          if (window['PIS'] && window['PIS']['version']) {
            config.headers['Component-Version'] = window['PIS']['version'];
          }

          if (instance.props['dashboard'] === true) {
            config.headers['dashboard'] = 'true';
          }
        }
        return config;
      },
      (error) => {
        // Do something with request error
        return Promise.reject(error);
      },
    );

    this.axiosInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error?.message == 'canceled') {
          return Promise.reject(error);
        }

        const errorData = error?.response?.data;

        const errorEvent: IErrorEvent = {
          type: 'ServerError',
          message: error.message,
          isHandled: false,
        };

        if (errorData) {
          errorEvent.message = errorData.Message;
          errorEvent.type = errorData.Type;
          errorEvent.traceId = errorData.TraceId;
        }

        if (instance.store.options.errorInterceptor) {
          const result = instance.store.options.errorInterceptor(errorEvent);
          if (!result.isHandled) {
            instance.store.notifications.push({
              type: NotificationType.danger,
              message: result.message || 'Unknown error',
            });
          }
        } else {
          instance.store.notifications.push({
            type: NotificationType.danger,
            message: errorEvent.message || 'Unknown error',
          });
        }

        return Promise.reject(error);
      },
    );

    serviceOptions.axios = this.axiosInstance;

    this.storeService = new StoreService(instance);
  }

  private setAxiosInstance() {
    serviceOptions.axios = this.axiosInstance;
  }

  public async load(newRoute?: IRouteData): Promise<void> {
    this.setAxiosInstance();
    if (!this.instance.router) return;

    const route = newRoute || this.instance.router.routeData;

    return await this.search(route);
  }

  public async search(route: IRouteData): Promise<void> {
    this.setAxiosInstance();
    this.instance.store.data.isLoadingData = true;
    try {
      const result = await ClassificationsBrowserService.search({
        body: {
          appSettings: this.instance.store?.options
            .application as ClassificationsBrowserAppSettings,

          search: {
            treeView: route.view,
            searchText: route.searchText ?? undefined,
            selectedCids: this.instance.selection?.selectedItems?.map((a) => a.cid) || [],
            favoriteCids: this.instance.favorites?.selectedItems?.map((a) => a.cid) || [],
          },
        },
      });

      this.instance.eventBus.emit('search-loaded', result);

      if (result) {
        this.setExpanded(result.items);

        this.storeService.setData(DataType.Classifications, result);
      }

      this.instance.store.data.isLoadingData = false;
      this.instance.eventBus.emit('search-changed');
    } catch (e) {
      this.instance.store.data.isLoadingData = false;
      throw e;
    }
  }

  public async autocomplete(searchText: string): Promise<ClassificationsBrowserAutocompleteResult> {
    this.setAxiosInstance();
    const routeData = this.instance.router.routeData;
    return ClassificationsBrowserService.autocomplete({
      body: {
        appSettings: this.instance.store?.options.application as ClassificationsBrowserAppSettings,
        search: {
          searchText,
          treeView: routeData.view,
          selectedCids: this.instance.selection?.selectedItems?.map((a) => a.cid) || [],
          favoriteCids: this.instance.favorites?.selectedItems?.map((a) => a.cid) || [],
        },
      },
    });
  }

  public async loadChildren(item: ClassificationsBrowserNode) {
    this.setAxiosInstance();

    const result = await ClassificationsBrowserService.children({
      body: {
        appSettings: this.instance.store?.options.application as ClassificationsBrowserAppSettings,
        search: {
          treeType: item.tree,
          selectedCids: this.instance.selection?.selectedItems?.map((a) => a.cid) || [],
          cid: item.cid,
        },
      },
    });

    if (result?.items) {
      item.children = result.items;

      // we want interceptor to fire with updated tree
      this.storeService.setData(DataType.Classifications, this.instance.store.data.classifications);
    }

    return result.items;
  }

  setExpanded = (nodes?: ClassificationsBrowserNode[]) => {
    nodes?.forEach((i) => {
      if (i.children?.length) {
        i['_isExpanded'] = true;

        this.setExpanded(i.children);
      }
    });
  };

  public async validate(nodes: ClassificationsBrowserValidateNode[]) {
    this.setAxiosInstance();
    const result = await ClassificationsBrowserService.validate({
      body: {
        appSettings: this.instance.store?.options.application as ClassificationsBrowserAppSettings,
        search: {
          nodes: nodes,
        },
      },
    });

    return result;
  }

  public async loadTranslations(): Promise<TranslationItem[] | undefined> {
    this.setAxiosInstance();
    const useCache = this.instance.store.options.cacheTranslations;
    const lang = this.instance.store.options.application?.langCode ?? 'en';
    const key = SESSION_TRANSLATIONS_KEY + '-' + lang;

    let data: TranslationItem[] | undefined;

    if (useCache) {
      try {
        const json = window.sessionStorage.getItem(key);
        if (json) {
          data = JSON.parse(json);
        }
      } catch (error) {
        // ignore
      }
    }

    if (!data) {
      data = await TranslationsService.translations({
        body: {
          appSettings: this.instance.store?.options.application as AppSettings,
          components: [],
        },
      });

      if (useCache) {
        try {
          window.sessionStorage.setItem(key, JSON.stringify(data));
        } catch (error) {
          // ignore
        }
      }
    }

    const interceptFunc = this.instance.store?.options.dataInterceptor;

    const loadEvent: ILoadEvent = {
      type: DataType.Translations,
      data: reactive({ items: data }),
      instance: this.instance,
    };

    if (interceptFunc) {
      const inc = await interceptFunc(loadEvent);
      return inc.data.items;
    } else {
      return data;
    }
  }
}
