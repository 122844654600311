
import { Ref, computed, defineComponent, ref } from 'vue';
import VRuntimeTemplate from 'vue3-runtime-template';
import { attrValue, attrValues, additionalInfo } from '@/common/helpers/productAttributes';
import { DataType } from '@/catalogs/api/configuration/application/DataType';
import { ComponentName } from '@/catalogs/api/configuration/components/ComponentName';

import { setupComponent } from '@/catalogs/composables/setupComponent';

import InjectStyles from '@/common/components/InjectStyles.vue';
import Clickable from '@/common/components/Clickable.vue';
import Tooltip from '@/common/components/Tooltip.vue';
import Icon from '@/common/components/Icon.vue';
import { CatalogItem, CatalogSortFieldEnum } from '@/common/services/swagger/index.defs';
import { ICatalogsTableOptions } from '@/catalogs/api/configuration/components/ICatalogsTableOptions';
import DropdownPopper from '@/common/components/DropdownPopper.vue';
import {
  CatalogProductsCommand,
  CatalogsSortByCommand,
  CatalogsPageSizeCommand,
} from '@/catalogs/api/runtime/CommandExecutor';
import useCatalogActions from '@/catalogs/composables/useCatalogActions';
import { Instance } from '@/catalogs/api/Instance';
import { IMenuItem } from '@/catalogs/api/configuration/components/IExportMenuOptions';

export default defineComponent({
  name: ComponentName.searchTable,

  components: {
    Icon,
    Tooltip,
    Clickable,
    InjectStyles,
    DropdownPopper,
    VRuntimeTemplate,
  },

  props: {
    instanceId: String,
  },

  setup(props) {
    const root = ref<HTMLElement>();

    const { componentName, isReady, isWebComponent, instance, store, routeData, emit, t } =
      setupComponent(root, props.instanceId, [DataType.Products]);

    const { onCatalogItemMenuAction } = useCatalogActions(instance as Ref<Instance>, t);

    const catalogItems = computed(() => store.value?.data.catalogs?.items);

    const previewEnabled = computed(
      () => instance.value?.registeredComponents.has(ComponentName.preview) ?? false,
    );

    const sortByItems = CatalogSortFieldEnum;

    const componentOptions = computed(
      () => store.value?.options.components?.catalogsTable ?? ({} as ICatalogsTableOptions),
    );

    const columns = computed(() => {
      return componentOptions.value.columns;
    });

    const details = async (catalogId: string) => {
      if (routeData.value && catalogId) {
        try {
          await instance.value?.execute(new CatalogProductsCommand({ catalogId }));
        } catch (error) {
          // Ignore error
        }
      }
    };

    const pagination = computed(() => routeData.value?.catalogs.pagination);
    const pageSizeItems = computed(() => store.value?.options.components?.searchToolbar?.pageSizes);
    const pageSize = computed({
      get: () => pagination.value?.pageSize ?? 24,
      set: (pageSize: number) => instance.value?.execute(new CatalogsPageSizeCommand({ pageSize })),
    });
    const sortBy = computed({
      get: () => routeData.value?.catalogs.sortField as CatalogSortFieldEnum,
      set: (sortField: CatalogSortFieldEnum) =>
        instance.value?.execute(new CatalogsSortByCommand({ sortField })),
    });

    const catalogItemMenu = computed(
      () => store.value?.options.components?.actions?.catalogItemMenu,
    );

    const catalogItemMenuItems = (catalog: CatalogItem): IMenuItem<CatalogItem>[] =>
      (catalogItemMenu.value?.items || []).filter((item: IMenuItem<CatalogItem>) => {
        if (typeof item.visible === 'function') {
          return item.visible(catalog);
        }
        return item.visible;
      });

    const isDisabled = (item: IMenuItem<CatalogItem>, catalog: CatalogItem) =>
      typeof item.disabled === 'function' ? item.disabled(catalog) : item.disabled;

    const actionsEnabled = computed(
      () =>
        instance.value?.registeredComponents.has(ComponentName.catalogsActionModals) &&
        catalogItemMenu.value?.visible,
    );

    return {
      root,
      store,
      sortBy,
      isReady,
      columns,
      pageSize,
      instance,
      routeData,
      sortByItems,
      catalogItems,
      componentName,
      pageSizeItems,
      isWebComponent,
      previewEnabled,
      actionsEnabled,
      catalogItemMenu,
      componentOptions,
      t,
      emit,
      details,
      attrValue,
      attrValues,
      isDisabled,
      additionalInfo,
      catalogItemMenuItems,
      onCatalogItemMenuAction,
    };
  },
});
