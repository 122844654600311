import {
  AliasAddAction,
  AliasAddItem,
  CatalogAppSettings,
  ClientInfo,
  Catalog,
  CatalogTypeEnum,
  Owner,
  OwnerTypeEnum,
  AliasActionResult,
  Alias,
  ActionInfo,
  AliasDeleteAction,
  AliasDeleteItem,
  ActionResult,
  AliasExportAction,
  ExportActionResult,
  AliasImportAction,
  AliasListQuery,
  ListSearchSettings,
  AliasListSearchResult,
  AliasItem,
  IList,
  List,
  IListResult,
  ListResultDto,
  IPagedResult,
  PagedResultDto,
  Dictionary,
  IDictionary,
  IRequestOptions,
  IRequestConfig,
  getConfigs,
  axios,
  basePath
} from './index.defs';

export class CatalogsAliasesService {
  /** Generate by swagger-axios-codegen */
  // @ts-nocheck
  /* eslint-disable */

  /**
   * Create aliases
   */
  static add(
    params: {
      /** requestBody */
      body?: AliasAddAction;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AliasActionResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/v1/Catalogs/Aliases/Add';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Delete aliases
   */
  static delete(
    params: {
      /** requestBody */
      body?: AliasDeleteAction;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ActionResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/v1/Catalogs/Aliases/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Download Excel file
   */
  static download(
    params: {
      /** Guid of requested file */
      guid?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/v1/Catalogs/Aliases/Download';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { guid: params['guid'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Export aliases
   */
  static export(
    params: {
      /** requestBody */
      body?: AliasExportAction;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExportActionResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/v1/Catalogs/Aliases/Export';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Import aliases
   */
  static import(
    params: {
      /** requestBody */
      body?: AliasImportAction;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ActionResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/v1/Catalogs/Aliases/Import';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * List aliases
   */
  static list(
    params: {
      /** requestBody */
      body?: AliasListQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AliasListSearchResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/v1/Catalogs/Aliases/List';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}
