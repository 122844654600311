
import { computed, defineComponent, ref } from 'vue';
import { ComponentName } from '@/products/api/configuration/components/ComponentName';
import { setupComponent } from '@/products/composables/setupComponent';

import InjectStyles from '@/common/components/InjectStyles.vue';
import PisReplacements from '@/common/components/PisReplacements.vue';
export default defineComponent({
  name: ComponentName.detailsReplacements,

  components: {
    InjectStyles,
    PisReplacements,
  },

  props: {
    instanceId: String,
  },

  setup(props) {
    const root = ref<HTMLElement>();

    const { instance, componentName, isReady, isWebComponent, store, shadowRoot, t } =
      setupComponent(root, props.instanceId);
    const item = computed(() => store.value?.data.productDetail?.item);

    const replacementsVisible = computed(
      () => store.value?.options.components?.common?.hideReplacements !== true,
    );
    return {
      root,
      componentName,
      isReady,
      isWebComponent,
      store,
      instance,
      item,
      shadowRoot,
      replacementsVisible,
      t,
    };
  },
});
