import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["part"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InjectStyles = _resolveComponent("InjectStyles")!
  const _component_VRuntimeTemplate = _resolveComponent("VRuntimeTemplate")!
  const _component_ImageGallery = _resolveComponent("ImageGallery")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "root",
    class: _normalizeClass(["pis-component", _ctx.componentName]),
    part: _ctx.componentName
  }, [
    (_ctx.isReady && _ctx.visible)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.isWebComponent)
            ? (_openBlock(), _createBlock(_component_InjectStyles, {
                key: 0,
                items: _ctx.store?.options.styles
              }, null, 8, ["items"]))
            : _createCommentVNode("", true),
          _createVNode(_component_ImageGallery, {
            "show-preview": _ctx.showPreview,
            images: _ctx.productDetail?.item?.images,
            "hide-thumbnails": _ctx.componentOptions.hideThumbnailImages,
            "show-overlay-arrows": _ctx.componentOptions.showOverlayArrows,
            title: _ctx.t('ProductGallery')
          }, {
            default: _withCtx(() => [
              (_ctx.componentOptions.imageOverlayTemplate)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createVNode(_component_VRuntimeTemplate, {
                      template: _ctx.componentOptions.imageOverlayTemplate,
                      "template-props": { instance: _ctx.instance, item: _ctx.productDetail?.item, emit: _ctx.emit, t: _ctx.t }
                    }, null, 8, ["template", "template-props"])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["show-preview", "images", "hide-thumbnails", "show-overlay-arrows", "title"])
        ]))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}