import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "pis-tree ps-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ClassificationNode = _resolveComponent("ClassificationNode")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
      return (_openBlock(), _createBlock(_component_ClassificationNode, {
        key: item.cid,
        item: item,
        "instance-id": _ctx.instanceId,
        t: _ctx.t
      }, null, 8, ["item", "instance-id", "t"]))
    }), 128))
  ]))
}