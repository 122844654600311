
import { computed, defineComponent, ref } from 'vue';
import { ComponentName } from '@/products/api/configuration/components/ComponentName';
import { setupComponent } from '@/products/composables/setupComponent';
import { DataType } from '@/products/api/configuration/application/DataType';
import { ClassificationCommand } from '../api/runtime/CommandExecutor';
import { BreadcrumbItem, ClassificationItem } from '@/common/services/swagger/index.defs';

import InjectStyles from '@/common/components/InjectStyles.vue';
import Arrow from '@/common/components/Arrow.vue';
import Clickable from '@/common/components/Clickable.vue';

export default defineComponent({
  name: ComponentName.breadcrumbs,

  components: {
    InjectStyles,
    Arrow,
    Clickable,
  },

  props: {
    instanceId: String,
  },

  setup(props) {
    const root = ref<HTMLElement>();

    const { componentName, isReady, isWebComponent, instance, store, routeData } = setupComponent(
      root,
      props.instanceId,
      [DataType.Breadcrumbs]
    );

    const breadcrumbs = computed(() => (store.value ? store.value?.data.breadcrumbs?.items : null));

    const isDetailsView = computed(() => routeData.value?.view === 'detail');

    const setClassification = async ({ cid }: ClassificationItem | BreadcrumbItem) => {
      if (instance.value && cid) {
        try {
          await instance.value.execute(new ClassificationCommand({ cid, clearSearch: true }));
        } catch (error) {
          // Ignore Error
        }
      }
    };

    const visible = computed(() => store.value?.options.components?.breadcrumbs?.visible);

    return {
      root,
      componentName,
      isReady,
      isWebComponent,
      store,
      breadcrumbs,
      isDetailsView,
      visible,
      setClassification,
    };
  },
});
