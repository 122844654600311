
import { computed, defineComponent, ref } from 'vue';
import { ComponentName } from '@/catalogs/api/configuration/components/ComponentName';
import { DataType } from '@/catalogs/api/configuration/application/DataType';
import { setupComponent } from '@/catalogs/composables/setupComponent';

import InjectStyles from '@/common/components/InjectStyles.vue';
import Badge from '@/common/components/Badge.vue';
import LinkSecondary from '@/common/components/LinkSecondary.vue';
import { IBaseComponentOptions } from '@/products/api/configuration/components/IBaseComponentOptions';

const BADGE_TEXT_MAX_LEN = 20;

export default defineComponent({
  name: ComponentName.searchFiltersStatus,

  components: {
    InjectStyles,
    Badge,
    LinkSecondary,
  },

  props: {
    instanceId: String,
  },

  setup(props) {
    const root = ref<HTMLElement>();

    const { componentName, isReady, isWebComponent, store, t, useProductsFilters } = setupComponent(
      root,
      props.instanceId,
      [DataType.Filters],
    );

    const { filtersApplied, excludeFilter, resetFiltersApplied } = useProductsFilters();

    const componentOptions = computed(
      () => store.value?.options.components?.searchFiltersStatus ?? ({} as IBaseComponentOptions),
    );

    const resetAll = () => {
      if (filtersApplied.value.length) {
        resetFiltersApplied();
      }
    };

    const truncateText = (text: string) => {
      return text.length > BADGE_TEXT_MAX_LEN
        ? text.substring(0, BADGE_TEXT_MAX_LEN) + '...'
        : text;
    };

    return {
      root,
      componentName,
      isReady,
      isWebComponent,
      store,
      filtersApplied,
      componentOptions,
      t,
      excludeFilter,
      resetAll,
      truncateText,
    };
  },
});
