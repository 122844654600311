import {
  PartTypeEnum,
  SearchTypeEnum,
  SortDirectionEnum,
  SortFieldEnum,
} from '@/common/services/swagger/index.defs';
import { DisplayMode, IRouteData } from '../runtime/IRouteData';

export const defaultRoute: IRouteData = {
  view: 'search',
  searchText: undefined,
  searchType: SearchTypeEnum.WithAllWords,
  cid: undefined,
  filters: [],
  pagination: { page: 1, pageSize: 24 },
  productId: undefined,
  displayMode: DisplayMode.table,
  sortField: SortFieldEnum.Product,
  sortDirection: SortDirectionEnum.Ascending,
  partsType: PartTypeEnum.All,
};
