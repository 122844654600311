import _cloneDeep from 'lodash/cloneDeep';
import _mergeWith from 'lodash/mergeWith';
import _isArray from 'lodash/isArray';
import _isString from 'lodash/isString';
import {
  ILegacyEventArgs,
  ILegacyEventArgsNode,
  ILegacyOptions,
  LegacyEventType,
  LegacyTreeCode,
} from './SalesforceLegacy';
import { IOptions } from '../runtime/IOptions';
import { IRouteData } from '../runtime/IRouteData';
import {
  ClassificationsBrowserNode,
  ClassificationsBrowserTreeTypeEnum,
  ClassificationsBrowserTreeViewEnum,
} from '@/common/services/swagger/index.defs';
import { Instance } from '../Instance';

class LegacyHelper {
  public toLegacyEventArgs(eventType: LegacyEventType, instance: Instance): ILegacyEventArgs {
    const args: ILegacyEventArgs = {
      type: eventType,
    };

    const selected = instance.selection.selectedItems;

    if (selected?.length) {
      const nodes: ILegacyEventArgsNode[] = [];

      selected.forEach((n) => {
        const node = n as ClassificationsBrowserNode;
        nodes.push(this.mapNodesToLegacy(node));
      });

      args.nodes = nodes;
    }

    const favorites = instance.favorites.selectedItems;

    if (favorites?.length) {
      const nodes: ILegacyEventArgsNode[] = [];

      favorites.forEach((n) => {
        const node = n as ClassificationsBrowserNode;
        nodes.push(this.mapNodesToLegacy(node));
      });

      args.favorites = nodes;
    }

    if (instance.store.data.invalidFavoriteItems) {
      args.invalidFavorites = instance.store.data.invalidFavoriteItems;
    }

    if (instance.store.data.invalidSelectedItems) {
      args.invalidNodes = instance.store.data.invalidSelectedItems;
    }

    return _cloneDeep(args);
  }

  private mapNodesToLegacy(node: ClassificationsBrowserNode): ILegacyEventArgsNode {
    return <ILegacyEventArgsNode>{
      cid: node.cid,
      englishDescription: node.enName,
      languageSpecificDescription: node.name,
      treeCode:
        node.tree == ClassificationsBrowserTreeTypeEnum.Offering ? 'OFFERING_TREE' : 'PL_TREE',
    };
  }

  public applyLegacyOptions(
    legacyOptions: ILegacyOptions,
    options?: IOptions | undefined,
  ): { options: IOptions; routeData: IRouteData } {
    const displayModes: ClassificationsBrowserTreeViewEnum[] = [];

    if (legacyOptions.parameters.favoriteView) {
      displayModes.push(ClassificationsBrowserTreeViewEnum.Favorites);
    }
    displayModes.push(ClassificationsBrowserTreeViewEnum.Offering);

    if (legacyOptions.parameters.productLineHierarchy) {
      displayModes.push(ClassificationsBrowserTreeViewEnum.ProductLine);
    }

    let opt: IOptions = {
      accessToken: legacyOptions.accessToken,
      selectedNodes: this.parseCids(legacyOptions.parameters.selectedNodes),
      favoriteNodes: this.parseCids(legacyOptions.parameters.favoriteNodes),
      application: {
        appCode: legacyOptions.appCode,
        countryCode: this.getIsoCountryCode(legacyOptions.countryCode),
        langCode: this.getIsoLangCode(legacyOptions.langCode),
        filters: {
          applicationUse: {
            disable: legacyOptions.parameters.disableAppUseCheck,
            exceptionCids: legacyOptions.parameters.disableAppUseCheckException,
          },
          lifeCyclePhases: legacyOptions.parameters.lifeCyclePhases,
        },
      },
      components: {
        treeTypeTabs: {
          displayModes: displayModes,
          defaultDisplayMode: displayModes[0],
          visible: true,
        },
        tree: {
          visible: true,
          scrollHeight: legacyOptions.parameters.scrollHeight,
          mobileScrollHeight: legacyOptions.parameters.mobileScrollHeight,
        },
        selection: {
          visible: true,
          scrollHeight: legacyOptions.parameters.scrollHeight,
          mobileScrollHeight: legacyOptions.parameters.mobileScrollHeight,
        },
      },
    };

    if (options) {
      opt = _mergeWith(options, opt, (a, b) => (_isArray(b) ? b : undefined));
    }

    const routeData: IRouteData = {
      view: displayModes[0],
    };

    return { options: opt, routeData: routeData };
  }

  private parseCids(items: string[] | { cid: string; treeCode: LegacyTreeCode }[]) {
    const tr: { cid: string; type?: ClassificationsBrowserTreeTypeEnum }[] = [];
    items.forEach((i) => {
      if (_isString(i)) {
        if (i.indexOf('|') > -1) {
          const parts = i.split('|');
          tr.push({ cid: parts[0], type: this.parseTreeType(parts[1]) });
        } else {
          tr.push({ cid: i });
        }
      } else {
        tr.push({ cid: i.cid, type: this.parseTreeType(i.treeCode) });
      }
    });

    return tr;
  }

  private parseTreeType(sfType: string): ClassificationsBrowserTreeTypeEnum | undefined {
    if (sfType === 'OFFERING_TREE') {
      return ClassificationsBrowserTreeTypeEnum.Offering;
    }

    if (sfType === 'PL_TREE') {
      return ClassificationsBrowserTreeTypeEnum.ProductLine;
    }

    return undefined;
  }

  private getIsoCountryCode(val?: string) {
    if (!val) {
      return undefined;
    }

    if (val.includes('_')) {
      return val.split('_')[1];
    }

    return val;
  }

  private getIsoLangCode(val?: string) {
    if (!val) {
      return undefined;
    }

    if (val.includes('_')) {
      return val.split('_')[0];
    }

    return val;
  }
}

const legacyHelper = new LegacyHelper();
export default legacyHelper;
