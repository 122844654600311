import { vModelText as _vModelText, mergeProps as _mergeProps, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "position-relative flex-grow-1 has-validation" }
const _hoisted_2 = ["placeholder"]
const _hoisted_3 = {
  key: 0,
  class: "position-absolute top-50 end-0 translate-middle-y pe-1"
}
const _hoisted_4 = {
  key: 1,
  class: "invalid-feedback d-block"
}
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { key: 2 }
const _hoisted_8 = { key: 3 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("textarea", _mergeProps({
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedValue) = $event))
    }, _ctx.$attrs, {
      class: ["form-control", [_ctx.lg && 'form-control-lg', _ctx.classInner, _ctx.isValid || 'is-invalid']],
      placeholder: _ctx.placeholder
    }), null, 16, _hoisted_2), [
      [_vModelText, _ctx.computedValue]
    ]),
    (_ctx.innerValue?.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("button", {
            type: "button",
            class: "btn-close",
            style: {"background-size":"0.7em"},
            onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.clear && _ctx.clear(...args)), ["prevent"]))
          })
        ]))
      : _createCommentVNode("", true),
    (!_ctx.isValid)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_ctx.validationErrors.required)
            ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString('Field is required')))
            : (_ctx.validationErrors.email)
              ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString('Field must be an email')))
              : (_ctx.validationErrors.csemails)
                ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString('Field must be a comma separated emails')))
                : (_ctx.validationErrors.minLength)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString(`Field must be at least ${_ctx.validators.minLength.minLength} characters long`), 1))
                  : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}