import {
  FavoriteViewDeleteQuery,
  FavoriteViewDeleteSettings,
  AppSettings,
  TreeTypeEnum,
  AliasSettings,
  Customer,
  ApplicationFilters,
  Criteria,
  AppSpecificSettings,
  ClientInfo,
  FavoriteViewsQuery,
  FavoriteViewsSearchSettings,
  FavoriteViewsSearchResult,
  FavoriteView,
  FavoriteViewData,
  PagerSettings,
  SearchSettings,
  PartType,
  PartTypeEnum,
  FilterSettings,
  FilterAttributeValue,
  SearchTypeEnum,
  SortSettings,
  SortFieldEnum,
  SortDirectionEnum,
  FavoriteViewSaveQuery,
  FavoriteViewSaveSettings,
  IList,
  List,
  IListResult,
  ListResultDto,
  IPagedResult,
  PagedResultDto,
  Dictionary,
  IDictionary,
  IRequestOptions,
  IRequestConfig,
  getConfigs,
  axios,
  basePath
} from './index.defs';

export class FavoriteViewsService {
  /** Generate by swagger-axios-codegen */
  // @ts-nocheck
  /* eslint-disable */

  /**
   * Delete a favorite view
   */
  static delete(
    params: {
      /** requestBody */
      body?: FavoriteViewDeleteQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/v1/FavoriteViews/Delete';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * List favorite views
   */
  static load(
    params: {
      /** requestBody */
      body?: FavoriteViewsQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FavoriteViewsSearchResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/v1/FavoriteViews/Load';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Create or update a favorite view
   */
  static save(
    params: {
      /** requestBody */
      body?: FavoriteViewSaveQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FavoriteView> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/v1/FavoriteViews/Save';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}
