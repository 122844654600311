import { AliasesEnum } from './../../../common/services/swagger/index.defs';
import {
  CatalogProductSearchTypeEnum,
  CatalogProductSortFieldEnum,
  CatalogSearchTypeEnum,
  CatalogSortFieldEnum,
  FilterSettings,
  SortDirectionEnum,
  TreeTypeEnum,
} from '@/common/services/swagger/index.defs';

export type ViewType = 'catalogs' | 'products' | 'aliases';

export enum DisplayMode {
  table = 'table',
  grid = 'grid',
  list = 'list',
}

export interface IRouteData {
  /**
   * Current view ('catalogs' | 'products' | 'aliases')
   */
  view: ViewType;

  /**
   * Catalogs view data. Applies to view = 'catalogs'
   */
  catalogs: ICatalogsRouteData;

  /**
   * Products view data. Applies to view = 'products' and 'aliases'
   */
  products: IProductsRouteData;
}

export interface Pagination {
  /**
   * Current page
   */
  page: number;
  /**
   * Number of items per page
   */
  pageSize: number;
}

export interface ICatalogsRouteData {
  /**
   * Current search text
   */
  searchText?: string;

  /**
   * Current search type ('CatalogCodeAndDescription' | 'CreatedBy' | 'CatalogCode' )
   */
  searchType?: CatalogSearchTypeEnum;

  /**
   * Current Pagination data
   */
  pagination: Pagination;

  /**
   * Current sort direction ( 'Ascending' | 'Descending')
   */
  sortDirection?: SortDirectionEnum;

  /**
   * Current sort field ('LastModified' | 'Code' | 'Description')
   */
  sortField?: CatalogSortFieldEnum;

  /**
   * Filter used to show only recently modified catalogs
   */
  createdWithinLastDays?: number;
}

export interface IProductsRouteData {
  /**
   * Current search text
   */
  searchText?: string;

  /**
   * Current search method ( 'WithAllWords' |  'WithAtLeastOneWord' | 'ExactPhrase')
   */
  searchType?: CatalogProductSearchTypeEnum;

  /**
   * Currently selected classification ID (CID)
   */
  cid?: string;

  /**
   * Currently selected classification tree ( 'Products' | 'Parts')
   */
  treeType?: TreeTypeEnum;

  /**
   * Applied filters (Refer to Web API documentation for details.)
   */
  filters?: FilterSettings[];

  /**
   * Current Pagination data
   */
  pagination: Pagination;

  /**
   * Current sort field ('LastImport' | 'Product' | 'Alias' | 'LastModified' | 'Hierarchical')
   */
  sortField?: CatalogProductSortFieldEnum;

  /**
   * Current sort direction ( 'Ascending' | 'Descending')
   */
  sortDirection?: SortDirectionEnum;

  /**
   * Current products display mode. Applies to view='products' only.
   */
  displayMode: DisplayMode;

  /**
   * Selected catalog ID (only products in the catalog are visible/searchable)
   */
  catalogId?: string;

  /**
   * Current alias filter. Applies to view='aliases' only. ('All' | 'Blank' | 'Populated')
   */
  aliases?: AliasesEnum;
}
