
import { defineComponent, computed, ref } from 'vue';
import { ComponentName } from '@/catalogs/api/configuration/components/ComponentName';
import { setupComponent } from '@/catalogs/composables/setupComponent';
import InjectStyles from '@/common/components/InjectStyles.vue';
import Confirmation from '@/common/components/Confirmation.vue';
import AliasEditModal from '@/catalogs/components/aliases/AliasEditModal.vue';
import AliasesImportModal from '@/catalogs/components/aliases/AliasesImportModal.vue';
import AliasesExportModal from '@/catalogs/components/aliases/AliasesExportModal.vue';
import {
  AliasesReloadCommand,
  CatalogActionCompleted,
  DeleteAliasesCommand,
} from '@/catalogs/api/runtime/CommandExecutor';
import { AliasItem } from '@/common/services/swagger/index.defs';
import { attrValue } from '@/common/helpers/productAttributes';

// Used in template
/* eslint-disable */
import { CatalogAction } from '@/catalogs/api/runtime/CatalogAction';
import {
  AliasDeleteActionData,
  AliasEditActionData,
  AliasExportActionData,
  AliasImportActionData,
} from '@/catalogs/api/runtime/CatalogActionData';
/* eslint-enable */

export default defineComponent({
  name: ComponentName.aliasActionModals,
  components: {
    InjectStyles,
    Confirmation,
    AliasEditModal,
    AliasesImportModal,
    AliasesExportModal,
  },
  props: {
    instanceId: String,
  },
  setup(props) {
    const root = ref<HTMLElement>();
    const { componentName, instance, isReady, isWebComponent, store, t } = setupComponent(
      root,
      props.instanceId,
    );
    const actions = computed(() => instance.value?.store.data.actions);
    const currentActionName = computed(() => actions.value?.current);
    const currentActionData = computed(() => actions.value?.data);
    const onActionCompleted = async () => {
      try {
        instance.value?.execute(new CatalogActionCompleted());
      } catch (error) {
        // Ignore error
      }
    };
    const onAliasesDelete = async () => {
      const currentActions = actions.value;
      if (!currentActions?.current || currentActions.current !== CatalogAction.aliasDelete) {
        return;
      }
      const aliasesForDeletion: AliasItem[] = (currentActions.data as AliasDeleteActionData)
        ?.aliases;
      if (!aliasesForDeletion || !aliasesForDeletion.length) {
        return;
      }
      try {
        await instance.value?.execute(
          new DeleteAliasesCommand({
            onSuccessMessage: t('AliasDeleteSuccess'),
            aliases: aliasesForDeletion.map((alias: AliasItem) => ({
              id: alias.id,
            })),
          }),
        );

        await instance.value?.execute(new AliasesReloadCommand());
      } catch (error) {
        // Ignore Error
      }
      onActionCompleted();
    };

    return {
      root,
      store,
      isReady,
      componentName,
      isWebComponent,
      currentActionName,
      currentActionData,
      CatalogAction,
      t,
      attrValue,
      onAliasDelete: onAliasesDelete,
      onActionCompleted,
    };
  },
});
