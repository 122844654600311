import {
  CatalogProductItem,
  ProductAdditionalInfo,
  ProductItem,
} from './../services/swagger/index.defs';

export const attrValue = (product: ProductItem | CatalogProductItem, attrName?: string): string => {
  if (product && product.attributes && attrName) {
    const attr = product.attributes[attrName];
    if (attr && attr.values && attr.values[0]) {
      return attr.values[0].text ?? '';
    }
  }
  return '';
};

export const attrValues = (product: ProductItem, attrName?: string): string[] => {
  if (product && product.attributes && attrName) {
    const attr = product.attributes[attrName];
    if (attr && attr.values && attr.values.length) {
      return attr.values.map((a) => a.text);
    }
  }
  return [];
};

export const additionalInfo = (info: ProductAdditionalInfo[]): string => {
  if (!info || info.length === 0) {
    return '';
  }
  if (info.length == 1) {
    return info[0].text || '';
  }

  let text = '';
  info.forEach((el) => {
    text += `<li>${el.text}</li>`;
  });
  return `<ul>${text}</ul>`;
};
