import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mb-0"
}
const _hoisted_2 = {
  key: 1,
  class: "mb-0"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.totalCount >= _ctx.pageSize)
    ? (_openBlock(), _createElementBlock("p", _hoisted_1, [
        _createTextVNode(_toDisplayString(_ctx.showingText) + " ", 1),
        _createElementVNode("strong", null, _toDisplayString(_ctx.pageStart) + "-" + _toDisplayString(_ctx.pageEnd), 1),
        _createTextVNode(" " + _toDisplayString(_ctx.ofText) + " ", 1),
        _createElementVNode("strong", null, _toDisplayString(_ctx.totalCount), 1)
      ]))
    : (_openBlock(), _createElementBlock("p", _hoisted_2, [
        _createTextVNode(_toDisplayString(_ctx.showingText) + " ", 1),
        _createElementVNode("strong", null, _toDisplayString(_ctx.totalCount), 1),
        _createTextVNode(" " + _toDisplayString(_ctx.ofText) + " ", 1),
        _createElementVNode("strong", null, _toDisplayString(_ctx.totalCount), 1)
      ]))
}