import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, toHandlers as _toHandlers, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "ms-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Clickable = _resolveComponent("Clickable")!
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_ctx.cat.nested)
    ? (_openBlock(), _createBlock(_component_Dropdown, {
        key: 0,
        open: _ctx.open,
        "onUpdate:open": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.open) = $event)),
        items: _ctx.cat.items,
        class: "d-inline-flex ext-cat-dropdown"
      }, {
        activator: _withCtx(({ on }) => [
          (_openBlock(), _createElementBlock("div", _mergeProps({
            key: _ctx.cat.cid,
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.open = !_ctx.open), ["prevent"]))
          }, _toHandlers(on, true), {
            class: ["badge rounded-pill me-2 my-1 py-2 d-inline-flex align-items-center dropdown-toggle", [
            _ctx.selectedCid && _ctx.cat.cids.includes(_ctx.selectedCid)
              ? 'bg-primary text-white'
              : 'bg-light text-body pis-hover',
          ]],
            style: {"cursor":"pointer"}
          }), [
            _createElementVNode("div", null, _toDisplayString(_ctx.cat.name), 1)
          ], 16))
        ]),
        item: _withCtx(({ item }) => [
          _createVNode(_component_Clickable, {
            class: _normalizeClass(["dropdown-item badge my-1 py-2 d-inline-flex align-items-center rounded-4 mx-2", [
            _ctx.selectedCid && item.cids.includes(_ctx.selectedCid)
              ? 'bg-primary text-white'
              : 'bg-light text-body pis-hover',
          ]]),
            style: {"width":"360px","white-space":"break-spaces","line-height":"1rem"},
            onClick: ($event: any) => {
            _ctx.open = false;
            _ctx.$emit('select', item.cid);
          }
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.getName(item)) + " (" + _toDisplayString(item.hitCount) + ") ", 1)
            ]),
            _: 2
          }, 1032, ["class", "onClick"])
        ]),
        _: 1
      }, 8, ["open", "items"]))
    : (_openBlock(), _createElementBlock("div", {
        key: _ctx.cat.cid,
        onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.$emit('select', _ctx.cat.cid)), ["prevent"])),
        class: _normalizeClass(["badge rounded-pill me-2 my-1 py-2 d-inline-flex", [
        _ctx.selectedCid && _ctx.cat.cids.includes(_ctx.selectedCid)
          ? 'bg-primary text-white'
          : 'bg-light text-body pis-hover',
      ]]),
        style: {"cursor":"pointer"}
      }, [
        _createElementVNode("div", null, _toDisplayString(_ctx.cat.name), 1),
        _createElementVNode("div", _hoisted_1, "(" + _toDisplayString(_ctx.cat.hitCount) + ")", 1)
      ], 2))
}