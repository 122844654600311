import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["part"]
const _hoisted_2 = { class: "mb-4" }
const _hoisted_3 = { class: "d-block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InjectStyles = _resolveComponent("InjectStyles")!
  const _component_ProductEditModal = _resolveComponent("ProductEditModal")!
  const _component_ProductCopyModal = _resolveComponent("ProductCopyModal")!
  const _component_Confirmation = _resolveComponent("Confirmation")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "root",
    class: "pis-component position-relative",
    part: _ctx.componentName
  }, [
    (_ctx.isReady)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.isWebComponent)
            ? (_openBlock(), _createBlock(_component_InjectStyles, {
                key: 0,
                items: _ctx.store?.options.styles
              }, null, 8, ["items"]))
            : _createCommentVNode("", true),
          (_ctx.currentActionName === _ctx.CatalogAction.productEdit)
            ? (_openBlock(), _createBlock(_component_ProductEditModal, {
                key: 1,
                t: _ctx.t,
                "instance-id": _ctx.instanceId,
                data: (_ctx.currentActionData as CatalogProductEditActionData),
                "onUpdate:show": _ctx.onActionCompleted
              }, null, 8, ["t", "instance-id", "data", "onUpdate:show"]))
            : (_ctx.currentActionName === _ctx.CatalogAction.productCopy)
              ? (_openBlock(), _createBlock(_component_ProductCopyModal, {
                  key: 2,
                  t: _ctx.t,
                  "instance-id": _ctx.instanceId,
                  data: (_ctx.currentActionData as CatalogProductCopyActionData),
                  "onUpdate:show": _ctx.onActionCompleted
                }, null, 8, ["t", "instance-id", "data", "onUpdate:show"]))
              : (_ctx.currentActionName === _ctx.CatalogAction.productDelete)
                ? (_openBlock(), _createBlock(_component_Confirmation, {
                    key: 3,
                    t: _ctx.t,
                    title: _ctx.t('DeleteCatalogProduct'),
                    item: (_ctx.currentActionData as CatalogProductDeleteActionData).product,
                    "onUpdate:show": _ctx.onActionCompleted,
                    onConfirmationCancelled: _ctx.onActionCompleted,
                    onConfirmationOk: _ctx.onCatalogProductDelete
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_2, [
                        _createTextVNode(_toDisplayString(_ctx.t('CatalogProductConfirmDelete')) + " ", 1),
                        _createElementVNode("strong", _hoisted_3, _toDisplayString(_ctx.attrValue(
                (_ctx.currentActionData as CatalogProductDeleteActionData).product,
                '#DisplayName',
              )), 1)
                      ])
                    ]),
                    _: 1
                  }, 8, ["t", "title", "item", "onUpdate:show", "onConfirmationCancelled", "onConfirmationOk"]))
                : _createCommentVNode("", true)
        ], 64))
      : _createCommentVNode("", true)
  ], 8, _hoisted_1))
}