
import { Instance } from '@/catalogs/api/Instance';
import PIS from '@/common/api/PIS';
import Btn from '@/common/components/Btn.vue';
import Modal from '@/common/components/Modal.vue';
import { ValidationType, Validators } from '@/common/helpers/validation';
import { computed, defineComponent, onMounted, PropType, ref } from 'vue';
import {
  CopyProductCommand,
  CopyProductToNewCommand,
  GetAllCatalogsCommand,
  CopyProductCommandArgs,
  CopyProductToNewCommandArgs,
  AddNotificationCommand,
} from '@/catalogs/api/runtime/CommandExecutor';
import { TranslateFunc } from '@/catalogs/composables/setupComponent';
import {
  Catalog,
  CatalogDetail,
  CatalogItem,
  CatalogTypeEnum,
  QuantityItem,
} from '@/common/services/swagger/index.defs';
import { NotificationType } from '@/common/api/runtime/INotification';
import { CatalogProductCopyActionData } from '@/catalogs/api/runtime/CatalogActionData';
import FormInputClearable from '@/common/components/FormInputClearable.vue';
import FormSelectFilterable, { SelectItem } from '@/common/components/FormSelectFilterable.vue';
import FormTextAreaClearable from '@/common/components/FormTextAreaClearable.vue';
import CatalogOptions from '@/catalogs/components/common/CatalogOptions.vue';

export default defineComponent({
  components: {
    Btn,
    Modal,
    FormInputClearable,
    FormSelectFilterable,
    FormTextAreaClearable,
    CatalogOptions,
  },
  props: {
    t: { type: Function as PropType<TranslateFunc>, required: true },
    instanceId: String,
    data: { type: Object as PropType<CatalogProductCopyActionData>, required: true },
  },
  emits: ['update:show'],
  setup(props, { emit }) {
    const newCatalogValue = 'NEW_CATALOG';
    const catalogType: typeof CatalogTypeEnum = CatalogTypeEnum;
    const show = ref(false);
    const instance = PIS.Catalogs.getInstance(props.instanceId) as Instance;
    const copyTo = ref<string | null>(null);
    const code = ref('');
    const description = ref('');
    const type = ref(CatalogTypeEnum.Personal);
    const allCatalogs = ref<CatalogItem[]>([]);
    const isFormValid = computed<boolean>(
      () =>
        copyTo.value !== null &&
        (copyTo.value !== newCatalogValue ||
          (copyTo.value === newCatalogValue &&
            !!code.value &&
            !!type.value &&
            !!description.value)),
    );
    const validators: Record<string, Validators> = {
      code: { [ValidationType.required]: {} },
      description: { [ValidationType.required]: {} },
    };
    const productCatalog = computed<CatalogDetail | undefined>(
      () => (props.data.product.catalogs ?? {})[0] || undefined,
    );
    const copyToOptions = computed<SelectItem[]>(() =>
      [
        { value: newCatalogValue, text: props.t('CreateNewCatalog'), type: 'fixed' } as SelectItem,
        { type: 'divider' } as SelectItem,
      ].concat(
        allCatalogs.value.reduce((result: SelectItem[], current: CatalogItem) => {
          if (current.code !== productCatalog.value?.code && current.editable) {
            return result.concat([{ value: current.code, text: current.code, type: 'standard' }]);
          }
          return result;
        }, []),
      ),
    );
    const close = () => emit('update:show', false);
    const onCopyToUpdate = (newCopyToValue: string) => (copyTo.value = newCopyToValue);
    const onCodeUpdate = (newCodeValue: string) => (code.value = newCodeValue);
    const onDescriptionUpdate = (newDescriptionValue: string) =>
      (description.value = newDescriptionValue);
    const onTypeUpdated = (newType: CatalogTypeEnum) => (type.value = newType);
    const onSave = async () => {
      if (!isFormValid.value) {
        return;
      }

      const catalogItem: Catalog = props.data.catalog;
      const catalogDetail: CatalogDetail | undefined = props.data.product.catalogs?.find(
        (catalog: CatalogDetail) => catalog.code === catalogItem.code,
      );
      if (!catalogDetail) {
        return;
      }
      const quantity: QuantityItem | undefined = (catalogDetail?.quantities ?? {})[0];
      if (!quantity) {
        return;
      }
      try {
        if (copyTo.value === newCatalogValue) {
          const copyToNewArgs: CopyProductToNewCommandArgs = {
            onSuccessMessage: props.t('CatalogProductCopySuccess'),
            sourceCatalogId: catalogDetail?.id,
            sourceQuantityIds: [quantity.id],
            code: code.value,
            type: type.value,
            description: description.value,
          };
          await instance.execute(new CopyProductToNewCommand(copyToNewArgs));
        } else {
          const copyArgs: CopyProductCommandArgs = {
            onSuccessMessage: props.t('CatalogProductCopySuccess'),
            sourceCatalogId: catalogDetail?.id,
            sourceQuantityIds: [quantity.id],
            targetCatalogId: '',
          };
          const targetCatalog: CatalogItem | undefined = allCatalogs.value.find(
            (catalogItem: CatalogItem) => catalogItem.code === copyTo.value,
          );
          const targetCatalogId = targetCatalog?.id;
          if (!targetCatalogId) {
            return;
          }
          copyArgs.targetCatalogId = targetCatalogId;
          await instance.execute(new CopyProductCommand(copyArgs));
        }
      } catch (error) {
        // Ignore error
      }
      close();
    };

    const onAllCatalogsLoad = async () => {
      try {
        allCatalogs.value = (await instance.execute(new GetAllCatalogsCommand())) as CatalogItem[];
      } catch (error) {
        // Ignore error
      }
    };

    onMounted(async () => {
      try {
        onAllCatalogsLoad();
        show.value = true;
      } catch (error) {
        await instance.execute(
          new AddNotificationCommand({ type: NotificationType.danger, message: error.message }),
        );
      }
    });

    return {
      newCatalogValue,
      show,
      copyTo,
      isFormValid,
      validators,
      catalogType,
      copyToOptions,
      code,
      type,
      description,
      close,
      onSave,
      onCopyToUpdate,
      onCodeUpdate,
      onTypeUpdated,
      onDescriptionUpdate,
    };
  },
});
