
import { defineComponent, computed, ref } from 'vue';
import { ComponentName } from '@/products/api/configuration/components/ComponentName';

import { setupComponent } from '@/products/composables/setupComponent';
import InjectStyles from '@/common/components/InjectStyles.vue';
import { ProductAction } from '@/products/api/runtime/ProductAction';
import ProductActionErrors from '@/products/components/common/ProductActionErrors.vue';
import AddToCatalogModal from './AddToCatalogModal.vue';

// Used in template
/* eslint-disable */
import {
  ProductAddToCatalogActionData,
  ProductErrorsActionData,
} from '@/products/api/runtime/ProductActionData';
import { ProductActionCompleted } from '@/products/api/runtime/CommandExecutor';
/* eslint-enable */

export default defineComponent({
  name: ComponentName.actionModals,
  components: {
    InjectStyles,
    ProductActionErrors,
    AddToCatalogModal,
  },
  props: {
    instanceId: String,
  },
  setup(props) {
    const root = ref<HTMLElement>();
    const { componentName, instance, isReady, isWebComponent, store, t } = setupComponent(
      root,
      props.instanceId,
    );
    const actions = computed(() => instance.value?.store.data.actions);
    const currentActionName = computed(() => actions.value?.current);
    const currentActionData = computed(() => actions.value?.data);
    const onActionCompleted = async () => {
      try {
        instance.value?.execute(new ProductActionCompleted());
      } catch (error) {
        // Ignore error
      }
    };

    return {
      root,
      store,
      isReady,
      componentName,
      ProductAction,
      isWebComponent,
      currentActionName,
      currentActionData,
      t,
      onActionCompleted,
    };
  },
});
