
import { defineComponent, ref, computed, watch } from 'vue';
import { ComponentName } from '@/products/api/configuration/components/ComponentName';
import { setupComponent } from '@/products/composables/setupComponent';

// Common components
import InjectStyles from '@/common/components/InjectStyles.vue';
import Message from '@/common/components/Message.vue';
import LoadingIndicator from './ProductsLoadingIndicator.vue';
import ProductsMobileNav from './ProductsMobileNav.vue';
import ProductsBreadcrumbs from './ProductsBreadcrumbs.vue';
import ProductsNotifications from './ProductsNotifications.vue';
import ProductActionModals from './common/ProductActionModals.vue';

// Search view
import ProductsSearchCategories from './search/ProductsSearchCategories.vue';
import ProductsSearchForm from './search/ProductsSearchForm.vue';
import ProductsSearchFilters from './search/ProductsSearchFilters.vue';
import ProductsSearchFiltersStatus from './search/ProductsSearchFiltersStatus.vue';
import ProductsSearchGrid from './search/ProductsSearchGrid.vue';
import ProductsSearchHeader from './search/ProductsSearchHeader.vue';
import ProductsSearchList from './search/ProductsSearchList.vue';
import ProductsSearchNoResults from './search/ProductsSearchNoResults.vue';
import ProductsSearchPagination from './search/ProductsSearchPagination.vue';
import ProductsSearchTable from './search/ProductsSearchTable.vue';
import ProductsSearchToolbar from './search/ProductsSearchToolbar.vue';
import ProductsSearchPartTypes from './search/ProductsSearchPartTypes.vue';
import ProductsSearchFavoriteViews from './search/ProductsSearchFavoriteViews.vue';
import ProductsSearchFavoriteCategories from './search/ProductsSearchFavoriteCategories.vue';
import ProductsSearchSelection from './search/ProductsSearchSelection.vue';

// Details view
import ProductsDetailsHeader from './details/ProductsDetailsHeader.vue';
import ProductsDetailsGallery from './details/ProductsDetailsGallery.vue';
import ProductDetailsGeneralInformation from './details/ProductDetailsGeneralInformation.vue';
import ProductDetailsReplacements from './details/ProductDetailsReplacements.vue';
import ProductDetailsIdentifiers from './details/ProductDetailsIdentifiers.vue';
import ProductsDetailsData from '@/products/components/details/ProductsDetailsData.vue';
import ProductsDetailsActions from '@/products/components/details/ProductsDetailsActions.vue';
import ProductDetailsClassifications from '@/products/components/details/ProductDetailsClassifications.vue';
import ProductDetailsAttributeGroups from '@/products/components/details/ProductDetailsAttributeGroups.vue';
import ProductDetailsRelationships from './details/ProductDetailsRelationships.vue';

// Preview View
import ProductsPreview from './preview/ProductsPreviewView.vue';

// Compare View
import ProductCompare from './compare/ProductCompare.vue';

import ProductsTableView from './views/ProductsTableView.vue';
import ProductsListView from './views/ProductsListView.vue';
import ProductsGridView from './views/ProductsGridView.vue';
import ProductsSearchNoResultsView from './views/ProductsSearchNoResultsView.vue';
import ProductsSearchNarrowByProduct from './search/ProductsSearchNarrowByProduct.vue';
import { ProductDetailSearchResult } from '@/common/services/swagger/index.defs';

export default defineComponent({
  name: ComponentName.layout,

  components: {
    InjectStyles,
    Message,
    LoadingIndicator,
    ProductsMobileNav,
    ProductsSearchCategories,
    ProductsSearchFilters,
    ProductsSearchFiltersStatus,
    ProductsSearchForm,
    ProductsSearchGrid,
    ProductsSearchHeader,
    ProductsSearchList,
    ProductsSearchNoResults,
    ProductsSearchNoResultsView,
    ProductsSearchPagination,
    ProductsSearchTable,
    ProductsSearchToolbar,
    ProductsSearchPartTypes,
    ProductsSearchFavoriteViews,
    ProductsSearchFavoriteCategories,
    ProductsSearchSelection,
    ProductsDetailsHeader,
    ProductsDetailsGallery,
    ProductDetailsGeneralInformation,
    ProductDetailsReplacements,
    ProductDetailsIdentifiers,
    ProductDetailsClassifications,
    ProductDetailsAttributeGroups,
    ProductDetailsRelationships,
    ProductsPreview,
    ProductsTableView,
    ProductsListView,
    ProductsGridView,
    ProductsSearchNarrowByProduct,
    ProductsDetailsData,
    ProductsDetailsActions,
    ProductsBreadcrumbs,
    ProductsNotifications,
    ProductCompare,
    ProductActionModals,
  },

  props: {
    instanceId: String,
  },

  setup(props) {
    const root = ref<HTMLElement>();

    const {
      componentName,
      instance,
      isWebComponent,
      isReady,
      translationsReady,
      store,
      routeData,
      widerThan,
      t,
    } = setupComponent(root, props.instanceId);

    const allowMobileView = computed(() => store.value?.options.allowMobileView);

    const productDetail = computed<ProductDetailSearchResult | undefined>(
      () => store.value?.data.productDetail || undefined,
    );

    const detailsGalleryVisible = computed(
      () =>
        (store.value?.options.components?.detailsGallery?.visible &&
          productDetail.value?.item &&
          productDetail.value?.item.hideImages !== true) ||
        store.value?.options.components?.details?.actionTemplate,
    );

    watch(isReady, () => {
      instance.value?.eventBus.on('internal:scroll-to-top', () => {
        scrollTop();
      });
    });

    const scrollTo = (elem: HTMLElement | undefined) =>
      elem?.scrollIntoView({ behavior: 'smooth' });

    const scrollTop = () => {
      const args = { isHandled: false };
      instance.value?.eventBus.emit('scroll-to-top', args);
      if (!args.isHandled) {
        scrollTo(root.value);
      }
    };

    const routeView = computed(() => routeData.value?.view);
    const itemLoaded = computed(() => store.value?.data.productDetail?.item != undefined);
    const itemNotFound = computed(() => store.value?.data.itemNotFound === true);

    // Scroll to top each time the route view changes. We need to use computed
    // because watching the routeData gives no chance to see if view has changed.
    watch(routeView, async (view, oldView) => {
      if (view && oldView && view !== oldView) {
        scrollTop();
      }
    });

    return {
      root,
      componentName,
      isWebComponent,
      isReady,
      instance,
      translationsReady,
      store,
      routeData,
      allowMobileView,
      detailsGalleryVisible,
      itemLoaded,
      itemNotFound,
      widerThan,
      t,
      scrollTop,
    };
  },
});
