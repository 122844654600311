import { AutocompleteItem, SearchTypeEnum } from '@/common/services/swagger/index.defs';

export interface AutocompleteModelItem extends AutocompleteItem {
  text?: string;
  secondaryText?: string;
  description?: string;
  count?: number;
  type: 'item' | 'root' | 'current' | 'category' | 'no-filters';
  location: 'top' | 'bottom';
  categoryName?: string;
}

export const searchTypeAllWords = {
  value: SearchTypeEnum.WithAllWords,
  translate: 'SearchTypeAllWords',
};

export const searchTypeHierarchical = {
  value: SearchTypeEnum.Hierarchical,
  translate: 'Hierarchical',
};

export const searchTypeOneWord = {
  value: SearchTypeEnum.WithAtLeastOneWord,
  translate: 'SearchTypeOneWord',
};
export const searchTypeExact = { value: SearchTypeEnum.ExactPhrase, translate: 'SearchTypeExact' };
export const searchTypeMasterId = {
  value: SearchTypeEnum.SparePartMasterIdentifier,
  translate: 'SearchTypeMasterId',
};

export interface Autocomplete {
  totalCount: number;
  visibleMatchCount: number;
  items: AutocompleteModelItem[];
  autoCategoryNavigationCid?: string;
  matchesOnlyInAllCatagories?: boolean;
  matchesOnlyWithoutFilters?: boolean;
}
