import { Instance as ProductsInstance } from '../../../products/api/Instance';
import { Instance as CatalogsInstance } from '../../../catalogs/api/Instance';
import { IProductSelection } from './IProductSelection';
import { ISelectionChanged } from './ISelectionChanged';

export class ProductSelection implements IProductSelection {
  constructor(private instance: ProductsInstance | CatalogsInstance) {
    if (!instance.store.data.selectedItems) {
      instance.store.data.selectedItems = new Map<string, { productId: string }>();
    }

    instance.on('search-loaded', () => {
      if (!instance.store.options.components?.selection?.persist) {
        this.clear();
      }
    });
  }

  get data() {
    return this.instance.store.data.selectedItems;
  }

  get selectedItems() {
    return this.data ? Array.from(this.data.values()) : [];
  }

  get count() {
    return this.selectedItems.length;
  }

  select = (items: { productId: string }[]) => {
    if (!this.data || !items?.length) {
      return;
    }
    const added: { productId: string }[] = [];
    items.forEach((item) => {
      if (item && item.productId && !this.isSelected(item)) {
        this.data?.set(item.productId, item);
        added.push(item);
      }
    });
    if (added.length) {
      this.instance.eventBus.emit('selection-changed', <ISelectionChanged>{
        added: added,
        removed: [],
        selected: this.selectedItems,
      });
    }
  };

  deselect = (items: { productId: string }[]) => {
    if (!this.data || !items?.length) {
      return;
    }
    const removed: { productId: string }[] = [];
    items.forEach((item) => {
      if (item && item.productId && this.isSelected(item)) {
        this.data?.delete(item.productId);
        removed.push(item);
      }
    });
    if (removed.length) {
      this.instance.eventBus.emit('selection-changed', <ISelectionChanged>{
        added: [],
        removed: removed,
        selected: this.selectedItems,
      });
    }
  };

  toggle = (item: { productId: string }) => {
    if (this.isSelected(item)) {
      this.deselect([item]);
    } else {
      this.select([item]);
    }
  };

  clear = () => {
    if (!this.data) {
      return;
    }

    const removed = this.selectedItems;

    this.data?.clear();
    if (removed.length) {
      this.instance.eventBus.emit('selection-changed', <ISelectionChanged>{
        added: [],
        removed: removed,
        selected: this.selectedItems,
      });
    }
  };

  isSelected = (item: { productId: string }) => {
    if (!this.data) {
      return false;
    }
    return this.data.has(item.productId);
  };

  selectAll() {
    return new Promise<void>((resolve) => {
      if (this.instance.store.data.products?.items) {
        const items = this.instance.store.data.products.items;
        this.select(items);
        resolve();
      }
    });
  }
}
