export enum CatalogAction {
  edit = 'CatalogEditAction',
  import = 'CatalogImportAction',
  export = 'CatalogExportAction',
  makeDefault = 'CatalogMakeDefaultAction',
  unmakeDefault = 'CatalogUnmakeDefaultAction',
  importAliases = 'CatalogImportAliasesAction',
  exportAliases = 'CatalogExportAliasesAction',
  copy = 'CatalogCopyAction',
  delete = 'CatalogDeleteAction',
  history = 'CatalogHistoryAction',
  createNew = 'CatalogCreateNewAction',
  productEdit = 'ProductEditAction',
  productCopy = 'ProductCopyAction',
  productDelete = 'ProductDeleteAction',
  errors = 'Errors',
  aliasCreateNew = 'AliasCreateNewAction',
  aliasEdit = 'AliasEditAction',
  aliasDelete = 'AliasDeleteAction',
  aliasImport = 'AliasImport',
  aliasExport = 'AliasExport',
}
