
import { computed, defineComponent } from 'vue';
import Clickable from './Clickable.vue';
import Icon from './Icon.vue';
import Spinner from './Spinner.vue';

export default defineComponent({
  name: 'Tabs',

  components: {
    Clickable,
    Icon,
    Spinner,
  },

  props: {
    modelValue: { type: Number, default: 0 },
    desktop: Boolean,
  },

  setup(props, { slots }) {
    const tabItems = computed(() => {
      if (slots && slots.default) {
        return slots.default().filter(({ type }: any) => type.name === 'Tab');
      }
      return [];
    });

    return {
      tabItems,
    };
  },
});
