import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, createVNode as _createVNode, withCtx as _withCtx, normalizeStyle as _normalizeStyle, vShow as _vShow, normalizeClass as _normalizeClass, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["part"]
const _hoisted_2 = { class: "d-flex" }
const _hoisted_3 = { class: "flex-grow-1 fw-bold" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  key: 0,
  class: "fw-bold my-2"
}
const _hoisted_6 = { class: "flex-grow-1 d-flex" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = {
  key: 1,
  class: "fw-bold my-2"
}
const _hoisted_10 = { class: "flex-grow-1 d-flex" }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = ["onClick"]
const _hoisted_13 = {
  key: 1,
  class: "p-3 text-muted"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InjectStyles = _resolveComponent("InjectStyles")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Tooltip = _resolveComponent("Tooltip")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    ref: "root",
    class: _normalizeClass(["pis-component", _ctx.componentName]),
    part: _ctx.componentName,
    style: {"z-index":"1"}
  }, [
    (_ctx.isReady)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.isWebComponent)
            ? (_openBlock(), _createBlock(_component_InjectStyles, {
                key: 0,
                items: _ctx.store?.options.styles
              }, null, 8, ["items"]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.t('SelectedLabel')), 1),
            (_ctx.nodes.length)
              ? (_openBlock(), _createElementBlock("a", {
                  key: 0,
                  href: "#",
                  class: "small",
                  onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.clearAll && _ctx.clearAll(...args)), ["prevent"]))
                }, _toDisplayString(_ctx.t('ClearAll')), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", {
            class: "overflow-auto border p-1",
            style: _normalizeStyle({ height: _ctx.height })
          }, [
            (_ctx.nodes && _ctx.nodes.length)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  (_ctx.offeringNodes.length)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.t('OfferingTree')), 1))
                    : _createCommentVNode("", true),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.offeringNodes, (node) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: node.cid,
                      class: "border p-1 mb-1 bg-light d-flex align-items-center rounded-2"
                    }, [
                      _createElementVNode("div", _hoisted_6, [
                        _createElementVNode("div", null, _toDisplayString(node.name), 1),
                        (node.productGroup.status === _ctx.ProductGroupValidationStatusEnum.Invalid)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                              _createVNode(_component_Tooltip, {
                                trigger: "hover",
                                text: _ctx.t('InvalidProductGroup'),
                                delay: 100
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_Icon, {
                                    glyph: "warning",
                                    sm: "",
                                    class: "ms-1 text-warning"
                                  })
                                ]),
                                _: 1
                              }, 8, ["text"])
                            ]))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("a", {
                        href: "#",
                        class: "text-dark pis-hover p-2 d-flex rounded-circle",
                        onClick: _withModifiers(($event: any) => (_ctx.selection?.deselect([node])), ["prevent"])
                      }, [
                        _createVNode(_component_Icon, {
                          glyph: "close",
                          sm: ""
                        })
                      ], 8, _hoisted_8)
                    ]))
                  }), 128)),
                  (_ctx.plNodes.length)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.t('ProductLineTree')), 1))
                    : _createCommentVNode("", true),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.plNodes, (node) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: node.cid,
                      class: "border p-1 mb-1 bg-light d-flex align-items-center rounded-2"
                    }, [
                      _createElementVNode("div", _hoisted_10, [
                        _createElementVNode("div", null, _toDisplayString(node.name), 1),
                        (node.productGroup.status === _ctx.ProductGroupValidationStatusEnum.Invalid)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                              _createVNode(_component_Tooltip, {
                                trigger: "hover",
                                text: _ctx.t('InvalidProductGroup'),
                                delay: 100
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_Icon, {
                                    glyph: "warning",
                                    sm: "",
                                    class: "ms-1 text-warning"
                                  })
                                ]),
                                _: 1
                              }, 8, ["text"])
                            ]))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("a", {
                        href: "#",
                        class: "text-dark pis-hover p-2 d-flex rounded-circle",
                        onClick: _withModifiers(($event: any) => (_ctx.selection?.deselect([node])), ["prevent"])
                      }, [
                        _createVNode(_component_Icon, {
                          glyph: "close",
                          sm: ""
                        })
                      ], 8, _hoisted_12)
                    ]))
                  }), 128))
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_13, _toDisplayString(_ctx.t('NothingSelected')), 1))
          ], 4)
        ], 64))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1)), [
    [_vShow, _ctx.componentOptions.visible]
  ])
}