
import { PropType, defineComponent } from 'vue';
import Btn from './Btn.vue';
import Modal from './Modal.vue';
import { TranslateFunc } from '@/common/composables/translateFunc';

export enum ConfirmationEventEnum {
  ok = 'confirmation-ok',
  cancelled = 'confirmation-cancelled',
}

export default defineComponent({
  name: 'Confirmation',

  components: {
    Btn,
    Modal,
  },

  props: {
    t: { type: Function as PropType<TranslateFunc>, required: true },
    item: { type: Object, required: false },
    title: String,
    lg: { type: Boolean, default: false },
    xl: { type: Boolean, default: false },
  },
  emits: ['update:show', ConfirmationEventEnum.ok, ConfirmationEventEnum.cancelled],
  setup() {
    return {
      confirmationEvent: ConfirmationEventEnum,
    };
  },
});
