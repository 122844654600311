import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["part"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "pis-heading ext-attr-group-heading" }
const _hoisted_5 = { class: "ext-attr-group-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InjectStyles = _resolveComponent("InjectStyles")!
  const _component_AttributesTable = _resolveComponent("AttributesTable")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "root",
    class: _normalizeClass(["pis-component", _ctx.componentName]),
    part: _ctx.componentName
  }, [
    (_ctx.isReady)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.isWebComponent)
            ? (_openBlock(), _createBlock(_component_InjectStyles, {
                key: 0,
                items: _ctx.store?.options.styles
              }, null, 8, ["items"]))
            : _createCommentVNode("", true),
          (_ctx.item)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("h4", _hoisted_4, _toDisplayString(_ctx.t('GeneralInfo')), 1),
                _createElementVNode("div", _hoisted_5, [
                  (_ctx.item.attributes)
                    ? (_openBlock(), _createBlock(_component_AttributesTable, {
                        key: 0,
                        attributes: _ctx.item.attributes,
                        instance: _ctx.instance,
                        "hide-document-links": _ctx.hideDocumentLinks,
                        t: _ctx.t
                      }, null, 8, ["attributes", "instance", "hide-document-links", "t"]))
                    : _createCommentVNode("", true)
                ])
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}