
import { computed, defineComponent, ref } from 'vue';
import { PartType } from '@/common/services/swagger/index.defs';
import { ComponentName } from '@/products/api/configuration/components/ComponentName';
import { setupComponent } from '@/products/composables/setupComponent';
import { DataType } from '@/products/api/configuration/application/DataType';
import { PartsTypeCommand } from '@/products/api/runtime/CommandExecutor';

import InjectStyles from '@/common/components/InjectStyles.vue';
import Card from '@/common/components/Card.vue';
import SparePartTypeList from '@/common/components/SparePartTypeList.vue';
import Message from '@/common/components/Message.vue';
import { ISparePartTypesOptions } from '@/products/api/configuration/components/ISparePartTypesOptions';

export default defineComponent({
  name: ComponentName.searchPartTypes,

  components: {
    InjectStyles,
    Card,
    SparePartTypeList,
    Message,
  },

  props: {
    instanceId: String,
  },

  setup(props) {
    const root = ref<HTMLElement>();

    const { isReady, isWebComponent, componentName, instance, store, t } = setupComponent(
      root,
      props.instanceId,
      [DataType.PartTypes]
    );

    const componentOptions = computed(
      () => store.value?.options.components?.sparePartTypes ?? ({} as ISparePartTypesOptions)
    );

    const partTypes = computed(() => store.value?.data.partTypes?.items ?? []);
    const currentPartType = computed(() => instance.value?.router.routeData?.partsType);
    const visible = computed(
      () => store.value?.options.application?.treeType === 'Parts' && componentOptions.value.visible
    );

    const setPartType = async (pt: PartType) => {
      if (instance.value && pt && pt.type && currentPartType.value != pt.type) {
        try {
          await instance.value.execute(new PartsTypeCommand({ partsType: pt.type }));
        } catch (error) {
          // Ignore Error
        }
      }
    };

    return {
      root,
      isReady,
      isWebComponent,
      componentName,
      componentOptions,
      store,
      visible,
      partTypes,
      currentPartType,
      t,
      setPartType,
    };
  },
});
