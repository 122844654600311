
import { Instance } from '@/catalogs/api/Instance';
import PIS from '@/common/api/PIS';
import Btn from '@/common/components/Btn.vue';
import Badge from '@/common/components/Badge.vue';
import Spinner from '@/common/components/Spinner.vue';
import FormInputClearable from '@/common/components/FormInputClearable.vue';
import Modal from '@/common/components/Modal.vue';
import { ValidationType, Validators } from '@/common/helpers/validation';
import { computed, defineComponent, onMounted, PropType, ref } from 'vue';
import {
  AddNotificationCommand,
  CatalogExportCommand,
  CatalogExportCommandArgs,
  DownloadJobHistoryCommand,
  CatalogGetOptionsCommand,
} from '@/catalogs/api/runtime/CommandExecutor';
import {
  CatalogOptionsResult,
  JobItem,
  JobSearchResult,
} from '@/common/services/swagger/index.defs';
import { TranslateFunc } from '@/catalogs/composables/setupComponent';
import { NotificationType } from '@/common/api/runtime/INotification';
import { CatalogExportActionData } from '@/catalogs/api/runtime/CatalogActionData';

export default defineComponent({
  components: {
    Btn,
    Badge,
    Modal,
    Spinner,
    FormInputClearable,
  },
  props: {
    t: { type: Function as PropType<TranslateFunc>, required: true },
    instanceId: String,
    data: { type: Object as PropType<CatalogExportActionData>, required: true },
  },
  emits: ['update:show'],
  setup(props, { emit }) {
    const instance = PIS.Catalogs.getInstance(props.instanceId) as Instance;
    const isLoadingJobs = ref(false);
    const canExportPrices = ref(false);
    const componentOptions = computed(() => instance.store.options.components?.catalogsExport);
    const lastScheduledJob = ref<JobItem | null>(null);
    const models = ref({
      exportPrices: false,
      emails: '',
    });
    const validators: Record<string, Validators> = {
      emails: {
        [ValidationType.required]:
          canExportPrices.value && models.value.exportPrices.valueOf() ? {} : undefined,
        [ValidationType.commaSeparatedEmails]: {},
      },
    };
    const isFormValid = computed<boolean>(
      () => !canExportPrices.value || !models.value.exportPrices || !!models.value.emails.valueOf(),
    );
    const onEmailsUpdate = (newEmails: string) => (models.value.emails = newEmails);
    const close = () => emit('update:show', false);
    const onExport = async () => {
      try {
        const commandArgs: CatalogExportCommandArgs = {
          catalogId: props.data.catalog.id,
          catalogCode: props.data.catalog.code,
          onSuccessMessage: props.t('CatalogExportReady'),
        };
        if (canExportPrices.value) {
          commandArgs.priceSettings = {
            emails: models.value.emails.split(','),
            enabled: models.value.exportPrices,
          };
        }
        const isJob: boolean | undefined = await instance.execute(
          new CatalogExportCommand(commandArgs),
        );
        if (isJob === true) {
          await instance.execute(
            new AddNotificationCommand({
              type: NotificationType.success,
              message: props.t('CatalogExportScheduled'),
            }),
          );
        }
      } catch (error) {
        // Ignore error
      }
      close();
    };

    const init = async () => {
      try {
        const catalogOptionsResult: CatalogOptionsResult | undefined = (await instance.execute(
          new CatalogGetOptionsCommand(),
        )) as CatalogOptionsResult;
        if (!catalogOptionsResult) {
          throw new Error(props.t('CatalogOptionsLoadingError'));
        }
        models.value.emails = catalogOptionsResult.email || '';
        if (catalogOptionsResult.pricesAvailable && componentOptions.value?.exportPrices) {
          canExportPrices.value = true;
          isLoadingJobs.value = true;
          const data: JobSearchResult = (await instance.execute(
            new DownloadJobHistoryCommand({}),
          )) as JobSearchResult;
          if (data && data.items) {
            lastScheduledJob.value = data.items[0];
          }
          isLoadingJobs.value = false;
        } else {
          await onExport();
        }
      } catch (error) {
        await instance.execute(
          new AddNotificationCommand({ type: NotificationType.danger, message: error.message }),
        );
      }
    };

    onMounted(() => init());

    return {
      models,
      validators,
      isFormValid,
      isLoadingJobs,
      canExportPrices,
      lastScheduledJob,
      close,
      onExport,
      onEmailsUpdate,
    };
  },
});
