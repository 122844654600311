
import { defineComponent, onMounted, PropType } from 'vue';
import { Instance } from '@/catalogs/api/Instance';
import PIS from '@/common/api/PIS';
import {
  AddNotificationCommand,
  ExportAliasesCommand,
} from '@/catalogs/api/runtime/CommandExecutor';
import { TranslateFunc } from '@/catalogs/composables/setupComponent';
import { NotificationType } from '@/common/api/runtime/INotification';
import { AliasExportActionData } from '@/catalogs/api/runtime/CatalogActionData';

export default defineComponent({
  components: {},
  props: {
    t: { type: Function as PropType<TranslateFunc>, required: true },
    instanceId: String,
    data: { type: Object as PropType<AliasExportActionData>, required: false },
  },
  emits: ['update:show'],
  setup(props, { emit }) {
    onMounted(async () => {
      const instance = PIS.Catalogs.getInstance(props.instanceId) as Instance;
      try {
        const isJob: boolean | undefined = await instance.execute(
          new ExportAliasesCommand({ onSuccessMessage: props.t('AliasesExportReady') }),
        );
        if (isJob === true) {
          await instance.execute(
            new AddNotificationCommand({
              type: NotificationType.success,
              message: props.t('AliasesExportScheduled'),
            }),
          );
        }
      } catch (error) {
        // Ignore error
      }
      emit('update:show', false);
    });
    return {};
  },
});
