import { renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withKeys as _withKeys, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  ref: "wrapperEl",
  class: "dropdown"
}
const _hoisted_2 = {
  key: 0,
  class: "dropdown-item-text text-muted text-sm"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "activator", { on: _ctx.eventHandlers }),
    _createElementVNode("ul", {
      ref: "menuEl",
      class: _normalizeClass(["dropdown-menu mt-1 overflow pis-scroll", [_ctx.open && 'show']]),
      style: _normalizeStyle([{"overflow-y":"auto"}, {
        width: _ctx.width ?? 'auto',
        'max-height': _ctx.maxHeight,
        'min-width': _ctx.minWidth ?? 'auto',
        inset: _ctx.inset,
      }])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
        return (_openBlock(), _createElementBlock("li", {
          ref_for: true,
          ref: (el) => (_ctx.itemElements[index] = el),
          key: index,
          tabindex: "0",
          onKeyup: _cache[0] || (_cache[0] = _withKeys(
//@ts-ignore
(...args) => (_ctx.onItemEnter && _ctx.onItemEnter(...args)), ["enter"])),
          onKeydown: [
            _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_ctx.onItemArrowUp && _ctx.onItemArrowUp(...args)), ["arrow-up"])),
            _cache[2] || (_cache[2] = _withKeys(
//@ts-ignore
(...args) => (_ctx.onItemArrowDown && _ctx.onItemArrowDown(...args)), ["arrow-down"])),
            _cache[3] || (_cache[3] = _withKeys(
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)), ["escape"]))
          ]
        }, [
          _renderSlot(_ctx.$slots, "item", { item: item }, () => [
            _createTextVNode(_toDisplayString(item), 1)
          ])
        ], 32))
      }), 128)),
      (_ctx.$slots.footer)
        ? (_openBlock(), _createElementBlock("li", _hoisted_2, [
            _renderSlot(_ctx.$slots, "footer")
          ]))
        : _createCommentVNode("", true)
    ], 6)
  ], 512))
}