
import { defineComponent } from 'vue';
import { InteractiveGuide } from '../services/swagger/index.defs';

export default defineComponent({
  name: 'InteractiveGuides',

  props: {
    guides: { type: Object as () => InteractiveGuide[], required: true },
  },

  setup() {
    const show = (guide: InteractiveGuide) => {
      window.open(guide.url, 'mywindow', 'location=0,status=0,scrollbars=1,toolbar=0,menubar=0');
    };

    return { show };
  },
});
