import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, withModifiers as _withModifiers, createVNode as _createVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["part"]
const _hoisted_2 = { class: "row g-0" }
const _hoisted_3 = { class: "col" }
const _hoisted_4 = { class: "text-muted" }
const _hoisted_5 = { class: "col" }
const _hoisted_6 = { class: "fw-bolder" }
const _hoisted_7 = { class: "col text-end" }
const _hoisted_8 = { class: "card-img-top position-relative pt-5" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { class: "card-body" }
const _hoisted_11 = { class: "d-flex justify-content-center text-center" }
const _hoisted_12 = {
  key: 0,
  class: "text-muted"
}
const _hoisted_13 = ["onClick"]
const _hoisted_14 = {
  key: 0,
  class: "card-footer"
}
const _hoisted_15 = { class: "text-center" }
const _hoisted_16 = {
  key: 2,
  class: "position-absolute p-2 top-0 start-0 ext-select-checkbox",
  style: {"z-index":"1","width":"auto","margin-top":"-0.1rem"}
}
const _hoisted_17 = ["checked", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InjectStyles = _resolveComponent("InjectStyles")!
  const _component_VRuntimeTemplate = _resolveComponent("VRuntimeTemplate")!
  const _component_Card = _resolveComponent("Card")!
  const _component_Image = _resolveComponent("Image")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Tooltip = _resolveComponent("Tooltip")!
  const _component_PisReplacements = _resolveComponent("PisReplacements")!
  const _component_Clickable = _resolveComponent("Clickable")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "root",
    class: _normalizeClass(["pis-component", _ctx.componentName]),
    part: _ctx.componentName
  }, [
    (_ctx.isReady && _ctx.componentOptions.visible)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.isWebComponent)
            ? (_openBlock(), _createBlock(_component_InjectStyles, {
                key: 0,
                items: _ctx.store?.options.styles
              }, null, 8, ["items"]))
            : _createCommentVNode("", true),
          (!_ctx.hideIfInitial && _ctx.configurators && _ctx.configuratorOptions.visible && _ctx.currentCIDName)
            ? (_openBlock(), _createBlock(_component_Card, {
                key: 1,
                class: "mb-4 bg-light",
                "use-markup": true
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, [
                      _createElementVNode("div", _hoisted_4, _toDisplayString([
                    _ctx.currentCIDName,
                    _ctx.configurators.length > 1 ? _ctx.t('Configurators') : _ctx.t('Configurator'),
                  ]
                    .join(' - ')
                    .concat(':')), 1)
                    ])
                  ]),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.configurators, (conf) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "row g-0",
                      key: conf.code
                    }, [
                      _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("div", _hoisted_6, _toDisplayString(conf.productId), 1)
                      ]),
                      _createElementVNode("div", _hoisted_7, [
                        (_ctx.configuratorOptions.actionTemplate)
                          ? (_openBlock(), _createBlock(_component_VRuntimeTemplate, {
                              key: 0,
                              template: _ctx.configuratorOptions.actionTemplate,
                              "template-props": { t: _ctx.t, item: conf, showConfigurator: _ctx.showConfigurator, emit: _ctx.emit }
                            }, null, 8, ["template", "template-props"]))
                          : _createCommentVNode("", true)
                      ])
                    ]))
                  }), 128))
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (!_ctx.hideIfInitial && _ctx.productsItems && _ctx.productsItems.length)
            ? (_openBlock(), _createElementBlock("div", {
                key: 2,
                class: _normalizeClass(["row", [_ctx.widerThan('md') ? 'g-4' : 'g-3']])
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.productsItems, (item) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: _normalizeClass([
            _ctx.widerThan('xl')
              ? 'col-3'
              : _ctx.widerThan('lg')
              ? 'col-4'
              : _ctx.widerThan('sm')
              ? 'col-6'
              : 'col-12',
          ]),
                    key: item.productId
                  }, [
                    _createElementVNode("div", {
                      class: _normalizeClass(["card h-100 pt-0", {
              'bg-light': item.exactMatch,
              'ext-selected': _ctx.instance?.selection.isSelected(item),
            }])
                    }, [
                      _createElementVNode("div", _hoisted_8, [
                        _createVNode(_component_Image, {
                          src: (item.images || [])[0]?.url,
                          "max-size": 300,
                          role: "button",
                          "hide-frame": "",
                          class: "card-img-top",
                          style: {"max-width":"80% !important","margin":"auto"},
                          clickable: _ctx.componentOptions.navigateOnContentClick && !item.inactive,
                          onClick: _withModifiers(($event: any) => (
                  _ctx.componentOptions.navigateOnContentClick && !item.inactive && _ctx.details(item)
                ), ["prevent"])
                        }, null, 8, ["src", "clickable", "onClick"]),
                        (_ctx.componentOptions.imageOverlayTemplate)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                              _createVNode(_component_VRuntimeTemplate, {
                                template: _ctx.componentOptions.imageOverlayTemplate,
                                "template-props": { instance: _ctx.instance, item: item, emit: _ctx.emit, t: _ctx.t }
                              }, null, 8, ["template", "template-props"])
                            ]))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_10, [
                        _createElementVNode("div", _hoisted_11, [
                          (item.additionalInfo && item.additionalInfo.length)
                            ? (_openBlock(), _createBlock(_component_Tooltip, {
                                key: 0,
                                text: _ctx.additionalInfo(item.additionalInfo)
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_Icon, {
                                    class: "me-2 text-warning",
                                    glyph: "warning"
                                  })
                                ]),
                                _: 2
                              }, 1032, ["text"]))
                            : _createCommentVNode("", true),
                          (_ctx.componentOptions.titleTemplate)
                            ? (_openBlock(), _createBlock(_component_VRuntimeTemplate, {
                                key: 1,
                                template: _ctx.componentOptions.titleTemplate,
                                "template-props": { instance: _ctx.instance, item, emit: _ctx.emit, details: _ctx.details, attrValue: _ctx.attrValue, t: _ctx.t }
                              }, null, 8, ["template", "template-props"]))
                            : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                                (item.inactive)
                                  ? (_openBlock(), _createElementBlock("strong", _hoisted_12, _toDisplayString(_ctx.attrValue(item, '#DisplayName')), 1))
                                  : (_openBlock(), _createElementBlock("a", {
                                      key: 1,
                                      href: "#",
                                      onClick: _withModifiers(($event: any) => (_ctx.details(item)), ["prevent"])
                                    }, [
                                      _createElementVNode("strong", null, _toDisplayString(_ctx.attrValue(item, '#DisplayName')), 1)
                                    ], 8, _hoisted_13))
                              ], 64))
                        ]),
                        (_ctx.componentOptions.showAllIdentifiers)
                          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.attrValues(item, '#AllIdentifiers'), (val, index) => {
                              return (_openBlock(), _createElementBlock("div", {
                                key: index,
                                class: "text-center small text-muted fw-bold my-2"
                              }, _toDisplayString(val), 1))
                            }), 128))
                          : _createCommentVNode("", true),
                        (_ctx.componentOptions.itemTemplate)
                          ? (_openBlock(), _createBlock(_component_VRuntimeTemplate, {
                              key: 1,
                              template: _ctx.componentOptions.itemTemplate,
                              "template-props": { instance: _ctx.instance, item, emit: _ctx.emit, details: _ctx.details, attrValue: _ctx.attrValue, attrValues: _ctx.attrValues, t: _ctx.t }
                            }, null, 8, ["template", "template-props"]))
                          : _createCommentVNode("", true),
                        (item.replacements?.length && _ctx.componentOptions.showReplacements)
                          ? (_openBlock(), _createBlock(_component_PisReplacements, {
                              key: 2,
                              class: "text-center mt-3",
                              instance: _ctx.instance,
                              replacements: item.replacements,
                              visible: _ctx.replacementsVisible
                            }, null, 8, ["instance", "replacements", "visible"]))
                          : _createCommentVNode("", true)
                      ]),
                      (_ctx.componentOptions.actionTemplate)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                            _createElementVNode("div", _hoisted_15, [
                              _createVNode(_component_VRuntimeTemplate, {
                                template: _ctx.componentOptions.actionTemplate,
                                "template-props": { instance: _ctx.instance, item, emit: _ctx.emit, details: _ctx.details, attrValue: _ctx.attrValue, attrValues: _ctx.attrValues, t: _ctx.t }
                              }, null, 8, ["template", "template-props"])
                            ])
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.previewEnabled)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                            (!item.inactive)
                              ? (_openBlock(), _createBlock(_component_Clickable, {
                                  key: 0,
                                  class: "position-absolute top-0 start-0 p-2 d-flex ext-icon-preview",
                                  style: _normalizeStyle({
                  'margin-left': _ctx.componentOptions.selectable ? '2rem' : '0',
                }),
                                  title: _ctx.t('Preview', true),
                                  onClick: ($event: any) => (_ctx.preview(item))
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_Icon, {
                                      glyph: "preview",
                                      lg: ""
                                    })
                                  ]),
                                  _: 2
                                }, 1032, ["style", "title", "onClick"]))
                              : (_openBlock(), _createElementBlock("span", {
                                  key: 1,
                                  class: "position-absolute top-0 start-0 p-2 d-flex ext-icon-preview text-muted",
                                  style: _normalizeStyle({
                  'margin-left': _ctx.componentOptions.selectable ? '2rem' : '0',
                })
                                }, [
                                  _createVNode(_component_Icon, {
                                    glyph: "preview",
                                    lg: ""
                                  })
                                ], 4))
                          ], 64))
                        : _createCommentVNode("", true),
                      (_ctx.componentOptions.selectable)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                            _createElementVNode("input", {
                              type: "checkbox",
                              class: "form-check-input",
                              checked: _ctx.instance?.selection.isSelected(item),
                              onClick: ($event: any) => (_ctx.instance?.selection.toggle(item))
                            }, null, 8, _hoisted_17)
                          ]))
                        : _createCommentVNode("", true)
                    ], 2)
                  ], 2))
                }), 128))
              ], 2))
            : _createCommentVNode("", true)
        ], 64))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}