
import { defineComponent, ref, computed, watch } from 'vue';
import { ComponentName } from '@/catalogs/api/configuration/components/ComponentName';
import { setupComponent } from '@/catalogs/composables/setupComponent';

// Common components
import InjectStyles from '@/common/components/InjectStyles.vue';
import LoadingIndicator from './CatalogsLoadingIndicator.vue';

// Catalogs view
import CatalogsSearchTable from './catalogs/CatalogsSearchTable.vue';
import CatalogsSearchForm from './catalogs/CatalogsSearchForm.vue';

// Products view
import ProductsSearchTable from './products/ProductsSearchTable.vue';
import ProductsSearchFiltersStatus from './products/ProductsSearchFiltersStatus.vue';
import ProductsSearchProductCategories from './products/ProductsSearchProductCategories.vue';
import ProductsSearchPartCategories from './products/ProductsSearchPartCategories.vue';
import ProductsBreadcrumbs from './products/ProductsBreadcrumbs.vue';
import ProductsSearchToolbar from './products/ProductsSearchToolbar.vue';
import ProductsSearchPagination from './products/ProductsSearchPagination.vue';
import CatalogsSearchPagination from './catalogs/CatalogsSearchPagination.vue';
import ProductsTableView from './views/ProductsTableView.vue';
import ProductsListView from './views/ProductsListView.vue';
import ProductsGridView from './views/ProductsGridView.vue';
import ProductsSearchList from './products/ProductsSearchList.vue';
import ProductsSearchGrid from './products/ProductsSearchGrid.vue';
import ProductsSearchFilters from './products/ProductsSearchFilters.vue';
import ProductsSearchForm from './products/ProductsSearchForm.vue';
import CatalogProductSwitch from '@/catalogs/components/common/CatalogProductSwitch.vue';
import ProductsPreviewView from '@/catalogs/components/preview/ProductsPreviewView.vue';
import ProductsSearchNoResults from '@/catalogs/components/products/ProductsSearchNoResults.vue';
import CatalogActionModals from '@/catalogs/components/common/CatalogActionModals.vue';
import CatalogProductActionModals from '@/catalogs/components/common/CatalogProductActionModals.vue';
import CatalogsSearchToolbar from './catalogs/CatalogsSearchToolbar.vue';
import CatalogsNotifications from './CatalogsNotifications.vue';
import ProductsSearchSelection from './products/ProductsSearchSelection.vue';
import CatalogProductsMobileNav from '@/catalogs/components/CatalogProductsMobileNav.vue';
import ProductsSearchAliasFilters from './products/ProductsSearchAliasFilters.vue';
import AliasesSearchTable from './aliases/AliasesSearchTable.vue';
import AliasesSearchToolbar from './aliases/AliasesSearchToolbar.vue';
import AliasActionModals from './common/AliasActionModals.vue';
import AliasesMobileNav from './AliasesMobileNav.vue';

// Preview View

export default defineComponent({
  name: ComponentName.layout,

  components: {
    InjectStyles,
    LoadingIndicator,
    CatalogsSearchTable,
    CatalogsSearchForm,
    ProductsSearchTable,
    ProductsSearchFilters,
    ProductsSearchFiltersStatus,
    ProductsSearchProductCategories,
    ProductsSearchPartCategories,
    ProductsBreadcrumbs,
    ProductsSearchToolbar,
    ProductsSearchPagination,
    CatalogsSearchPagination,
    ProductsTableView,
    ProductsListView,
    ProductsGridView,
    ProductsSearchList,
    ProductsSearchGrid,
    ProductsSearchForm,
    CatalogProductSwitch,
    CatalogActionModals,
    CatalogProductActionModals,
    CatalogsSearchToolbar,
    ProductsPreviewView,
    ProductsSearchNoResults,
    CatalogsNotifications,
    ProductsSearchSelection,
    CatalogProductsMobileNav,
    ProductsSearchAliasFilters,
    AliasesSearchTable,
    AliasActionModals,
    AliasesSearchToolbar,
    AliasesMobileNav,
  },

  props: {
    instanceId: String,
  },

  setup(props) {
    const root = ref<HTMLElement>();

    const {
      componentName,
      instance,
      isWebComponent,
      isReady,
      translationsReady,
      store,
      routeData,
      widerThan,
      t,
    } = setupComponent(root, props.instanceId);

    const allowMobileView = computed(() => store.value?.options.allowMobileView);

    const scrollTo = (elem: HTMLElement | undefined) =>
      elem?.scrollIntoView({ behavior: 'smooth' });

    const scrollTop = () => {
      const args = { isHandled: false };
      instance.value?.eventBus.emit('scroll-to-top', args);
      if (!args.isHandled) {
        scrollTo(root.value);
      }
    };

    const routeView = computed(() => routeData.value?.view);

    // Scroll to top each time the route view changes. We need to use computed
    // because watching the routeData gives no chance to see if view has changed.
    watch(routeView, async (view, oldView) => {
      if (view && oldView && view !== oldView) {
        scrollTop();
      }
    });

    return {
      root,
      componentName,
      isWebComponent,
      isReady,
      instance,
      translationsReady,
      store,
      routeData,
      allowMobileView,
      widerThan,
      t,
      scrollTop,
    };
  },
});
