import {
  FilterSearchResult,
  BreadcrumbsSearchResult,
  ClassificationsSearchResult,
} from './../../../common/services/swagger/index.defs';
import {
  CatalogProductsSearchResult,
  CatalogSearchResult,
  ProductPreview,
} from '@/common/services/swagger/index.defs';
import { reactive } from 'vue';
import { DataType } from '../configuration/application/DataType';
import { Instance } from '../Instance';
import { ILoadEvent } from './ILoadEvent';

export class StoreService {
  constructor(private instance: Instance) {}

  public async setData(type: DataType, data: unknown): Promise<void> {
    if (data === undefined) {
      const loadEvent: ILoadEvent = {
        type,
        data: undefined,
        instance: this.instance,
      };
      this.setStoreData(loadEvent);
      return;
    }

    const interceptFunc = this.instance.store?.options.dataInterceptor;

    const loadEvent: ILoadEvent = {
      type,
      data: reactive(data as Record<string | number, unknown>),
      instance: this.instance,
    };

    if (interceptFunc) {
      this.setStoreData(await interceptFunc(loadEvent));
    } else {
      this.setStoreData(loadEvent);
    }
  }

  setStoreData({ type, data }: ILoadEvent): void {
    if (!this.instance.store) return;

    const storeData = this.instance.store.data;

    switch (type) {
      case DataType.Catalogs:
        storeData.catalogs = data as CatalogSearchResult;
        break;

      case DataType.ProductPreview:
        storeData.productPreview = data as ProductPreview;
        break;

      case DataType.Products:
        storeData.products = data as CatalogProductsSearchResult;
        break;

      case DataType.ProductClassifications:
        storeData.productClassifications = data as ClassificationsSearchResult;
        break;

      case DataType.PartClassifications:
        storeData.partClassifications = data as ClassificationsSearchResult;
        break;

      case DataType.Filters:
        storeData.filters = data as FilterSearchResult;
        break;

      case DataType.Breadcrumbs:
        storeData.breadcrumbs = data as BreadcrumbsSearchResult;
        break;

      default:
        this.instance.logger.log('Setting store data not handled', { type, data });
    }
  }
}
