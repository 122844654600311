import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, createVNode as _createVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-grow-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([['alert', `alert-${_ctx.notification.type}`, 'alert-dismissible'], "d-flex flex-row align-items-center justify-content-between fade show"]),
    role: "alert"
  }, [
    (_ctx.icon)
      ? (_openBlock(), _createBlock(_component_Icon, {
          key: 0,
          glyph: _ctx.icon,
          lg: ""
        }, null, 8, ["glyph"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _createElementVNode("button", {
      type: "button",
      class: "d-flex pis-alert-close-button",
      "aria-label": "Close",
      onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.closeAlert && _ctx.closeAlert(...args)), ["prevent"]))
    }, [
      _createVNode(_component_Icon, {
        glyph: "close",
        sm: ""
      })
    ])
  ], 2))
}