
import { computed, defineComponent, ref } from 'vue';

import { ComponentName } from '@/products/api/configuration/components/ComponentName';
import { DisplayMode } from '@/products/api/runtime/IRouteData';
import { setupComponent } from '@/products/composables/setupComponent';
import {
  DisplayModeCommand,
  ExportCommand,
  SetPageSizeCommand,
  SetSortByCommand,
} from '@/products/api/runtime/CommandExecutor';
import { SortFieldEnum } from '@/common/services/swagger/index.defs';

import InjectStyles from '@/common/components/InjectStyles.vue';
import Icon from '@/common/components/Icon.vue';
import Clickable from '@/common/components/Clickable.vue';
import PaginationAmount from '@/common/components/PaginationAmount.vue';
import Btn from '@/common/components/Btn.vue';
import Dropdown from '@/common/components/Dropdown.vue';
import Arrow from '@/common/components/Arrow.vue';
import { IExportMenuItem } from '@/products/api/configuration/components/IExportMenuOptions';
import VRuntimeTemplate from 'vue3-runtime-template';
export default defineComponent({
  name: ComponentName.searchToolbar,

  components: {
    InjectStyles,
    PaginationAmount,
    Icon,
    Clickable,
    Btn,
    Dropdown,
    Arrow,
    VRuntimeTemplate,
  },

  props: {
    instanceId: String,
  },

  setup(props) {
    const root = ref<HTMLElement>();
    const exportMenuOpen = ref(false);
    const sortByItems = SortFieldEnum;

    const {
      componentName,
      isReady,
      isWebComponent,
      instance,
      store,
      routeData,
      t,
      widerThan,
      narrowerThan,
      emit,
    } = setupComponent(root, props.instanceId);

    const hideIfInitial = computed(() => {
      const history = instance.value?.router.history;
      return store.value?.options.hideInitialSearchResults && history && history.length <= 2;
    });

    const displayModes = [
      {
        type: DisplayMode.table,
        tName: 'DisplayModeTable',
        icon: 'table',
        relatedComponent: ComponentName.tableView,
      },
      {
        type: DisplayMode.list,
        tName: 'DisplayModeList',
        icon: 'list',
        relatedComponent: ComponentName.listView,
      },
      {
        type: DisplayMode.grid,
        tName: 'DisplayModeGrid',
        icon: 'grid',
        relatedComponent: ComponentName.gridView,
      },
    ];

    const componentOptions = computed(() => store.value?.options.components?.searchToolbar);

    const allowedDisplayModes = computed(
      () => store.value?.options.components?.searchToolbar?.displayModes,
    );

    const visibleDisplayModes = computed(() => {
      if (!allowedDisplayModes.value) {
        return displayModes;
      }

      const filtered = displayModes.filter(
        ({ type, relatedComponent }) =>
          allowedDisplayModes.value?.includes(type) &&
          instance.value?.registeredComponents.has(relatedComponent),
      );

      const ordered: any[] = [];

      allowedDisplayModes.value.forEach((type) => {
        var item = filtered.find((a) => a.type == type);
        if (item) {
          ordered.push(item);
        }
      });

      return ordered;
    });

    const totalCount = computed(() => store.value?.data.products?.totalCount);
    const pagination = computed(() => routeData.value?.pagination);

    const sortBy = computed({
      get: () => routeData.value?.sortField,
      set: async (sortField: any) => {
        try {
          await instance.value?.execute(new SetSortByCommand({ sortField }));
        } catch (error) {
          // Ignore Error
        }
      },
    });

    const pageSize = computed({
      get: () => pagination.value?.pageSize ?? 24,
      set: async (pageSize: number) => {
        try {
          await instance.value?.execute(new SetPageSizeCommand({ pageSize }));
        } catch (error) {
          // Ignore Error
        }
      },
    });

    const pageSizeItems = computed(() => store.value?.options.components?.searchToolbar?.pageSizes);
    const toolbarVisible = computed(
      () =>
        store.value?.options.components?.searchToolbar?.visible &&
        store.value.data.products?.items?.length,
    );

    const changeDisplayMode = async (mode: DisplayMode) => {
      try {
        await instance.value?.execute(new DisplayModeCommand({ mode }));
      } catch (error) {
        // Ignore Error
      }
    };

    const exportMenu = computed(() => store.value?.options.components?.exportMenu);

    const executeExportMenuItemCommand = async (item: IExportMenuItem) => {
      try {
        await instance.value?.execute(new ExportCommand(item));
      } catch (error) {
        // Ignore Error
      }
    };

    const isModeActive = ({ type }) => type === routeData.value?.displayMode;

    return {
      root,
      componentName,
      isReady,
      isWebComponent,
      store,
      routeData,
      toolbarVisible,
      hideIfInitial,
      displayModes,
      visibleDisplayModes,
      totalCount,
      pagination,
      sortByItems,
      sortBy,
      pageSizeItems,
      pageSize,
      exportMenuOpen,
      exportMenu,
      componentOptions,
      instance,
      executeExportMenuItemCommand,
      t,
      widerThan,
      narrowerThan,
      changeDisplayMode,
      isModeActive,
      emit,
    };
  },
});
