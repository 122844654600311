import ProductsBreadcrumbs from './ProductsBreadcrumbs.vue';
import ProductsInstance from './ProductsInstance.vue';
import ProductsLayout from './ProductsLayout.vue';
import ProductsLoadingIndicator from './ProductsLoadingIndicator.vue';
import ProductsMobileNav from './ProductsMobileNav.vue';

import ProductsPreview from './preview/ProductsPreviewView.vue';

import ProductsSearchCategories from './search/ProductsSearchCategories.vue';
import ProductsSearchFilters from './search/ProductsSearchFilters.vue';
import ProductsSearchFiltersStatus from './search/ProductsSearchFiltersStatus.vue';
import ProductsSearchForm from './search/ProductsSearchForm.vue';
import ProductsSearchGrid from './search/ProductsSearchGrid.vue';
import ProductsSearchHeader from './search/ProductsSearchHeader.vue';
import ProductsSearchList from './search/ProductsSearchList.vue';
import ProductsSearchNoResults from './search/ProductsSearchNoResults.vue';
import ProductsSearchPagination from './search/ProductsSearchPagination.vue';
import ProductsSearchTable from './search/ProductsSearchTable.vue';
import ProductsSearchToolbar from './search/ProductsSearchToolbar.vue';
import ProductsSearchNarrowByProduct from './search/ProductsSearchNarrowByProduct.vue';
import ProductsSearchPartTypes from './search/ProductsSearchPartTypes.vue';
import ProductsSearchFavoriteViews from './search/ProductsSearchFavoriteViews.vue';
import ProductsSearchFavoriteCategories from './search/ProductsSearchFavoriteCategories.vue';
import ProductsSearchSelection from './search/ProductsSearchSelection.vue';
import ProductsSearchGridHorizontal from './search/ProductsSearchGridHorizontal.vue';

import ProductsDetailsData from './details/ProductsDetailsData.vue';
import ProductsDetailsActions from './details/ProductsDetailsActions.vue';
import ProductsDetailsGallery from './details/ProductsDetailsGallery.vue';
import ProductDetailsGeneralInformation from './details/ProductDetailsGeneralInformation.vue';
import ProductsDetailsHeader from './details/ProductsDetailsHeader.vue';
import ProductDetailsIdentifiers from './details/ProductDetailsIdentifiers.vue';
import ProductDetailsReplacements from './details/ProductDetailsReplacements.vue';
import ProductDetailsNavigation from './details/ProductDetailsNavigation.vue';
import ProductDetailsRelatedLinks from './details/ProductDetailsRelatedLinks.vue';
import ProductDetailsAttributeGroups from './details/ProductDetailsAttributeGroups.vue';
import ProductDetailsRelationships from './details/ProductDetailsRelationships.vue';
import ProductDetailsClassifications from './details/ProductDetailsClassifications.vue';

import ProductsSearchView from './views/ProductsSearchView.vue';
import ProductsDetailsView from './views/ProductsDetailsView.vue';
import ProductsTableView from './views/ProductsTableView.vue';
import ProductsListView from './views/ProductsListView.vue';
import ProductsGridView from './views/ProductsGridView.vue';
import ProductsSearchNoResultsView from './views/ProductsSearchNoResultsView.vue';
// Compare View
import ProductCompare from './compare/ProductCompare.vue';
export default [
  ProductsBreadcrumbs,
  ProductsInstance,
  ProductsLayout,
  ProductsLoadingIndicator,
  ProductsMobileNav,

  ProductsPreview,

  ProductsSearchCategories,
  ProductsSearchFilters,
  ProductsSearchFiltersStatus,
  ProductsSearchForm,
  ProductsSearchGrid,
  ProductsSearchHeader,
  ProductsSearchList,
  ProductsSearchNoResults,
  ProductsSearchPagination,
  ProductsSearchTable,
  ProductsSearchToolbar,
  ProductsSearchView,
  ProductsSearchNarrowByProduct,
  ProductsSearchPartTypes,
  ProductsSearchFavoriteViews,
  ProductsSearchFavoriteCategories,
  ProductsSearchSelection,
  ProductsSearchGridHorizontal,

  ProductsDetailsData,
  ProductsDetailsGallery,
  ProductsDetailsHeader,
  ProductsDetailsView,
  ProductDetailsGeneralInformation,
  ProductDetailsIdentifiers,
  ProductDetailsReplacements,
  ProductsDetailsActions,
  ProductDetailsNavigation,
  ProductDetailsRelatedLinks,
  ProductDetailsAttributeGroups,
  ProductDetailsRelationships,
  ProductDetailsClassifications,

  ProductsTableView,
  ProductsListView,
  ProductsGridView,
  ProductsSearchNoResultsView,
  ProductCompare,
];
