
import { computed, defineComponent } from 'vue';
import Icon from './Icon.vue';

export default defineComponent({
  components: {
    Icon,
  },

  props: {
    dir: {
      type: String,
      default: 'right',
    },
    sm: Boolean,
  },

  setup: (props) => ({
    angle: computed(() => {
      switch (props.dir) {
        case 'down':
          return 90;
        case 'left':
          return 180;
        case 'up':
          return 270;
        default:
          return 0;
      }
    }),
  }),
});
