import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-nowrap pis-text-ellipsis" }
const _hoisted_2 = { class: "text-nowrap pis-text-ellipsis" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Arrow = _resolveComponent("Arrow")!
  const _component_Btn = _resolveComponent("Btn")!
  const _component_Tooltip = _resolveComponent("Tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.lastSearchIndex > 1 && _ctx.previousProductName)
      ? (_openBlock(), _createBlock(_component_Tooltip, {
          key: 0,
          text: _ctx.previousProductName,
          placement: 'bottom',
          trigger: 'hover focus'
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Btn, {
              class: "pis-mw-10-rem ext-prod-back",
              onClick: _withModifiers(_ctx.navigateBack, ["prevent"])
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Arrow, {
                  dir: "left",
                  class: "me-1"
                }),
                _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.previousProductName ?? _ctx.t('PreviousProduct', true)), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        }, 8, ["text"]))
      : _createCommentVNode("", true),
    (_ctx.allowBack)
      ? (_openBlock(), _createBlock(_component_Btn, {
          key: 1,
          class: "pis-mw-10-rem ext-back",
          onClick: _withModifiers(_ctx.navigateToSearch, ["prevent"])
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Arrow, {
              dir: "left",
              class: "me-1"
            }),
            _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.t('BackToSearch', true)), 1)
          ]),
          _: 1
        }, 8, ["onClick"]))
      : _createCommentVNode("", true)
  ], 64))
}