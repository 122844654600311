
import { computed, defineComponent, ref } from 'vue';
import { ComponentName } from '@/products/api/configuration/components/ComponentName';
import { setupComponent } from '@/products/composables/setupComponent';
import { DataType } from '@/products/api/configuration/application/DataType';

import InjectStyles from '@/common/components/InjectStyles.vue';

export default defineComponent({
  name: ComponentName.searchHeader,

  components: {
    InjectStyles,
  },

  props: {
    instanceId: String,
  },

  setup(props) {
    const root = ref<HTMLElement>();

    const { componentName, isReady, isWebComponent, store, t } = setupComponent(
      root,
      props.instanceId,
      [DataType.Breadcrumbs]
    );

    const title = computed(() => {
      // searchTitle overrides title from categories
      if (store.value?.data.searchTitle) {
        return store.value?.data.searchTitle;
      }

      const breadcrumbs = store.value ? store.value?.data.breadcrumbs?.items : null;

      if (breadcrumbs) {
        return breadcrumbs[breadcrumbs.length - 1]?.name;
      }

      return null;
    });

    const isMasterProductSelected = computed(() => {
      return store.value?.data.searchTitle !== undefined;
    });

    const headerVisible = computed(() => {
      return store.value?.options?.components?.searchHeader?.visible;
    });

    return {
      root,
      componentName,
      isReady,
      headerVisible,
      isWebComponent,
      store,
      title,
      isMasterProductSelected,
      t,
    };
  },
});
