
import { PropType, defineComponent } from 'vue';
import ClassificationNode from './ClassificationNode.vue';
import { ClassificationsBrowserNode } from '@/common/services/swagger/index.defs';
import { TranslateFunc } from '@/classifications/composables/setupComponent';

export default defineComponent({
  props: {
    items: { type: Object as () => ClassificationsBrowserNode[], required: true },
    t: { type: Function as PropType<TranslateFunc>, required: true },
    instanceId: { type: String, required: true },
  },

  setup() {
    return {};
  },
  components: { ClassificationNode },
});
