
import { computed, defineComponent, ref } from 'vue';
import { ComponentName } from '@/products/api/configuration/components/ComponentName';
import { setupComponent } from '@/products/composables/setupComponent';

import InjectStyles from '@/common/components/InjectStyles.vue';
import Message from '@/common/components/Message.vue';
import ProductsListItem from '@/common/components/ProductsListItem.vue';
import Tooltip from '@/common/components/Tooltip.vue';
import Icon from '@/common/components/Icon.vue';
import { IRelatedLinksOptions } from '@/products/api/configuration/components/IRelatedLinksOptions';

export default defineComponent({
  name: ComponentName.detailsRelatedLinks,

  components: {
    InjectStyles,
    Message,
    ProductsListItem,
    Tooltip,
    Icon,
  },

  props: {
    instanceId: String,
  },

  setup(props) {
    const root = ref<HTMLElement>();

    const { componentName, isReady, isWebComponent, store, t, widerThan } = setupComponent(
      root,
      props.instanceId,
    );
    const relatedLinks = computed(() => store.value?.data.relatedLinks?.items);
    const componentOptions = computed(
      () => store.value?.options.components?.relatedLinks ?? ({} as IRelatedLinksOptions),
    );

    return {
      root,
      componentName,
      componentOptions,
      isReady,
      isWebComponent,
      store,
      relatedLinks,
      t,
      widerThan,
    };
  },
});
