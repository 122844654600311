export enum NotificationType {
  success = 'success',
  danger = 'danger',
  warning = 'warning',
  info = 'info',
}
export interface INotification {
  dismissAfter?: number | undefined;
  message: string;
  type: NotificationType;
}
