export type ValidationValue = string | null | undefined;
export type ValidatorOptions = Record<string, unknown>;
export type ValidatorErrors = Record<string, unknown> | null;
export type ValidationErrors = Partial<Record<ValidationType, ValidatorErrors>>;

export type ValidatorFunction<O extends ValidatorOptions> = (
  value: ValidationValue,
  options: O,
) => ValidatorErrors;

export enum ValidationType {
  required = 'required',
  email = 'email',
  commaSeparatedEmails = 'csemails',
  minLength = 'minLength',
  min = 'min',
  max = 'max',
}
export type Validators = Partial<Record<ValidationType, ValidatorOptions>>;

export type ValidationHandler = (
  value: ValidationValue,
  validators: Validators,
) => ValidationErrors;

export const isString = (value: ValidationValue): value is string => typeof value === 'string';

export const castToInteger = (value: ValidationValue): number | null => {
  const casted: number = parseInt(`${value}`, 10);
  if (isNaN(casted) || casted.toString().length !== `${value}`.length) {
    return null;
  }
  return casted;
};

export const requiredValidator: ValidatorFunction<ValidatorOptions> = (value: ValidationValue) =>
  value ? null : {};

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const emailValidator: ValidatorFunction<ValidatorOptions> = (value: ValidationValue) =>
  emailRegex.test(`${value}`) ? null : {};

export const commaSeparatedEmailsValidator: ValidatorFunction<ValidatorOptions> = (
  value: ValidationValue,
) => {
  const emails = `${value}`.split(',').map((value: string) => value.trim());
  if (!emails.length) {
    return {};
  }
  return emails.every((value: string) => emailValidator(value, {}) === null) ? null : {};
};

export type MinLengthValidatorOptions = {
  minLength: number;
};

export const minLengthValidator: ValidatorFunction<MinLengthValidatorOptions> = (
  value: ValidationValue,
  options: MinLengthValidatorOptions,
) => (isString(value) && value.length >= options.minLength ? null : {});

export type MinValidatorOptions = {
  min: number;
};

export const minValidator: ValidatorFunction<MinValidatorOptions> = (
  value: ValidationValue,
  options: MinValidatorOptions,
) => {
  const integer = castToInteger(value);
  if (integer === null || integer < options.min) {
    return {};
  }
  return null;
};

export type MaxValidatorOptions = {
  max: number;
};

export const maxValidator: ValidatorFunction<MaxValidatorOptions> = (
  value: ValidationValue,
  options: MaxValidatorOptions,
) => {
  const integer = castToInteger(value);
  if (integer === null || integer > options.max) {
    return {};
  }
  return null;
};

const validationMap: Record<ValidationType, ValidatorFunction<any>> = {
  [ValidationType.required]: requiredValidator,
  [ValidationType.email]: emailValidator,
  [ValidationType.commaSeparatedEmails]: commaSeparatedEmailsValidator,
  [ValidationType.minLength]: minLengthValidator,
  [ValidationType.min]: minValidator,
  [ValidationType.max]: maxValidator,
};

export const validate: ValidationHandler = (
  value: ValidationValue,
  validators: Validators,
): ValidationErrors =>
  Object.entries(validators).reduce(
    (errors: ValidationErrors, [validatorName, validatorOptions]) => {
      const validator: ValidatorFunction<any> | undefined = validationMap[validatorName];
      if (!validator) {
        throw new Error(`Invalid validator name ${validatorName}!`);
      }
      const validatorErrors: ValidatorErrors = validator(value, validatorOptions);
      if (validatorErrors) {
        errors[validatorName] = validatorErrors;
      }
      return errors;
    },
    {},
  );
