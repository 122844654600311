import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "d-flex align-items-center justify-content-between" }
const _hoisted_2 = { class: "pis-label me-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Badge = _resolveComponent("Badge")!
  const _component_Clickable = _resolveComponent("Clickable")!
  const _component_ScrollableList = _resolveComponent("ScrollableList")!

  return (_ctx.isReady)
    ? (_openBlock(), _createBlock(_component_ScrollableList, {
        key: 0,
        class: "list-group list-group-flush",
        "key-field": "attributeName",
        items: _ctx.items,
        height: _ctx.height
      }, {
        item: _withCtx(({ item }) => [
          _createVNode(_component_Clickable, {
            class: _normalizeClass(["list-group-item list-group-item-action px-1 d-block", [_ctx.clickableClass(item)]]),
            onClick: _withModifiers(($event: any) => (_ctx.$emit('item-click', item)), ["prevent"])
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, _toDisplayString(item.attributeName), 1),
                _createElementVNode("div", null, [
                  _createVNode(_component_Badge, {
                    class: "ms-auto",
                    textContent: _toDisplayString(item.hitCount)
                  }, null, 8, ["textContent"])
                ])
              ])
            ]),
            _: 2
          }, 1032, ["class", "onClick"])
        ]),
        _: 1
      }, 8, ["items", "height"]))
    : _createCommentVNode("", true)
}