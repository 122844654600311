
import { computed, defineComponent, ref } from 'vue';
import { ComponentName } from '@/products/api/configuration/components/ComponentName';
import { setupComponent } from '@/products/composables/setupComponent';

import InjectStyles from '@/common/components/InjectStyles.vue';
import { DataType } from '@/products/api/configuration/application/DataType';
import { BreadcrumbItem } from '@/common/services/swagger/index.defs';
import { ClassificationCommand } from '@/products/api/runtime/CommandExecutor';
import Message from '@/common/components/Message.vue';

export default defineComponent({
  name: ComponentName.detailsClassifications,

  components: {
    InjectStyles,
    Message,
  },

  props: {
    instanceId: String,
  },

  setup(props) {
    const root = ref<HTMLElement>();

    const { instance, componentName, isReady, isWebComponent, store, t } = setupComponent(
      root,
      props.instanceId,
      [DataType.ProductClassifications],
    );

    const classifications = computed(
      () =>
        store.value?.data.productClassifications?.items as { [key: string]: BreadcrumbItem[][] },
    );

    const setCategory = async (item: BreadcrumbItem) => {
      if (instance.value && item) {
        try {
          await instance.value.execute(
            new ClassificationCommand({ cid: item.cid, clearSearch: true }),
          );
        } catch (error) {
          // Ignore Error
        }
      }
    };

    return {
      root,
      componentName,
      isReady,
      isWebComponent,
      store,
      classifications,
      setCategory,
      t,
    };
  },
});
