import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, renderSlot as _renderSlot, renderList as _renderList, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "position-relative" }
const _hoisted_2 = {
  key: 0,
  class: "col-12 d-flex align-items-center justify-content-center pt-2 position-relative"
}
const _hoisted_3 = { class: "d-flex flex-grow-1 justify-content-center" }
const _hoisted_4 = {
  key: 1,
  class: "position-relative"
}
const _hoisted_5 = {
  key: 0,
  class: "position-relative ext-gallery-modal"
}
const _hoisted_6 = { class: "px-2 position-absolute top-50 start-0 text-dark fs-3 ext-arrow-left" }
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "px-2 position-absolute top-50 end-0 text-dark fs-3 ext-arrow-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Image = _resolveComponent("Image")!
  const _component_Clickable = _resolveComponent("Clickable")!
  const _component_Arrow = _resolveComponent("Arrow")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.images && _ctx.images.length)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["pis-gallery", [_ctx.showPreview ? 'row g-2' : 'd-flex flex-wrap']]),
          style: {"max-width":"700px"}
        }, [
          _createElementVNode("div", _hoisted_1, [
            (_ctx.showPreview && _ctx.currentImage)
              ? (_openBlock(), _createBlock(_component_Clickable, {
                  key: 0,
                  class: "col-12",
                  disabled: _ctx.disableModal,
                  onClick: _ctx.openImageModal
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Image, {
                      src: _ctx.currentImage.url,
                      "max-size": 500,
                      "is-loading": _ctx.isLoading,
                      padding: 4
                    }, null, 8, ["src", "is-loading"])
                  ]),
                  _: 1
                }, 8, ["disabled", "onClick"]))
              : _createCommentVNode("", true),
            (_ctx.showOverlayArrows && _ctx.images.length > 1)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createElementVNode("a", {
                    href: "",
                    onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.previousImage && _ctx.previousImage(...args)), ["prevent"])),
                    class: "text-dark ext-arrow-left",
                    style: {"z-index":"2","font-size":"1.6rem","position":"absolute","top":"calc(50% - 1rem)","left":"0.4rem"}
                  }, [
                    _createVNode(_component_Arrow, { dir: "left" })
                  ]),
                  _createElementVNode("a", {
                    href: "",
                    onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.nextImage && _ctx.nextImage(...args)), ["prevent"])),
                    class: "text-dark ext-arrow-right",
                    style: {"z-index":"2","font-size":"1.6rem","position":"absolute","top":"calc(50% - 1rem)","right":"0.4rem"}
                  }, [
                    _createVNode(_component_Arrow, { dir: "right" })
                  ])
                ], 64))
              : _createCommentVNode("", true),
            _renderSlot(_ctx.$slots, "default")
          ]),
          (_ctx.images.length > (_ctx.alwaysShowThumbnails ? 0 : 1))
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                (_ctx.hideThumbnails)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                      _createElementVNode("div", _hoisted_3, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.images, (image, index) => {
                          return (_openBlock(), _createBlock(_component_Clickable, {
                            class: "px-2",
                            key: index,
                            onClick: ($event: any) => (_ctx.onItemClick(index))
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", {
                                class: _normalizeClass(["ps-3 pt-3 rounded-circle", [index === _ctx.currentIndex ? 'bg-primary' : 'bg-light']])
                              }, null, 2)
                            ]),
                            _: 2
                          }, 1032, ["onClick"]))
                        }), 128))
                      ])
                    ]))
                  : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.images, (image, index) => {
                      return (_openBlock(), _createBlock(_component_Clickable, {
                        key: index,
                        class: _normalizeClass([_ctx.showPreview ? 'col-4' : 'me-2 mb-2']),
                        style: _normalizeStyle([!_ctx.showPreview && 'width: 6rem;']),
                        onClick: ($event: any) => (_ctx.onItemClick(index))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Image, {
                            src: image.thumbnailUrl,
                            active: index === _ctx.currentIndex && _ctx.showPreview,
                            "max-size": 100,
                            padding: 3
                          }, null, 8, ["src", "active"])
                        ]),
                        _: 2
                      }, 1032, ["class", "style", "onClick"]))
                    }), 128))
              ], 64))
            : _createCommentVNode("", true)
        ], 2))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_Image, {
            src: undefined,
            "is-loading": _ctx.isLoading
          }, null, 8, ["is-loading"]),
          _renderSlot(_ctx.$slots, "default")
        ])),
    _createVNode(_component_Modal, {
      show: _ctx.imageModalShown,
      "onUpdate:show": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.imageModalShown) = $event)),
      title: _ctx.title,
      xl: ""
    }, {
      default: _withCtx(() => [
        (_ctx.images && _ctx.images.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              (_ctx.images.length > 1)
                ? (_openBlock(), _createElementBlock("a", {
                    key: 0,
                    href: "",
                    class: "position-absolute top-0 bottom-0 start-0 h-100 pis-hover",
                    style: {"width":"40px"},
                    onClick: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.previousImage && _ctx.previousImage(...args)), ["prevent"]))
                  }, [
                    _createElementVNode("div", _hoisted_6, [
                      _createVNode(_component_Arrow, { dir: "left" })
                    ])
                  ]))
                : _createCommentVNode("", true),
              (_ctx.currentImage)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 1,
                    class: "pis-scale-down p-2 p-lg-5",
                    src: _ctx.currentImage.masterUrl,
                    alt: ""
                  }, null, 8, _hoisted_7))
                : _createCommentVNode("", true),
              (_ctx.images.length > 1)
                ? (_openBlock(), _createElementBlock("a", {
                    key: 2,
                    href: "",
                    class: "position-absolute top-0 end-0 bottom-0 pis-hover",
                    style: {"width":"40px"},
                    onClick: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.nextImage && _ctx.nextImage(...args)), ["prevent"]))
                  }, [
                    _createElementVNode("div", _hoisted_8, [
                      _createVNode(_component_Arrow, { dir: "right" })
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["show", "title"])
  ], 64))
}