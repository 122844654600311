
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Tab',

  props: {
    title: String,
    icon: String,
    loading: Boolean,
    count: [String, Number],
    extclass: String,
  },
});
