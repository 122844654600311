import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["part"]
const _hoisted_2 = { class: "mb-4 row g-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InjectStyles = _resolveComponent("InjectStyles")!
  const _component_Btn = _resolveComponent("Btn")!
  const _component_AliasFilters = _resolveComponent("AliasFilters")!
  const _component_Modal = _resolveComponent("Modal")!
  const _component_CategoriesList = _resolveComponent("CategoriesList")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "root",
    class: _normalizeClass(["pis-component", _ctx.componentName]),
    part: _ctx.componentName
  }, [
    (_ctx.isReady)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.isWebComponent)
            ? (_openBlock(), _createBlock(_component_InjectStyles, {
                key: 0,
                items: _ctx.store?.options.styles
              }, null, 8, ["items"]))
            : _createCommentVNode("", true),
          _createElementVNode("nav", _hoisted_2, [
            _createElementVNode("div", {
              class: _normalizeClass([`col-${12 / _ctx.columnsNumber}`])
            }, [
              _createVNode(_component_Btn, {
                icon: "filter",
                class: "w-100 h-100",
                vertical: "",
                onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.aliasFiltersModalOpen = true), ["prevent"]))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t('AliasFilters')), 1)
                ]),
                _: 1
              })
            ], 2),
            (_ctx.categoriesShown)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass([`col-${12 / _ctx.columnsNumber}`])
                }, [
                  _createVNode(_component_Btn, {
                    icon: "tree-view",
                    class: "w-100 h-100",
                    vertical: "",
                    disabled: !_ctx.categories.length,
                    number: _ctx.categories.length,
                    onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.categoriesModalOpen = true), ["prevent"]))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('Products')), 1)
                    ]),
                    _: 1
                  }, 8, ["disabled", "number"])
                ], 2))
              : _createCommentVNode("", true),
            (_ctx.partCategoriesShown)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: _normalizeClass([`col-${12 / _ctx.columnsNumber}`])
                }, [
                  _createVNode(_component_Btn, {
                    icon: "tree-view",
                    class: "w-100 h-100",
                    vertical: "",
                    disabled: !_ctx.partCategories.length,
                    number: _ctx.partCategories.length,
                    onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.partCategoriesModalOpen = true), ["prevent"]))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('PartsAndServices')), 1)
                    ]),
                    _: 1
                  }, 8, ["disabled", "number"])
                ], 2))
              : _createCommentVNode("", true)
          ]),
          _createVNode(_component_Modal, {
            show: _ctx.aliasFiltersModalOpen,
            "onUpdate:show": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.aliasFiltersModalOpen) = $event)),
            title: _ctx.t('AliasFilters')
          }, {
            default: _withCtx(() => [
              _createVNode(_component_AliasFilters)
            ]),
            _: 1
          }, 8, ["show", "title"]),
          _createVNode(_component_Modal, {
            show: _ctx.categoriesModalOpen,
            "onUpdate:show": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.categoriesModalOpen) = $event)),
            title: _ctx.t('AllOfferings')
          }, {
            default: _withCtx(() => [
              _createVNode(_component_CategoriesList, {
                categories: _ctx.categories,
                "configurators-visible": false,
                onClickItem: _ctx.setCategory
              }, null, 8, ["categories", "onClickItem"])
            ]),
            _: 1
          }, 8, ["show", "title"]),
          _createVNode(_component_Modal, {
            show: _ctx.partCategoriesModalOpen,
            "onUpdate:show": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.partCategoriesModalOpen) = $event)),
            title: _ctx.t('PartsAndServices')
          }, {
            default: _withCtx(() => [
              _createVNode(_component_CategoriesList, {
                categories: _ctx.partCategories,
                "configurators-visible": false,
                onClickItem: _ctx.setPartCategory
              }, null, 8, ["categories", "onClickItem"])
            ]),
            _: 1
          }, 8, ["show", "title"])
        ], 64))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}