
import { computed, defineComponent, ref } from 'vue';
import { ComponentName } from '@/products/api/configuration/components/ComponentName';
import { setupComponent } from '@/products/composables/setupComponent';

import InjectStyles from '@/common/components/InjectStyles.vue';
import Clickable from '@/common/components/Clickable.vue';
import { PrmAgmData, PrmDataSetup, setupPrmData } from '@/products/composables/prmData';
import { PrmRelationship } from '@/common/services/swagger/index.defs';
import { querySelectorDeep } from 'query-selector-shadow-dom';

export default defineComponent({
  name: ComponentName.detailsNavigation,

  components: {
    InjectStyles,
    Clickable,
  },

  props: {
    instanceId: String,
  },

  setup(props) {
    const root = ref<HTMLElement>();

    const { instance, componentName, isReady, isWebComponent, store, shadowRoot, widerThan, t } =
      setupComponent(root, props.instanceId, []);

    const prmData: PrmDataSetup = setupPrmData(instance);

    const links = computed(() => {
      const links: { id: string; text: string }[] = [];
      const translations = instance.value?.translations.translations;

      if (!translations) {
        return links;
      }

      const guides = store.value?.data.interactiveGuides?.items;

      if (guides) {
        links.push({
          text: translations['products']['InteractiveGuidesHeader'],
          id: 'pis-anchor-interactive-guides',
        });
      }

      prmData.visibleAgmData.value.forEach((agmData: PrmAgmData) =>
        links.push({
          text: agmData.attributeGroup.description,
          id: `pis-anchor-agm-${agmData.attributeGroup.code}`,
        }),
      );
      prmData.visiblePrmData.value.forEach((prm: PrmRelationship) =>
        links.push({
          text: prm.description,
          id: `pis-anchor-prm-${prm.code}`,
        }),
      );
      return links;
    });

    const scrollToLink = (link: { id: string; text: string }) => {
      let scrolled = false;

      if (shadowRoot.value) {
        const el = shadowRoot.value.getElementById(link.id);
        if (el) {
          el.scrollIntoView({ behavior: 'smooth' });
          scrolled = true;
        }
      }

      if (!scrolled) {
        const el = querySelectorDeep('#' + link.id);
        if (el) {
          el.scrollIntoView({ behavior: 'smooth' });
        }
      }
    };

    return {
      root,
      componentName,
      isReady,
      isWebComponent,
      store,
      links,
      t,
      scrollToLink,
      widerThan,
    };
  },
});
