import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "list-group list-group-flush" }
const _hoisted_2 = { class: "pis-label text-break" }
const _hoisted_3 = { class: "d-flex align-items-center ms-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Badge = _resolveComponent("Badge")!
  const _component_Clickable = _resolveComponent("Clickable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, (category) => {
      return (_openBlock(), _createBlock(_component_Clickable, {
        key: category.cid,
        class: "list-group-item list-group-item-action d-flex align-items-start pis-category-link border-0",
        onClick: ($event: any) => (_ctx.$emit('click-item', category))
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_2, _toDisplayString(category.name), 1),
          _createElementVNode("span", _hoisted_3, [
            _createVNode(_component_Badge, {
              textContent: _toDisplayString(category.hitCount),
              class: "ms-1"
            }, null, 8, ["textContent"]),
            (_ctx.configuratorsVisible && category.configuratorHitCount)
              ? (_openBlock(), _createBlock(_component_Badge, {
                  key: 0,
                  textContent: _toDisplayString(category.configuratorHitCount),
                  class: "ms-1",
                  highlighted: "",
                  title: _ctx.configuratorsNumberTitle
                }, null, 8, ["textContent", "title"]))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 2
      }, 1032, ["onClick"]))
    }), 128))
  ]))
}