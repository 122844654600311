import { reactive } from 'vue';
import { Instance } from './Instance';
import { ICatalogsInstance } from './ICatalogsInstance';
import { ICatalogsAccessor } from './ICatalogsAccessor';

export class Accessor implements ICatalogsAccessor {
  instances = reactive<Map<string, Instance>>(new Map());

  public destroyInstance(id: string): void {
    this.instances.delete(id);
  }

  public getInstance(id?: string): ICatalogsInstance | undefined {
    const size = this.instances.size;

    if (id) {
      if (this.instances.has(id)) {
        return this.instances.get(id);
      } else {
        const instance = new Instance(id, this);
        this.instances.set(id, instance);
        return instance;
      }
    } else if (size === 1) {
      return this.instances.values().next().value;
    } else if (size > 1) {
      throw new Error('[PIS Catalogs] Cannot pick default instance if more than one exists');
    }

    return undefined;
  }

  public setAccessTokenInAllInstances(token: string) {
    this.instances.forEach((inst: any) => {
      inst.getOptions().accessToken = token;
    });
  }
}
