
import Badge from '@/common/components/Badge.vue';
import Clickable from '@/common/components/Clickable.vue';
import ScrollableList from '@/common/components/ScrollableList.vue';
import { FilterItem } from '@/common/services/swagger/index.defs';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  components: {
    Badge,
    Clickable,
    ScrollableList,
  },

  props: {
    items: { type: Array as PropType<FilterItem[]>, required: true },
    currentAttributeCode: String,
    height: String,
  },

  computed: {
    isReady() {
      return this.$props.items && this.$props.items.length;
    },
  },

  methods: {
    clickableClass(item: FilterItem) {
      return this.$props.currentAttributeCode === item.attributeCode && 'active';
    },
  },

  emits: ['item-click'],
});
