
import { computed, defineComponent, ref, onMounted, onUnmounted, watch, nextTick } from 'vue';
import VRuntimeTemplate from 'vue3-runtime-template';

import { DataType } from '@/products/api/configuration/application/DataType';
import { ComponentName } from '@/products/api/configuration/components/ComponentName';
import { attrValue, attrValues, additionalInfo } from '@/common/helpers/productAttributes';
import { setupComponent } from '@/products/composables/setupComponent';
import { ProductItem } from '@/common/services/swagger/index.defs';
import { DetailsCommand } from '@/products/api/runtime/CommandExecutor';
import { IConfiguratorOptions } from '@/products/api/configuration/components/IConfiguratorOptions';

import InjectStyles from '@/common/components/InjectStyles.vue';
import Image from '@/common/components/Image.vue';
import PisReplacements from '@/common/components/PisReplacements.vue';
import Tooltip from '@/common/components/Tooltip.vue';
import Icon from '@/common/components/Icon.vue';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import { IItemsGridHorizontalOptions } from '@/products/api/configuration/components/IItemsGridHorizontalOptions';

export default defineComponent({
  name: ComponentName.searchGridHorizontal,

  components: {
    InjectStyles,
    Image,
    PisReplacements,
    Tooltip,
    Icon,
    Carousel,
    Slide,
    Pagination,
    Navigation,
    VRuntimeTemplate,
  },

  props: {
    instanceId: String,
  },

  setup(props) {
    const root = ref<HTMLElement>();

    const {
      componentName,
      isWebComponent,
      isReady,
      instance,
      store,
      routeData,
      t,
      widerThan,
      narrowerThan,
      emit,
    } = setupComponent(root, props.instanceId, [DataType.Products]);

    const carousel = ref();
    const unwatch = watch(
      () => isReady.value,
      () => {
        if (instance.value) {
          unwatch();
          instance.value.eventBus.on('route-changed', () => {
            const val = carousel.value as any;
            if (val) {
              nextTick(() => {
                val.slideTo(0);
              });

              setTimeout(() => {
                loaded.value = true;
              }, 100);
            }
          });
          resizeHandler();
        }
      },
    );

    const findSettings = () => {
      const settings = componentOptions.value.carousel;
      const width = window.innerWidth;
      if (settings) {
        if (!settings.breakpoints) return settings.defaults;
        const keys = Object.keys(settings.breakpoints);
        if (keys.length) {
          for (let i = keys.length - 1; i >= 0; i--) {
            if (width > parseInt(keys[i])) {
              return settings.breakpoints[keys[i]];
            }
          }
        }
        return settings.defaults;
      }
      return {};
    };

    const currentSettings = ref();
    const loaded = ref(false);
    const resizeHandler = () => {
      currentSettings.value = findSettings();
    };

    onMounted(() => {
      window.addEventListener('resize', resizeHandler);
    });

    onUnmounted(() => {
      window.removeEventListener('resize', resizeHandler);
    });

    const componentOptions = computed(
      () =>
        store.value?.options.components?.itemsGridHorizontal ?? ({} as IItemsGridHorizontalOptions),
    );

    const configuratorOptions = computed(
      () => store.value?.options.components?.configurators as IConfiguratorOptions,
    );

    const details = async (product: ProductItem) => {
      if (product?.inactive) return;

      if (routeData.value && product) {
        try {
          await instance.value?.execute(new DetailsCommand(product));
        } catch (error) {
          // Ignore Error
        }
      }
    };

    const productsItems = computed(() => store.value?.data.products?.items ?? []);

    const configurators = computed(() => store.value?.data.configurators?.items);

    const replacementsVisible = computed(
      () => store.value?.options.components?.common?.hideReplacements != true,
    );

    const settings = computed(() => {
      const defaultSettings = componentOptions.value.carousel.defaults || {};

      if (defaultSettings) {
        return {
          ...defaultSettings,
          mouseDrag: componentOptions.value.carousel.mouseDrag,
          touchDrag: componentOptions.value.carousel.touchDrag,
          wrapAround: componentOptions.value.carousel.wrapAround,
          transition: componentOptions.value.carousel.transition,
        };
      }

      return {};
    });

    const breakpoints = computed(() => componentOptions.value.carousel.breakpoints || {});

    const i18n = computed(() => {
      return {
        ariaNextSlide: t('CarouselAriaNextSlide'),
        ariaPreviousSlide: t('CarouselAriaPreviousSlide'),
        ariaNavigateToSlide: t('CarouselAriaNavigateToSlide'),
        ariaGallery: t('CarouselAriaGallery'),
        itemXofY: t('CarouselItemXofY'),
        iconArrowUp: t('CarouselIconArrowUp'),
        iconArrowDown: t('CarouselIconArrowDown'),
        iconArrowRight: t('CarouselIconArrowRight'),
        iconArrowLeft: t('CarouselIconArrowLeft'),
      };
    });

    return {
      root,
      instance,
      loaded,
      componentName,
      componentOptions,
      isWebComponent,
      isReady,
      store,
      productsItems,
      configurators,
      configuratorOptions,
      replacementsVisible,
      settings,
      breakpoints,
      carousel,
      i18n,
      currentSettings,
      t,
      widerThan,
      narrowerThan,
      attrValue,
      attrValues,
      details,
      additionalInfo,
      emit,
    };
  },
});
