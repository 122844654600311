
import { computed } from 'vue';
import { defineComponent, ref, PropType } from 'vue';
import { IRouteData } from '@/products/api/runtime/IRouteData';
import { FavoriteView } from '@/common/services/swagger/index.defs';
import PIS from '@/common/api/PIS';

import { TranslateFunc } from '@/products/composables/setupComponent';
import Card from '@/common/components/Card.vue';
import Modal from '@/common/components/Modal.vue';
import Arrow from '@/common/components/Arrow.vue';
import Message from '@/common/components/Message.vue';
import Confirmation from '@/common/components/Confirmation.vue';
import LinkSecondary from '@/common/components/LinkSecondary.vue';
import FavoriteViewsList from '@/common/components/FavoriteViewsList.vue';
import FavoriteViewsSaveForm from '@/common/components/FavoriteViewsSaveForm.vue';
import {
  LoadFavoriteViewsCommand,
  SaveFavoriteViewCommand,
  DeleteFavoriteViewCommand,
  ApplyFavoriteViewCommand,
} from '@/products/api/runtime/CommandExecutor';
import { Instance } from '@/products/api/Instance';
import { isEmptyCID } from '../helpers/isEmptyCID';

export default defineComponent({
  components: {
    Card,
    Modal,
    Arrow,
    Message,
    Confirmation,
    LinkSecondary,
    FavoriteViewsList,
    FavoriteViewsSaveForm,
  },

  props: {
    instanceId: String,
    isMobile: Boolean,
    mobileShow: Boolean,
    t: { type: Function as PropType<TranslateFunc>, required: true },
  },

  setup(props) {
    const root = ref<HTMLElement>();
    const instance = PIS.Products.getInstance(props.instanceId) as Instance;
    const trimList = ref(true);
    const favoriteID = ref('');
    const favoriteCode = ref('');
    const isUpdateModalVisible = ref(false);
    const deleteConfirmationModalItem = ref<FavoriteView | undefined>(undefined);
    const componentOptions = computed(() => instance.store?.options.components?.favoriteViews);
    const isVisible = computed(() => componentOptions.value?.visible);
    const maxViewsVisible = computed(() => componentOptions.value?.maxViewsVisible || 0);
    const currentCID = computed(() => instance.router.routeData?.cid);
    const hasChosenCID = computed(() => !isEmptyCID(currentCID.value));
    const hasChosenFilters = computed(() => instance.router.routeData?.filters?.length);
    const hasChosenText = computed(() => instance.router.routeData?.searchText);
    const fallbackCode = computed(() => componentOptions.value?.fallbackCode);
    const favoriteViews = computed(() => instance.store?.data.favoriteViews?.items ?? []);
    const showMoreBtn = computed(() => favoriteViews.value.length > maxViewsVisible.value);
    const isFormValid = computed(() => !!favoriteCode.value);

    const favoriteViewsTrimmed = computed(() =>
      trimList.value
        ? (favoriteViews.value ?? []).slice(0, maxViewsVisible.value)
        : favoriteViews.value,
    );

    const canSave = computed(
      () => hasChosenCID.value || hasChosenFilters.value || hasChosenText.value,
    );

    const toggleTrimList = () => (trimList.value = !trimList.value);

    const updateModal = (show: boolean) => {
      favoriteID.value = '';
      isUpdateModalVisible.value = show;
    };

    const updateDeleteConfirmationModal = (item: FavoriteView | undefined) =>
      (deleteConfirmationModalItem.value = item);

    const onFormCancel = () => (isUpdateModalVisible.value = false);

    const onFavoriteViewCheck = async (favoriteView: FavoriteView) => {
      try {
        await instance.execute(new ApplyFavoriteViewCommand({ favoriteView }));
      } catch (error) {
        // Ignore Error
      }
    };

    const onFavoriteViewDelete = async (item: FavoriteView | undefined) => {
      if (item && item.id) {
        try {
          await instance.execute(new DeleteFavoriteViewCommand({ id: item.id as string }));
        } catch (error) {
          return;
        }
      }
      updateDeleteConfirmationModal(undefined);
    };

    const onFormUpdate = async ({ id, code }, isSubmit = false) => {
      favoriteID.value = id;
      favoriteCode.value = code;
      if (!isSubmit || !isFormValid.value) {
        return;
      }
      try {
        await instance.execute(
          new SaveFavoriteViewCommand({
            id,
            code,
            routeData: instance.router.routeData as IRouteData,
          }),
        );
        isUpdateModalVisible.value = false;
      } catch (error) {
        // Ignore Error
      }
    };

    const init = async () => {
      const ids = componentOptions.value?.savedViewIds;
      if (ids && ids.length && !favoriteViews.value.length) {
        try {
          await instance.execute(new LoadFavoriteViewsCommand({ ids }));
        } catch (error) {
          // Ignore Error
        }
      }
    };

    init();

    return {
      root,
      canSave,
      trimList,
      isVisible,
      favoriteID,
      showMoreBtn,
      isFormValid,
      favoriteCode,
      fallbackCode,
      favoriteViews,
      componentOptions,
      isUpdateModalVisible,
      favoriteViewsTrimmed,
      deleteConfirmationModalItem,
      updateModal,
      onFormUpdate,
      onFormCancel,
      toggleTrimList,
      onFavoriteViewCheck,
      onFavoriteViewDelete,
      updateDeleteConfirmationModal,
    };
  },
  emits: ['update:mobileShow'],
});
