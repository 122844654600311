
import { computed, defineComponent, PropType } from 'vue';
import { Instance } from '@/products/api/Instance';
import { BackCommand, SearchViewCommand } from '@/products/api/runtime/CommandExecutor';
import { TranslateFunc } from '@/products/composables/setupComponent';

import Arrow from '@/common/components/Arrow.vue';
import Btn from '@/common/components/Btn.vue';
import Tooltip from '@/common/components/Tooltip.vue';
import { setupRouteData } from '@/products/composables/routeData';

export default defineComponent({
  components: {
    Btn,
    Arrow,
    Tooltip,
  },

  props: {
    instance: { type: Object as PropType<Instance | undefined>, required: true },
    t: { type: Function as PropType<TranslateFunc>, required: true },
  },

  setup(props) {
    const { lastSearchIndex, previousProductName } = setupRouteData(props.instance as Instance);

    const navigateBack = async () => {
      try {
        await props.instance?.execute(new BackCommand());
      } catch (error) {
        // Ignore Error
      }
    };

    const navigateToSearch = async () => {
      try {
        if (lastSearchIndex.value > 0) {
          await props.instance?.execute(new BackCommand({ step: lastSearchIndex.value }));
        } else {
          await props.instance?.execute(new SearchViewCommand());
        }
      } catch (error) {
        // Ignore Error
      }
    };

    const allowBack = computed(() => props.instance?.store?.data.itemNotFound !== true);

    return {
      allowBack,
      lastSearchIndex,
      previousProductName,
      navigateBack,
      navigateToSearch,
    };
  },
});
