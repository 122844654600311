
import { computed, defineComponent, ref } from 'vue';
import {
  ClassificationItem,
  MasterProduct,
  PartType,
  TreeTypeEnum,
} from '@/common/services/swagger/index.defs';
import { ComponentName } from '../api/configuration/components/ComponentName';
import {
  ClassificationCommand,
  NarrowByProductCommand,
  PartsTypeCommand,
} from '../api/runtime/CommandExecutor';
import { setupComponent } from '../composables/setupComponent';

import InjectStyles from '@/common/components/InjectStyles.vue';
import Btn from '@/common/components/Btn.vue';
import CategoriesList from '@/common/components/CategoriesList.vue';
import Modal from '@/common/components/Modal.vue';
import FiltersNavigatorModal from '@/products/components/common/FiltersNavigatorModal.vue';
import SparePartTypeList from '@/common/components/SparePartTypeList.vue';
import Clickable from '@/common/components/Clickable.vue';
import FormInputClearable from '@/common/components/FormInputClearable.vue';
import Badge from '@/common/components/Badge.vue';
import LinkSecondary from '@/common/components/LinkSecondary.vue';
import { IConfiguratorOptions } from '../api/configuration/components/IConfiguratorOptions';
import FavoriteViews from '@/common/components/FavoriteViews.vue';
import FavoriteCategories from '@/common/components/FavoriteCategories.vue';
import { DataType } from '../api/configuration/application/DataType';

export default defineComponent({
  name: ComponentName.mobileNav,

  components: {
    InjectStyles,
    Btn,
    Modal,
    CategoriesList,
    FiltersNavigatorModal,
    SparePartTypeList,
    Clickable,
    FormInputClearable,
    Badge,
    LinkSecondary,
    FavoriteViews,
    FavoriteCategories,
  },

  props: {
    instanceId: String,
  },

  setup(props) {
    const root = ref<HTMLElement>();
    const narrowByProductModalOpen = ref(false);
    const partTypesModalOpen = ref(false);
    const categoriesModalOpen = ref(false);
    const filtersModalOpen = ref(false);
    const sparePartMasterProductsSearch = ref('');
    const favoriteViewsModalOpen = ref(false);
    const favoriteCategoriesModalOpen = ref(false);

    const {
      componentName,
      isWebComponent,
      isReady,
      instance,
      store,
      routeData,
      t,
      useProductsFilters,
    } = setupComponent(root, props.instanceId, [DataType.Breadcrumbs]);

    const {
      filtersAll,
      filtersPromoted,
      filtersAdditional,
      filtersModel,
      filtersFetched,
      filteredResultCount,
      fetchFilterValues,
      applyModelFilters,
      refreshResultCount,
      resetFiltersApplied,
    } = useProductsFilters();

    const isSparePartsView = computed(
      () => store.value?.options.application?.treeType === TreeTypeEnum.Parts,
    );

    const narrowByProductShown = computed(
      () =>
        isSparePartsView.value &&
        instance.value?.registeredComponents.has(ComponentName.searchNarrowByProduct),
    );

    const sparePartMasterProducts = computed(() => {
      const products = store.value?.data.sparePartMasterProducts?.items;

      if (products?.length) {
        const searchPhrase = sparePartMasterProductsSearch.value.toLowerCase();
        return products.filter(
          (item) =>
            item.description.toLowerCase().includes(searchPhrase) ||
            item.productId.toLowerCase().includes(searchPhrase),
        );
      }

      return null;
    });

    const sparePartsMasterProductSelected = computed(() => routeData.value?.masterProductId);

    const setMasterProductId = async ({ productId, description }: MasterProduct) => {
      try {
        await instance.value?.execute(
          new NarrowByProductCommand({
            masterProductId: productId,
            masterProductName: description,
          }),
        );
        narrowByProductModalOpen.value = false;
      } catch (error) {
        // Ignore Error
      }
    };

    const clearMasterProduct = async () => {
      try {
        await instance.value?.execute(
          new NarrowByProductCommand({ masterProductId: undefined, masterProductName: undefined }),
        );
        narrowByProductModalOpen.value = false;
      } catch (error) {
        // Ignore Error
      }
    };

    const partTypes = computed(() => store.value?.data.partTypes?.items ?? []);

    const partTypesShown = computed(
      () =>
        isSparePartsView.value &&
        instance.value?.registeredComponents.has(ComponentName.searchPartTypes),
    );

    const partTypeCurrent = computed(() => instance.value?.router.routeData?.partsType);

    const setPartType = async ({ type }: PartType) => {
      if (instance.value && type && partTypeCurrent.value != type) {
        try {
          instance.value.execute(new PartsTypeCommand({ partsType: type }));
          partTypesModalOpen.value = false;
        } catch (error) {
          // Ignore Error
        }
      }
    };

    const categoriesShown = computed(
      () =>
        instance.value?.registeredComponents.has(ComponentName.searchCategories) &&
        store.value?.options.components?.searchCategories?.visible &&
        store.value?.options.application?.agmListCode == undefined,
    );

    const favoriteViewsShown = computed(
      () =>
        instance.value?.registeredComponents.has(ComponentName.searchFavoriteViews) &&
        store.value?.options.components?.favoriteViews?.visible,
    );

    const filtersShown = computed(() =>
      instance.value?.registeredComponents.has(ComponentName.searchFilters),
    );

    const favoriteViews = computed(() => store.value?.data.favoriteViews?.items ?? []);

    const favoriteCategoriesShown = computed(
      () =>
        instance.value?.registeredComponents.has(ComponentName.searchFavoriteCategories) &&
        store.value?.options.components?.favoriteCategories?.visible,
    );

    const categories = computed(() => store.value?.data.classifications?.items ?? []);

    const favoriteCategories = computed(() =>
      store.value?.options.application?.treeType === TreeTypeEnum.Products
        ? store.value?.data.favoriteProductCategories?.items ?? []
        : store.value?.data.favoritePartCategories?.items ?? [],
    );

    const columnsNumber = computed(() => {
      const itemsNumber = [
        narrowByProductShown.value,
        partTypesShown.value,
        categoriesShown.value,
        filtersShown.value,
        favoriteCategoriesShown.value,
      ].filter(Boolean).length;

      return itemsNumber > 3 ? 3 : itemsNumber; // 3 or less columns
    });

    const configuratorOptions = computed(
      () => store.value?.options.components?.configurators as IConfiguratorOptions,
    );

    const applyFilters = () => {
      filtersModalOpen.value = false;
      applyModelFilters();
    };

    const onModalFilterValueChange = ({ attributeCode, filterValueName, value }) => {
      filtersModel.value[attributeCode][filterValueName] = value;
      refreshResultCount();
    };

    const setCategory = async ({ cid }: ClassificationItem) => {
      if (instance.value && cid) {
        categoriesModalOpen.value = false;
        try {
          await instance.value.execute(new ClassificationCommand({ cid, clearSearch: false }));
        } catch (error) {
          // Ignore Error
        }
      }
    };

    return {
      root,
      componentName,
      isWebComponent,
      isReady,
      store,
      columnsNumber,
      t,
      instance,

      // Narrow by product
      narrowByProductShown,
      narrowByProductModalOpen,
      sparePartMasterProducts,
      sparePartMasterProductsSearch,
      sparePartsMasterProductSelected,
      setMasterProductId,
      clearMasterProduct,

      // Part Types
      partTypes,
      partTypesModalOpen,
      partTypesShown,
      partTypeCurrent,
      setPartType,

      // Categories
      categoriesModalOpen,
      categories,
      categoriesShown,
      configuratorOptions,
      setCategory,

      // Filters
      filtersModalOpen,
      filtersShown,
      filtersAll,
      filtersPromoted,
      filtersAdditional,
      filtersModel,
      filtersFetched,
      filteredResultCount,
      onModalFilterValueChange,
      fetchFilterValues,
      resetFiltersApplied,
      applyFilters,

      // Favorite Views
      favoriteViews,
      favoriteViewsShown,
      favoriteViewsModalOpen,

      // Favorite Categories
      favoriteCategoriesModalOpen,
      favoriteCategoriesShown,
      favoriteCategories,
    };
  },
});
