import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["part"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InjectStyles = _resolveComponent("InjectStyles")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Tooltip = _resolveComponent("Tooltip")!
  const _component_ProductsListItem = _resolveComponent("ProductsListItem")!
  const _component_Message = _resolveComponent("Message")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "root",
    class: _normalizeClass(["pis-component", _ctx.componentName]),
    part: _ctx.componentName
  }, [
    (_ctx.componentOptions.visible && _ctx.isReady)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.isWebComponent)
            ? (_openBlock(), _createBlock(_component_InjectStyles, {
                key: 0,
                items: _ctx.store?.options.styles
              }, null, 8, ["items"]))
            : _createCommentVNode("", true),
          (_ctx.relatedLinks?.length)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.relatedLinks, (link, linkIndex) => {
                return (_openBlock(), _createElementBlock("a", {
                  target: "_blank",
                  key: linkIndex,
                  href: link.url
                }, [
                  _createVNode(_component_ProductsListItem, {
                    "image-src": _ctx.componentOptions.showImages ? link.image : '',
                    wide: _ctx.widerThan('sm'),
                    "hide-image": !_ctx.componentOptions.showImages
                  }, {
                    content: _withCtx(() => [
                      (link.internal)
                        ? (_openBlock(), _createBlock(_component_Tooltip, {
                            key: 0,
                            text: _ctx.t('Internal', true)
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_Icon, {
                                class: "text-warning text-end",
                                glyph: "warning"
                              })
                            ]),
                            _: 1
                          }, 8, ["text"]))
                        : _createCommentVNode("", true),
                      _createTextVNode(" " + _toDisplayString(link.text), 1)
                    ]),
                    _: 2
                  }, 1032, ["image-src", "wide", "hide-image"])
                ], 8, _hoisted_3))
              }), 128))
            : (_openBlock(), _createBlock(_component_Message, {
                key: 2,
                class: "p-2"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t('RelatedLinksNotAvailable')), 1)
                ]),
                _: 1
              }))
        ]))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}