import { Instance } from '../Instance';
import { IRouteData } from './IRouteData';
import _cloneDeep from 'lodash/cloneDeep';
import _omit from 'lodash/omit';
import { ICommand } from '@/common/api/runtime/ICommand';
import { INotification, NotificationType } from '@/common/api/runtime/INotification';
import { IInstance } from '@/common/api/runtime/IInstance';
import {
  ClassificationsBrowserNode,
  ClassificationsBrowserTreeViewEnum,
} from '@/common/services/swagger/index.defs';
import legacyHelper from '../salesforce/LegacyHelper';

export abstract class Command<TArgs, ReturnType = unknown>
implements ICommand<Instance, ReturnType>
{
  isHandled = false;
  constructor(public args: TArgs) {}
  abstract execute: (instance: Instance) => Promise<ReturnType | undefined>;
  abstract name: string;
}

export class SearchCommand extends Command<{
  searchText: string;
}> {
  name = 'classifications-filter-search';

  public execute = async (instance: Instance): Promise<unknown> => {
    const routeData = {
      searchText: this.args.searchText,
    } as IRouteData;

    return instance.router.setRoute(routeData);
  };
}

export class ExpandNodeCommand extends Command<{
  item: ClassificationsBrowserNode;
}> {
  name = 'classifications-node-expand';

  public execute = async (instance: Instance): Promise<unknown> => {
    if (this.args.item.leaf) {
      return Promise.resolve(this.args.item);
    }

    if (this.args.item.children?.length) {
      this.args.item['_isExpanded'] = true;
      return Promise.resolve(this.args.item);
    }

    if (instance.httpService) {
      this.args.item['_isLoading'] = true;

      try {
        await instance.httpService.loadChildren(this.args.item);
        this.args.item['_isExpanded'] = true;
      } catch (e) {
        // ignored?
      } finally {
        this.args.item['_isLoading'] = false;
      }
    }

    return Promise.resolve(this.args.item);
  };
}

export class CollapseNodeCommand extends Command<{
  item: ClassificationsBrowserNode;
}> {
  name = 'classifications-node-collapse';

  public execute = async (): Promise<unknown> => {
    this.args.item['_isExpanded'] = false;
    return Promise.resolve(this.args.item);
  };
}

export class CollapseAllCommand extends Command<void> {
  name = 'classifications-collapse-all';

  setExpandedFalse = (nodes?: ClassificationsBrowserNode[]) => {
    nodes?.forEach((i) => {
      if (i.children?.length) {
        i['_isExpanded'] = false;

        this.setExpandedFalse(i.children);
      }
    });
  };

  public execute = async (instance: Instance): Promise<unknown> => {
    const nodes = instance.store.data?.classifications?.items ?? [];
    this.setExpandedFalse(nodes);
    return Promise.resolve(this.args);
  };
}

export class ShowFavoritesCommand extends Command<void> {
  name = 'classifications-favorites-show';

  public execute = async (instance: Instance): Promise<unknown> => {
    const routeData = {
      favoriteView: true,
    } as IRouteData;

    return instance.router?.setRoute(routeData);
  };
}

export class ShowAllCommand extends Command<void> {
  name = 'classifications-show-all';

  public execute = async (instance: Instance): Promise<unknown> => {
    const routeData = {
      favoriteView: undefined,
    } as IRouteData;

    return instance.router?.setRoute(routeData);
  };
}

export class ChangeTreeTypeCommand extends Command<{
  view: ClassificationsBrowserTreeViewEnum;
}> {
  name = 'classifications-set-view';

  public execute = async (instance: Instance): Promise<unknown> => {
    const routeData = {
      view: this.args.view,
    } as IRouteData;

    return await instance.router?.setRoute(routeData);
  };
}

export class SelectCommand extends Command<{
  item: ClassificationsBrowserNode;
}> {
  name = 'classifications-item-select';

  public execute = async (instance: Instance): Promise<unknown> => {
    instance.selection.select([this.args.item]);
    return Promise.resolve(this.args);
  };
}

export class DeselectCommand extends Command<{
  item: ClassificationsBrowserNode;
}> {
  name = 'classifications-item-deselect';

  public execute = async (instance: Instance): Promise<unknown> => {
    instance.selection.deselect([this.args.item]);

    return Promise.resolve(this.args);
  };
}

export class AddFavoriteCommand extends Command<{
  item: ClassificationsBrowserNode;
}> {
  name = 'classifications-favorite-select';

  public execute = async (instance: Instance): Promise<unknown> => {
    instance.favorites.select([this.args.item]);
    return Promise.resolve(this.args);
  };
}

export class RemoveFavoriteCommand extends Command<{
  item: ClassificationsBrowserNode;
}> {
  name = 'classifications-favorite-deselect';

  public execute = async (instance: Instance): Promise<unknown> => {
    instance.favorites.deselect([this.args.item]);

    return Promise.resolve(this.args);
  };
}

export class ClearSelectionCommand extends Command<void> {
  name = 'classifications-selection-clear';

  public execute = async (instance: Instance): Promise<unknown> => {
    instance.selection.clear();

    return Promise.resolve(this.args);
  };
}

export class ApplyCommand extends Command<void> {
  name = 'classifications-apply';

  public execute = async (instance: Instance): Promise<unknown> => {
    const legacyArgs = legacyHelper.toLegacyEventArgs('Apply', instance);

    instance.eventBus.emit('classification-selector-closed', legacyArgs);

    return Promise.resolve(this.args);
  };
}

export class CancelCommand extends Command<void> {
  name = 'classifications-cancel';

  public execute = async (instance: Instance): Promise<unknown> => {
    const legacyArgs = legacyHelper.toLegacyEventArgs('Cancel', instance);

    instance.eventBus.emit('classification-selector-closed', legacyArgs);
    return Promise.resolve(this.args);
  };
}

export class AddNotificationCommand extends Command<INotification> {
  name = 'classifications-notification-show';
  public execute = async (instance: Instance): Promise<void> => {
    let showDelay = 0;
    let dismissAfter: number | undefined = this.args.dismissAfter;

    if (instance.store.notifications.length) {
      instance.store.notifications = [];
      showDelay = 1000;
    }

    if (!dismissAfter && dismissAfter !== 0 && this.args.type !== NotificationType.danger) {
      dismissAfter = 6000;
      instance.store.notifications = [];
    }

    if (dismissAfter && dismissAfter !== 0) {
      setTimeout(() => {
        const index = instance.store.notifications.indexOf(this.args);
        if (index !== -1) {
          instance.store.notifications.splice(index, 1);
        }
      }, this.args.dismissAfter);
    }
    setTimeout(() => {
      return instance.store.notifications.push(this.args);
    }, showDelay);
  };
}

export class DeleteNotificationCommand extends Command<INotification> {
  name = 'classifications-notification-hide';
  public execute = async (instance: Instance): Promise<void> => {
    const index = instance.store.notifications.indexOf(this.args);
    if (index !== -1) {
      instance.store.notifications.splice(index, 1);
    }
  };
}

export class CommandExecutor {
  constructor(private instance: Instance) {}

  public async execute<ReturnType = unknown>(
    command: ICommand<Instance, ReturnType>,
  ): Promise<ReturnType | undefined> {
    const commandInterceptor = this.instance.store.options.commandInterceptor;

    if (commandInterceptor) {
      try {
        // we don't want to expose execute function nor let external app to modify args
        // we clone the command exposed externally
        const commandExternal = _cloneDeep(_omit(command, ['execute']));

        await commandInterceptor(commandExternal as ICommand<IInstance, ReturnType>);
        if (commandExternal.isHandled) {
          return Promise.reject();
        }

        return command?.execute(this.instance);
      } catch (e) {
        this.instance.logger.log('Command cancelled', command);
      }
    } else {
      return command?.execute(this.instance);
    }
  }
}
