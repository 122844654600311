
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    glyph: {
      type: String,
      required: true,
    },
    sm: Boolean,
    lg: Boolean,
    xl: Boolean,
    ul: Boolean,
  },
});
