import { Accessor as ProductsAccessor } from '@/products/api/Accessor';
import { Accessor as CatalogsAccessor } from '@/catalogs/api/Accessor';
import { Accessor as ClassificationsAccessor } from '@/classifications/api/Accessor';
import { IPIS } from './IPIS';

class PIS implements IPIS {
  Products = new ProductsAccessor();
  Catalogs = new CatalogsAccessor();
  Classifications = new ClassificationsAccessor();
  version = '1.0';
  build = 'TIMESTAMP';
}

export default new PIS();
