
import { computed, defineComponent, ref } from 'vue';
import { ComponentName } from '@/products/api/configuration/components/ComponentName';
import { setupComponent } from '@/products/composables/setupComponent';
import { DataType } from '@/products/api/configuration/application/DataType';
import { ProductDetailSearchResult } from '@/common/services/swagger/index.defs';

import InjectStyles from '@/common/components/InjectStyles.vue';
import ImageGallery from '@/common/components/ImageGallery.vue';
import { IDetailsGalleryOptions } from '@/products/api/configuration/components/IDetailsGalleryOptions';
import VRuntimeTemplate from 'vue3-runtime-template';
export default defineComponent({
  name: ComponentName.detailsGallery,

  components: {
    InjectStyles,
    ImageGallery,
    VRuntimeTemplate,
  },

  props: {
    instanceId: String,
    showPreview: { type: Boolean, default: true },
  },

  setup(props) {
    const root = ref<HTMLElement>();

    const { instance, componentName, isReady, isWebComponent, store, t, emit } = setupComponent(
      root,
      props.instanceId,
      [DataType.Breadcrumbs, DataType.ProductDetails],
    );

    const componentOptions = computed(
      () => store.value?.options.components?.detailsGallery ?? ({} as IDetailsGalleryOptions),
    );

    const productDetail = computed<ProductDetailSearchResult | undefined>(
      () => store.value?.data.productDetail || undefined,
    );

    const visible = computed(
      () =>
        componentOptions.value.visible &&
        productDetail.value?.item &&
        productDetail.value?.item.hideImages !== true,
    );

    return {
      root,
      componentName,
      componentOptions,
      isReady,
      isWebComponent,
      store,
      visible,
      instance,
      productDetail,
      t,
      emit,
    };
  },
});
