
import { defineComponent, onBeforeUnmount, ref, watch } from 'vue';
import { useResizeObserver } from '@vueuse/core';

const MODAL_OPEN_BODY_CLASSES = ['modal-open', 'pis-modal-open'];

export default defineComponent({
  props: {
    show: [Boolean, Object],
    title: String,
    lg: { type: Boolean, default: false },
    xl: { type: Boolean, default: false },
    closeOnOuterClick: { type: Boolean, default: true },
    centered: { type: Boolean, default: false },
  },

  emits: ['update:show', 'resize'],

  setup(props, { slots, emit }) {
    const dialog = ref<HTMLElement>();
    const modalBody = ref<HTMLElement>();

    useResizeObserver(modalBody, (entries) => {
      const entry = entries[0];
      const { width, height } = entry.contentRect;
      emit('resize', { width, height });
    });

    const overlayClose = () => {
      if (props.closeOnOuterClick) {
        close();
      }
    };

    const applyBodyStyles = () => {
      const scrollBarWidth = window.innerWidth - document.documentElement.clientWidth;

      document.body.classList.add(...MODAL_OPEN_BODY_CLASSES);
      document.body.style.overflow = 'hidden';
      document.body.style.paddingRight = scrollBarWidth + 'px';
    };

    const removeBodyStyles = () => {
      document.body.classList.remove(...MODAL_OPEN_BODY_CLASSES);
      document.body.style.overflow = '';
      document.body.style.paddingRight = '';
    };

    const close = () => {
      emit('update:show', false);
      visible.value = false;
      removeBodyStyles();
    };

    const visible = ref(false);

    const clickOutside = ({ target }) => {
      if (props.closeOnOuterClick) {
        if (target !== dialog.value && !dialog.value?.contains(target)) {
          close();
        }
      }
    };

    watch(
      () => props.show,
      (val) => {
        if (val) {
          applyBodyStyles();
          visible.value = true;
        } else {
          removeBodyStyles();
          visible.value = false;
        }
      },
      { immediate: true },
    );

    onBeforeUnmount(() => {
      removeBodyStyles();
    });

    return {
      visible,
      dialog,
      modalBody,
      slots,
      close,
      clickOutside,
      overlayClose,
    };
  },
});
