
import { computed, defineComponent, ref } from 'vue';
import { DataType } from '@/catalogs/api/configuration/application/DataType';
import { ComponentName } from '@/catalogs/api/configuration/components/ComponentName';
import { setupComponent } from '@/catalogs/composables/setupComponent';
import InjectStyles from '@/common/components/InjectStyles.vue';
import Btn from '@/common/components/Btn.vue';
import { CatalogProductSearchTypeEnum } from '@/common/services/swagger/index.defs';
import { SearchProductsCommand } from '@/catalogs/api/runtime/CommandExecutor';

export default defineComponent({
  name: ComponentName.searchProductsNoResults,

  components: {
    InjectStyles,
    Btn,
  },

  props: {
    instanceId: String,
  },

  setup(props) {
    const root = ref<HTMLElement>();

    const { componentName, isReady, isWebComponent, instance, store, routeData, t } =
      setupComponent(root, props.instanceId, [DataType.Products]);

    const noSearchResults = computed(
      () =>
        instance.value?.getRouteData().products.searchText &&
        !store.value?.data.products?.items?.length,
    );

    const noProducts = computed(
      () =>
        !instance.value?.getRouteData().products.searchText &&
        !instance.value?.getRouteData().products.filters &&
        store.value?.data.products?.totalCount == 0,
    );

    const clearResults = async () => {
      try {
        await instance.value?.execute(
          new SearchProductsCommand({
            searchText: '',
            searchType:
              routeData.value?.products.searchType ?? CatalogProductSearchTypeEnum.WithAllWords,
          }),
        );
      } catch (error) {
        // Ignore Error
      }
    };

    return {
      root,
      componentName,
      isReady,
      isWebComponent,
      store,
      noSearchResults,
      noProducts,
      t,
      clearResults,
    };
  },
});
