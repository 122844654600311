
import { defineComponent, ref } from 'vue';
import { ComponentName } from '@/catalogs/api/configuration/components/ComponentName';
import { DataType } from '@/catalogs/api/configuration/application/DataType';
import { setupComponent } from '@/catalogs/composables/setupComponent';

export default defineComponent({
  name: ComponentName.catalogsProductsSearchView,

  props: {
    instanceId: String,
  },

  setup(props) {
    const root = ref<HTMLElement>();

    const { componentName, isReady, store, routeData } = setupComponent(root, props.instanceId, [
      DataType.Products,
    ]);

    return {
      root,
      componentName,
      isReady,
      store,
      routeData,
    };
  },
});
