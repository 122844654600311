
import { computed, defineComponent } from 'vue';

export default defineComponent({
  props: {
    totalCount: { type: Number, default: 0 },
    page: { type: Number, default: 0 },
    pageSize: { type: Number, default: 0 },
    showingText: String,
    ofText: String,
  },

  setup(props) {
    const pageStart = computed(() => {
      return (((props.page ?? 1) - 1) * (props.pageSize ?? 1)) + 1;
    });

    const pageEnd = computed(() => {
      return Math.min((props.page ?? 1) * (props.pageSize ?? 1), props.totalCount)
    });

    return {
      pageStart,
      pageEnd,
    };
  },
});
