import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["part"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InjectStyles = _resolveComponent("InjectStyles")!
  const _component_FormDatalist = _resolveComponent("FormDatalist")!
  const _component_LinkSecondary = _resolveComponent("LinkSecondary")!
  const _component_Message = _resolveComponent("Message")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "root",
    class: _normalizeClass(["pis-component", _ctx.componentName]),
    part: _ctx.componentName
  }, [
    (_ctx.isReady && _ctx.isSparePartView && _ctx.componentOptions.visible)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.isWebComponent)
            ? (_openBlock(), _createBlock(_component_InjectStyles, {
                key: 0,
                items: _ctx.store?.options.styles
              }, null, 8, ["items"]))
            : _createCommentVNode("", true),
          _createVNode(_component_Card, {
            class: "mb-4",
            icon: _ctx.componentOptions.icon && 'narrow',
            header: _ctx.t('NarrowByProduct', true),
            "use-markup": _ctx.componentOptions.card
          }, {
            default: _withCtx(() => [
              (_ctx.sparePartMasterProducts.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createVNode(_component_FormDatalist, {
                      name: "narrow-by-product",
                      "value-field": "productId",
                      "text-field": "description",
                      "model-value": _ctx.masterProductSelected,
                      options: _ctx.sparePartMasterProducts,
                      placeholder: _ctx.t('SelectProduct'),
                      disabled: _ctx.masterProductSelected,
                      "no-results-message": _ctx.t('NoResults'),
                      "onUpdate:modelValue": _ctx.setMasterProductId
                    }, null, 8, ["model-value", "options", "placeholder", "disabled", "no-results-message", "onUpdate:modelValue"]),
                    (_ctx.masterProductSelected)
                      ? (_openBlock(), _createBlock(_component_LinkSecondary, {
                          key: 0,
                          icon: "cancel",
                          class: "mt-2",
                          onClick: _withModifiers(_ctx.clear, ["prevent"])
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.t('ClearProduct')), 1)
                          ]),
                          _: 1
                        }, 8, ["onClick"]))
                      : _createCommentVNode("", true)
                  ]))
                : (_openBlock(), _createBlock(_component_Message, { key: 1 }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('TooManyMasterProducts')), 1)
                    ]),
                    _: 1
                  }))
            ]),
            _: 1
          }, 8, ["icon", "header", "use-markup"])
        ]))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}