import { ComputedRef, computed } from 'vue';
import { Instance } from './../api/Instance';
import { IRouteData } from './../api/runtime/IRouteData';

export interface RouteHistoryData {
  historyReversed: ComputedRef<IRouteData[]>;
  lastSearchIndex: ComputedRef<number>;
  previousProductName: ComputedRef<string | undefined>;
}

const instances: Record<string, RouteHistoryData> = {};

export const setupRouteData = (instance: Instance): RouteHistoryData => {
  const instanceID = instance.id;
  if (!instances[instanceID]) {
    const historyReversed = computed<IRouteData[]>(() =>
      [...(instance.router.history ?? [])].reverse(),
    );

    const lastSearchIndex = computed<number>(() =>
      historyReversed.value.findIndex(({ view }) => view === 'search'),
    );

    const previousProductName = computed<string | undefined>(() => {
      if (lastSearchIndex.value > 1) {
        const currentProductId = instance.router.routeData.productId;
        const productId = historyReversed.value.find((route) => {
          if (route.view !== 'detail') {
            return false;
          }
          if (currentProductId && route.productId === currentProductId) {
            return false;
          }
          return true;
        })?.productId;
        if (productId) {
          return (instance.store?.data.productIdToDisplayName || {})[productId];
        }
      }
      return undefined;
    });

    instances[instanceID] = {
      historyReversed,
      lastSearchIndex,
      previousProductName,
    };
  }
  return instances[instanceID];
};
