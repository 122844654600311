import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["part"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 1,
  class: "list-unstyled"
}
const _hoisted_4 = { class: "d-flex align-items-center" }
const _hoisted_5 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InjectStyles = _resolveComponent("InjectStyles")!
  const _component_Clickable = _resolveComponent("Clickable")!
  const _component_Arrow = _resolveComponent("Arrow")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "root",
    class: _normalizeClass(["pis-component", _ctx.componentName]),
    part: _ctx.componentName
  }, [
    (_ctx.isReady && _ctx.visible)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.isWebComponent)
            ? (_openBlock(), _createBlock(_component_InjectStyles, {
                key: 0,
                items: _ctx.store?.options.styles
              }, null, 8, ["items"]))
            : _createCommentVNode("", true),
          (_ctx.breadcrumbs && _ctx.breadcrumbs.length > 1)
            ? (_openBlock(), _createElementBlock("ul", _hoisted_3, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.breadcrumbs, (item, index) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: index,
                    class: "list-inline-item"
                  }, [
                    _createElementVNode("div", _hoisted_4, [
                      (_ctx.isDetailsView || index < _ctx.breadcrumbs.length - 1)
                        ? (_openBlock(), _createBlock(_component_Clickable, {
                            key: 0,
                            onClick: _withModifiers(($event: any) => (_ctx.setClassification(item)), ["prevent"])
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.name), 1)
                            ]),
                            _: 2
                          }, 1032, ["onClick"]))
                        : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(item.name), 1)),
                      (index < _ctx.breadcrumbs.length - 1)
                        ? (_openBlock(), _createBlock(_component_Arrow, {
                            key: 2,
                            class: "ms-2",
                            sm: ""
                          }))
                        : _createCommentVNode("", true)
                    ])
                  ]))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}