import _mergeWith from 'lodash/mergeWith';
import { reactive } from 'vue';
import { Instance } from '../Instance';
import { IRouteData } from './IRouteData';

import _cloneDeep from 'lodash/cloneDeep';
import { defaultRoute } from '../configuration/DefaultRoute';

export class Router {
  routeData = reactive<IRouteData>(_cloneDeep(defaultRoute));
  history: IRouteData[] = [];

  constructor(private instance: Instance) {}

  /**
   * @param routeSettings
   * @param passive flag that disables the API call. Useful with changing the display modes.
   */
  public async setRoute(routeSettings?: IRouteData): Promise<void> {
    // Fill in routeData if this is initial call

    if (!Object.keys(this.routeData).length) {
      _mergeWith(this.routeData, routeSettings || defaultRoute);
    }

    const newRoute = _cloneDeep(this.routeData);

    if (routeSettings) {
      // When route is set from outside pagination may be passed as string

      _mergeWith(newRoute, routeSettings, (objValue, srcValue) => {
        // Allow to override existing arrays with empty ones instead of merging them.
        // Useful with eg.: filters.
        if (Array.isArray(srcValue)) {
          return srcValue;
        }

        // To clear value we need to return null
        if (srcValue === undefined) {
          return null;
        }
      });
    }

    if (this.instance.store.data.isInitialized && this.instance.httpService) {
      await this.instance.httpService.load(newRoute);
      this.commitRoute(newRoute);
      return Promise.resolve();
    } else {
      this.commitRoute(newRoute);
      return Promise.resolve();
    }
  }

  private commitRoute(routeData: IRouteData) {
    this.history.push({ ...routeData });
    _mergeWith(this.routeData, routeData, (objValue, srcValue) => {
      // Allow to override existing arrays with empty ones instead of merging them.
      // Useful with eg.: filters.
      if (Array.isArray(srcValue)) {
        return srcValue;
      }

      // To clear value we need to return null
      if (srcValue === undefined) {
        return null;
      }
    });

    this.instance.eventBus.emit('route-changed', _cloneDeep(this.routeData));
  }

  public async back(steps = 1): Promise<void> {
    const newIndex = this.history.length - steps - 1;
    const newRoute = this.history[newIndex];

    if (newRoute) {
      this.history.splice(newIndex);
    }

    this.setRoute(newRoute ?? defaultRoute);
  }
}
