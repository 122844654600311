
import { computed, defineComponent, ref, onMounted, onUnmounted, watch, nextTick } from 'vue';
import VRuntimeTemplate from 'vue3-runtime-template';

import { DataType } from '@/catalogs/api/configuration/application/DataType';
import { ComponentName } from '@/catalogs/api/configuration/components/ComponentName';
import { attrValue, attrValues } from '@/common/helpers/productAttributes';
import { setupComponent } from '@/catalogs/composables/setupComponent';

import { DetailsCommand, PreviewCommand } from '@/catalogs/api/runtime/CommandExecutor';

import InjectStyles from '@/common/components/InjectStyles.vue';
import Image from '@/common/components/Image.vue';
import Icon from '@/common/components/Icon.vue';

import {
  CatalogProductTypeEnum,
  CatalogProductItem,
  ProductItem,
} from '@/common/services/swagger/index.defs';

import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import { IItemsGridHorizontalOptions } from '@/catalogs/api/configuration/components/IItemsGridHorizontalOptions';
import Clickable from '@/common/components/Clickable.vue';
import ItemCatalogsDetailsModal from './components/ItemCatalogsDetailsModal.vue';
export default defineComponent({
  name: ComponentName.searchProductsGridHorizontal,

  components: {
    InjectStyles,
    Image,
    Icon,
    Carousel,
    Slide,
    Pagination,
    Navigation,
    VRuntimeTemplate,
    Clickable,
    ItemCatalogsDetailsModal,
  },

  props: {
    instanceId: String,
  },

  setup(props) {
    const catalogProductType = CatalogProductTypeEnum;

    const root = ref<HTMLElement>();

    const {
      componentName,
      isWebComponent,
      isReady,
      instance,
      store,
      routeData,
      t,
      widerThan,
      narrowerThan,
      emit,
    } = setupComponent(root, props.instanceId, [DataType.Products]);

    const carousel = ref();
    const unwatch = watch(
      () => isReady.value,
      () => {
        if (instance.value) {
          unwatch();
          instance.value.eventBus.on('route-changed', () => {
            const val = carousel.value as any;
            if (val) {
              nextTick(() => {
                val.slideTo(0);
              });

              setTimeout(() => {
                loaded.value = true;
              }, 100);
            }
          });
          resizeHandler();
        }
      },
    );

    const findSettings = () => {
      const settings = componentOptions.value.carousel;
      const width = window.innerWidth;
      if (settings) {
        if (!settings.breakpoints) return settings.defaults;
        const keys = Object.keys(settings.breakpoints);
        if (keys.length) {
          for (let i = keys.length - 1; i >= 0; i--) {
            if (width > parseInt(keys[i])) {
              return settings.breakpoints[keys[i]];
            }
          }
        }
        return settings.defaults;
      }
      return {};
    };

    const currentSettings = ref();
    const loaded = ref(false);
    const resizeHandler = () => {
      currentSettings.value = findSettings();
    };

    onMounted(() => {
      window.addEventListener('resize', resizeHandler);
    });

    onUnmounted(() => {
      window.removeEventListener('resize', resizeHandler);
    });

    const componentOptions = computed(
      () =>
        store.value?.options.components?.itemsGridHorizontal ?? ({} as IItemsGridHorizontalOptions),
    );

    const details = async (product: CatalogProductItem): Promise<void> => {
      if (product.inactive) {
        return;
      }
      try {
        if (routeData.value && product) {
          await instance.value?.execute(new DetailsCommand(product as ProductItem));
        }
      } catch (error) {
        //Ignore Error
      }
    };

    const baseProductDetails = async (baseProduct: ProductItem): Promise<void> => {
      if (baseProduct.inactive) {
        return;
      }
      try {
        await instance.value?.execute(new DetailsCommand(baseProduct));
      } catch (error) {
        //Ignore Error
      }
    };

    const productsItems = computed(() => store.value?.data.products?.items ?? []);

    const settings = computed(() => {
      const defaultSettings = componentOptions.value.carousel.defaults || {};

      if (defaultSettings) {
        return {
          ...defaultSettings,
          mouseDrag: componentOptions.value.carousel.mouseDrag,
          touchDrag: componentOptions.value.carousel.touchDrag,
          wrapAround: componentOptions.value.carousel.wrapAround,
          transition: componentOptions.value.carousel.transition,
        };
      }

      return {};
    });

    const breakpoints = computed(() => componentOptions.value.carousel.breakpoints || {});

    const i18n = computed(() => {
      return {
        ariaNextSlide: t('CarouselAriaNextSlide'),
        ariaPreviousSlide: t('CarouselAriaPreviousSlide'),
        ariaNavigateToSlide: t('CarouselAriaNavigateToSlide'),
        ariaGallery: t('CarouselAriaGallery'),
        itemXofY: t('CarouselItemXofY'),
        iconArrowUp: t('CarouselIconArrowUp'),
        iconArrowDown: t('CarouselIconArrowDown'),
        iconArrowRight: t('CarouselIconArrowRight'),
        iconArrowLeft: t('CarouselIconArrowLeft'),
      };
    });

    const selectedItem = ref();
    const catalogsModalVisible = ref(false);

    const toggleItemDetails = (product: CatalogProductItem) => {
      selectedItem.value = product;
      catalogsModalVisible.value = false;
      nextTick(() => {
        catalogsModalVisible.value = true;
      });
    };

    const getItemImage = (item: CatalogProductItem): string | undefined => {
      if (item.baseProduct) {
        return (item.baseProduct.images || [])[0]?.url;
      }
      return (item.images || [])[0]?.url;
    };

    const previewEnabled = computed(
      () => instance.value?.registeredComponents.has(ComponentName.preview) ?? false,
    );

    const isPreviewDisabled = (item: CatalogProductItem) =>
      (item.baseProduct && item.baseProduct.inactive) || item.inactive;

    const previewByProductId = async (productId: string): Promise<void> => {
      try {
        await instance.value?.execute(new PreviewCommand({ productId }));
      } catch (error) {
        // Ignore Error
      }
    };

    const preview = (product: CatalogProductItem): Promise<void> =>
      previewByProductId(product.productId);

    const showingAllCatalogs = computed(() => {
      return !routeData.value?.products.catalogId;
    });

    return {
      root,
      instance,
      loaded,
      componentName,
      componentOptions,
      isWebComponent,
      isReady,
      store,
      productsItems,

      settings,
      breakpoints,
      carousel,
      i18n,
      currentSettings,
      selectedItem,
      baseProductDetails,
      t,
      widerThan,
      narrowerThan,
      attrValue,
      attrValues,
      details,
      previewByProductId,
      emit,
      toggleItemDetails,
      getItemImage,
      previewEnabled,
      isPreviewDisabled,
      preview,
      catalogProductType,
      catalogsModalVisible,
      showingAllCatalogs,
    };
  },
});
