
import { computed, defineComponent, ref } from 'vue';
import { ComponentName } from '@/products/api/configuration/components/ComponentName';
import { setupComponent } from '@/products/composables/setupComponent';
import { DataType } from '@/products/api/configuration/application/DataType';
import { NarrowByProductCommand } from '@/products/api/runtime/CommandExecutor';

import InjectStyles from '@/common/components/InjectStyles.vue';
import Card from '@/common/components/Card.vue';
import LinkSecondary from '@/common/components/LinkSecondary.vue';
import FormDatalist from '@/common/components/FormDatalist.vue';
import Message from '@/common/components/Message.vue';
import { ISearchNarrowByProductOptions } from '@/products/api/configuration/components/ISearchNarrowByProductOptions';

export default defineComponent({
  name: ComponentName.searchNarrowByProduct,

  components: {
    InjectStyles,
    Card,
    LinkSecondary,
    FormDatalist,
    Message,
  },

  props: {
    instanceId: String,
  },

  setup(props) {
    const root = ref<HTMLElement>();

    const { isReady, isWebComponent, componentName, instance, store, routeData, t } =
      setupComponent(root, props.instanceId, [DataType.MasterProducts]);

    const componentOptions = computed(
      () =>
        store.value?.options.components?.searchNarrowByProduct ??
        ({} as ISearchNarrowByProductOptions)
    );

    const sparePartMasterProducts = computed(
      () => store.value?.data.sparePartMasterProducts?.items ?? []
    );
    const isSparePartView = computed(() => store.value?.options.application?.treeType === 'Parts');

    const masterProductSelected = computed(() => routeData.value?.masterProductId);

    const setMasterProductId = async (productId: string) => {
      const item = sparePartMasterProducts.value.find((product) => product.productId == productId);
      if (item) {
        try {
          await instance.value?.execute(
            new NarrowByProductCommand({
              masterProductId: item.productId,
              masterProductName: item.description,
            })
          );
        } catch (error) {
          // Ignore Error
        }
      }
    };

    const clear = async () => {
      try {
        instance.value?.execute(
          new NarrowByProductCommand({ masterProductId: undefined, masterProductName: undefined })
        );
      } catch (error) {
        // Ignore Error
      }
    };

    return {
      root,
      isReady,
      isWebComponent,
      componentName,
      componentOptions,
      store,
      isSparePartView,
      sparePartMasterProducts,
      masterProductSelected,
      t,
      setMasterProductId,
      clear,
    };
  },
});
