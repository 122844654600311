import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  ref: "root",
  class: "pis-component",
  "aria-label": "Items page navigation"
}
const _hoisted_2 = {
  key: 0,
  class: "my-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InjectStyles = _resolveComponent("InjectStyles")!
  const _component_PaginationAmount = _resolveComponent("PaginationAmount")!
  const _component_VRuntimeTemplate = _resolveComponent("VRuntimeTemplate")!
  const _component_Pagination = _resolveComponent("Pagination")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    (_ctx.isReady && _ctx.visible)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.isWebComponent)
            ? (_openBlock(), _createBlock(_component_InjectStyles, {
                key: 0,
                items: _ctx.store?.options.styles
              }, null, 8, ["items"]))
            : _createCommentVNode("", true),
          (!_ctx.hideIfInitial && _ctx.totalCount && _ctx.pagination)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: _normalizeClass(["d-flex align-items-center justify-content-between mt-4", [_ctx.widerThan('md') ? 'flex-row' : 'flex-column']])
              }, [
                (_ctx.componentOptions.hideCounts !== true)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                      _createVNode(_component_PaginationAmount, {
                        "total-count": _ctx.totalCount,
                        page: _ctx.pagination.page,
                        "page-size": _ctx.pagination.pageSize,
                        "showing-text": _ctx.t('PaginationShowing', true),
                        "of-text": _ctx.t('PaginationOf', true)
                      }, null, 8, ["total-count", "page", "page-size", "showing-text", "of-text"])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.componentOptions.paginationTemplate)
                  ? (_openBlock(), _createBlock(_component_VRuntimeTemplate, {
                      key: 1,
                      template: _ctx.componentOptions.paginationTemplate,
                      "template-props": {
              instance: _ctx.instance,
              page: _ctx.page,
              pageSize: _ctx.pagination.pageSize,
              totalPages: _ctx.totalPages,
              totalCount: _ctx.totalCount,
              changePage: _ctx.changePage,
              emit: _ctx.emit,
              t: _ctx.t,
            }
                    }, null, 8, ["template", "template-props"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_Pagination, {
                  class: _normalizeClass(["my-2", _ctx.componentName]),
                  part: _ctx.componentName,
                  total: Math.ceil(_ctx.totalCount / _ctx.pagination.pageSize),
                  current: _ctx.pagination.page,
                  "trim-to": _ctx.componentOptions.visiblePages,
                  onChange: _ctx.changePage
                }, null, 8, ["part", "class", "total", "current", "trim-to", "onChange"])
              ], 2))
            : _createCommentVNode("", true)
        ], 64))
      : _createCommentVNode("", true)
  ], 512))
}