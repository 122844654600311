
import PIS from '@/common/api/PIS';
import {
  CancelJobCommand,
  DownloadJobCommand,
  DownloadJobHistoryCommand,
} from '@/catalogs/api/runtime/CommandExecutor';
import Btn from '@/common/components/Btn.vue';
import Modal from '@/common/components/Modal.vue';
import { Instance } from '@/catalogs/api/Instance';
import Spinner from '@/common/components/Spinner.vue';
import { TranslateFunc } from '@/catalogs/composables/setupComponent';
import { defineComponent, onMounted, PropType, ref, watch } from 'vue';
import {
  JobItem,
  JobSearchResult,
  JobSortFieldEnum,
  JobStatusEnum,
  SortDirectionEnum,
} from '@/common/services/swagger/index.defs';

export default defineComponent({
  components: {
    Btn,
    Modal,
    Spinner,
  },
  props: {
    t: { type: Function as PropType<TranslateFunc>, required: true },
    instanceId: String,
  },
  emits: ['update:show'],
  setup(props, { emit }) {
    const instance = PIS.Catalogs.getInstance(props.instanceId) as Instance;
    const show = ref(true);
    const isReady = ref(false);
    const items = ref<JobItem[]>([]);
    const sortByItems = JobSortFieldEnum;
    const sortBy = ref<JobSortFieldEnum>(JobSortFieldEnum.Catalog);
    const close = () => emit('update:show', false);
    const onDownload = async (jobId: string) => {
      try {
        await instance.execute(
          new DownloadJobCommand({
            jobId,
            onSuccessMessage: props.t('JobDownloadReady'),
          }),
        );
      } catch (error) {
        // Ignore error
      }
    };
    const onCancel = async (jobId: string) => {
      try {
        await instance.execute(
          new CancelJobCommand({
            jobId,
            onSuccessMessage: props.t('JobCancelled'),
          }),
        );
        return loadJobHistory();
      } catch (error) {
        // Ignore error
      }
    };
    const loadJobHistory = async () => {
      try {
        const data: JobSearchResult | undefined = await instance.execute(
          new DownloadJobHistoryCommand({
            sortSettings: {
              field: sortBy.value,
              direction:
                sortBy.value === JobSortFieldEnum.LastModified
                  ? SortDirectionEnum.Descending
                  : SortDirectionEnum.Ascending,
            },
          }),
        );
        if (data && data.items) {
          items.value = data.items;
        }
      } catch (error) {
        // Ignore error
      }
      isReady.value = true;
    };

    watch(
      () => sortBy.value,
      () => loadJobHistory(),
    );

    onMounted(() => {
      loadJobHistory();
    });

    return {
      show,
      items,
      sortBy,
      isReady,
      sortByItems,
      JobStatusEnum,
      close,
      onCancel,
      onDownload,
    };
  },
});
