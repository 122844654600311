
import { PropType, computed, defineComponent, ref } from 'vue';

import Popper from 'vue3-popper';
export default defineComponent({
  components: { Popper },
  props: {
    modelValue: {
      type: null as unknown as PropType<string | null>,
      default: null,
      required: false,
    },
    items: Array as PropType<SelectItem[]>,
  },

  emits: ['update:modelValue'],

  setup(props, { emit }) {
    const expanded = ref<boolean>(false);
    const innerValue = ref<SelectItem | null>();
    const toggleEl = ref<HTMLElement>();
    const inputEl = ref<HTMLElement>();
    const ddEl = ref<HTMLElement>();
    const filter = ref<string>('');
    const fixedItems = computed(() =>
      props.items != null
        ? props.items.filter((x) => x.type === 'fixed' || x.type === 'divider')
        : [],
    );
    const filteredItems = computed(() => {
      if (filter.value != null && props.items != null)
        return props.items.filter(
          (x) =>
            x.type === 'standard' && x.text.toLowerCase().indexOf(filter.value.toLowerCase()) >= 0,
        );
      else return [];
    });

    const open = () => {
      if (ddEl.value && toggleEl.value) {
        ddEl.value.style.width = toggleEl.value.clientWidth + 2 + 'px';
      }

      setTimeout(() => {
        if (inputEl.value) {
          inputEl.value.focus();
        }
      }, 10);
    };

    const select = (item: SelectItem) => {
      emit('update:modelValue', item.value);
      innerValue.value = item;
    };

    return {
      expanded,
      innerValue,
      inputEl,
      toggleEl,
      ddEl,
      filter,
      filteredItems,
      fixedItems,
      open,
      select,
    };
  },
});

export interface SelectItem {
  text: string;
  value: string;
  type: SelectItemType;
}

export type SelectItemType = 'standard' | 'fixed' | 'divider';
