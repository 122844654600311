
import { computed, defineComponent, ref } from 'vue';
import { DataType } from '@/classifications/api/configuration/application/DataType';
import { ComponentName } from '@/classifications/api/configuration/components/ComponentName';
import { setupComponent } from '@/classifications/composables/setupComponent';
import InjectStyles from '@/common/components/InjectStyles.vue';
import { IBaseComponentOptions } from '@/classifications/api/configuration/components/IBaseComponentOptions';

export default defineComponent({
  name: ComponentName.note,

  components: {
    InjectStyles,
  },

  props: {
    instanceId: String,
  },

  setup(props) {
    const root = ref<HTMLElement>();

    const { componentName, isReady, isWebComponent, instance, store, routeData, t } =
      setupComponent(root, props.instanceId, [DataType.Classifications]);

    const componentOptions = computed(
      () => store.value?.options.components?.note ?? ({} as IBaseComponentOptions),
    );

    return {
      root,
      store,
      isReady,
      instance,
      routeData,
      componentName,
      isWebComponent,
      componentOptions,
      t,
    };
  },
});
