
import { defineComponent } from 'vue';
import Spinner from '@/common/components/Spinner.vue';

export default defineComponent({
  name: 'Loader',
  props: {
    visible: Boolean,
  },
  components: {
    Spinner,
  },
});
