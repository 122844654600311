
import { defineComponent, PropType } from 'vue';
import { PartType } from '../services/swagger/index.defs';
import { getPathFromEvent } from '../helpers/dom';

export default defineComponent({
  name: 'SparePartTypeList',
  data: () => ({
    isChecked: (event: Event) => getPathFromEvent<Event, HTMLInputElement>(event)[0].checked,
  }),
  props: {
    partTypes: Array as PropType<PartType[]>,
    currentPartType: String,
  },
  emits: ['click-item'],
});
