
import { defineComponent } from 'vue';

import Card from '@/common/components/Card.vue';
//import Clickable from '@/common/components/Clickable.vue';
import Image from '@/common/components/Image.vue';

export default defineComponent({
  components: {
    Card,
    // Clickable,
    Image,
  },

  props: {
    imageSrc: String,
    imageLoading: Boolean,
    inactive: Boolean,
    contentClickable: Boolean,
    wide: Boolean,
    useCardMarkup: { type: Boolean, default: true },
    hideImage: Boolean,
    imageClickable: { type: Boolean, default: true },
    imageWidth: { type: String, default: '8rem' },
    additionalColumnTemplateWidth: { type: Number, default: 2 },
    actionTemplateWidth: { type: Number, default: 2 },
  },

  emits: ['click-content'],
});
