import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-inline-flex justify-content-center align-items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    _createElementVNode("span", {
      class: _normalizeClass(["pis-icon flex-grow-0 flex-shrink-0", [
        `pis-icon-${_ctx.glyph}`,
        _ctx.sm && 'pis-icon-sm',
        _ctx.lg && 'pis-icon-lg',
        _ctx.xl && 'pis-icon-xl',
        _ctx.ul && 'pis-icon-ul',
      ]])
    }, null, 2)
  ]))
}