import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toHandlers as _toHandlers, mergeProps as _mergeProps, renderList as _renderList, Fragment as _Fragment, vModelSelect as _vModelSelect, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withDirectives as _withDirectives, withModifiers as _withModifiers, createSlots as _createSlots } from "vue"

const _hoisted_1 = ["part"]
const _hoisted_2 = {
  key: 0,
  class: "mb-4"
}
const _hoisted_3 = { class: "dropdown-item-text text-muted small mb-1 text-uppercase fw-bold ext-match-category" }
const _hoisted_4 = { class: "dropdown-item-text text-muted mt-3 mb-1 small text-uppercase fw-bold ext-match-category" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = {
  key: 0,
  style: {"width":"4rem","min-width":"4rem"},
  class: "me-2"
}
const _hoisted_8 = { class: "fw-bold ext-display-name" }
const _hoisted_9 = {
  key: 0,
  class: "small ext-all-identifiers text-wrap"
}
const _hoisted_10 = {
  key: 1,
  class: "small ext-description text-wrap"
}
const _hoisted_11 = { class: "d-flex align-items-center" }
const _hoisted_12 = {
  key: 0,
  style: {"width":"4rem","min-width":"4rem"},
  class: "me-2"
}
const _hoisted_13 = { class: "fw-bold ext-display-name" }
const _hoisted_14 = {
  key: 0,
  class: "small ext-all-identifiers text-wrap"
}
const _hoisted_15 = {
  key: 1,
  class: "small ext-description text-wrap"
}
const _hoisted_16 = {
  key: 0,
  class: "text-center"
}
const _hoisted_17 = { key: 1 }
const _hoisted_18 = { key: 2 }
const _hoisted_19 = ["textContent", "value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InjectStyles = _resolveComponent("InjectStyles")!
  const _component_Image = _resolveComponent("Image")!
  const _component_Highlighter = _resolveComponent("Highlighter")!
  const _component_Clickable = _resolveComponent("Clickable")!
  const _component_Badge = _resolveComponent("Badge")!
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_FormInputClearable = _resolveComponent("FormInputClearable")!
  const _component_Btn = _resolveComponent("Btn")!
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "root",
    class: _normalizeClass(["pis-component", _ctx.componentName]),
    part: _ctx.componentName
  }, [
    (_ctx.isReady && _ctx.visible)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.isWebComponent)
            ? (_openBlock(), _createBlock(_component_InjectStyles, {
                key: 0,
                items: _ctx.store?.options.styles
              }, null, 8, ["items"]))
            : _createCommentVNode("", true),
          _createVNode(_component_Dropdown, {
            open: _ctx.isAutocompleteVisible,
            "onUpdate:open": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.isAutocompleteVisible) = $event)),
            "min-width": "340px",
            items: _ctx.autocomplete.items
          }, _createSlots({
            footer: _withCtx(() => [
              (_ctx.isAutocompleteLoading)
                ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                    _createVNode(_component_Spinner, { sm: "" })
                  ]))
                : (_ctx.autocomplete.totalCount)
                  ? (_openBlock(), _createElementBlock("small", _hoisted_17, _toDisplayString(_ctx.t('ShowingNOfNMatches', false, [
                `${_ctx.autocomplete.visibleMatchCount || 0}`,
                `${_ctx.autocomplete.totalCount || 0}`,
              ])), 1))
                  : (_openBlock(), _createElementBlock("small", _hoisted_18, _toDisplayString(_ctx.autocomplete.matchesOnlyWithoutFilters
              ? _ctx.t('NoResultsWithAppliedFilters')
              : _ctx.autocomplete.matchesOnlyInAllCatagories
              ? _ctx.t('NoResultsInCategory')
              : _ctx.t('NoResults')), 1))
            ]),
            activator: _withCtx(({ on }) => [
              _createElementVNode("form", {
                onSubmit: _cache[5] || (_cache[5] = _withModifiers(($event: any) => (_ctx.onSearch(false)), ["prevent"])),
                class: _normalizeClass([_ctx.widerThan('sm') && 'input-group flex-nowrap'])
              }, [
                _createVNode(_component_FormInputClearable, _mergeProps({
                  modelValue: _ctx.searchModel.text,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.searchModel.text) = $event))
                }, _toHandlers(on), {
                  "class-inner": _ctx.widerThan('sm') ? 'border-end-0' : 'mb-1',
                  lg: _ctx.widerThan('sm'),
                  placeholder: 
                _ctx.isSparePartsView
                  ? _ctx.t('SearchSparePartsInputPlaceholder')
                  : _ctx.t('SearchProductsInputPlaceholder')
              ,
                  "onUpdate:modelValue": _ctx.onSearchInput,
                  onClear: _ctx.onClear,
                  onFocus: _ctx.openAutocomplete
                }), null, 16, ["modelValue", "class-inner", "lg", "placeholder", "onUpdate:modelValue", "onClear", "onFocus"]),
                (_ctx.isSearchTypeDropdownEnabled)
                  ? _withDirectives((_openBlock(), _createElementBlock("select", {
                      key: 0,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.searchModel.type) = $event)),
                      class: _normalizeClass(["form-select px-2", [_ctx.narrowerThan('sm') && 'mb-1']]),
                      style: _normalizeStyle({
                'min-width': _ctx.widerThan('sm') ? '200px' : '100%',
                'max-width': _ctx.widerThan('sm') ? '300px' : '100%',
              })
                    }, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.searchTypeOptions, (searchType) => {
                        return (_openBlock(), _createElementBlock("option", {
                          textContent: _toDisplayString(_ctx.t(searchType.translate)),
                          key: searchType.value,
                          value: searchType.value
                        }, null, 8, _hoisted_19))
                      }), 128))
                    ], 6)), [
                      [_vModelSelect, _ctx.searchModel.type]
                    ])
                  : _createCommentVNode("", true),
                _createVNode(_component_Btn, {
                  "aria-label": 
                _ctx.isSparePartsView
                  ? _ctx.t('SearchSparePartsInputAriaLabel')
                  : _ctx.t('SearchProductsInputAriaLabel')
              ,
                  icon: "search",
                  class: _normalizeClass([_ctx.narrowerThan('sm') && 'w-100 d-flex justify-content-center'])
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString(_ctx.t('SearchButtonText')), 1)
                  ]),
                  _: 1
                }, 8, ["aria-label", "class"])
              ], 34)
            ]),
            _: 2
          }, [
            (_ctx.hasCategories && !_ctx.isAutocompleteLoading)
              ? {
                  name: "item-top-title",
                  fn: _withCtx(() => [
                    _createElementVNode("div", _hoisted_3, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.t('Classifications')), 1)
                    ])
                  ]),
                  key: "0"
                }
              : undefined,
            (_ctx.hasCategories && !_ctx.isAutocompleteLoading)
              ? {
                  name: "item-title",
                  fn: _withCtx(() => [
                    _createElementVNode("div", _hoisted_4, [
                      (_ctx.isSparePartsView)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.t('SpareParts', true)), 1))
                        : (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.t('Products', true)), 1))
                    ])
                  ]),
                  key: "1"
                }
              : undefined,
            (!_ctx.isAutocompleteLoading)
              ? {
                  name: "item-top",
                  fn: _withCtx(({ item }) => [
                    (item.type === 'category')
                      ? (_openBlock(), _createBlock(_component_Clickable, {
                          key: 0,
                          class: "d-flex align-items-center dropdown-item",
                          onClick: ($event: any) => (_ctx.setClassification(item.productId))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item.match) + " ", 1),
                            _createElementVNode("div", null, [
                              (_ctx.componentOptions?.showCategoryImages)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                                    _createVNode(_component_Image, {
                                      src: _ctx.getThumbnailUrl(item)
                                    }, null, 8, ["src"])
                                  ]))
                                : _createCommentVNode("", true)
                            ]),
                            _createElementVNode("div", null, [
                              _createElementVNode("div", _hoisted_8, [
                                _createVNode(_component_Highlighter, {
                                  highlightClassName: "text-primary p-0 display-inline bg-transparent ext-highlight",
                                  highlightTag: "span",
                                  searchWords: _ctx.highlightTerms,
                                  autoEscape: true,
                                  textToHighlight: item.text
                                }, null, 8, ["searchWords", "textToHighlight"])
                              ]),
                              (item.description)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                                    _createTextVNode(_toDisplayString(_ctx.t('Keywords')) + ": ", 1),
                                    _createVNode(_component_Highlighter, {
                                      highlightClassName: "fw-bold text-primary p-0 display-inline bg-transparent ext-highlight",
                                      highlightTag: "span",
                                      searchWords: _ctx.highlightTerms,
                                      autoEscape: true,
                                      textToHighlight: item.description
                                    }, null, 8, ["searchWords", "textToHighlight"])
                                  ]))
                                : _createCommentVNode("", true),
                              (item.secondaryText)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(item.secondaryText), 1))
                                : _createCommentVNode("", true)
                            ])
                          ]),
                          _: 2
                        }, 1032, ["onClick"]))
                      : _createCommentVNode("", true)
                  ]),
                  key: "2"
                }
              : undefined,
            (!_ctx.isAutocompleteLoading)
              ? {
                  name: "item",
                  fn: _withCtx(({ item }) => [
                    (item.type === 'no-filters')
                      ? (_openBlock(), _createBlock(_component_Clickable, {
                          key: 0,
                          class: "d-flex align-items-center dropdown-item py-2",
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onSearch(true, true)))
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("em", null, "\"" + _toDisplayString(_ctx.searchModel.text) + "\"", 1),
                            _createVNode(_component_Badge, { class: "ms-auto" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.t('ShowingNWithoutFilters', false, [(item && item.count) || 0])), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024))
                      : _createCommentVNode("", true),
                    (item.type === 'root')
                      ? (_openBlock(), _createBlock(_component_Clickable, {
                          key: 1,
                          class: "d-flex align-items-center dropdown-item py-2",
                          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onSearch(true)))
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("em", null, "\"" + _toDisplayString(_ctx.searchModel.text) + "\"", 1),
                            _createVNode(_component_Badge, { class: "ms-auto" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.t('ShowingNOfAllCategories', false, [(item && item.count) || 0])), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024))
                      : (item.type === 'current')
                        ? (_openBlock(), _createBlock(_component_Clickable, {
                            key: 2,
                            class: "d-flex align-items-center dropdown-item py-2",
                            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onSearch(false)))
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("em", null, "\"" + _toDisplayString(_ctx.searchModel.text) + "\"", 1),
                              (item.categoryName)
                                ? (_openBlock(), _createBlock(_component_Badge, {
                                    key: 0,
                                    class: "ms-auto"
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString((item && item.count) || 0) + " " + _toDisplayString(_ctx.t('In')) + " \"" + _toDisplayString(item.categoryName) + "\" ", 1)
                                    ]),
                                    _: 2
                                  }, 1024))
                                : (_openBlock(), _createBlock(_component_Badge, {
                                    key: 1,
                                    class: "ms-auto"
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.t('ShowingNOfCurrentCategory', false, [(item && item.count) || 0])), 1)
                                    ]),
                                    _: 2
                                  }, 1024))
                            ]),
                            _: 2
                          }, 1024))
                        : (item.type === 'item')
                          ? (_openBlock(), _createBlock(_component_Clickable, {
                              key: 3,
                              class: "dropdown-item mb-1",
                              onClick: ($event: any) => (
              _ctx.autoDetailsNavigation && item.productId
                ? _ctx.details({ productId: item.productId })
                : _ctx.searchText(item.text)
            )
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_11, [
                                  (_ctx.componentOptions?.showProductImages)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                                        _createVNode(_component_Image, {
                                          src: _ctx.getThumbnailUrl(item)
                                        }, null, 8, ["src"])
                                      ]))
                                    : _createCommentVNode("", true),
                                  _createElementVNode("div", null, [
                                    _createElementVNode("div", _hoisted_13, [
                                      _createVNode(_component_Highlighter, {
                                        highlightClassName: "text-primary p-0 display-inline bg-transparent ext-highlight",
                                        highlightTag: "span",
                                        searchWords: _ctx.highlightTerms,
                                        autoEscape: true,
                                        textToHighlight: item.text
                                      }, null, 8, ["searchWords", "textToHighlight"])
                                    ]),
                                    (item.text != item.secondaryText)
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                                          _createVNode(_component_Highlighter, {
                                            highlightClassName: "fw-bold text-primary p-0 display-inline bg-transparent ext-highlight",
                                            highlightTag: "span",
                                            searchWords: _ctx.highlightTerms,
                                            autoEscape: true,
                                            textToHighlight: item.secondaryText
                                          }, null, 8, ["searchWords", "textToHighlight"])
                                        ]))
                                      : _createCommentVNode("", true),
                                    (item.description)
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                                          _createVNode(_component_Highlighter, {
                                            highlightClassName: "fw-bold text-primary p-0 display-inline bg-transparent ext-highlight",
                                            highlightTag: "span",
                                            searchWords: _ctx.highlightTerms,
                                            autoEscape: true,
                                            textToHighlight: item.description
                                          }, null, 8, ["searchWords", "textToHighlight"])
                                        ]))
                                      : _createCommentVNode("", true)
                                  ])
                                ])
                              ]),
                              _: 2
                            }, 1032, ["onClick"]))
                          : _createCommentVNode("", true)
                  ]),
                  key: "3"
                }
              : undefined
          ]), 1032, ["open", "items"])
        ]))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}