import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, renderSlot as _renderSlot, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = ["part"]
const _hoisted_2 = { class: "container-fluid" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "d-sm-none" }
const _hoisted_5 = { class: "container-fluid d-print-none" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "row" }
const _hoisted_9 = { class: "container-fluid pis-print-only" }
const _hoisted_10 = { class: "row" }
const _hoisted_11 = { class: "container-fluid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InjectStyles = _resolveComponent("InjectStyles")!
  const _component_ProductsNotifications = _resolveComponent("ProductsNotifications")!
  const _component_ProductsBreadcrumbs = _resolveComponent("ProductsBreadcrumbs")!
  const _component_ProductsSearchHeader = _resolveComponent("ProductsSearchHeader")!
  const _component_ProductsMobileNav = _resolveComponent("ProductsMobileNav")!
  const _component_ProductsSearchFavoriteViews = _resolveComponent("ProductsSearchFavoriteViews")!
  const _component_ProductsSearchFavoriteCategories = _resolveComponent("ProductsSearchFavoriteCategories")!
  const _component_ProductsSearchNarrowByProduct = _resolveComponent("ProductsSearchNarrowByProduct")!
  const _component_ProductsSearchPartTypes = _resolveComponent("ProductsSearchPartTypes")!
  const _component_ProductsSearchCategories = _resolveComponent("ProductsSearchCategories")!
  const _component_ProductsSearchFilters = _resolveComponent("ProductsSearchFilters")!
  const _component_ProductsSearchForm = _resolveComponent("ProductsSearchForm")!
  const _component_ProductsSearchFiltersStatus = _resolveComponent("ProductsSearchFiltersStatus")!
  const _component_ProductsSearchToolbar = _resolveComponent("ProductsSearchToolbar")!
  const _component_ProductsSearchSelection = _resolveComponent("ProductsSearchSelection")!
  const _component_ProductsSearchTable = _resolveComponent("ProductsSearchTable")!
  const _component_ProductsTableView = _resolveComponent("ProductsTableView")!
  const _component_ProductsSearchList = _resolveComponent("ProductsSearchList")!
  const _component_ProductsListView = _resolveComponent("ProductsListView")!
  const _component_ProductsSearchGrid = _resolveComponent("ProductsSearchGrid")!
  const _component_ProductsGridView = _resolveComponent("ProductsGridView")!
  const _component_ProductsSearchPagination = _resolveComponent("ProductsSearchPagination")!
  const _component_ProductsSearchNoResults = _resolveComponent("ProductsSearchNoResults")!
  const _component_ProductsSearchNoResultsView = _resolveComponent("ProductsSearchNoResultsView")!
  const _component_ProductsDetailsHeader = _resolveComponent("ProductsDetailsHeader")!
  const _component_ProductDetailsIdentifiers = _resolveComponent("ProductDetailsIdentifiers")!
  const _component_ProductDetailsReplacements = _resolveComponent("ProductDetailsReplacements")!
  const _component_ProductDetailsGeneralInformation = _resolveComponent("ProductDetailsGeneralInformation")!
  const _component_Message = _resolveComponent("Message")!
  const _component_ProductsDetailsData = _resolveComponent("ProductsDetailsData")!
  const _component_ProductsDetailsGallery = _resolveComponent("ProductsDetailsGallery")!
  const _component_ProductsDetailsActions = _resolveComponent("ProductsDetailsActions")!
  const _component_ProductDetailsClassifications = _resolveComponent("ProductDetailsClassifications")!
  const _component_ProductDetailsAttributeGroups = _resolveComponent("ProductDetailsAttributeGroups")!
  const _component_ProductDetailsRelationships = _resolveComponent("ProductDetailsRelationships")!
  const _component_ProductCompare = _resolveComponent("ProductCompare")!
  const _component_ProductsPreview = _resolveComponent("ProductsPreview")!
  const _component_LoadingIndicator = _resolveComponent("LoadingIndicator")!
  const _component_ProductActionModals = _resolveComponent("ProductActionModals")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "root",
    class: _normalizeClass(["pis-component position-relative", _ctx.componentName]),
    part: _ctx.componentName
  }, [
    _withDirectives(_createElementVNode("div", null, [
      (_ctx.isWebComponent)
        ? (_openBlock(), _createBlock(_component_InjectStyles, {
            key: 0,
            items: _ctx.store?.options.styles
          }, null, 8, ["items"]))
        : _createCommentVNode("", true),
      _createVNode(_component_ProductsNotifications),
      _createVNode(_component_LoadingIndicator, null, {
        default: _withCtx(() => [
          _withDirectives(_createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_ProductsBreadcrumbs),
              _createVNode(_component_ProductsSearchHeader),
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", {
                  class: _normalizeClass([_ctx.widerThan('xl') ? 'col-3' : _ctx.widerThan('md') ? 'col-4' : 'col-12'])
                }, [
                  _withDirectives(_createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_ProductsMobileNav)
                  ], 512), [
                    [_vShow, _ctx.allowMobileView]
                  ]),
                  _createElementVNode("div", {
                    class: _normalizeClass([_ctx.allowMobileView && 'd-none d-sm-block'])
                  }, [
                    _createVNode(_component_ProductsSearchFavoriteViews),
                    _createVNode(_component_ProductsSearchFavoriteCategories),
                    _createVNode(_component_ProductsSearchNarrowByProduct),
                    _createVNode(_component_ProductsSearchPartTypes),
                    _createVNode(_component_ProductsSearchCategories),
                    _createVNode(_component_ProductsSearchFilters)
                  ], 2)
                ], 2),
                _createElementVNode("div", {
                  class: _normalizeClass([_ctx.widerThan('xl') ? 'col-9' : _ctx.widerThan('md') ? 'col-8' : 'col-12'])
                }, [
                  _createVNode(_component_ProductsSearchForm),
                  _createVNode(_component_ProductsSearchFiltersStatus),
                  _createVNode(_component_ProductsSearchToolbar),
                  _createVNode(_component_ProductsSearchSelection, { class: "mb-2" }),
                  _createVNode(_component_ProductsTableView, null, {
                    default: _withCtx(() => [
                      _renderSlot(_ctx.$slots, "search-table", {}, () => [
                        _createVNode(_component_ProductsSearchTable)
                      ])
                    ]),
                    _: 3
                  }),
                  _createVNode(_component_ProductsListView, null, {
                    default: _withCtx(() => [
                      _renderSlot(_ctx.$slots, "search-list", {}, () => [
                        _createVNode(_component_ProductsSearchList)
                      ])
                    ]),
                    _: 3
                  }),
                  _createVNode(_component_ProductsGridView, null, {
                    default: _withCtx(() => [
                      _renderSlot(_ctx.$slots, "search-grid", {}, () => [
                        _createVNode(_component_ProductsSearchGrid)
                      ])
                    ]),
                    _: 3
                  }),
                  _createVNode(_component_ProductsSearchPagination),
                  _createVNode(_component_ProductsSearchNoResultsView, null, {
                    default: _withCtx(() => [
                      _renderSlot(_ctx.$slots, "search-no-results", {}, () => [
                        _createVNode(_component_ProductsSearchNoResults)
                      ])
                    ]),
                    _: 3
                  })
                ], 2)
              ])
            ])
          ], 512), [
            [_vShow, _ctx.routeData?.view === 'search']
          ]),
          _withDirectives(_createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_5, [
              (_ctx.itemNotFound)
                ? (_openBlock(), _createElementBlock("h4", _hoisted_6, _toDisplayString(_ctx.t('ProductNotFound', true)), 1))
                : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _withDirectives(_createElementVNode("div", null, [
                      _renderSlot(_ctx.$slots, "detail-before-breadcrumbs")
                    ], 512), [
                      [_vShow, _ctx.itemLoaded]
                    ]),
                    _createVNode(_component_ProductsBreadcrumbs),
                    _withDirectives(_createElementVNode("div", null, [
                      _renderSlot(_ctx.$slots, "detail-after-breadcrumbs")
                    ], 512), [
                      [_vShow, _ctx.itemLoaded]
                    ]),
                    _createVNode(_component_ProductsDetailsHeader),
                    _createElementVNode("div", _hoisted_8, [
                      _createElementVNode("div", {
                        class: _normalizeClass([_ctx.detailsGalleryVisible && _ctx.widerThan('md') ? 'col-8' : 'col-12'])
                      }, [
                        _createVNode(_component_ProductDetailsIdentifiers, { class: "mt-3" }),
                        _createVNode(_component_ProductDetailsReplacements, { class: "mt-3" }),
                        _withDirectives(_createElementVNode("div", null, [
                          _renderSlot(_ctx.$slots, "detail-before-general")
                        ], 512), [
                          [_vShow, _ctx.itemLoaded]
                        ]),
                        _createVNode(_component_ProductDetailsGeneralInformation),
                        _withDirectives(_createElementVNode("div", null, [
                          _renderSlot(_ctx.$slots, "detail-after-general")
                        ], 512), [
                          [_vShow, _ctx.itemLoaded]
                        ]),
                        _createVNode(_component_ProductsDetailsData, null, {
                          "detail-downloads": _withCtx(() => [
                            _renderSlot(_ctx.$slots, "detail-downloads", {}, () => [
                              _createVNode(_component_Message, { class: "p-2" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t('DownloadsNotAvailable')), 1)
                                ]),
                                _: 1
                              })
                            ])
                          ]),
                          _: 3
                        })
                      ], 2),
                      (_ctx.detailsGalleryVisible)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            class: _normalizeClass([_ctx.widerThan('md') ? 'col-4' : 'col-12 order-first'])
                          }, [
                            _withDirectives(_createElementVNode("div", null, [
                              _renderSlot(_ctx.$slots, "detail-before-gallery")
                            ], 512), [
                              [_vShow, _ctx.itemLoaded]
                            ]),
                            _createVNode(_component_ProductsDetailsGallery, { class: "mt-5" }),
                            _createVNode(_component_ProductsDetailsActions),
                            _withDirectives(_createElementVNode("div", null, [
                              _renderSlot(_ctx.$slots, "detail-after-gallery")
                            ], 512), [
                              [_vShow, _ctx.itemLoaded]
                            ])
                          ], 2))
                        : _createCommentVNode("", true)
                    ])
                  ]))
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createVNode(_component_ProductDetailsIdentifiers, { class: "mt-3" }),
                _createVNode(_component_ProductDetailsReplacements, { class: "mt-3" }),
                _createVNode(_component_ProductDetailsGeneralInformation, { class: "mt-3" }),
                _createVNode(_component_ProductDetailsClassifications, { class: "mt-3" }),
                _createVNode(_component_ProductDetailsAttributeGroups, { class: "mt-3" }),
                _createVNode(_component_ProductDetailsRelationships, { class: "mt-3" })
              ])
            ])
          ], 512), [
            [_vShow, _ctx.routeData?.view === 'detail']
          ]),
          _withDirectives(_createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_11, [
              _createVNode(_component_ProductCompare)
            ])
          ], 512), [
            [_vShow, _ctx.routeData?.view === 'compare']
          ]),
          _createVNode(_component_ProductsPreview)
        ]),
        _: 3
      }),
      _createVNode(_component_ProductActionModals)
    ], 512), [
      [_vShow, _ctx.isReady]
    ])
  ], 10, _hoisted_1))
}