import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["part"]
const _hoisted_2 = { class: "h5" }
const _hoisted_3 = {
  key: 1,
  class: "mt-4"
}
const _hoisted_4 = {
  style: {"width":"4rem","min-width":"4rem"},
  class: "me-2"
}
const _hoisted_5 = { class: "fw-bold ext-display-name" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = {
  key: 0,
  class: "small ext-all-identifiers text-wrap"
}
const _hoisted_8 = {
  key: 1,
  class: "small ext-description text-wrap"
}
const _hoisted_9 = { class: "mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InjectStyles = _resolveComponent("InjectStyles")!
  const _component_Image = _resolveComponent("Image")!
  const _component_Clickable = _resolveComponent("Clickable")!
  const _component_Btn = _resolveComponent("Btn")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "root",
    class: _normalizeClass(["pis-component", _ctx.componentName]),
    part: _ctx.componentName
  }, [
    (_ctx.isReady && _ctx.isLoaded)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.isWebComponent)
            ? (_openBlock(), _createBlock(_component_InjectStyles, {
                key: 0,
                items: _ctx.store?.options.styles
              }, null, 8, ["items"]))
            : _createCommentVNode("", true),
          _createElementVNode("div", null, [
            _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.autocomplete.items.length ? _ctx.t('NoProductResults') : _ctx.t('NoResults')), 1),
            _createElementVNode("p", null, [
              _createElementVNode("strong", null, _toDisplayString(_ctx.t('Suggestions')) + ":", 1)
            ]),
            _createElementVNode("ul", null, [
              _createElementVNode("li", null, _toDisplayString(_ctx.t('CheckSpelling')), 1),
              _createElementVNode("li", null, _toDisplayString(_ctx.t('DifferentKeywords')), 1),
              _createElementVNode("li", null, _toDisplayString(_ctx.t('ContactSupport')), 1)
            ])
          ]),
          (_ctx.autocomplete.items.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("strong", null, _toDisplayString(_ctx.t('CategorySuggestions')), 1),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.autocomplete.items, (item, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: index,
                    class: "mt-3"
                  }, [
                    (item.type === 'category')
                      ? (_openBlock(), _createBlock(_component_Clickable, {
                          key: 0,
                          class: "d-flex align-items-center dropdown-item",
                          onClick: ($event: any) => (_ctx.setClassification(item.productId))
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", null, [
                              _createElementVNode("div", _hoisted_4, [
                                _createVNode(_component_Image, {
                                  src: _ctx.getThumbnailUrl(item)
                                }, null, 8, ["src"])
                              ])
                            ]),
                            _createElementVNode("div", null, [
                              _createElementVNode("div", _hoisted_5, [
                                _createElementVNode("a", {
                                  href: "#",
                                  onClick: _withModifiers(($event: any) => (_ctx.setClassification(item.productId)), ["prevent"])
                                }, _toDisplayString(item.text), 9, _hoisted_6)
                              ]),
                              (item.description)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.t('Keywords')) + ":" + _toDisplayString(item.description), 1))
                                : _createCommentVNode("", true),
                              (item.secondaryText)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(item.secondaryText), 1))
                                : _createCommentVNode("", true)
                            ])
                          ]),
                          _: 2
                        }, 1032, ["onClick"]))
                      : _createCommentVNode("", true)
                  ]))
                }), 128))
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_Btn, {
              onClick: _withModifiers(_ctx.clearResults, ["prevent"])
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('Clear')), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ])
        ], 64))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}