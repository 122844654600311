import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    show: true,
    title: _ctx.t('Errors'),
    "onUpdate:show": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:show'))),
    closeOnOuterClick: false
  }, {
    default: _withCtx(() => [
      _createElementVNode("ul", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.errors, (error) => {
          return (_openBlock(), _createElementBlock("li", {
            key: error.id
          }, _toDisplayString(error.message), 1))
        }), 128)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.warnings, (warning) => {
          return (_openBlock(), _createElementBlock("li", {
            key: warning.id
          }, _toDisplayString(warning.message), 1))
        }), 128))
      ])
    ]),
    _: 1
  }, 8, ["title"]))
}