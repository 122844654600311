import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["part"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InjectStyles = _resolveComponent("InjectStyles")!
  const _component_Notifications = _resolveComponent("Notifications")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "root",
    class: "pis-component",
    part: _ctx.componentName
  }, [
    (_ctx.isReady && _ctx.store)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.isWebComponent)
            ? (_openBlock(), _createBlock(_component_InjectStyles, {
                key: 0,
                items: _ctx.store?.options.styles
              }, null, 8, ["items"]))
            : _createCommentVNode("", true),
          _createVNode(_component_Notifications, {
            instance: _ctx.instance!
          }, null, 8, ["instance"])
        ], 64))
      : _createCommentVNode("", true)
  ], 8, _hoisted_1))
}