import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["part"]
const _hoisted_2 = { class: "mb-4" }
const _hoisted_3 = { class: "list-unstyled" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InjectStyles = _resolveComponent("InjectStyles")!
  const _component_AliasEditModal = _resolveComponent("AliasEditModal")!
  const _component_AliasesImportModal = _resolveComponent("AliasesImportModal")!
  const _component_AliasesExportModal = _resolveComponent("AliasesExportModal")!
  const _component_Confirmation = _resolveComponent("Confirmation")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "root",
    class: "pis-component position-relative",
    part: _ctx.componentName
  }, [
    (_ctx.isReady)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.isWebComponent)
            ? (_openBlock(), _createBlock(_component_InjectStyles, {
                key: 0,
                items: _ctx.store?.options.styles
              }, null, 8, ["items"]))
            : _createCommentVNode("", true),
          (_ctx.currentActionName === _ctx.CatalogAction.aliasEdit)
            ? (_openBlock(), _createBlock(_component_AliasEditModal, {
                key: 1,
                t: _ctx.t,
                "instance-id": _ctx.instanceId,
                data: (_ctx.currentActionData as AliasEditActionData),
                "onUpdate:show": _ctx.onActionCompleted
              }, null, 8, ["t", "instance-id", "data", "onUpdate:show"]))
            : _createCommentVNode("", true),
          (_ctx.currentActionName === _ctx.CatalogAction.aliasImport)
            ? (_openBlock(), _createBlock(_component_AliasesImportModal, {
                key: 2,
                t: _ctx.t,
                "instance-id": _ctx.instanceId,
                data: (_ctx.currentActionData as AliasImportActionData),
                "onUpdate:show": _ctx.onActionCompleted
              }, null, 8, ["t", "instance-id", "data", "onUpdate:show"]))
            : _createCommentVNode("", true),
          (_ctx.currentActionName === _ctx.CatalogAction.aliasExport)
            ? (_openBlock(), _createBlock(_component_AliasesExportModal, {
                key: 3,
                t: _ctx.t,
                "instance-id": _ctx.instanceId,
                data: (_ctx.currentActionData as AliasExportActionData),
                "onUpdate:show": _ctx.onActionCompleted
              }, null, 8, ["t", "instance-id", "data", "onUpdate:show"]))
            : (_ctx.currentActionName === _ctx.CatalogAction.aliasDelete)
              ? (_openBlock(), _createBlock(_component_Confirmation, {
                  key: 4,
                  title: _ctx.t('DeleteProductAliases'),
                  item: (_ctx.currentActionData as AliasDeleteActionData).aliases,
                  "onUpdate:show": _ctx.onActionCompleted,
                  onConfirmationCancelled: _ctx.onActionCompleted,
                  onConfirmationOk: _ctx.onAliasDelete,
                  t: _ctx.t
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_2, [
                      _createTextVNode(_toDisplayString(_ctx.t('AliasesConfirmDelete')) + " ", 1),
                      _createElementVNode("ul", _hoisted_3, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((_ctx.currentActionData as AliasDeleteActionData).aliases, (alias, index) => {
                          return (_openBlock(), _createElementBlock("li", { key: index }, [
                            _createElementVNode("strong", null, _toDisplayString(alias.value), 1)
                          ]))
                        }), 128))
                      ])
                    ])
                  ]),
                  _: 1
                }, 8, ["title", "item", "onUpdate:show", "onConfirmationCancelled", "onConfirmationOk", "t"]))
              : _createCommentVNode("", true)
        ], 64))
      : _createCommentVNode("", true)
  ], 8, _hoisted_1))
}