
import { computed, defineComponent, PropType, ref } from 'vue';
import { getPathFromEvent } from '../helpers/dom';
import Badge from './Badge.vue';
import Clickable from './Clickable.vue';
import Dropdown from './Dropdown.vue';
import Message from './Message.vue';

export default defineComponent({
  components: {
    Dropdown,
    Clickable,
    Badge,
    Message,
  },

  props: {
    modelValue: [String, Number],
    options: Array as PropType<Record<string, string>[]>,
    placeholder: String,
    name: { type: String, required: true },
    noResultsMessage: String,

    // Field name in the `options` array that should be used for the text label
    textField: { type: String, default: 'text' },

    // Field name in the `options` array that should be used for the value
    valueField: { type: String, default: 'value' },
  },

  emits: ['update:modelValue', 'change'],

  setup(props, { emit }) {
    const optionsOpen = ref(false);
    const searchPhrase = ref('');

    const inputText = computed(() => {
      if (searchPhrase.value) {
        return searchPhrase.value;
      }

      const optionFound = props.options?.find(
        (option) => option[props.valueField] === props.modelValue
      );
      return optionFound ? optionFound[props.textField] : '';
    });

    const matchesSearchPhrase = (val: string) => val.toLowerCase().includes(searchPhrase.value);

    const optionsFiltered = computed(() =>
      props.options?.filter(
        (option) =>
          matchesSearchPhrase(option[props.textField]) ||
          matchesSearchPhrase(option[props.valueField])
      )
    );

    const onInput = (event: InputEvent) => {
      searchPhrase.value = getPathFromEvent<InputEvent, HTMLInputElement>(
        event
      )[0].value.toLowerCase();
    };

    const onItemClick = (item) => {
      emit('update:modelValue', item[props.valueField]);
      searchPhrase.value = '';
      optionsOpen.value = false;
    };

    return {
      optionsOpen,
      inputText,
      optionsFiltered,
      onInput,
      onItemClick,
    };
  },
});
