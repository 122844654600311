import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "fw-bolder" }
const _hoisted_2 = ["href", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.guides, (guide, index) => {
    return (_openBlock(), _createElementBlock("div", {
      key: index,
      class: "row g-0 py-1 py-lg-2 ps-lg-3 border-bottom"
    }, [
      _createElementVNode("div", _hoisted_1, _toDisplayString(guide.typeDescription), 1),
      _createElementVNode("a", {
        href: guide.url,
        onClick: _withModifiers(($event: any) => (_ctx.show(guide)), ["prevent"])
      }, _toDisplayString(guide.description), 9, _hoisted_2)
    ]))
  }), 128))
}