
import { defineComponent } from 'vue';
import VRuntimeTemplate from 'vue3-runtime-template';
import { LinkTypeEnum, ProductItem, ProductReplacement } from '../services/swagger/index.defs';
import { DetailsCommand } from '@/products/api/runtime/CommandExecutor';

import PisLink from '@/common/components/PisLink.vue';
import Icon from '@/common/components/Icon.vue';
import { IInstance } from '../api/runtime/IInstance';

export default defineComponent({
  name: 'PisReplacements',

  components: {
    PisLink,
    Icon,
    VRuntimeTemplate,
  },

  props: {
    replacements: { type: Object as () => ProductReplacement[] | undefined, required: true },
    instance: { type: Object as () => IInstance | undefined, required: true },
    showIcon: Boolean,
    isDetailView: Boolean,
    shadowRoot: { type: Object as () => ShadowRoot },
    visible: Boolean,
  },

  setup(props) {
    const getLinkTemplate = (repl: ProductReplacement) => {
      if (!repl.link?.text) return '';

      const matches = repl.link.text.match(/{[^}]+}/gm);

      let template = repl.link.text;
      if (matches) {
        matches.forEach((m) => {
          const linkParts = m.replace('{', '').replace('}', '').split('|');
          const replacement = `<a href="#" @click.prevent="navigate('${linkParts[0]}')">${linkParts[1]}</a>`;
          template = template.replace(m, replacement);
        });

        return template;
      }

      return repl.label;
    };

    const navigate = async (productId: string) => {
      if (props.isDetailView) {
        if (props.shadowRoot) {
          var el = props.shadowRoot.getElementById('pis-anchor-agm-Technical');
          if (el) {
            el.scrollIntoView({ behavior: 'smooth' });
          }
        }
      } else {
        try {
          await props.instance?.execute(new DetailsCommand({ productId } as ProductItem));
        } catch (error) {
          // Ignore Error
        }
      }
    };

    return { LinkTypeEnum, getLinkTemplate, navigate };
  },
});
