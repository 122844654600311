import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx, createElementBlock as _createElementBlock, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["part"]
const _hoisted_2 = {
  key: 1,
  class: "card-body"
}
const _hoisted_3 = {
  key: 1,
  class: "card-body"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InjectStyles = _resolveComponent("InjectStyles")!
  const _component_CategoriesList = _resolveComponent("CategoriesList")!
  const _component_Arrow = _resolveComponent("Arrow")!
  const _component_LinkSecondary = _resolveComponent("LinkSecondary")!
  const _component_Message = _resolveComponent("Message")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "root",
    class: _normalizeClass(["pis-component", _ctx.componentName]),
    part: _ctx.componentName
  }, [
    (_ctx.isReady && _ctx.visible)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.isWebComponent)
            ? (_openBlock(), _createBlock(_component_InjectStyles, {
                key: 0,
                items: _ctx.store?.options.styles
              }, null, 8, ["items"]))
            : _createCommentVNode("", true),
          _createVNode(_component_Card, {
            class: "mb-4",
            icon: _ctx.componentOptions.icon && 'tree-view',
            header: _ctx.t('Products'),
            "use-markup": _ctx.componentOptions.card
          }, {
            list: _withCtx(() => [
              (_ctx.categories?.length)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    (_ctx.categories?.length)
                      ? (_openBlock(), _createBlock(_component_CategoriesList, {
                          key: 0,
                          categories: _ctx.categoriesTrimmed,
                          onClickItem: _ctx.setCategory
                        }, null, 8, ["categories", "onClickItem"]))
                      : _createCommentVNode("", true),
                    (_ctx.showMoreBtn)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                          _createVNode(_component_LinkSecondary, {
                            onClick: _withModifiers(_ctx.showAllCategories, ["prevent"])
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_Arrow, {
                                class: "me-2",
                                dir: _ctx.trimList ? 'down' : 'up'
                              }, null, 8, ["dir"]),
                              _createTextVNode(" " + _toDisplayString(_ctx.trimList ? _ctx.t('ShowAllCategories') : _ctx.t('HideAllCategories')) + " (" + _toDisplayString(_ctx.categories.length) + ") ", 1)
                            ]),
                            _: 1
                          }, 8, ["onClick"])
                        ]))
                      : _createCommentVNode("", true)
                  ], 64))
                : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createVNode(_component_Message, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t('NoCategories')), 1)
                      ]),
                      _: 1
                    })
                  ]))
            ]),
            _: 1
          }, 8, ["icon", "header", "use-markup"])
        ], 64))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}