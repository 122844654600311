import { FavoriteView } from '@/common/services/swagger/index.defs';
import { IRouteData } from '../../runtime/IRouteData';

export enum SearchFavoriteViewsEvent {
  loaded = 'favorite-views-loaded',
  saved = 'favorite-views-saved',
  deleted = 'favorite-views-deleted',
  applied = 'favorite-views-applied',
}

export interface SearchFavoriteViewsLoadedData {
  items: FavoriteView[];
}

export interface SearchFavoriteViewsSavedData {
  item: FavoriteView;
}

export interface SearchFavoriteViewsDeletedData {
  id: string;
}

export interface SearchFavoriteViewsAppliedData {
  routeData: IRouteData;
}
