
import { defineComponent, ref, reactive, computed, watch } from 'vue';
import _debounce from 'lodash/debounce';

import { CatalogSearchTypeEnum } from '@/common/services/swagger/index.defs';
import { ComponentName } from '@/catalogs/api/configuration/components/ComponentName';
import { SearchCommand } from '@/catalogs/api/runtime/CommandExecutor';
import { setupComponent } from '@/catalogs/composables/setupComponent';

import InjectStyles from '@/common/components/InjectStyles.vue';
import Btn from '@/common/components/Btn.vue';
import FormInputClearable from '@/common/components/FormInputClearable.vue';
import Dropdown from '@/common/components/Dropdown.vue';
import Clickable from '@/common/components/Clickable.vue';
import Spinner from '@/common/components/Spinner.vue';

interface AutocompleteModelItem {
  text?: string;
  count?: number;
  type: 'item' | 'root' | 'current';
}

const searchTypeCatalogName = {
  value: CatalogSearchTypeEnum.CatalogCodeAndDescription,
  translate: 'SearchByCodeAndDescription',
};

const searchTypeCreatedBy = {
  value: CatalogSearchTypeEnum.CreatedBy,
  translate: 'SearchByCreatedBy',
};

interface Autocomplete {
  totalCount: number;
  visibleMatchCount: number;
  items: AutocompleteModelItem[];
}

export default defineComponent({
  name: ComponentName.searchForm,

  components: {
    InjectStyles,
    Btn,
    Dropdown,
    FormInputClearable,
    Clickable,
    Spinner,
  },

  props: {
    instanceId: String,
  },

  setup(props) {
    const root = ref<HTMLElement>();

    const searchModel = reactive({
      text: '',
      type: CatalogSearchTypeEnum.CatalogCodeAndDescription,
      isDirty: false,
    });

    const autocomplete = ref<Autocomplete>({
      totalCount: 0,
      visibleMatchCount: 0,
      items: [] as AutocompleteModelItem[],
    });
    const isAutocompleteVisible = ref(false);
    const isAutocompleteLoading = ref(false);

    const {
      componentName,
      isReady,
      isWebComponent,
      instance,
      store,
      routeData,
      widerThan,
      narrowerThan,
      t,
    } = setupComponent(root, props.instanceId);

    const visible = computed(() => store.value?.options.components?.searchForm?.visible);

    const autocompleteEnabled = computed(
      () => store.value?.options.components?.searchForm?.useAutocomplete,
    );

    const baseSearchTypeOptions = [searchTypeCatalogName, searchTypeCreatedBy];

    const searchTypeOptions = computed(() => {
      return baseSearchTypeOptions;
    });

    const onSearch = async () => {
      isAutocompleteVisible.value = false;
      try {
        await instance.value?.execute(
          new SearchCommand({
            searchText: searchModel.text,
            searchType: searchModel.type,
          }),
        );

        searchModel.isDirty = false;
      } catch (error) {
        // Ignore Error
      }
    };

    const searchText = (text: string) => {
      searchModel.text = text;
      onSearch();
    };

    const onClear = () => {
      if (!searchModel.isDirty) {
        searchModel.text = '';
        autocomplete.value.items = [];
        autocomplete.value.totalCount = 0;
        onSearch();
      }
    };

    const openAutocomplete = () => {
      if (!autocompleteEnabled.value) {
        return;
      }

      if (searchModel.text == routeData.value?.catalogs.searchText) {
        return;
      }

      if (searchModel.text.length > 2) {
        isAutocompleteVisible.value = true;
      }
    };

    const closeAutocomplete = () => {
      isAutocompleteVisible.value = false;
    };

    const handleAutocompleteSearch = _debounce(async (text) => {
      if (!autocompleteEnabled.value) {
        return;
      }
      const hasText: boolean = text != null && text.length > 2;
      isAutocompleteVisible.value = hasText;

      if (hasText) {
        isAutocompleteLoading.value = true;
        const items: Autocomplete['items'] = [];
        const result = await instance.value?.httpService?.autocompleteCatalogs(text);
        if (result) {
          const matches: Autocomplete['items'] = (result.matches || []).map((match) => ({
            text: match,
            type: 'item',
          }));

          const autocompleteValue: Autocomplete = {
            totalCount: result.totalCount || 0,
            visibleMatchCount: result.matches?.length || 0,
            items: [],
          };

          autocompleteValue.items = items.concat(matches);
          autocomplete.value = autocompleteValue;
        }

        isAutocompleteLoading.value = false;
      }
    }, 300);

    const onSearchInput = () => {
      searchModel.isDirty =
        searchModel.text?.length > 0 && searchModel.text != routeData.value?.catalogs.searchText;
      handleAutocompleteSearch(searchModel.text);
    };

    // Sync the search model text with route search text after initializing the app.
    watch(
      () => routeData.value?.products.searchText,
      (text, oldText) => {
        if (text !== oldText) {
          searchModel.text = text ?? '';
        }
      },
    );

    watch(
      () => routeData.value?.catalogs.searchType,
      (type, oldType) => {
        if (type && type !== oldType) {
          searchModel.type = type;
        }
      },
    );

    watch(
      () => searchModel.type,
      () => {
        if (routeData.value) {
          routeData.value.catalogs.searchType = searchModel.type;
        }
      },
    );

    return {
      root,
      componentName,
      isReady,
      isWebComponent,
      store,
      searchModel,
      searchTypeOptions,
      autocomplete,
      isAutocompleteVisible,
      isAutocompleteLoading,
      widerThan,
      narrowerThan,
      t,
      onSearch,
      onSearchInput,
      onClear,
      searchText,
      openAutocomplete,
      closeAutocomplete,
      visible,
    };
  },
});
