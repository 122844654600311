import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["part"]
const _hoisted_2 = {
  key: 1,
  class: "table-responsive pis-scroll"
}
const _hoisted_3 = { class: "table" }
const _hoisted_4 = {
  key: 0,
  style: {"width":"1%"}
}
const _hoisted_5 = {
  key: 1,
  style: {"width":"1%"}
}
const _hoisted_6 = {
  key: 2,
  style: {"width":"1%"}
}
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { key: 2 }
const _hoisted_10 = { class: "bg-light" }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { key: 1 }
const _hoisted_13 = { key: 2 }
const _hoisted_14 = ["colspan"]
const _hoisted_15 = { class: "row g-0" }
const _hoisted_16 = { class: "col" }
const _hoisted_17 = { class: "text-muted" }
const _hoisted_18 = { key: 0 }
const _hoisted_19 = { key: 1 }
const _hoisted_20 = { key: 2 }
const _hoisted_21 = ["colspan"]
const _hoisted_22 = { class: "row g-0" }
const _hoisted_23 = { class: "col" }
const _hoisted_24 = { class: "fw-bolder" }
const _hoisted_25 = { class: "col text-end" }
const _hoisted_26 = { key: 0 }
const _hoisted_27 = { class: "ext-select-checkbox" }
const _hoisted_28 = ["checked", "onClick"]
const _hoisted_29 = { key: 1 }
const _hoisted_30 = { key: 2 }
const _hoisted_31 = { key: 1 }
const _hoisted_32 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InjectStyles = _resolveComponent("InjectStyles")!
  const _component_VRuntimeTemplate = _resolveComponent("VRuntimeTemplate")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Clickable = _resolveComponent("Clickable")!
  const _component_Tooltip = _resolveComponent("Tooltip")!
  const _component_PisReplacements = _resolveComponent("PisReplacements")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "root",
    class: _normalizeClass(["pis-component", _ctx.componentName]),
    part: _ctx.componentName
  }, [
    (_ctx.isReady && _ctx.componentOptions.visible)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.isWebComponent)
            ? (_openBlock(), _createBlock(_component_InjectStyles, {
                key: 0,
                items: _ctx.store?.options.styles
              }, null, 8, ["items"]))
            : _createCommentVNode("", true),
          (!_ctx.hideIfInitial && _ctx.productsItems && _ctx.productsItems.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("table", _hoisted_3, [
                  _createElementVNode("colgroup", null, [
                    (_ctx.componentOptions.selectable)
                      ? (_openBlock(), _createElementBlock("col", _hoisted_4))
                      : _createCommentVNode("", true),
                    (_ctx.previewEnabled)
                      ? (_openBlock(), _createElementBlock("col", _hoisted_5))
                      : _createCommentVNode("", true),
                    (_ctx.shouldDisplayAdditionalInfo)
                      ? (_openBlock(), _createElementBlock("col", _hoisted_6))
                      : _createCommentVNode("", true),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (col, index) => {
                      return (_openBlock(), _createElementBlock("col", {
                        key: index,
                        style: _normalizeStyle({ width: col.width })
                      }, null, 4))
                    }), 128))
                  ]),
                  _createElementVNode("thead", null, [
                    _createElementVNode("tr", null, [
                      (_ctx.componentOptions.selectable)
                        ? (_openBlock(), _createElementBlock("th", _hoisted_7))
                        : _createCommentVNode("", true),
                      (_ctx.previewEnabled)
                        ? (_openBlock(), _createElementBlock("th", _hoisted_8))
                        : _createCommentVNode("", true),
                      (_ctx.shouldDisplayAdditionalInfo)
                        ? (_openBlock(), _createElementBlock("th", _hoisted_9))
                        : _createCommentVNode("", true),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (col, index) => {
                        return (_openBlock(), _createElementBlock("th", { key: index }, [
                          (col.headerTemplate)
                            ? (_openBlock(), _createBlock(_component_VRuntimeTemplate, {
                                key: 0,
                                template: col.headerTemplate,
                                "template-props": { t: _ctx.t, col }
                              }, null, 8, ["template", "template-props"]))
                            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                _createTextVNode(_toDisplayString(col.caption), 1)
                              ], 64))
                        ]))
                      }), 128))
                    ])
                  ]),
                  _createElementVNode("tbody", null, [
                    (_ctx.configurators && _ctx.configuratorOptions.visible && _ctx.currentCIDName)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          _createElementVNode("tr", _hoisted_10, [
                            (_ctx.componentOptions.selectable)
                              ? (_openBlock(), _createElementBlock("td", _hoisted_11))
                              : _createCommentVNode("", true),
                            (_ctx.previewEnabled)
                              ? (_openBlock(), _createElementBlock("td", _hoisted_12))
                              : _createCommentVNode("", true),
                            (_ctx.shouldDisplayAdditionalInfo)
                              ? (_openBlock(), _createElementBlock("td", _hoisted_13))
                              : _createCommentVNode("", true),
                            _createElementVNode("td", {
                              colspan: _ctx.columns.length
                            }, [
                              _createElementVNode("div", _hoisted_15, [
                                _createElementVNode("div", _hoisted_16, [
                                  _createElementVNode("div", _hoisted_17, _toDisplayString([
                            _ctx.currentCIDName,
                            _ctx.configurators.length > 1 ? _ctx.t('Configurators') : _ctx.t('Configurator'),
                          ]
                            .join(' - ')
                            .concat(':')), 1)
                                ])
                              ])
                            ], 8, _hoisted_14)
                          ]),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.configurators, (configurator) => {
                            return (_openBlock(), _createElementBlock("tr", {
                              class: "bg-light",
                              key: configurator.code
                            }, [
                              (_ctx.componentOptions.selectable)
                                ? (_openBlock(), _createElementBlock("td", _hoisted_18))
                                : _createCommentVNode("", true),
                              (_ctx.previewEnabled)
                                ? (_openBlock(), _createElementBlock("td", _hoisted_19))
                                : _createCommentVNode("", true),
                              (_ctx.shouldDisplayAdditionalInfo)
                                ? (_openBlock(), _createElementBlock("td", _hoisted_20))
                                : _createCommentVNode("", true),
                              _createElementVNode("td", {
                                colspan: _ctx.columns.length
                              }, [
                                _createElementVNode("div", _hoisted_22, [
                                  _createElementVNode("div", _hoisted_23, [
                                    _createElementVNode("div", _hoisted_24, _toDisplayString(configurator.productId), 1)
                                  ]),
                                  _createElementVNode("div", _hoisted_25, [
                                    (_ctx.configuratorOptions.actionTemplate)
                                      ? (_openBlock(), _createBlock(_component_VRuntimeTemplate, {
                                          key: 0,
                                          template: _ctx.configuratorOptions.actionTemplate,
                                          "template-props": { t: _ctx.t, item: configurator, showConfigurator: _ctx.showConfigurator, emit: _ctx.emit },
                                          t: _ctx.t
                                        }, null, 8, ["template", "template-props", "t"]))
                                      : _createCommentVNode("", true)
                                  ])
                                ])
                              ], 8, _hoisted_21)
                            ]))
                          }), 128))
                        ], 64))
                      : _createCommentVNode("", true),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.productsItems, (item) => {
                      return (_openBlock(), _createElementBlock("tr", {
                        key: item.productId,
                        class: _normalizeClass({
                'bg-light': item.exactMatch,
                'ext-selected': _ctx.instance?.selection.isSelected(item),
              })
                      }, [
                        (_ctx.componentOptions.selectable)
                          ? (_openBlock(), _createElementBlock("td", _hoisted_26, [
                              _createElementVNode("div", _hoisted_27, [
                                _createElementVNode("input", {
                                  type: "checkbox",
                                  class: "form-check-input",
                                  checked: _ctx.instance?.selection.isSelected(item),
                                  onClick: ($event: any) => (_ctx.instance?.selection.toggle(item))
                                }, null, 8, _hoisted_28)
                              ])
                            ]))
                          : _createCommentVNode("", true),
                        (_ctx.previewEnabled)
                          ? (_openBlock(), _createElementBlock("td", _hoisted_29, [
                              _createVNode(_component_Clickable, {
                                class: "d-flex ext-icon-preview",
                                title: _ctx.t(item.inactive ? 'PreviewNotAvailable' : 'Preview', true),
                                disabled: item.inactive,
                                onClick: _withModifiers(($event: any) => (!item.inactive && _ctx.preview(item)), ["prevent"])
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_Icon, {
                                    glyph: "preview",
                                    lg: ""
                                  })
                                ]),
                                _: 2
                              }, 1032, ["title", "disabled", "onClick"])
                            ]))
                          : _createCommentVNode("", true),
                        (_ctx.shouldDisplayAdditionalInfo)
                          ? (_openBlock(), _createElementBlock("td", _hoisted_30, [
                              (item.additionalInfo && item.additionalInfo.length)
                                ? (_openBlock(), _createBlock(_component_Tooltip, {
                                    key: 0,
                                    text: _ctx.additionalInfo(item.additionalInfo)
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_Icon, {
                                        class: "text-warning",
                                        glyph: "warning"
                                      })
                                    ]),
                                    _: 2
                                  }, 1032, ["text"]))
                                : _createCommentVNode("", true)
                            ]))
                          : _createCommentVNode("", true),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (col, index) => {
                          return (_openBlock(), _createElementBlock("td", { key: index }, [
                            (col.cellTemplate)
                              ? (_openBlock(), _createBlock(_component_VRuntimeTemplate, {
                                  key: 0,
                                  template: col.cellTemplate,
                                  "template-props": { instance: _ctx.instance, col, item, emit: _ctx.emit, details: _ctx.details, attrValue: _ctx.attrValue, attrValues: _ctx.attrValues, t: _ctx.t }
                                }, null, 8, ["template", "template-props"]))
                              : (col.dataField == 'ProductId')
                                ? (_openBlock(), _createElementBlock("span", _hoisted_31, [
                                    _createVNode(_component_Clickable, {
                                      onClick: ($event: any) => (_ctx.details(item))
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.attrValue(item, col.dataField)), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["onClick"])
                                  ]))
                                : (_openBlock(), _createElementBlock("span", _hoisted_32, _toDisplayString(_ctx.attrValue(item, col.dataField)), 1)),
                            (col.showReplacements && item.replacements && item.replacements.length)
                              ? (_openBlock(), _createBlock(_component_PisReplacements, {
                                  key: 3,
                                  instance: _ctx.instance,
                                  replacements: item.replacements,
                                  visible: _ctx.replacementsVisible
                                }, null, 8, ["instance", "replacements", "visible"]))
                              : _createCommentVNode("", true),
                            (col.showAllIdentifiers)
                              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 4 }, _renderList(_ctx.attrValues(item, '#AllIdentifiers'), (val, index) => {
                                  return (_openBlock(), _createElementBlock("div", {
                                    class: "small text-muted fw-bold",
                                    key: index
                                  }, _toDisplayString(val), 1))
                                }), 128))
                              : _createCommentVNode("", true)
                          ]))
                        }), 128))
                      ], 2))
                    }), 128))
                  ])
                ])
              ]))
            : _createCommentVNode("", true)
        ], 64))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}