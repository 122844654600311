
import { defineComponent, onBeforeUnmount, watch } from 'vue';
import _debounce from 'lodash/debounce';
import _uniqueId from 'lodash/uniqueId';
import PIS from '@/common/api/PIS';
import { IOptions } from '../api/runtime/IOptions';
import { IRouteData } from '../api/runtime/IRouteData';
import { Instance } from '../api/Instance';
import { ComponentName } from '../api/configuration/components/ComponentName';

const INSTANCE_NAME_PREFIX = 'pisClassificationsInstance_';

export default defineComponent({
  name: ComponentName.instance,

  props: {
    // Allow to configure the APP before displaying it
    // Without this property developers will need to use `instance.init({})` on their own.
    // We use `String` type because adding empty `init` attribute in react apps converts it into `init="true"`.
    init: [Boolean, String],
    instanceId: String,
    baseUrl: String,
    accessToken: String,
    dashboard: Boolean,
    styles: String,
    settingAppCode: String,
    settingLangCode: String,
    settingCountryCode: String,

    inheritStyles: [Boolean, String],
  },

  setup(props) {
    const instance = PIS.Classifications.getInstance(
      props.instanceId ?? _uniqueId(INSTANCE_NAME_PREFIX),
    ) as Instance;

    const getInstanceId = () => instance?.id;
    const getInstance = () => instance;
    const requiredPropNames = ['accessToken', 'settingAppCode'];

    const handlePropertyChanged = () => {
      if (instance?.isInitialized()) {
        // TODO: we need to update props, app settings will be watched

        if (props.accessToken) {
          instance.store.options.accessToken = props.accessToken;
        }
        const appOptions = instance.store.options.application;

        if (props.settingLangCode && appOptions) {
          appOptions.langCode = props.settingLangCode;
        }

        return;
      }

      if (props.dashboard) {
        instance.props['dashboard'] = true;
      }

      const settings = {
        baseUrl: props.baseUrl,
        accessToken: props.accessToken,
        application: {
          appCode: props.settingAppCode,
          langCode: props.settingLangCode,
          countryCode: props.settingCountryCode,
        },
        components: {
          productsSearchToolbar: {},
        },
        styles: props.styles ? JSON.parse(props.styles) : undefined,
        inheritStyles: !!props.inheritStyles,
        selectedNodes: [],
        favoriteNodes: [],
      } as IOptions;

      const route = {} as IRouteData;

      (instance as any)['_routeJson'] = JSON.stringify(route, null, 4);
      (instance as any)['_optionsJson'] = JSON.stringify(settings, null, 4);

      if (props.init) {
        const missingProps = requiredPropNames.filter((propName) => !(props as any)[propName]);

        if (missingProps?.length) {
          instance?.logger.log(`Following required props are missing: ${missingProps.join(', ')}`);
        } else {
          instance?.init(settings, route);
        }
      } else {
        instance?.update(settings, route);
      }
    };

    onBeforeUnmount(() => {
      instance?.destroy();
    });

    watch(props, _debounce(handlePropertyChanged, 50), { immediate: true });

    return {
      getInstanceId,
      getInstance,
    };
  },
});
