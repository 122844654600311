
import { PropType, defineComponent } from 'vue';
import Modal from '@/common/components/Modal.vue';
import { TranslateFunc } from '@/products/composables/setupComponent';
import { ActionInfo } from '@/common/services/swagger/index.defs';

export default defineComponent({
  components: {
    Modal,
  },
  props: {
    instanceId: String,
    t: { type: Function as PropType<TranslateFunc>, required: true },
    errors: { type: Array as PropType<ActionInfo[]>, required: true },
    warnings: { type: Array as PropType<ActionInfo[]>, required: true },
  },
  emits: ['update:show'],
  setup(props, { emit }) {
    const close = () => emit('update:show', false);
    return { close };
  },
});
