export class LoggerService {
  public log(message: string, obj?: any) {
    if (obj) {
      /* eslint-disable */
      console.group('[LOGGER]');
      console.log(message);
      console.log(obj);
      console.groupEnd();
      /* eslint-enable */
    } else {
      // eslint-disable-next-line
      console.log('[LOGGER]', message);
    }
  }
}

export default new LoggerService();
