
import { computed, defineComponent, ref, watch } from 'vue';
import { ComponentName } from '@/products/api/configuration/components/ComponentName';
import { setupComponent } from '@/products/composables/setupComponent';
import { DataType } from '@/products/api/configuration/application/DataType';

import InjectStyles from '@/common/components/InjectStyles.vue';

import Tab from '@/common/components/Tab.vue';
import Tabs from '@/common/components/Tabs.vue';
import Message from '@/common/components/Message.vue';

import NavigationBtns from '../common/NavigationBtns.vue';
import ProductDetailsRelatedLinks from '@/products/components/details/ProductDetailsRelatedLinks.vue';
import Arrow from '@/common/components/Arrow.vue';
import Btn from '@/common/components/Btn.vue';
import ProductDetailsAttributeGroups from './ProductDetailsAttributeGroups.vue';
import ProductDetailsRelationships from './ProductDetailsRelationships.vue';
import ProductDetailsInteractiveGuides from './ProductDetailsInteractiveGuides.vue';
import ProductDetailsNavigation from './ProductDetailsNavigation.vue';
import ProductDetailsClassifications from './ProductDetailsClassifications.vue';
import { ScrollToTopCommand } from '@/products/api/runtime/CommandExecutor';
import { BreadcrumbItem } from '@/common/services/swagger/index.defs';

export default defineComponent({
  name: ComponentName.detailsData,

  components: {
    InjectStyles,
    Tabs,
    Tab,
    NavigationBtns,
    Arrow,
    Btn,
    ProductDetailsRelatedLinks,
    ProductDetailsAttributeGroups,
    ProductDetailsRelationships,
    ProductDetailsInteractiveGuides,
    ProductDetailsNavigation,
    ProductDetailsClassifications,
    Message,
  },

  props: {
    instanceId: String,
  },

  setup(props) {
    const root = ref<HTMLElement>();
    const currentTabIndex = ref(0);

    const { componentName, isReady, isWebComponent, instance, store, routeData, t, widerThan } =
      setupComponent(root, props.instanceId, [
        DataType.ProductClassifications,
        DataType.AttributeGroups,
        DataType.ProductRelationships,
        DataType.RelatedLinks,
        DataType.InteractiveGuides,
      ]);

    const componentOptions = computed(() => store.value?.options.components?.details);

    const classifications = computed(
      () => store.value?.options.components?.detailsClassifications?.visible,
    );

    const relatedLinks = computed(() => store.value?.options.components?.relatedLinks?.visible);

    const downloads = computed(() => store.value?.options.components?.downloads?.visible);
    const downloadsLoading = computed(() => store.value?.options.components?.downloads?.loading);
    const downloadsCount = computed(() => store.value?.options.components?.downloads?.count);
    const itemLoaded = computed(() => store.value?.data.productDetail?.item != undefined);

    const classificationCount = computed(() => {
      if (!componentOptions.value?.showTabCounts) {
        return undefined;
      }

      var cl = store.value?.data.productClassifications?.items as {
        [key: string]: BreadcrumbItem[][];
      };

      if (!cl) {
        return 0;
      }

      let count = 0;

      for (let gr in cl) {
        cl[gr].forEach(() => {
          count++;
        });
      }

      return count;
    });

    const relatedLinkCount = computed(() => {
      if (!componentOptions.value?.showTabCounts) {
        return undefined;
      }

      var cl = store.value?.data.relatedLinks?.items;

      if (!cl) {
        return 0;
      }

      return cl.length;
    });

    const scrollTop = async () => {
      try {
        await instance.value?.execute(
          new ScrollToTopCommand({ productId: routeData.value?.productId || '' }),
        );
      } catch (e) {
        // Ignore Error
      }
    };

    // Reset selected tab when opening the details view
    watch(
      () => routeData.value?.view,
      (view, viewOld) => {
        if (view === 'detail' && view !== viewOld) {
          currentTabIndex.value = 0;
        }
      },
    );

    return {
      root,
      itemLoaded,
      currentTabIndex,
      componentName,
      isReady,
      isWebComponent,
      store,
      classifications,
      instance,
      downloads,
      downloadsLoading,
      downloadsCount,
      relatedLinks,
      componentOptions,
      classificationCount,
      relatedLinkCount,
      t,
      widerThan,
      scrollTop,
    };
  },
});
