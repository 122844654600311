
import { computed, defineComponent, ref } from 'vue';
import VRuntimeTemplate from 'vue3-runtime-template';
import { attrValue, attrValues, additionalInfo } from '@/common/helpers/productAttributes';
import { ConfiguratorItem, ProductItem, TreeTypeEnum } from '@/common/services/swagger/index.defs';
import { DataType } from '@/products/api/configuration/application/DataType';
import { ComponentName } from '@/products/api/configuration/components/ComponentName';
import { IItemsTableOptions } from '@/products/api/configuration/components/IItemsTableOptions';
import {
  DetailsCommand,
  ConfiguratorCommand,
  PreviewCommand,
} from '@/products/api/runtime/CommandExecutor';
import { setupComponent } from '@/products/composables/setupComponent';
import { IConfiguratorOptions } from '@/products/api/configuration/components/IConfiguratorOptions';

import InjectStyles from '@/common/components/InjectStyles.vue';
import Icon from '@/common/components/Icon.vue';
import Tooltip from '@/common/components/Tooltip.vue';
import PisReplacements from '@/common/components/PisReplacements.vue';
import Clickable from '@/common/components/Clickable.vue';

export default defineComponent({
  name: ComponentName.searchTable,

  components: {
    VRuntimeTemplate,
    InjectStyles,
    Icon,
    Tooltip,
    PisReplacements,
    Clickable,
  },

  props: {
    instanceId: String,
  },

  setup(props) {
    const root = ref<HTMLElement>();

    const { componentName, isReady, isWebComponent, instance, store, routeData, emit, t } =
      setupComponent(root, props.instanceId, [DataType.Products]);

    const productsItems = computed(() => store.value?.data.products?.items);

    const previewEnabled = computed(
      () => instance.value?.registeredComponents.has(ComponentName.preview) ?? false,
    );

    const componentOptions = computed(
      () => store.value?.options.components?.itemsTable ?? ({} as IItemsTableOptions),
    );

    const configuratorOptions = computed(
      () => store.value?.options.components?.configurators as IConfiguratorOptions,
    );

    const currentCIDName = computed(() => {
      const cid = instance.value?.router.routeData?.cid;
      if (cid) {
        const breadcrumbs = instance.value?.store?.data.breadcrumbs?.items;
        if (breadcrumbs) {
          return breadcrumbs[breadcrumbs.length - 1]?.name;
        }
      }
      return undefined;
    });

    const hideIfInitial = computed(() => {
      const history = instance.value?.router.history;
      return store.value?.options.hideInitialSearchResults && history && history.length <= 2;
    });

    const columns = computed(() => {
      const masterProduct = routeData.value?.masterProductId;

      if (store.value?.options.application?.treeType == TreeTypeEnum.Products) {
        return componentOptions.value.columns.filter(
          (a) =>
            a.treeTypes.indexOf(TreeTypeEnum.Products) > -1 &&
            ((a.masterProductRequired && masterProduct) || !a.masterProductRequired),
        );
      } else {
        return componentOptions.value.columns.filter(
          (a) =>
            a.treeTypes.indexOf(TreeTypeEnum.Parts) > -1 &&
            ((a.masterProductRequired && masterProduct) || !a.masterProductRequired),
        );
      }
    });

    const configurators = computed(() => store.value?.data.configurators?.items);

    const shouldDisplayAdditionalInfo = computed(() =>
      productsItems.value?.some(({ additionalInfo }) => additionalInfo?.length),
    );

    const details = async (product: ProductItem) => {
      if (routeData.value && product) {
        try {
          await instance.value?.execute(new DetailsCommand(product));
        } catch (error) {
          // Ignore Error
        }
      }
    };

    const showConfigurator = async (configurator: ConfiguratorItem) => {
      try {
        await instance.value?.execute(new ConfiguratorCommand(configurator));
      } catch (error) {
        // Ignore Error
      }
    };

    const preview = async (product: ProductItem) => {
      try {
        await instance.value?.execute(new PreviewCommand({ productId: product.productId }));
      } catch (error) {
        // Ignore Error
      }
    };

    const replacementsVisible = computed(
      () => store.value?.options.components?.common?.hideReplacements != true,
    );

    return {
      root,
      componentName,
      componentOptions,
      instance,
      isReady,
      isWebComponent,
      store,
      routeData,
      productsItems,
      previewEnabled,
      hideIfInitial,
      columns,
      configuratorOptions,
      configurators,
      shouldDisplayAdditionalInfo,
      replacementsVisible,
      currentCIDName,
      t,
      emit,
      details,
      preview,
      attrValue,
      additionalInfo,
      showConfigurator,
      attrValues,
    };
  },
});
