
import { computed, defineComponent, ref } from 'vue';
import { ComponentName } from '@/products/api/configuration/components/ComponentName';
import { setupComponent } from '@/products/composables/setupComponent';
import { DataType } from '@/products/api/configuration/application/DataType';

import PrmList from '@/common/components/PrmList.vue';
import InjectStyles from '@/common/components/InjectStyles.vue';
import { PrmDataSetup, setupPrmData } from '@/products/composables/prmData';

export default defineComponent({
  name: ComponentName.detailsRelationships,

  components: {
    InjectStyles,
    PrmList,
  },

  props: {
    instanceId: String,
  },

  setup(props) {
    const root = ref<HTMLElement>();

    const { instance, componentName, isReady, isWebComponent, store, t, emit } = setupComponent(
      root,
      props.instanceId,
      [DataType.ProductRelationships],
    );

    const prmData: PrmDataSetup = setupPrmData(instance);

    const prmItems = prmData.visiblePrmData;

    const productId = computed(() => store.value?.data.productDetail?.item?.productId);

    return {
      root,
      componentName,
      isReady,
      isWebComponent,
      store,
      instance,
      prmItems,
      productId,
      t,
      emit,
    };
  },
});
