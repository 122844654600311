import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-light pis-clickable pis-disabled"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.disabled)
    ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
        _renderSlot(_ctx.$slots, "default")
      ]))
    : (_openBlock(), _createElementBlock("a", {
        key: 1,
        tabindex: "0",
        role: "button",
        class: "pis-clickable",
        style: {"cursor":"pointer"},
        onKeyup: _cache[0] || (_cache[0] = _withKeys(($event: any) => (_ctx.$emit('click', $event)), ["enter"]))
      }, [
        _renderSlot(_ctx.$slots, "default")
      ], 32))
}