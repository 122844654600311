import { IInstance } from '@/common/api/runtime/IInstance';
import { reactive } from 'vue';
import { Instance } from './Instance';
import { IProductsInstance } from './IProductsInstance';
import { IProductsAccessor } from './IProductsAccessor';

export class Accessor implements IProductsAccessor {
  instances = reactive<Map<string, IInstance>>(new Map());

  public destroyInstance(id: string): void {
    try {
      const inst = this.getInstance(id);
      if (inst) {
        inst.off();
      }
    } catch (error) {
      //
    }

    this.instances.delete(id);
  }

  public getInstance(id?: string): IProductsInstance | undefined {
    const size = this.instances.size;

    if (id) {
      if (this.instances.has(id)) {
        return this.instances.get(id) as IProductsInstance;
      } else {
        const instance = new Instance(id, this);
        this.instances.set(id, instance);
        return instance;
      }
    } else if (size === 1) {
      return this.instances.values().next().value;
    } else if (size > 1) {
      throw new Error('[PIS Products] Cannot pick default instance if more than one exists');
    }

    return undefined;
  }

  public setAccessTokenInAllInstances(token: string) {
    this.instances.forEach((inst: any) => {
      inst.getOptions().accessToken = token;
    });
  }
}
