import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["part"]
const _hoisted_2 = { class: "mb-4 row g-2" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = ["value"]
const _hoisted_5 = {
  key: 0,
  class: "list-group"
}
const _hoisted_6 = {
  key: 1,
  class: "pis-message"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InjectStyles = _resolveComponent("InjectStyles")!
  const _component_Btn = _resolveComponent("Btn")!
  const _component_LinkSecondary = _resolveComponent("LinkSecondary")!
  const _component_FormInputClearable = _resolveComponent("FormInputClearable")!
  const _component_Badge = _resolveComponent("Badge")!
  const _component_Clickable = _resolveComponent("Clickable")!
  const _component_Modal = _resolveComponent("Modal")!
  const _component_SparePartTypeList = _resolveComponent("SparePartTypeList")!
  const _component_CategoriesList = _resolveComponent("CategoriesList")!
  const _component_FiltersNavigatorModal = _resolveComponent("FiltersNavigatorModal")!
  const _component_FavoriteViews = _resolveComponent("FavoriteViews")!
  const _component_FavoriteCategories = _resolveComponent("FavoriteCategories")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "root",
    class: _normalizeClass(["pis-component", _ctx.componentName]),
    part: _ctx.componentName
  }, [
    (_ctx.isReady)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.isWebComponent)
            ? (_openBlock(), _createBlock(_component_InjectStyles, {
                key: 0,
                items: _ctx.store?.options.styles
              }, null, 8, ["items"]))
            : _createCommentVNode("", true),
          _createElementVNode("nav", _hoisted_2, [
            (_ctx.favoriteViewsShown)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass([`col-${12 / _ctx.columnsNumber}`])
                }, [
                  _createVNode(_component_Btn, {
                    icon: "star",
                    class: "w-100 h-100",
                    vertical: "",
                    number: _ctx.favoriteViews.length,
                    onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.favoriteViewsModalOpen = true), ["prevent"]))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('FavoriteViews')), 1)
                    ]),
                    _: 1
                  }, 8, ["number"])
                ], 2))
              : _createCommentVNode("", true),
            (_ctx.favoriteCategoriesShown)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: _normalizeClass([`col-${12 / _ctx.columnsNumber}`])
                }, [
                  _createVNode(_component_Btn, {
                    icon: "star",
                    class: "w-100 h-100",
                    vertical: "",
                    number: _ctx.favoriteCategories.length,
                    onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.favoriteCategoriesModalOpen = true), ["prevent"]))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('FavoriteCategories')), 1)
                    ]),
                    _: 1
                  }, 8, ["number"])
                ], 2))
              : _createCommentVNode("", true),
            (_ctx.partTypesShown)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 2,
                  class: _normalizeClass([`col-${12 / _ctx.columnsNumber}`])
                }, [
                  _createVNode(_component_Btn, {
                    icon: "narrow",
                    class: "w-100 h-100",
                    vertical: "",
                    disabled: !_ctx.sparePartMasterProducts,
                    onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.narrowByProductModalOpen = true), ["prevent"]))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('NarrowByProduct', true)), 1)
                    ]),
                    _: 1
                  }, 8, ["disabled"])
                ], 2))
              : _createCommentVNode("", true),
            (_ctx.partTypesShown)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 3,
                  class: _normalizeClass([`col-${12 / _ctx.columnsNumber}`])
                }, [
                  _createVNode(_component_Btn, {
                    icon: "wrench",
                    class: "w-100 h-100",
                    vertical: "",
                    onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.partTypesModalOpen = true), ["prevent"]))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('SparePartsType', true)), 1)
                    ]),
                    _: 1
                  })
                ], 2))
              : _createCommentVNode("", true),
            (_ctx.categoriesShown)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 4,
                  class: _normalizeClass([`col-${12 / _ctx.columnsNumber}`])
                }, [
                  _createVNode(_component_Btn, {
                    icon: "tree-view",
                    class: "w-100 h-100",
                    vertical: "",
                    disabled: !_ctx.categories.length,
                    number: _ctx.categories.length,
                    onClick: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (_ctx.categoriesModalOpen = true), ["prevent"]))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('Categories', true)), 1)
                    ]),
                    _: 1
                  }, 8, ["disabled", "number"])
                ], 2))
              : _createCommentVNode("", true),
            (_ctx.filtersShown)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 5,
                  class: _normalizeClass([`col-${12 / _ctx.columnsNumber}`])
                }, [
                  _createVNode(_component_Btn, {
                    icon: "filter",
                    class: "w-100 h-100",
                    vertical: "",
                    disabled: !_ctx.filtersAll.length,
                    number: _ctx.filtersAll.length,
                    onClick: _cache[5] || (_cache[5] = _withModifiers(($event: any) => (_ctx.filtersModalOpen = true), ["prevent"]))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('Filters', true)), 1)
                    ]),
                    _: 1
                  }, 8, ["disabled", "number"])
                ], 2))
              : _createCommentVNode("", true)
          ]),
          _createVNode(_component_Modal, {
            show: _ctx.narrowByProductModalOpen,
            "onUpdate:show": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.narrowByProductModalOpen) = $event)),
            title: _ctx.t('NarrowByProductModalTitle')
          }, {
            default: _withCtx(() => [
              (_ctx.sparePartsMasterProductSelected)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createElementVNode("input", {
                      type: "text",
                      class: "form-control",
                      disabled: "",
                      value: _ctx.sparePartsMasterProductSelected
                    }, null, 8, _hoisted_4),
                    _createVNode(_component_LinkSecondary, {
                      icon: "cancel",
                      class: "mt-3",
                      onClick: _ctx.clearMasterProduct
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t('NarrowByProductClear')), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]))
                : (_ctx.sparePartMasterProducts)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createVNode(_component_FormInputClearable, {
                        modelValue: _ctx.sparePartMasterProductsSearch,
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.sparePartMasterProductsSearch) = $event)),
                        placeholder: _ctx.t('FindProduct'),
                        class: "mb-4"
                      }, null, 8, ["modelValue", "placeholder"]),
                      (_ctx.sparePartMasterProducts.length)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sparePartMasterProducts, (item, index) => {
                              return (_openBlock(), _createBlock(_component_Clickable, {
                                class: "list-group-item list-group-item-action d-flex align-items-center",
                                key: index,
                                onClick: ($event: any) => (_ctx.setMasterProductId(item))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(item.description) + " ", 1),
                                  _createVNode(_component_Badge, { class: "ms-auto" }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(item.hitCount), 1)
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]),
                                _: 2
                              }, 1032, ["onClick"]))
                            }), 128))
                          ]))
                        : (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_ctx.t('NoMasterProductsFound')), 1))
                    ], 64))
                  : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["show", "title"]),
          _createVNode(_component_Modal, {
            show: _ctx.partTypesModalOpen,
            "onUpdate:show": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.partTypesModalOpen) = $event)),
            title: _ctx.t('PartTypesModalTitle')
          }, {
            default: _withCtx(() => [
              _createVNode(_component_SparePartTypeList, {
                "part-types": _ctx.partTypes,
                "current-part-type": _ctx.partTypeCurrent,
                onClickItem: _ctx.setPartType
              }, null, 8, ["part-types", "current-part-type", "onClickItem"])
            ]),
            _: 1
          }, 8, ["show", "title"]),
          _createVNode(_component_Modal, {
            show: _ctx.categoriesModalOpen,
            "onUpdate:show": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.categoriesModalOpen) = $event)),
            title: _ctx.t('CategoriesModalTitle')
          }, {
            default: _withCtx(() => [
              _createVNode(_component_CategoriesList, {
                categories: _ctx.categories,
                "configurators-number-title": _ctx.t('ConfiguratorsNumberTitle'),
                "configurators-visible": _ctx.configuratorOptions.visible,
                onClickItem: _ctx.setCategory
              }, null, 8, ["categories", "configurators-number-title", "configurators-visible", "onClickItem"])
            ]),
            _: 1
          }, 8, ["show", "title"]),
          (_ctx.filtersModalOpen)
            ? (_openBlock(), _createBlock(_component_FiltersNavigatorModal, {
                key: 1,
                show: _ctx.filtersModalOpen,
                "onUpdate:show": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.filtersModalOpen) = $event)),
                title: _ctx.t('Filters', true),
                "is-mobile": "",
                t: _ctx.t,
                "filters-all": _ctx.filtersAll,
                "filters-promoted": _ctx.filtersPromoted,
                "filters-additional": _ctx.filtersAdditional,
                "filters-model": _ctx.filtersModel,
                "filters-fetched": _ctx.filtersFetched,
                "filtered-result-count": _ctx.filteredResultCount,
                "fetch-filter-values": _ctx.fetchFilterValues,
                onValueChange: _ctx.onModalFilterValueChange,
                onApplyFilters: _ctx.applyFilters,
                onClear: _ctx.resetFiltersApplied
              }, null, 8, ["show", "title", "t", "filters-all", "filters-promoted", "filters-additional", "filters-model", "filters-fetched", "filtered-result-count", "fetch-filter-values", "onValueChange", "onApplyFilters", "onClear"]))
            : _createCommentVNode("", true),
          (_ctx.favoriteViewsModalOpen)
            ? (_openBlock(), _createBlock(_component_FavoriteViews, {
                key: 2,
                mobileShow: _ctx.favoriteViewsModalOpen,
                "onUpdate:mobileShow": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.favoriteViewsModalOpen) = $event)),
                "is-mobile": "",
                t: _ctx.t
              }, null, 8, ["mobileShow", "t"]))
            : _createCommentVNode("", true),
          (_ctx.favoriteCategoriesModalOpen)
            ? (_openBlock(), _createBlock(_component_FavoriteCategories, {
                key: 3,
                mobileShow: _ctx.favoriteCategoriesModalOpen,
                "onUpdate:mobileShow": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.favoriteCategoriesModalOpen) = $event)),
                "is-mobile": "",
                t: _ctx.t
              }, null, 8, ["mobileShow", "t"]))
            : _createCommentVNode("", true)
        ], 64))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}