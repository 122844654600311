import {
  CatalogProductSearchTypeEnum,
  CatalogProductSortFieldEnum,
  CatalogSearchTypeEnum,
  CatalogSortFieldEnum,
  SortDirectionEnum,
} from '@/common/services/swagger/index.defs';
import { DisplayMode, IRouteData } from '../runtime/IRouteData';

export const defaultRoute: IRouteData = {
  view: 'catalogs',
  catalogs: {
    searchText: undefined,
    searchType: CatalogSearchTypeEnum.CatalogCodeAndDescription,
    pagination: { page: 1, pageSize: 10 },
    sortField: CatalogSortFieldEnum.LastModified,
    sortDirection: SortDirectionEnum.Descending,
  },
  products: {
    searchText: undefined,
    searchType: CatalogProductSearchTypeEnum.WithAllWords,
    cid: undefined,
    treeType: undefined,
    filters: [],
    pagination: { page: 1, pageSize: 12 },
    displayMode: DisplayMode.table,
    sortField: CatalogProductSortFieldEnum.LastImport,
    sortDirection: SortDirectionEnum.Descending,
  },
};
