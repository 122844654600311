import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "pis-component" }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Alert = _resolveComponent("Alert")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notifications, (notification, index) => {
      return (_openBlock(), _createBlock(_component_Alert, {
        notification: notification,
        key: index,
        onClosed: ($event: any) => (_ctx.onClosed(notification))
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", {
            class: "px-4",
            innerHTML: notification.message
          }, null, 8, _hoisted_2)
        ]),
        _: 2
      }, 1032, ["notification", "onClosed"]))
    }), 128))
  ]))
}