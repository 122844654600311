import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DynamicScrollerItem = _resolveComponent("DynamicScrollerItem")!
  const _component_DynamicScroller = _resolveComponent("DynamicScroller")!

  return (_ctx.items.length > 200)
    ? (_openBlock(), _createBlock(_component_DynamicScroller, {
        key: 0,
        class: "pis-scroll",
        "key-field": _ctx.keyField,
        style: _normalizeStyle({ maxHeight: _ctx.height }),
        items: _ctx.items,
        "min-item-size": 32
      }, {
        default: _withCtx(({ item, index, active }) => [
          _createVNode(_component_DynamicScrollerItem, {
            item: item,
            active: active,
            "size-dependencies": [item.name],
            "data-index": index
          }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "item", { item: item })
            ]),
            _: 2
          }, 1032, ["item", "active", "size-dependencies", "data-index"])
        ]),
        _: 3
      }, 8, ["key-field", "style", "items"]))
    : (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: "pis-scroll",
        style: _normalizeStyle([{"overflow-y":"auto"}, { maxHeight: _ctx.height }])
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
          return _renderSlot(_ctx.$slots, "item", {
            key: index,
            item: item
          })
        }), 128))
      ], 4))
}