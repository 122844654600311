
import { computed, defineComponent, ref } from 'vue';
import { ComponentName } from '@/catalogs/api/configuration/components/ComponentName';
import { setupComponent } from '@/catalogs/composables/setupComponent';
import { DataType } from '@/catalogs/api/configuration/application/DataType';

import { BreadcrumbItem, ClassificationItem } from '@/common/services/swagger/index.defs';

import InjectStyles from '@/common/components/InjectStyles.vue';
import Arrow from '@/common/components/Arrow.vue';
import Clickable from '@/common/components/Clickable.vue';
import { ClassificationCommand } from '@/catalogs/api/runtime/CommandExecutor';

export default defineComponent({
  name: ComponentName.breadcrumbs,

  components: {
    InjectStyles,
    Arrow,
    Clickable,
  },

  props: {
    instanceId: String,
  },

  setup(props) {
    const root = ref<HTMLElement>();

    const { componentName, isReady, isWebComponent, instance, store, routeData } = setupComponent(
      root,
      props.instanceId,
      [DataType.Breadcrumbs],
    );

    const breadcrumbs = computed(() => (store.value ? store.value?.data.breadcrumbs?.items : null));

    const setClassification = ({ cid }: ClassificationItem | BreadcrumbItem) => {
      if (instance.value && cid) {
        try {
          if (cid == 'ROOT') {
            instance.value.execute(
              new ClassificationCommand({
                cid,
                clearSearch: true,
                treeType: undefined,
              }),
            );
          } else {
            instance.value.execute(
              new ClassificationCommand({
                cid,
                clearSearch: true,
                treeType: routeData.value?.products.treeType,
              }),
            );
          }
        } catch (err) {
          // ignore
        }
      }
    };

    const visible = computed(() => store.value?.options.components?.breadcrumbs?.visible);

    return {
      root,
      componentName,
      isReady,
      isWebComponent,
      store,
      breadcrumbs,
      visible,
      setClassification,
    };
  },
});
