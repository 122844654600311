import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, createTextVNode as _createTextVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "col"
}
const _hoisted_2 = {
  key: 0,
  class: "fw-bold ps-lg-3"
}
const _hoisted_3 = { class: "col-md-4" }
const _hoisted_4 = { class: "d-flex" }
const _hoisted_5 = { class: "w-100" }
const _hoisted_6 = { class: "ps-lg-3" }
const _hoisted_7 = ["innerHTML", "title"]
const _hoisted_8 = {
  key: 0,
  class: "flex-shrink-1 px-2"
}
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = { class: "d-print-none ext-attr-val" }
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AttributeValueTable = _resolveComponent("AttributeValueTable")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Tooltip = _resolveComponent("Tooltip")!
  const _component_InteractiveText = _resolveComponent("InteractiveText")!
  const _component_PisLink = _resolveComponent("PisLink")!
  const _component_Arrow = _resolveComponent("Arrow")!
  const _component_LinkSecondary = _resolveComponent("LinkSecondary")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.records, (record, attrKey) => {
    return (_openBlock(), _createElementBlock("div", {
      class: _normalizeClass(["row g-0 py-1 py-lg-2", { 'border-bottom': record.attr.type != 'Table', 'bg-warning': record.attr.highlight }]),
      key: attrKey
    }, [
      (record.attr.type == 'Table' && record.attr.table)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (record.attr.attributeName)
              ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(record.attr.attributeName) + ": ", 1))
              : _createCommentVNode("", true),
            _createVNode(_component_AttributeValueTable, {
              table: record.attr.table
            }, null, 8, ["table"])
          ]))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("span", {
                      class: "d-inline-block w-100 text-wrap fw-bold ext-attr-name",
                      style: {"overflow":"hidden","text-overflow":"ellipsis"},
                      innerHTML: record.attr.attributeName + ':',
                      title: record.attr.tooltip
                    }, null, 8, _hoisted_7)
                  ])
                ]),
                (record.attr.isInternal)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _createVNode(_component_Tooltip, {
                        text: _ctx.t('Internal', true)
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Icon, {
                            class: "text-warning text-end",
                            glyph: "warning"
                          })
                        ]),
                        _: 1
                      }, 8, ["text"])
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _createElementVNode("div", {
              class: "col-md-8",
              ref_for: true,
              ref: (el) => _ctx.storeAttrRef(record.attr.attributeCode, el as HTMLElement)
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(record.attr.values, (attrValue, attrIndex) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "pis-print-only ext-attr-val",
                  key: attrIndex
                }, [
                  (attrValue.interactiveText)
                    ? (_openBlock(), _createBlock(_component_InteractiveText, {
                        key: 0,
                        instance: _ctx.instance,
                        text: attrValue.interactiveText
                      }, null, 8, ["instance", "text"]))
                    : (attrValue.link && !_ctx.hideDocumentLinks)
                      ? (_openBlock(), _createBlock(_component_PisLink, {
                          key: 1,
                          link: attrValue.link,
                          instance: _ctx.instance,
                          "clear-search": true
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(attrValue.link.text), 1)
                          ]),
                          _: 2
                        }, 1032, ["link", "instance"]))
                      : (_openBlock(), _createElementBlock("div", {
                          key: 2,
                          style: {"white-space":"pre-wrap"},
                          innerHTML: attrValue.text
                        }, null, 8, _hoisted_9))
                ]))
              }), 128)),
              _createElementVNode("div", _hoisted_10, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(record.trimmedValues, (attrValue, attrIndex) => {
                  return (_openBlock(), _createElementBlock("div", { key: attrIndex }, [
                    (attrValue.interactiveText)
                      ? (_openBlock(), _createBlock(_component_InteractiveText, {
                          key: 0,
                          instance: _ctx.instance,
                          text: attrValue.interactiveText
                        }, null, 8, ["instance", "text"]))
                      : (attrValue.link && !_ctx.hideDocumentLinks)
                        ? (_openBlock(), _createBlock(_component_PisLink, {
                            key: 1,
                            link: attrValue.link,
                            instance: _ctx.instance,
                            "clear-search": true
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(attrValue.link.text), 1)
                            ]),
                            _: 2
                          }, 1032, ["link", "instance"]))
                        : (_openBlock(), _createElementBlock("div", {
                            key: 2,
                            style: {"white-space":"pre-wrap"},
                            innerHTML: attrValue.text
                          }, null, 8, _hoisted_11))
                  ]))
                }), 128)),
                (record.shouldBeTrimmed)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                      _createVNode(_component_LinkSecondary, {
                        onClick: _withModifiers(($event: any) => (_ctx.toggleValueTrimming(record.attr.attributeCode)), ["prevent"])
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Arrow, {
                            class: "me-2",
                            dir: _ctx.isDisabledTrimming(record.attr.attributeCode) ? 'up' : 'down'
                          }, null, 8, ["dir"]),
                          _createTextVNode(" " + _toDisplayString(_ctx.isDisabledTrimming(record.attr.attributeCode) ? _ctx.t('HideMore') : _ctx.t('ShowMore')), 1)
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ]))
                  : _createCommentVNode("", true)
              ])
            ], 512)
          ], 64))
    ], 2))
  }), 128))
}