
import { computed, defineComponent, onMounted, PropType, ref } from 'vue';
import { Instance } from '@/products/api/Instance';
import PIS from '@/common/api/PIS';
import Btn from '@/common/components/Btn.vue';
import Modal from '@/common/components/Modal.vue';
import FormInputClearable from '@/common/components/FormInputClearable.vue';
import FormTextAreaClearable from '@/common/components/FormTextAreaClearable.vue';
import { ValidationType, Validators } from '@/common/helpers/validation';
import { TranslateFunc } from '@/products/composables/setupComponent';
import {
  CatalogItem,
  CatalogTypeEnum,
  CatalogOptionsResult,
  CatalogProductTypeEnum,
} from '@/common/services/swagger/index.defs';
import { NotificationType } from '@/common/api/runtime/INotification';
import { ProductAddToCatalogActionData } from '@/products/api/runtime/ProductActionData';
import {
  AddNotificationCommand,
  CatalogGetOptionsCommand,
  GetAllCatalogsCommand,
  CatalogCreateNewCommand,
} from '@/products/api/runtime/CommandExecutor';
import { ProductAddToCatalogCommand } from '@/products/api/runtime/CommandExecutor';

export default defineComponent({
  components: {
    Btn,
    Modal,
    FormInputClearable,
    FormTextAreaClearable,
  },
  props: {
    t: { type: Function as PropType<TranslateFunc>, required: true },
    instanceId: String,
    data: { type: Object as PropType<ProductAddToCatalogActionData>, required: true },
  },
  emits: ['update:show'],
  setup(props, { emit }) {
    const sessionStorageKey = 'PIS:Products:AddToCatalogModal:LastCatalogCode';
    const instance = PIS.Products.getInstance(props.instanceId) as Instance;
    const catalogType: typeof CatalogTypeEnum = CatalogTypeEnum;
    const show = ref(false);
    const copyTo = ref<string | null>(null);
    const code = ref<string>('');
    const description = ref<string>('');
    const productDescription = ref<string>('');
    const quantity = ref('0');
    const unitOfMeasure = ref('');
    const unitOfMeasureEditable = ref(true);
    const type = ref<string>(props.data.catalogType);
    const allCatalogs = ref<CatalogItem[]>([]);
    const catalogOptions = ref<CatalogOptionsResult['items']>([]);
    const catalogOptionsEditable = ref(true);
    const ownerType = ref<CatalogTypeEnum>(props.data.catalogType);

    const onCodeUpdate = (newCode: string) => (code.value = newCode);
    const onDescriptionUpdate = (newDescription: string) => (description.value = newDescription);
    const onProductDescriptionUpdate = (newDescription: string) =>
      (productDescription.value = newDescription);
    const onQuantityUpdate = (newQuantity: string) => (quantity.value = newQuantity);
    const onUOMUpdate = (newUOM: string) => (unitOfMeasure.value = newUOM);
    const onTypeUpdated = (newType: CatalogTypeEnum) => (type.value = newType);
    const close = () => emit('update:show', false);
    const onSave = async () => {
      try {
        let targetCatalogId: string;
        if (copyTo.value !== null) {
          const targetCatalogItem: CatalogItem | undefined = allCatalogs.value.find(
            (catalogItem: CatalogItem) => catalogItem && catalogItem.code === copyTo.value,
          );
          if (!targetCatalogItem) {
            throw new Error(props.t('CannotFindCatalog'));
          }
          targetCatalogId = targetCatalogItem.id;
        } else {
          targetCatalogId = (await instance.execute(
            new CatalogCreateNewCommand({
              code: code.value,
              type: props.data.catalogType,
              description: description.value,
              onSuccessMessage: props.t('CatalogCreateNewSuccess'),
            }),
          )) as string;
        }
        window.sessionStorage.setItem(sessionStorageKey, targetCatalogId);
        await instance.execute(
          new ProductAddToCatalogCommand({
            products: [
              {
                productId: props.data.productId,
                description: productDescription.value,
                productType: CatalogProductTypeEnum.Listed,
                quantity: parseInt(quantity.value, 10),
                unitOfMeasure: unitOfMeasure.value,
              },
            ],
            id: targetCatalogId,
            onSuccessMessage: props.t('ProductAddedToCatalogSuccess'),
          }),
        );
      } catch (error) {
        await instance.execute(
          new AddNotificationCommand({ type: NotificationType.danger, message: error.message }),
        );
      }
      close();
    };
    const isFormValid = computed<boolean>(
      () =>
        (copyTo.value !== null || (!!code.value && !!description.value && !!type.value)) &&
        !!quantity.value &&
        !!unitOfMeasure.value &&
        !!productDescription.value,
    );
    const validators: Record<string, Validators> = {
      code: { [ValidationType.required]: {} },
      description: { [ValidationType.required]: {} },
      productDescription: { [ValidationType.required]: {} },
      unitOfMeasure: { [ValidationType.required]: {} },
      quantity: {
        [ValidationType.required]: {},
        [ValidationType.min]: { min: 0 },
        [ValidationType.max]: { max: 1000000 },
      },
    };
    const copyToOptions = computed<string[]>(() =>
      allCatalogs.value.map((current: CatalogItem) => current.code),
    );
    onMounted(async () => {
      try {
        if (!props.data.productId) {
          throw new Error(props.t('ProductIdIsRequired'));
        }
        if (!props.data.catalogType) {
          throw new Error(props.t('CatalogTypeIsRequired'));
        }
        allCatalogs.value = (await instance.execute(new GetAllCatalogsCommand())) as CatalogItem[];
        if (props.data.catalogType) {
          catalogOptionsEditable.value = false;
          catalogOptions.value = [{ type: props.data.catalogType }];
        } else {
          const catalogOptionsResult: CatalogOptionsResult | undefined = (await instance.execute(
            new CatalogGetOptionsCommand(),
          )) as CatalogOptionsResult;
          catalogOptions.value = catalogOptionsResult.items || [];
        }
        if (props.data.uom) {
          unitOfMeasureEditable.value = false;
          unitOfMeasure.value = props.data.uom;
        }
        const preselectedCatalogCode =
          props.data.catalogId || window.sessionStorage.getItem(sessionStorageKey);
        if (preselectedCatalogCode) {
          const foundPreselectedCatalogCode: string | undefined = copyToOptions.value.find(
            (code: string) => code === preselectedCatalogCode,
          );
          if (foundPreselectedCatalogCode) {
            copyTo.value = foundPreselectedCatalogCode;
          }
        }
        show.value = true;
      } catch (error) {
        await instance.execute(
          new AddNotificationCommand({ type: NotificationType.danger, message: error.message }),
        );
      }
    });

    return {
      show,
      code,
      type,
      copyTo,
      instance,
      quantity,
      ownerType,
      validators,
      description,
      isFormValid,
      catalogType,
      unitOfMeasure,
      copyToOptions,
      catalogOptions,
      productDescription,
      unitOfMeasureEditable,
      catalogOptionsEditable,
      close,
      onSave,
      onUOMUpdate,
      onCodeUpdate,
      onTypeUpdated,
      onQuantityUpdate,
      onDescriptionUpdate,
      onProductDescriptionUpdate,
    };
  },
});
