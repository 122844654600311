
import { defineComponent } from 'vue';
import Icon from './Icon.vue';
import Spinner from './Spinner.vue';

export default defineComponent({
  components: {
    Spinner,
    Icon,
  },

  props: {
    src: String,
    active: Boolean,
    clickable: { type: Boolean, default: true },
    hideFrame: Boolean,
    isLoading: Boolean,
    maxSize: Number,
    maxSizeCss: String,
    padding: { type: Number, default: 0 },
  },
});
