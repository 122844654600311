import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "modal-content" }
const _hoisted_2 = { class: "modal-header pb-0" }
const _hoisted_3 = { class: "modal-title" }
const _hoisted_4 = { class: "d-flex align-items-center" }
const _hoisted_5 = {
  ref: "modalBody",
  class: "modal-body pis-scroll"
}
const _hoisted_6 = {
  key: 0,
  class: "modal-footer py-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["modal fade", { show: _ctx.visible }]),
      tabindex: "-1",
      role: "dialog",
      style: _normalizeStyle({ display: _ctx.visible ? 'block' : 'none' }),
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.clickOutside && _ctx.clickOutside(...args)))
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["modal-dialog modal-fullscreen-md-down", [
        _ctx.lg &&
          'modal-lg modal-fullscreen-md-down modal-fullscreen-sm-down modal-fullscreen-lg-down ',
        _ctx.xl && 'modal-xl modal-fullscreen-lg-down modal-fullscreen-md-down modal-fullscreen-sm-down',
        _ctx.centered && 'modal-dialog-centered',
      ]]),
        role: "document",
        ref: "dialog"
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("h5", _hoisted_3, _toDisplayString(_ctx.title), 1),
            _createElementVNode("div", _hoisted_4, [
              _renderSlot(_ctx.$slots, "header-actions"),
              _createElementVNode("button", {
                type: "button",
                class: "btn-close ms-3",
                "aria-label": "Close",
                onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)), ["prevent"]))
              })
            ])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _renderSlot(_ctx.$slots, "default")
          ], 512),
          (_ctx.slots.footer)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _renderSlot(_ctx.$slots, "footer")
              ]))
            : _createCommentVNode("", true)
        ])
      ], 2)
    ], 6),
    (_ctx.visible)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.overlayClose && _ctx.overlayClose(...args))),
          class: "modal-backdrop fade show"
        }))
      : _createCommentVNode("", true)
  ], 64))
}