
import { computed, defineComponent, onMounted, PropType, ref, watch } from 'vue';
import PIS from '@/common/api/PIS';

import Btn from '@/common/components/Btn.vue';
import FormInputClearable from '@/common/components/FormInputClearable.vue';
import { FavoriteView } from '../services/swagger/index.defs';
import { Instance } from '@/products/api/Instance';
import { isEmptyCID } from '../helpers/isEmptyCID';
import { ValidationType, Validators } from '../helpers/validation';

export default defineComponent({
  components: {
    Btn,
    FormInputClearable,
  },

  props: {
    instanceId: String,
    fallbackCode: String,
    favoriteViews: Array as PropType<FavoriteView[]>,
    t: Function,
  },

  emits: ['on-form-submit', 'on-form-cancel', 'on-update'],

  setup(props, { emit }) {
    const instance = PIS.Products.getInstance(props.instanceId) as Instance;
    const favoriteID = ref('');
    const favoriteCode = ref('');
    const isFormValid = computed(() => !!favoriteCode.value);
    const currentCID = computed(() => instance.router.routeData?.cid);
    const hasChosenCID = computed(() => !isEmptyCID(currentCID.value));
    const validators: Record<string, Validators> = {
      favoriteCode: { [ValidationType.required]: {} },
    };

    const currentCIDName = computed(() => {
      const cid = currentCID.value;
      if (cid) {
        const breadcrumbs = instance.store?.data.breadcrumbs?.items;
        if (breadcrumbs) {
          return breadcrumbs[breadcrumbs.length - 1]?.name;
        }
      }
      return undefined;
    });

    const getCIDName = () =>
      (hasChosenCID.value ? currentCIDName.value : props.fallbackCode) || props.fallbackCode || '';

    const onFavoriteCodeUpdate = (newValue: string) => {
      favoriteCode.value = newValue;
      onUpdate();
    };

    const updateFormFields = () => {
      const currentID = favoriteID.value;
      if (currentID) {
        const found = props.favoriteViews?.find((item) => item.id === currentID);
        if (found) {
          favoriteCode.value = found.code as string;
          return onUpdate();
        }
      }
      favoriteCode.value = getCIDName();
      return onUpdate();
    };

    const onUpdate = (isSubmit = false) =>
      emit(isSubmit ? 'on-form-submit' : 'on-update', {
        id: favoriteID.value,
        code: favoriteCode.value,
      });

    watch(
      () => favoriteID.value,
      () => updateFormFields()
    );

    onMounted(() => updateFormFields());

    return {
      validators,
      favoriteID,
      isFormValid,
      favoriteCode,
      onUpdate,
      onFavoriteCodeUpdate,
    };
  },
});
