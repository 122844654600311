import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "my-3 d-flex flex-row justify-content-center align-items-center flex-wrap" }
const _hoisted_3 = { class: "d-flex align-items-center" }
const _hoisted_4 = { class: "text-nowrap" }
const _hoisted_5 = ["value"]
const _hoisted_6 = { class: "table-responsive pis-scroll" }
const _hoisted_7 = { class: "table" }
const _hoisted_8 = {
  key: 1,
  class: "d-flex justify-content-center align-items-center"
}
const _hoisted_9 = {
  key: 1,
  class: "d-flex justify-content-center align-items-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Btn = _resolveComponent("Btn")!
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    show: _ctx.show,
    title: _ctx.t('CatalogHistoryModal'),
    "onUpdate:show": _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('update:show'))),
    lg: "",
    closeOnOuterClick: false
  }, {
    default: _withCtx(() => [
      (_ctx.isReady)
        ? (_openBlock(), _createElementBlock("form", {
            key: 0,
            onSubmit: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"]))
          }, [
            (_ctx.items && _ctx.items.length)
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("label", _hoisted_3, [
                      _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.t('SortBy')) + ":", 1),
                      _withDirectives(_createElementVNode("select", {
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.sortBy) = $event)),
                        class: "form-select ms-2 w-100"
                      }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortByItems, (sortName, sortKey) => {
                          return (_openBlock(), _createElementBlock("option", {
                            key: sortKey,
                            value: sortKey
                          }, _toDisplayString(_ctx.t(`JobHistorySortBy${sortName}`)), 9, _hoisted_5))
                        }), 128))
                      ], 512), [
                        [_vModelSelect, _ctx.sortBy]
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("table", _hoisted_7, [
                      _createElementVNode("thead", null, [
                        _createElementVNode("tr", null, [
                          _createElementVNode("th", null, _toDisplayString(_ctx.t('CatalogCode')), 1),
                          _createElementVNode("th", null, _toDisplayString(_ctx.t('JobType')), 1),
                          _createElementVNode("th", null, _toDisplayString(_ctx.t('JobStatus')), 1),
                          _createElementVNode("th", null, _toDisplayString(_ctx.t('DateModified')), 1),
                          _createElementVNode("th", null, _toDisplayString(_ctx.t('Actions')), 1)
                        ])
                      ]),
                      _createElementVNode("tbody", null, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
                          return (_openBlock(), _createElementBlock("tr", { key: index }, [
                            _createElementVNode("td", null, _toDisplayString(item.catalog?.code), 1),
                            _createElementVNode("td", null, _toDisplayString(_ctx.t(`JobType${item.type}`)), 1),
                            _createElementVNode("td", null, _toDisplayString(_ctx.t(`JobStatus${item.status}`)), 1),
                            _createElementVNode("td", null, _toDisplayString(new Date(item.dateModified).toLocaleString([], { dateStyle: 'medium' })), 1),
                            _createElementVNode("td", null, [
                              (item.status === _ctx.JobStatusEnum.Success)
                                ? (_openBlock(), _createBlock(_component_Btn, {
                                    key: 0,
                                    onClick: _withModifiers(($event: any) => (_ctx.onDownload(item.id)), ["prevent"]),
                                    primary: ""
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.t('Download', true)), 1)
                                    ]),
                                    _: 2
                                  }, 1032, ["onClick"]))
                                : (item.status === _ctx.JobStatusEnum.InQueue)
                                  ? (_openBlock(), _createBlock(_component_Btn, {
                                      key: 1,
                                      onClick: _withModifiers(($event: any) => (_ctx.onCancel(item.id)), ["prevent"]),
                                      primary: ""
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.t('Cancel', true)), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["onClick"]))
                                  : _createCommentVNode("", true)
                            ])
                          ]))
                        }), 128))
                      ])
                    ])
                  ])
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.t('JobHistoryListEmpty')), 1)
                ]))
          ], 32))
        : (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _createVNode(_component_Spinner)
          ]))
    ]),
    _: 1
  }, 8, ["show", "title"]))
}