const toBase64 = (file: File) =>
  new Promise<string>((resolve, reject) => {
    if (!window.FileReader) {
      return reject(new Error('FileReader not available!'));
    }
    const reader = new FileReader();
    reader.onerror = (error) => reject(error);
    reader.onload = () => resolve(reader.result as string);
    reader.readAsDataURL(file);
  });
export default toBase64;
