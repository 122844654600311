
import { computed, defineComponent, ref } from 'vue';
import { ComponentName } from '@/products/api/configuration/components/ComponentName';
import { DataType } from '@/products/api/configuration/application/DataType';
import { setupComponent } from '@/products/composables/setupComponent';
import Pagination from '@/common/components/Pagination.vue';
import { PaginateCommand } from '@/products/api/runtime/CommandExecutor';
import PaginationAmount from '@/common/components/PaginationAmount.vue';
import InjectStyles from '@/common/components/InjectStyles.vue';
import { ISearchPaginationOptions } from '@/products/api/configuration/components/ISearchPaginationOptions';
import VRuntimeTemplate from 'vue3-runtime-template';
export default defineComponent({
  name: ComponentName.searchPagination,

  components: {
    Pagination,
    PaginationAmount,
    InjectStyles,
    VRuntimeTemplate,
  },

  props: {
    instanceId: String,
  },

  setup(props) {
    const root = ref<HTMLElement>();

    const {
      componentName,
      isWebComponent,
      isReady,
      instance,
      store,
      routeData,
      t,
      widerThan,
      emit,
    } = setupComponent(root, props.instanceId, [DataType.Products]);

    const componentOptions = computed(
      () => store.value?.options.components?.searchPagination ?? ({} as ISearchPaginationOptions),
    );

    const hideIfInitial = computed(() => {
      const history = instance.value?.router.history;
      return store.value?.options.hideInitialSearchResults && history && history.length <= 2;
    });

    const totalCount = computed(() => store.value?.data.products?.totalCount ?? 0);
    const pagination = computed(() => routeData.value?.pagination);

    const changePage = async (page: number) => {
      if (instance.value) {
        try {
          await instance.value.execute(new PaginateCommand({ page }));
        } catch (error) {
          // Ignore Error
        }
      }
    };

    const totalPages = computed(() =>
      Math.ceil(totalCount.value / (routeData.value?.pagination?.pageSize ?? 1)),
    );

    const page = computed(() => pagination.value?.page);

    const visible = computed(() => componentOptions.value.visible);

    return {
      root,
      visible,
      componentName,
      isWebComponent,
      isReady,
      store,
      hideIfInitial,
      totalCount,
      pagination,
      routeData,
      componentOptions,
      instance,
      totalPages,
      page,
      t,
      widerThan,
      changePage,
      emit,
    };
  },
});
