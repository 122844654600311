import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["part"]
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    ref: "root",
    class: _normalizeClass(_ctx.componentName),
    part: _ctx.componentName
  }, [
    (
        _ctx.isReady &&
        _ctx.routeData &&
        _ctx.routeData.view === 'products' &&
        _ctx.routeData.products.displayMode == 'list'
      )
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "default")
        ]))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}