
import { defineComponent, nextTick, ref } from 'vue';
import Popper from 'vue3-popper';

export default defineComponent({
  name: 'DropdownPopper',
  components: { Popper },
  props: {
    top: { type: Boolean, default: false },
  },
  setup(props) {
    const popper = ref();

    const open = () => {
      const p = popper.value;

      nextTick(() => {
        const dropdown = p.$el.querySelector('.dropdown-menu') as HTMLElement;
        const trigger = p.$el.querySelector('.btn') as HTMLElement;
        if (dropdown && trigger) {
          const translateX = -(dropdown.clientWidth - trigger.clientWidth);
          let translateY = 0;

          if (props.top) {
            translateY = -(dropdown.clientHeight + trigger.clientHeight + 24);
          }

          dropdown.style.transform = `translate(${translateX}px, ${translateY}px)`;

          // add shadow
          dropdown.classList.add('shadow-sm');
        }
      });
    };

    return { open, popper };
  },
});
