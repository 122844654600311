import { ClassificationFavorites } from './runtime/ClassificationFavorites';
import { reactive, watch } from 'vue';
import mitt from 'mitt';
import _mergeWith from 'lodash/mergeWith';
import _isArray from 'lodash/isArray';
import _cloneDeep from 'lodash/cloneDeep';
import { Accessor } from './Accessor';
import { IStore } from './runtime/IStore';
import { IOptions } from './runtime/IOptions';
import { Router } from './runtime/Router';
import { HttpService } from './runtime/HttpService';
import { Translations } from './runtime/Translations';
import { IRouteData } from './runtime/IRouteData';
import { CommandExecutor, ApplyCommand, CancelCommand } from './runtime/CommandExecutor';
import { ICommand } from '../../common/api/runtime/ICommand';
import { DefaultOptions } from './configuration/DefaultOptions';
import { ComponentName } from './configuration/components/ComponentName';
import { DataType } from './configuration/application/DataType';
import LoggerService from '@/common/services/LoggerService';
import { IStyle } from '@/common/interfaces/IStyle';
import { InstanceType } from '../../common/api/runtime/IInstance';
import { IClassificationsInstance } from './IClassificationsInstance';
import { ClassificationSelection } from './runtime/ClassificationSelection';
import {
  ClassificationsBrowserNode,
  ClassificationsBrowserTreeTypeEnum,
} from '@/common/services/swagger/index.defs';
import { ILegacyOptions } from './salesforce/SalesforceLegacy';
import legacyHelper from './salesforce/LegacyHelper';
import { defaultRoute } from '../api/configuration/DefaultRoute';

export class Instance implements IClassificationsInstance {
  public instanceType: InstanceType = 'Classifications';
  public props: { [key: string]: any } = {};
  public httpService?: HttpService;
  public store: IStore = reactive({
    notifications: [],
    data: { isInitialized: false, actions: { current: null, data: null } },
    options: { isInitialized: false },
  });
  public router = new Router(this);
  public translations = new Translations(this);
  public commandExecutor = new CommandExecutor(this);
  public logger = LoggerService;

  public registeredComponents = reactive(new Set<ComponentName>());
  public dataTypesRequired = reactive(new Set<DataType>());

  public eventBus = mitt();
  private eventBusExt = mitt();

  public selection = new ClassificationSelection(this);
  public favorites = new ClassificationFavorites(this);

  constructor(public id: string, public accessor: Accessor) {
    this.eventBus.on('*', (type, data: unknown) => {
      // dispatch external events, some events are filtered out
      if (!type.toString().startsWith('internal:')) {
        this.eventBusExt.emit(type, data);
      }
    });
  }

  public isInitialized(): boolean | undefined {
    return this.store.data.isInitialized;
  }

  public reload(): void {
    this.router.setRoute(this.router.routeData);
    this.translations.load();
  }

  public setRoute(routeData: IRouteData): void {
    this.router.setRoute(routeData);
  }

  public async init(optionsOverrides: IOptions, routeData?: IRouteData): Promise<void> {
    if (this.store.data.isInitialized) {
      throw new Error(`Instance "${this.id}" is already initialized`);
    }

    const currentOptions: IOptions = this.store.options.isInitialized
      ? this.store.options
      : new DefaultOptions();

    _mergeWith(currentOptions, optionsOverrides, (a, b) => (_isArray(b) ? b : undefined));

    if (currentOptions.inheritStyles) {
      currentOptions.styles = [...(currentOptions.styles ?? []), ...this.getDocumentStyles()];
    }

    this.store.options = currentOptions;
    this.store.options.isInitialized = true;

    this.httpService = new HttpService(this, currentOptions.baseUrl, currentOptions.accessToken);
    this.store.data.isInitialized = true;
    try {
      await this.translations.load();
    } catch (err) {
      this.store.data.isInitialized = false;
      alert('PIS initialization error.');
    }

    await this.validateParameters();
    this.router.setRoute(routeData);
  }

  public update(optionsOverrides: IOptions, routeData?: IRouteData): void {
    const currentOptions: IOptions = this.store.options.isInitialized
      ? this.store.options
      : new DefaultOptions();

    _mergeWith(currentOptions, optionsOverrides, (a, b) => (_isArray(b) ? b : undefined));

    if (currentOptions.inheritStyles) {
      currentOptions.styles = [...(currentOptions.styles ?? []), ...this.getDocumentStyles()];
    }

    this.store.options = currentOptions;
    this.store.options.isInitialized = true;

    if (routeData) {
      this.router.setRoute(routeData);
    }
  }

  public on(name: string, handler: (type: string, data: unknown) => void): void {
    this.eventBusExt.on(name, handler as never);
  }

  public onReady(callback: () => any): void {
    const unwatch = watch(
      () => this.store.data,
      ({ isInitialized }) => {
        if (isInitialized) {
          callback();
          unwatch();
        }
      },
      { deep: true },
    );
  }

  public off(name?: string | undefined, handler?: (type: string, data: unknown) => void): void {
    if (name) {
      this.eventBusExt.off(name, handler as never);
    } else {
      this.eventBusExt.all.clear();
      // this.intercept = undefined;
    }
  }

  public registerComponent(componentName: ComponentName): void {
    this.registeredComponents.add(componentName);
  }

  public registerRequiredDataTypes(dataTypes: DataType[]): void {
    dataTypes.forEach((dataType) => this.dataTypesRequired.add(dataType));
  }

  public getRegisteredSearchDataTypes(): string[] {
    // if (this.dataTypesRequired && this.dataTypesRequired.size) {
    //   const searchDataTypes = Object.values(CatalogProductSearchDataTypeEnum);
    //   const arr = Array.from(this.dataTypesRequired).filter((dataType) =>
    //     searchDataTypes.includes(dataType as CatalogProductSearchDataTypeEnum),
    //   ) as CatalogProductSearchDataTypeEnum[];
    //   return _uniq(arr);
    // } else {
    //   return [];
    // }

    return [];
  }

  public destroy(): void {
    return this.accessor.destroyInstance(this.id);
  }

  public execute<ReturnType = unknown>(
    command: ICommand<Instance, ReturnType>,
  ): Promise<ReturnType | undefined> {
    return this.commandExecutor.execute<ReturnType>(command);
  }

  public getDocumentStyles(): IStyle[] {
    return [...document.styleSheets].map((sheet) => {
      if (sheet.href) {
        return { url: sheet.href };
      } else {
        try {
          return { css: [...sheet.cssRules].map((rule) => rule.cssText).join('') };
        } catch (e) {
          return {};
        }
      }
    });
  }

  public getDefaultOptions(): IOptions {
    return new DefaultOptions();
  }

  public getDefaultRouteData(): IRouteData {
    return _cloneDeep(defaultRoute);
  }

  public getOptions(): IOptions {
    return this.store.options;
  }

  public getRouteData(): IRouteData {
    return _cloneDeep(this.router.routeData);
  }

  public applyLegacyOptions(
    legacyOptions: ILegacyOptions,
    options?: IOptions | undefined,
  ): { options: IOptions; routeData: IRouteData } {
    return legacyHelper.applyLegacyOptions(legacyOptions, options);
  }

  public async apply() {
    try {
      await this.execute(new ApplyCommand());
    } catch (error) {
      // ignored
    }
  }

  public async cancel() {
    try {
      await this.execute(new CancelCommand());
    } catch (error) {
      // ignored
    }
  }

  private async validateParameters() {
    if (!this.httpService) {
      return;
    }

    if (this.store.options.selectedNodes || this.store.options.favoriteNodes) {
      const toValidate = [
        ...(this.store.options.selectedNodes ?? []),
        ...(this.store.options.favoriteNodes ?? []),
      ];

      if (toValidate.length) {
        const result = await this.httpService.validate(toValidate);

        const validSelection: ClassificationsBrowserNode[] = [];
        const invalidSelection: {
          cid: string;
          type?: ClassificationsBrowserTreeTypeEnum | undefined;
        }[] = [];
        const validFavorites: ClassificationsBrowserNode[] = [];
        const invalidFavorites: {
          cid: string;
          type?: ClassificationsBrowserTreeTypeEnum | undefined;
        }[] = [];

        if (this.store.options.selectedNodes?.length) {
          this.store.options.selectedNodes.forEach((n) => {
            const r = n.type
              ? result.validNodes?.find((a) => a.cid === n.cid && a.tree === n.type)
              : result.validNodes?.find((a) => a.cid === n.cid);
            if (r) {
              validSelection.push(r);
            } else {
              invalidSelection.push(n);
            }
          });
        }

        if (this.store.options.favoriteNodes?.length) {
          this.store.options.favoriteNodes.forEach((n) => {
            const r = n.type
              ? result.validNodes?.find((a) => a.cid === n.cid && a.tree === n.type)
              : result.validNodes?.find((a) => a.cid === n.cid);
            if (r) {
              validFavorites.push(r);
            } else {
              invalidFavorites.push(n);
            }
          });
        }

        if (validSelection.length) {
          this.selection.select(validSelection);
        }

        if (validFavorites.length) {
          this.favorites.select(validFavorites);
        }

        if (invalidSelection.length) {
          this.eventBusExt.emit('selection-validated', {
            invalid: _cloneDeep(invalidSelection),
            valid: _cloneDeep(validSelection),
          });
          this.store.data.invalidSelectedItems = invalidSelection;
        }

        if (invalidFavorites.length) {
          this.eventBusExt.emit('favorites-validated', {
            invalid: _cloneDeep(invalidFavorites),
            valid: _cloneDeep(validFavorites),
          });
          this.store.data.invalidFavoriteItems = invalidFavorites;
        }
      }
    }
  }
}
