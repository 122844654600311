import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "d-inline-flex align-items-center",
  ref: "tooltip",
  style: {"cursor":"default"},
  "data-bs-toggle": "tooltip",
  "data-bs-html": "true"
}
const _hoisted_2 = { ref: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", {
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.hide && _ctx.hide(...args))),
    ref: "wrapper"
  }, [
    _createElementVNode("span", _hoisted_1, [
      _renderSlot(_ctx.$slots, "default")
    ], 512),
    _createElementVNode("span", _hoisted_2, null, 512)
  ], 512))
}