
import { computed, defineComponent, onMounted, PropType, reactive } from 'vue';
import { attrValue } from '@/common/helpers/productAttributes';
import { UpdateProductCommand } from '@/catalogs/api/runtime/CommandExecutor';
import { Instance } from '@/catalogs/api/Instance';
import { TranslateFunc } from '@/catalogs/composables/setupComponent';
import PIS from '@/common/api/PIS';
import Btn from '@/common/components/Btn.vue';
import Modal from '@/common/components/Modal.vue';
import { ValidationType, Validators } from '@/common/helpers/validation';
import FormInputClearable from '@/common/components/FormInputClearable.vue';
import { CatalogProductEditActionData } from '@/catalogs/api/runtime/CatalogActionData';
import {
  Catalog,
  CatalogDetail,
  CatalogProductTypeEnum,
  CatalogProductUpdateItem,
  ProductItem,
  CustomFieldItem,
} from '@/common/services/swagger/index.defs';

interface ProductEditModalFormData {
  uom: string;
  guid: string;
  quantityValue: string;
  productId: string;
  productDisplayName: string;
  description: string;
  customerClassification: string | undefined;
  baseProductId: string | undefined;
  customFields: CustomFieldItem[];
}

export default defineComponent({
  components: {
    Btn,
    Modal,
    FormInputClearable,
  },
  props: {
    t: { type: Function as PropType<TranslateFunc>, required: true },
    instanceId: String,
    data: { type: Object as PropType<CatalogProductEditActionData>, required: true },
  },
  emits: ['update:show'],
  setup(props, { emit }) {
    const instance = PIS.Catalogs.getInstance(props.instanceId) as Instance;
    const isProductDescriptionEditable = computed(
      () => props.data.product.productType === CatalogProductTypeEnum.Unlisted,
    );
    const formData = reactive<ProductEditModalFormData>({
      uom: '',
      guid: '',
      quantityValue: '',
      productId: '',
      productDisplayName: '',
      description: '',
      customerClassification: undefined,
      baseProductId: undefined,
      customFields: [],
    });
    const validators: Record<string, Validators> = {
      quantity: {
        [ValidationType.required]: {},
        [ValidationType.min]: { min: 1 },
        [ValidationType.max]: { max: 1000000 },
      },
    };
    const isFormValid = computed(
      () => (!isProductDescriptionEditable.value || formData.description) && formData.quantityValue,
    );
    const close = () => emit('update:show', false);
    const onDescriptionUpdate = (newDescription: string) => (formData.description = newDescription);
    const onQuantityUpdate = (newQuantity: string) => (formData.quantityValue = newQuantity);
    const onClassificationUpdate = (newClassification: string) =>
      (formData.customerClassification = newClassification);
    const onSave = async () => {
      try {
        const catalogId = props.data.catalog.id;
        if (!catalogId) {
          return;
        }
        const productPatch: CatalogProductUpdateItem = {
          description: formData.description,
          productId: formData.productId,
          quantity: Number.parseInt(formData.quantityValue, 10),
          unitOfMeasure: formData.uom,
          customerClassification: formData.customerClassification,
          baseProductId: formData.baseProductId,
          customFields: formData.customFields,
        };
        if (isProductDescriptionEditable.value) {
          productPatch.description = formData.description;
        }
        await instance.execute(
          new UpdateProductCommand({
            catalogId,
            product: productPatch,
            onSuccessMessage: props.t('CatalogProductEditSuccess'),
          }),
        );
      } catch (error) {
        // Ignore error
      }
      close();
    };

    onMounted(() => {
      const product = props.data.product;
      formData.productId = product.productId;
      formData.productDisplayName = attrValue(product as ProductItem, '#DisplayName');
      formData.description = attrValue(product as ProductItem, '#Description');
      formData.customerClassification = product.customerClassification;
      formData.baseProductId = product.baseProduct?.productId;
      formData.customFields = [...(product.customFields || [])];
      const catalogItem: Catalog | undefined = props.data.catalog;
      const catalogDetail: CatalogDetail | undefined = catalogItem
        ? product.catalogs?.find((catalog: CatalogDetail) => catalog.code === catalogItem.code)
        : (product.catalogs ?? {})[0];
      const productQuantity = (catalogDetail?.quantities ?? {})[0];
      if (productQuantity) {
        formData.quantityValue = `${productQuantity.value}`;
        formData.guid = productQuantity.guid;
        formData.uom = productQuantity.unitOfMeasure;
      }
    });

    return {
      formData,
      validators,
      isFormValid,
      isProductDescriptionEditable,
      close,
      onSave,
      onQuantityUpdate,
      onDescriptionUpdate,
      onClassificationUpdate,
    };
  },
});
