import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "table-responsive pis-scroll" }
const _hoisted_2 = { class: "table table-sm mt-2" }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("table", _hoisted_2, [
      _createElementVNode("thead", null, [
        _createElementVNode("tr", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.table.columns, (col) => {
            return (_openBlock(), _createElementBlock("th", {
              key: col.dataField,
              class: "text-center"
            }, [
              _createElementVNode("span", {
                innerHTML: col.text
              }, null, 8, _hoisted_3)
            ]))
          }), 128))
        ])
      ]),
      _createElementVNode("tbody", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.table.rows, (row, index) => {
          return (_openBlock(), _createElementBlock("tr", { key: index }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.table.columns, (col) => {
              return (_openBlock(), _createElementBlock("td", {
                key: col.dataField,
                class: "text-center"
              }, [
                (row.values[col.dataField])
                  ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(row.values[col.dataField], (val, vIndex) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "text-nowrap",
                        key: vIndex
                      }, _toDisplayString(val.text), 1))
                    }), 128))
                  : _createCommentVNode("", true)
              ]))
            }), 128))
          ]))
        }), 128))
      ])
    ])
  ]))
}