
import { defineComponent, ref, computed } from 'vue';
import { ComponentName } from '@/products/api/configuration/components/ComponentName';
import { setupComponent } from '@/products/composables/setupComponent';

import InjectStyles from '@/common/components/InjectStyles.vue';
import FavoriteViews from '@/common/components/FavoriteViews.vue';
import { DataType } from '@/products/api/configuration/application/DataType';

export default defineComponent({
  name: ComponentName.searchFavoriteViews,

  components: {
    InjectStyles,
    FavoriteViews,
  },

  props: {
    instanceId: String,
  },

  setup(props) {
    const root = ref<HTMLElement>();

    const { isReady, isWebComponent, componentName, store, t, instance } = setupComponent(
      root,
      props.instanceId,
      [DataType.Breadcrumbs],
    );

    const visible = computed(() => store.value?.options.components?.favoriteViews?.visible);

    return {
      root,
      instance,
      store,
      visible,
      isReady,
      componentName,
      isWebComponent,
      t,
    };
  },
});
