import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withModifiers as _withModifiers, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  class: "row gx-5 gy-3 h-100",
  style: {"min-height":"400px"}
}
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = {
  key: 1,
  class: "h-100 d-flex align-items-center"
}
const _hoisted_4 = {
  key: 0,
  class: "card-body"
}
const _hoisted_5 = {
  key: 0,
  class: "card-body"
}
const _hoisted_6 = {
  key: 1,
  class: "card-body"
}
const _hoisted_7 = {
  key: 2,
  class: "card-body"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FavoriteViewsList = _resolveComponent("FavoriteViewsList")!
  const _component_Message = _resolveComponent("Message")!
  const _component_LinkSecondary = _resolveComponent("LinkSecondary")!
  const _component_Modal = _resolveComponent("Modal")!
  const _component_Arrow = _resolveComponent("Arrow")!
  const _component_Card = _resolveComponent("Card")!
  const _component_FavoriteViewsSaveForm = _resolveComponent("FavoriteViewsSaveForm")!
  const _component_Confirmation = _resolveComponent("Confirmation")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isMobile)
      ? (_openBlock(), _createBlock(_component_Modal, {
          key: 0,
          show: _ctx.mobileShow,
          title: _ctx.t('FavoriteViewsModalTitle'),
          xl: "",
          "onUpdate:show": _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:mobileShow')))
        }, {
          footer: _withCtx(() => [
            (_ctx.canSave)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createVNode(_component_LinkSecondary, {
                    icon: "plus",
                    onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.updateModal(true)), ["prevent"])),
                    class: "ext-save"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('FavoriteViewsSave')), 1)
                    ]),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true)
          ]),
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                (_ctx.favoriteViews.length)
                  ? (_openBlock(), _createBlock(_component_FavoriteViewsList, {
                      key: 0,
                      "favorite-views": _ctx.favoriteViewsTrimmed,
                      onClickItem: _ctx.onFavoriteViewCheck,
                      onRemoveItem: _ctx.updateDeleteConfirmationModal
                    }, null, 8, ["favorite-views", "onClickItem", "onRemoveItem"]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                      _createVNode(_component_Message, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t('FavoriteViewsEmpty')), 1)
                        ]),
                        _: 1
                      })
                    ]))
              ])
            ])
          ]),
          _: 1
        }, 8, ["show", "title"]))
      : (_openBlock(), _createBlock(_component_Card, {
          key: 1,
          class: "mb-4",
          icon: _ctx.componentOptions?.icon && 'star',
          header: _ctx.t('FavoriteViews'),
          "use-markup": _ctx.componentOptions?.card
        }, {
          list: _withCtx(() => [
            (_ctx.favoriteViews.length)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createVNode(_component_FavoriteViewsList, {
                    "favorite-views": _ctx.favoriteViewsTrimmed,
                    onClickItem: _ctx.onFavoriteViewCheck,
                    onRemoveItem: _ctx.updateDeleteConfirmationModal
                  }, null, 8, ["favorite-views", "onClickItem", "onRemoveItem"]),
                  (_ctx.showMoreBtn)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                        _createVNode(_component_LinkSecondary, {
                          onClick: _withModifiers(_ctx.toggleTrimList, ["prevent"])
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_Arrow, {
                              class: "me-2",
                              dir: _ctx.trimList ? 'down' : 'up'
                            }, null, 8, ["dir"]),
                            _createTextVNode(" " + _toDisplayString(_ctx.trimList ? _ctx.t('FavoriteViewsShowMore') : _ctx.t('FavoriteViewsHideMore')) + " (" + _toDisplayString(_ctx.favoriteViews.length) + ") ", 1)
                          ]),
                          _: 1
                        }, 8, ["onClick"])
                      ]))
                    : _createCommentVNode("", true)
                ], 64))
              : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createVNode(_component_Message, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('FavoriteViewsEmpty')), 1)
                    ]),
                    _: 1
                  })
                ])),
            (_ctx.canSave)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createVNode(_component_LinkSecondary, {
                    icon: "plus",
                    onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.updateModal(true)), ["prevent"]))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('FavoriteViewsSave')), 1)
                    ]),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["icon", "header", "use-markup"])),
    _createVNode(_component_Modal, {
      show: _ctx.isUpdateModalVisible,
      title: _ctx.t('FavoriteViewsSaveTitle'),
      "onUpdate:show": _cache[5] || (_cache[5] = ($event: any) => (_ctx.updateModal(false)))
    }, {
      default: _withCtx(() => [
        (_ctx.isUpdateModalVisible)
          ? (_openBlock(), _createBlock(_component_FavoriteViewsSaveForm, {
              key: 0,
              t: _ctx.t,
              "instance-id": _ctx.instanceId,
              favoriteViews: _ctx.favoriteViews,
              "fallback-code": _ctx.fallbackCode,
              onOnChange: _cache[3] || (_cache[3] = ({ id, code }) => _ctx.onFormUpdate({ id, code })),
              onOnFormSubmit: _cache[4] || (_cache[4] = ({ id, code }) => _ctx.onFormUpdate({ id, code }, true)),
              onOnFormCancel: _ctx.onFormCancel
            }, null, 8, ["t", "instance-id", "favoriteViews", "fallback-code", "onOnFormCancel"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["show", "title"]),
    _createVNode(_component_Confirmation, {
      t: _ctx.t,
      title: _ctx.t('FavoriteViewsConfirmDelete', false, [_ctx.deleteConfirmationModalItem?.code ?? '']),
      item: _ctx.deleteConfirmationModalItem,
      "onUpdate:show": _cache[6] || (_cache[6] = ($event: any) => (_ctx.updateDeleteConfirmationModal(undefined))),
      onConfirmationCancelled: _cache[7] || (_cache[7] = ($event: any) => (_ctx.updateDeleteConfirmationModal(undefined))),
      onConfirmationOk: _cache[8] || (_cache[8] = ($event: any) => (_ctx.onFavoriteViewDelete(_ctx.deleteConfirmationModalItem)))
    }, null, 8, ["t", "title", "item"])
  ], 64))
}