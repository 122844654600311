import {
  ClassificationsBrowserAutocompleteQuery,
  ClassificationsBrowserSearchSettings,
  ClassificationsBrowserTreeViewEnum,
  ClassificationsBrowserAppSettings,
  ClientInfo,
  ClassificationsBrowserFilters,
  ApplicationUseSettings,
  PagerSettings,
  ClassificationsBrowserAutocompleteResult,
  ClassificationsBrowserChildrenQuery,
  ClassificationsBrowserChildrenSearchSettings,
  ClassificationsBrowserTreeTypeEnum,
  ClassificationsBrowserChildrenResult,
  ClassificationsBrowserNode,
  ProductGroup,
  ProductGroupValidationStatusEnum,
  ClassificationsBrowserPathQuery,
  ClassificationsBrowserPathSearchSettings,
  ClassificationsBrowserPathNode,
  ClassificationsBrowserSearchQuery,
  ClassificationsBrowserSearchResult,
  ClassificationsBrowserValidateQuery,
  ClassificationsBrowserValidateSearchSettings,
  ClassificationsBrowserValidateNode,
  ClassificationsBrowserValidateResult,
  IList,
  List,
  IListResult,
  ListResultDto,
  IPagedResult,
  PagedResultDto,
  Dictionary,
  IDictionary,
  IRequestOptions,
  IRequestConfig,
  getConfigs,
  axios,
  basePath
} from './index.defs';

export class ClassificationsBrowserService {
  /** Generate by swagger-axios-codegen */
  // @ts-nocheck
  /* eslint-disable */

  /**
   * Autocomplete classifications browser
   */
  static autocomplete(
    params: {
      /** requestBody */
      body?: ClassificationsBrowserAutocompleteQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ClassificationsBrowserAutocompleteResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/v1/Classifications/Browser/Autocomplete';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Retrieve classifications browser children
   */
  static children(
    params: {
      /** requestBody */
      body?: ClassificationsBrowserChildrenQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ClassificationsBrowserChildrenResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/v1/Classifications/Browser/Children';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Retrieve classifications browser node path
   */
  static path(
    params: {
      /** requestBody */
      body?: ClassificationsBrowserPathQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ClassificationsBrowserNode> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/v1/Classifications/Browser/Path';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Search classifications browser
   */
  static search(
    params: {
      /** requestBody */
      body?: ClassificationsBrowserSearchQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ClassificationsBrowserSearchResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/v1/Classifications/Browser/Search';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Validate classifications browser CIDs
   */
  static validate(
    params: {
      /** requestBody */
      body?: ClassificationsBrowserValidateQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ClassificationsBrowserValidateResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/v1/Classifications/Browser/Validate';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}
