import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, withModifiers as _withModifiers, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["part"]
const _hoisted_2 = { class: "card p-2" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = {
  key: 0,
  class: "me-3"
}
const _hoisted_5 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InjectStyles = _resolveComponent("InjectStyles")!
  const _component_VRuntimeTemplate = _resolveComponent("VRuntimeTemplate")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "root",
    class: _normalizeClass(["pis-component", _ctx.componentName]),
    part: _ctx.componentName
  }, [
    (_ctx.isReady && _ctx.visible)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.isWebComponent)
            ? (_openBlock(), _createBlock(_component_InjectStyles, {
                key: 0,
                items: _ctx.store?.options.styles
              }, null, 8, ["items"]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              (_ctx.componentOptions?.countVisible)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass([_ctx.widerThan('md') ? 'col-2' : 'col-12'])
                  }, [
                    _createElementVNode("strong", null, _toDisplayString(_ctx.instance?.selection.count), 1),
                    _createTextVNode(" " + _toDisplayString(_ctx.t('ItemsSelected', true)), 1)
                  ], 2))
                : _createCommentVNode("", true),
              _createElementVNode("div", {
                class: _normalizeClass([[_ctx.widerThan('md') ? 'col-4' : 'col-12'], "d-flex flex-row"])
              }, [
                (_ctx.componentOptions?.selectAllVisible)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _createElementVNode("a", {
                        href: "",
                        onClick: _cache[0] || (_cache[0] = _withModifiers(() => _ctx.instance?.selection.selectAll(), ["prevent"]))
                      }, _toDisplayString(_ctx.t('SelectAll', true)), 1)
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.componentOptions?.deselectAllVisible && _ctx.instance?.selection.count)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      _createElementVNode("a", {
                        href: "",
                        onClick: _cache[1] || (_cache[1] = _withModifiers(() => _ctx.instance?.selection.clear(), ["prevent"]))
                      }, _toDisplayString(_ctx.t('DeselectAll', true)), 1)
                    ]))
                  : _createCommentVNode("", true)
              ], 2),
              (_ctx.componentOptions?.actionsTemplate)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: _normalizeClass([_ctx.widerThan('md') ? 'col-6 text-end' : 'col-12 '])
                  }, [
                    _createVNode(_component_VRuntimeTemplate, {
                      template: _ctx.componentOptions.actionsTemplate,
                      "template-props": { instance: _ctx.instance, t: _ctx.t }
                    }, null, 8, ["template", "template-props"])
                  ], 2))
                : _createCommentVNode("", true)
            ])
          ])
        ], 64))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}