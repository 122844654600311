import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["part"]
const _hoisted_2 = { class: "container-fluid g-0" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-md-12" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "col-md-6 col-sm-12 mb-3" }
const _hoisted_7 = { class: "col-md-6 col-sm-12 text-end mb-3" }
const _hoisted_8 = { class: "row" }
const _hoisted_9 = { class: "col-md-6 mb-3" }
const _hoisted_10 = { class: "col-md-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InjectStyles = _resolveComponent("InjectStyles")!
  const _component_ClassificationsNotifications = _resolveComponent("ClassificationsNotifications")!
  const _component_ClassificationNote = _resolveComponent("ClassificationNote")!
  const _component_ClassificationsTreeTabs = _resolveComponent("ClassificationsTreeTabs")!
  const _component_ClassificationsSearchForm = _resolveComponent("ClassificationsSearchForm")!
  const _component_ClassificationActions = _resolveComponent("ClassificationActions")!
  const _component_ClassificationsTree = _resolveComponent("ClassificationsTree")!
  const _component_ClassificationsSelection = _resolveComponent("ClassificationsSelection")!
  const _component_LoadingIndicator = _resolveComponent("LoadingIndicator")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "root",
    class: _normalizeClass(["pis-component w-100 h-100", _ctx.componentName]),
    part: _ctx.componentName
  }, [
    _withDirectives(_createElementVNode("div", null, [
      (_ctx.isWebComponent)
        ? (_openBlock(), _createBlock(_component_InjectStyles, {
            key: 0,
            items: _ctx.store?.options.styles
          }, null, 8, ["items"]))
        : _createCommentVNode("", true),
      _createVNode(_component_ClassificationsNotifications),
      _createVNode(_component_LoadingIndicator, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_ClassificationNote, { t: _ctx.t }, null, 8, ["t"]),
                _createVNode(_component_ClassificationsTreeTabs)
              ])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_ClassificationsSearchForm)
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_ClassificationActions)
              ])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createVNode(_component_ClassificationsTree)
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createVNode(_component_ClassificationsSelection)
              ])
            ])
          ])
        ]),
        _: 1
      })
    ], 512), [
      [_vShow, _ctx.isReady]
    ])
  ], 10, _hoisted_1))
}