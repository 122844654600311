import _mergeWith from 'lodash/mergeWith';
import _isEqualWith from 'lodash/isEqualWith';
import { reactive } from 'vue';
import { Instance } from '../Instance';
import { IRouteData } from './IRouteData';
import { defaultRoute } from '@/products/api/configuration/DefaultRoute';
import _cloneDeep from 'lodash/cloneDeep';

export class Router {
  routeData = reactive<IRouteData>(_cloneDeep(defaultRoute));
  history: IRouteData[] = [];

  constructor(private instance: Instance) {}

  /**
   * @param routeSettings
   * @param passive flag that disables the API call. Useful with changing the display modes.
   */
  public async setRoute(routeSettings?: IRouteData, passive = false): Promise<void> {
    const newRoute = _cloneDeep(this.routeData);

    if (routeSettings) {
      // When route is set from outside pagination may be passed as string
      if (routeSettings.pagination) {
        routeSettings.pagination.page = parseInt(routeSettings.pagination.page as never, 10);
        routeSettings.pagination.pageSize = parseInt(
          routeSettings.pagination.pageSize as never,
          10,
        );

        if (isNaN(routeSettings.pagination.page)) {
          routeSettings.pagination.page = this.routeData?.pagination?.page;
        }

        if (isNaN(routeSettings.pagination.pageSize)) {
          routeSettings.pagination.pageSize = this.routeData?.pagination?.pageSize;
        }
      }

      _mergeWith(newRoute, routeSettings, (objValue, srcValue) => {
        // Allow to override existing arrays with empty ones instead of merging them.
        // Useful with eg.: filters.
        if (Array.isArray(srcValue)) {
          return srcValue;
        }

        // To clear value we need to return null
        if (srcValue === undefined) {
          return null;
        }
      });
    }

    // if only displayMode changes, no need to reload results
    let onlyDisplayModeChanged = false;
    if (!passive && this.history.length > 1 && newRoute.view == 'search') {
      const lastRoute = this.history[this.history.length - 1];
      onlyDisplayModeChanged = _isEqualWith(lastRoute, newRoute, (v1, v2, key) => {
        if (key == 'displayMode') {
          return true;
        }
        return undefined;
      });
    }

    if (onlyDisplayModeChanged) {
      this.commitRoute(newRoute);
      return;
    }

    let onlyPaginationChanged = false;

    if (!passive && this.history.length > 1 && newRoute.view == 'search') {
      const lastRoute = this.history[this.history.length - 1];
      onlyPaginationChanged = _isEqualWith(lastRoute, newRoute, (v1, v2, key) => {
        if (key == 'page' || key == 'pageSize') {
          return true;
        }
        return undefined;
      });
    }

    const nextRoute = JSON.parse(JSON.stringify(this.routeData));
    _mergeWith(nextRoute, newRoute, (objValue, srcValue) => {
      // Allow to override existing arrays with empty ones instead of merging them.
      // Useful with eg.: filters.
      if (Array.isArray(srcValue)) {
        return srcValue;
      }

      // To clear value we need to return null
      if (srcValue === undefined) {
        return null;
      }
    });

    this.instance.props['nextRoute'] = nextRoute;

    if (this.instance.store.data.isInitialized && this.instance.httpService) {
      if (!passive) {
        if (onlyPaginationChanged) {
          await this.instance.httpService.paginate(newRoute);
          return this.commitRoute(newRoute);
        } else {
          await this.instance.httpService?.load(newRoute);
          return this.commitRoute(newRoute);
        }
      } else {
        return this.commitRoute(newRoute);
      }
    } else {
      return this.commitRoute(newRoute);
    }
  }

  private commitRoute(routeData: IRouteData) {
    this.instance.props['nextRoute'] = null;

    this.history.push({ ...routeData });
    this.merge(routeData);

    const eventData = _cloneDeep(this.routeData);
    setTimeout(() => this.instance.eventBus.emit('route-changed', eventData), 10);
  }

  public merge(routeData: IRouteData) {
    _mergeWith(this.routeData, routeData, (objValue, srcValue) => {
      // Allow to override existing arrays with empty ones instead of merging them.
      // Useful with eg.: filters.
      if (Array.isArray(srcValue)) {
        return srcValue;
      }

      // To clear value we need to return null
      if (srcValue === undefined) {
        return null;
      }
    });
  }

  public async reload() {
    if (this.routeData && this.routeData.pagination) {
      this.routeData.pagination.page = 1;
    }
    await this.instance.httpService?.load(this.routeData);
  }

  public async back(steps = 1): Promise<void> {
    const newIndex = this.history.length - steps - 1;
    const newRoute = this.history[newIndex];

    if (newRoute) {
      this.history.splice(newIndex);
    }

    return this.setRoute(newRoute ?? defaultRoute);
  }
}
