
import Btn from '@/common/components/Btn.vue';
import Modal from '@/common/components/Modal.vue';
import { ValidationType, Validators } from '@/common/helpers/validation';
import { computed, defineComponent, PropType, ref } from 'vue';
import { TranslateFunc } from '@/catalogs/composables/setupComponent';
import PIS from '@/common/api/PIS';
import { Instance } from '@/catalogs/api/Instance';
import { ImportAliasesCommand } from '@/catalogs/api/runtime/CommandExecutor';
import toBase64 from '@/common/helpers/toBase64';
import { AliasImportActionData } from '@/catalogs/api/runtime/CatalogActionData';

export default defineComponent({
  components: {
    Btn,
    Modal,
  },
  props: {
    t: { type: Function as PropType<TranslateFunc>, required: true },
    instanceId: String,
    data: { type: Object as PropType<AliasImportActionData>, required: false },
  },
  emits: ['update:show'],
  setup(props, { emit }) {
    const show = ref<boolean>(true);
    const instance = PIS.Catalogs.getInstance(props.instanceId) as Instance;
    const uploadedFile = ref<File | null>();
    const isFormValid = computed(() => !!uploadedFile.value);
    const validators: Record<string, Validators> = {
      uploadedFile: { [ValidationType.required]: {} },
    };
    const onFileUpload = ($event: Event) => {
      const input = $event.target as HTMLInputElement;
      if (input && input.files) {
        uploadedFile.value = input.files[0];
      }
    };
    const close = () => emit('update:show', false);
    const onImport = async () => {
      if (!uploadedFile.value) {
        return;
      }
      try {
        const file = await toBase64(uploadedFile.value);
        await instance.execute(
          new ImportAliasesCommand({
            file,
            fileName: uploadedFile.value.name,
            onSuccessMessage: props.t('CatalogImportAliasesSuccess'),
          }),
        );
      } catch (error) {
        // Ignore Error
      }
      close();
    };

    return {
      show,
      validators,
      isFormValid,
      uploadedFile,
      close,
      onImport,
      onFileUpload,
    };
  },
});
