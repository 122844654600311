import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex justify-content-end mt-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Btn = _resolveComponent("Btn")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    show: !!_ctx.item,
    title: _ctx.title,
    lg: _ctx.lg,
    xl: _ctx.xl,
    "onUpdate:show": _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('update:show')))
  }, {
    default: _withCtx(() => [
      _createElementVNode("form", null, [
        _renderSlot(_ctx.$slots, "default"),
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_Btn, {
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit(_ctx.confirmationEvent.ok, _ctx.item)), ["prevent"])),
            primary: ""
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('Confirm', true)), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_Btn, {
            onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.$emit(_ctx.confirmationEvent.cancelled, _ctx.item)), ["prevent"])),
            class: "mx-2"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('Cancel', true)), 1)
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _: 3
  }, 8, ["show", "title", "lg", "xl"]))
}