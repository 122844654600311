
import { defineComponent, PropType } from 'vue';
import { IStyle } from '@/common/interfaces/IStyle';

export default defineComponent({
  props: {
    items: {
      type: Array as PropType<IStyle[]>,
      default: () => [],
    },
  },
});
