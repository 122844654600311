
import { defineComponent, onMounted, reactive, ref } from 'vue';
import { DataType } from '@/products/api/configuration/application/DataType';
import { ComponentName } from '@/products/api/configuration/components/ComponentName';
import { setupComponent } from '@/products/composables/setupComponent';

import InjectStyles from '@/common/components/InjectStyles.vue';
import Btn from '@/common/components/Btn.vue';
import { ClassificationCommand, SearchCommand } from '@/products/api/runtime/CommandExecutor';
import { AutocompleteItem, SearchTypeEnum } from '@/common/services/swagger/index.defs';
import { Autocomplete, AutocompleteModelItem } from '@/products/api/runtime/Search';
import Clickable from '@/common/components/Clickable.vue';
import Image from '@/common/components/Image.vue';
export default defineComponent({
  name: ComponentName.searchNoResults,

  components: {
    InjectStyles,
    Btn,
    Clickable,
    Image,
  },

  props: {
    instanceId: String,
  },

  setup(props) {
    const root = ref<HTMLElement>();
    const isLoaded = ref(false);

    const { componentName, isReady, isWebComponent, instance, store, routeData, t } =
      setupComponent(root, props.instanceId, [DataType.Products]);

    const autocomplete = reactive<Autocomplete>({
      totalCount: 0,
      visibleMatchCount: 0,
      items: [] as AutocompleteModelItem[],
    });

    onMounted(async () => {
      autocomplete.items = [];
      setTimeout(async () => {
        if (routeData.value?.searchText) {
          const items: AutocompleteModelItem[] = [];

          const result = await instance.value?.httpService?.autocomplete(
            routeData.value?.searchText,
            true,
          );

          if (result && result.categories) {
            result.categories.forEach((c, index) => {
              if (index > 1) return;

              let secondaryText = '';

              if (c.breadcrumbs && c.breadcrumbs.length > 0) {
                secondaryText = c.breadcrumbs
                  .slice(0, c.breadcrumbs.length - 1)
                  .map((a) => a.name)
                  .join(' > ');
              }

              items.push({
                attributes: {},
                productId: c.match?.cid ?? '',
                text: c.match?.name,
                secondaryText: secondaryText,
                description: c.keywords?.join(', '),
                images: c.images,
                type: 'category',
                location: 'top',
              });
            });
          }

          autocomplete.items = items;
          isLoaded.value = true;
        } else {
          isLoaded.value = true;
        }
      }, 1);
    });

    const clearResults = async () => {
      try {
        await instance.value?.execute(
          new SearchCommand({
            searchText: '',
            searchType: routeData.value?.searchType as SearchTypeEnum,
          }),
        );
      } catch (error) {
        // Ignore Error
      }
    };

    const getThumbnailUrl = (item: AutocompleteItem) => {
      if (item.images && item.images.length > 0) {
        return item.images[0].thumbnailUrl;
      }

      return undefined;
    };

    const setClassification = async (cid: string) => {
      if (instance.value && cid) {
        try {
          await instance.value.execute(new ClassificationCommand({ cid, clearSearch: true }));
        } catch (error) {
          // Ignore Error
        }
      }
    };

    return {
      root,
      componentName,
      isReady,
      isWebComponent,
      store,
      autocomplete,
      isLoaded,
      t,
      clearResults,
      getThumbnailUrl,
      setClassification,
    };
  },
});
