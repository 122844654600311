
import { defineComponent } from 'vue';
import Clickable from './Clickable.vue';
import Icon from '@/common/components/Icon.vue';

export default defineComponent({
  components: {
    Icon,
    Clickable,
  },

  props: {
    items: Object,
    iterationKey: String,
    namingKey: String,
  },

  emits: ['click-item', 'remove-item'],
});
