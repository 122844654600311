import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["part"]
const _hoisted_2 = {
  key: 1,
  class: "table-responsive pis-scroll"
}
const _hoisted_3 = { class: "table" }
const _hoisted_4 = {
  key: 0,
  style: {"width":"1%"}
}
const _hoisted_5 = {
  key: 1,
  style: {"width":"1%"}
}
const _hoisted_6 = {
  key: 2,
  style: {"width":"1%"}
}
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { key: 2 }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { class: "ext-select-checkbox" }
const _hoisted_12 = ["checked", "onClick"]
const _hoisted_13 = { key: 1 }
const _hoisted_14 = { key: 1 }
const _hoisted_15 = {
  key: 1,
  class: "fw-bold text-muted"
}
const _hoisted_16 = {
  key: 2,
  class: "text-muted small"
}
const _hoisted_17 = { key: 2 }
const _hoisted_18 = { key: 2 }
const _hoisted_19 = { class: "d-flex align-items-right list-unstyled mb-0" }
const _hoisted_20 = ["disabled", "onClick"]
const _hoisted_21 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InjectStyles = _resolveComponent("InjectStyles")!
  const _component_VRuntimeTemplate = _resolveComponent("VRuntimeTemplate")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Clickable = _resolveComponent("Clickable")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "root",
    class: _normalizeClass(["pis-component", _ctx.componentName]),
    part: _ctx.componentName
  }, [
    (_ctx.isReady && _ctx.componentOptions.visible)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.isWebComponent)
            ? (_openBlock(), _createBlock(_component_InjectStyles, {
                key: 0,
                items: _ctx.store?.options.styles
              }, null, 8, ["items"]))
            : _createCommentVNode("", true),
          (_ctx.productsItems && _ctx.productsItems.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("table", _hoisted_3, [
                  _createElementVNode("colgroup", null, [
                    (_ctx.componentOptions.selectable)
                      ? (_openBlock(), _createElementBlock("col", _hoisted_4))
                      : _createCommentVNode("", true),
                    (_ctx.previewEnabled)
                      ? (_openBlock(), _createElementBlock("col", _hoisted_5))
                      : _createCommentVNode("", true),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (col, index) => {
                      return (_openBlock(), _createElementBlock("col", {
                        key: index,
                        style: _normalizeStyle({ width: col.width })
                      }, null, 4))
                    }), 128)),
                    (_ctx.actionsEnabled)
                      ? (_openBlock(), _createElementBlock("col", _hoisted_6))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("thead", null, [
                    _createElementVNode("tr", null, [
                      (_ctx.componentOptions.selectable)
                        ? (_openBlock(), _createElementBlock("th", _hoisted_7))
                        : _createCommentVNode("", true),
                      (_ctx.previewEnabled)
                        ? (_openBlock(), _createElementBlock("th", _hoisted_8))
                        : _createCommentVNode("", true),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (col, index) => {
                        return (_openBlock(), _createElementBlock("th", { key: index }, [
                          (col.headerTemplate)
                            ? (_openBlock(), _createBlock(_component_VRuntimeTemplate, {
                                key: 0,
                                template: col.headerTemplate,
                                "template-props": { t: _ctx.t, col }
                              }, null, 8, ["template", "template-props"]))
                            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                _createTextVNode(_toDisplayString(col.caption), 1)
                              ], 64))
                        ]))
                      }), 128)),
                      (_ctx.actionsEnabled)
                        ? (_openBlock(), _createElementBlock("th", _hoisted_9))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _createElementVNode("tbody", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.productsItems, (item, itemIndex) => {
                      return (_openBlock(), _createElementBlock("tr", {
                        key: itemIndex,
                        class: _normalizeClass({
                  'bg-light': item.exactMatch,
                  'no-border-bottom': item['expanded'],
                  'ext-selected': _ctx.instance?.selection.isSelected(item),
                })
                      }, [
                        (_ctx.componentOptions.selectable)
                          ? (_openBlock(), _createElementBlock("td", _hoisted_10, [
                              _createElementVNode("div", _hoisted_11, [
                                _createElementVNode("input", {
                                  type: "checkbox",
                                  class: "form-check-input",
                                  checked: _ctx.instance?.selection.isSelected(item),
                                  onClick: ($event: any) => (_ctx.instance?.selection.toggle(item))
                                }, null, 8, _hoisted_12)
                              ])
                            ]))
                          : _createCommentVNode("", true),
                        (_ctx.previewEnabled)
                          ? (_openBlock(), _createElementBlock("td", _hoisted_13, [
                              _createVNode(_component_Clickable, {
                                class: "d-flex ext-icon-preview",
                                title: 
                      _ctx.t(
                        item.productType !== _ctx.CatalogProductTypeEnum.Listed || item.inactive
                          ? 'PreviewNotAvailable'
                          : 'Preview',
                        true,
                      )
                    ,
                                disabled: item.productType !== _ctx.CatalogProductTypeEnum.Listed || item.inactive,
                                onClick: _withModifiers(($event: any) => (
                      item.productType === _ctx.CatalogProductTypeEnum.Listed &&
                        !item.inactive &&
                        _ctx.preview(item)
                    ), ["prevent"])
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_Icon, {
                                    glyph: "preview",
                                    lg: ""
                                  })
                                ]),
                                _: 2
                              }, 1032, ["title", "disabled", "onClick"])
                            ]))
                          : _createCommentVNode("", true),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (col, index) => {
                          return (_openBlock(), _createElementBlock("td", {
                            key: index,
                            style: {"max-width":"100px","white-space":"nowrap","overflow":"hidden","text-overflow":"ellipsis"}
                          }, [
                            (col.cellTemplate)
                              ? (_openBlock(), _createBlock(_component_VRuntimeTemplate, {
                                  key: 0,
                                  template: col.cellTemplate,
                                  "template-props": {
                      col,
                      item,
                      instance: _ctx.instance,
                      catalogProductType: _ctx.catalogProductType,
                      CatalogProductTypeEnum: _ctx.CatalogProductTypeEnum,
                      emit: _ctx.emit,
                      details: _ctx.details,
                      attrValue: _ctx.attrValue,
                      attrValues: _ctx.attrValues,
                      t: _ctx.t,
                    }
                                }, null, 8, ["template", "template-props"]))
                              : (col.dataField == 'ProductId')
                                ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                                    (!item.inactive)
                                      ? (_openBlock(), _createBlock(_component_Clickable, {
                                          key: 0,
                                          onClick: ($event: any) => (_ctx.details(item))
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.attrValue(item, col.dataField)), 1)
                                          ]),
                                          _: 2
                                        }, 1032, ["onClick"]))
                                      : (_openBlock(), _createElementBlock("span", _hoisted_15, _toDisplayString(_ctx.attrValue(item, col.dataField)), 1)),
                                    (item.productType == _ctx.catalogProductType.Configured)
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                                          _createElementVNode("em", null, _toDisplayString(_ctx.t('ConfiguredProducts')), 1)
                                        ]))
                                      : _createCommentVNode("", true)
                                  ]))
                                : (_openBlock(), _createElementBlock("div", _hoisted_17, _toDisplayString(_ctx.attrValue(item, col.dataField)), 1))
                          ]))
                        }), 128)),
                        (_ctx.actionsEnabled)
                          ? (_openBlock(), _createElementBlock("td", _hoisted_18, [
                              _createElementVNode("ul", _hoisted_19, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.aliasItemMenuItems(item), (menuItem, index) => {
                                  return (_openBlock(), _createElementBlock("li", { key: index }, [
                                    (menuItem.itemTemplate)
                                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                          _createTextVNode(_toDisplayString(menuItem.itemTemplate), 1)
                                        ], 64))
                                      : (_openBlock(), _createElementBlock("button", {
                                          key: 1,
                                          type: "button",
                                          class: "btn btn-link text-dark",
                                          disabled: _ctx.isDisabled(menuItem, item),
                                          onClick: _withModifiers(($event: any) => (
                            !_ctx.isDisabled(menuItem, item) && _ctx.onAliasItemMenuAction(menuItem, item)
                          ), ["prevent"])
                                        }, [
                                          (menuItem.text)
                                            ? (_openBlock(), _createElementBlock("span", _hoisted_21, _toDisplayString(menuItem.text), 1))
                                            : _createCommentVNode("", true),
                                          (menuItem.icon)
                                            ? (_openBlock(), _createBlock(_component_Icon, {
                                                key: 1,
                                                glyph: menuItem.icon
                                              }, null, 8, ["glyph"]))
                                            : _createCommentVNode("", true)
                                        ], 8, _hoisted_20))
                                  ]))
                                }), 128))
                              ])
                            ]))
                          : _createCommentVNode("", true)
                      ], 2))
                    }), 128))
                  ])
                ])
              ]))
            : _createCommentVNode("", true)
        ], 64))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}