import {
  DetailDataTypeEnum,
  ProductItem,
  SearchDataTypeEnum,
} from '@/common/services/swagger/index.defs';

export interface ProductIdToNameData {
  requestedProductId: string;
  item: ProductItem;
}

export enum InternalDataTypeEnum {
  'ProductPreview' = 'ProductPreview',
  'FavoriteProductCategories' = 'FavoriteProductCategories',
  'FavoritePartCategories' = 'FavoritePartCategories',
  'FavoriteViews' = 'FavoriteViews',
  'Translations' = 'Translations',
  'RelatedProducts' = 'RelatedProducts',
  'ProductCompare' = 'ProductCompare',
  'ProductIdToName' = 'ProductIdToName',
}

export const DataType = { ...SearchDataTypeEnum, ...DetailDataTypeEnum, ...InternalDataTypeEnum };
export type DataType = DetailDataTypeEnum | SearchDataTypeEnum | InternalDataTypeEnum;
