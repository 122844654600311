import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withModifiers as _withModifiers, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = ["part"]
const _hoisted_2 = { class: "card-img-top position-relative pt-5" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "card-body" }
const _hoisted_5 = { class: "d-flex justify-content-center text-center" }
const _hoisted_6 = {
  key: 0,
  class: "text-muted"
}
const _hoisted_7 = ["onClick"]
const _hoisted_8 = {
  key: 0,
  class: "card-footer"
}
const _hoisted_9 = { class: "text-center" }
const _hoisted_10 = {
  key: 1,
  class: "position-absolute p-2 top-0 start-0 ext-select-checkbox",
  style: {"z-index":"1","width":"auto","margin-top":"-0.1rem"}
}
const _hoisted_11 = ["checked", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InjectStyles = _resolveComponent("InjectStyles")!
  const _component_Image = _resolveComponent("Image")!
  const _component_VRuntimeTemplate = _resolveComponent("VRuntimeTemplate")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Tooltip = _resolveComponent("Tooltip")!
  const _component_PisReplacements = _resolveComponent("PisReplacements")!
  const _component_slide = _resolveComponent("slide")!
  const _component_navigation = _resolveComponent("navigation")!
  const _component_pagination = _resolveComponent("pagination")!
  const _component_carousel = _resolveComponent("carousel")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "root",
    class: _normalizeClass(["pis-component position-relative", _ctx.componentName]),
    part: _ctx.componentName
  }, [
    (_ctx.isReady && _ctx.componentOptions?.visible && _ctx.currentSettings)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.isWebComponent)
            ? (_openBlock(), _createBlock(_component_InjectStyles, {
                key: 0,
                items: _ctx.store?.options.styles
              }, null, 8, ["items"]))
            : _createCommentVNode("", true),
          _createVNode(_component_carousel, _mergeProps({ ref: "carousel" }, _ctx.settings, {
            breakpoints: _ctx.breakpoints,
            i18n: _ctx.i18n
          }), {
            addons: _withCtx(() => [
              (_ctx.currentSettings.navigationVisible)
                ? (_openBlock(), _createBlock(_component_navigation, { key: 0 }, {
                    next: _withCtx(() => [
                      _createVNode(_component_Icon, { glyph: "carousel-right" })
                    ]),
                    prev: _withCtx(() => [
                      _createVNode(_component_Icon, { glyph: "carousel-left" })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.currentSettings.paginationVisible)
                ? (_openBlock(), _createBlock(_component_pagination, { key: 1 }))
                : _createCommentVNode("", true)
            ]),
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.productsItems, (item) => {
                return (_openBlock(), _createBlock(_component_slide, {
                  key: item.productId
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      class: _normalizeClass(["card h-100 w-100", ['mx-' + _ctx.currentSettings.margin]])
                    }, [
                      _createElementVNode("div", _hoisted_2, [
                        _createVNode(_component_Image, {
                          src: (item.images || [])[0]?.url,
                          "max-size": 300,
                          role: "button",
                          "hide-frame": "",
                          class: "card-img-top",
                          style: {"max-width":"80% !important","margin":"auto"},
                          clickable: _ctx.componentOptions.navigateOnContentClick && !item.inactive,
                          onClick: _withModifiers(($event: any) => (
                  _ctx.componentOptions.navigateOnContentClick && !item.inactive && _ctx.details(item)
                ), ["prevent"])
                        }, null, 8, ["src", "clickable", "onClick"]),
                        (_ctx.componentOptions.imageOverlayTemplate)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                              _createVNode(_component_VRuntimeTemplate, {
                                template: _ctx.componentOptions.imageOverlayTemplate,
                                "template-props": { instance: _ctx.instance, item: item, emit: _ctx.emit, t: _ctx.t }
                              }, null, 8, ["template", "template-props"])
                            ]))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_4, [
                        _createElementVNode("div", _hoisted_5, [
                          (item.additionalInfo && item.additionalInfo.length)
                            ? (_openBlock(), _createBlock(_component_Tooltip, {
                                key: 0,
                                text: _ctx.additionalInfo(item.additionalInfo)
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_Icon, {
                                    class: "me-2 text-warning",
                                    glyph: "warning"
                                  })
                                ]),
                                _: 2
                              }, 1032, ["text"]))
                            : _createCommentVNode("", true),
                          (_ctx.componentOptions.titleTemplate)
                            ? (_openBlock(), _createBlock(_component_VRuntimeTemplate, {
                                key: 1,
                                template: _ctx.componentOptions.titleTemplate,
                                "template-props": { instance: _ctx.instance, item, emit: _ctx.emit, details: _ctx.details, attrValue: _ctx.attrValue, t: _ctx.t }
                              }, null, 8, ["template", "template-props"]))
                            : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                                (item.inactive)
                                  ? (_openBlock(), _createElementBlock("strong", _hoisted_6, _toDisplayString(_ctx.attrValue(item, '#DisplayName')), 1))
                                  : (_openBlock(), _createElementBlock("a", {
                                      key: 1,
                                      href: "#",
                                      onClick: _withModifiers(($event: any) => (_ctx.details(item)), ["prevent"])
                                    }, [
                                      _createElementVNode("strong", null, _toDisplayString(_ctx.attrValue(item, '#DisplayName')), 1)
                                    ], 8, _hoisted_7))
                              ], 64))
                        ]),
                        (_ctx.componentOptions.showAllIdentifiers)
                          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.attrValues(item, '#AllIdentifiers'), (val, index) => {
                              return (_openBlock(), _createElementBlock("div", {
                                key: index,
                                class: "text-center small text-muted fw-bold my-2"
                              }, _toDisplayString(val), 1))
                            }), 128))
                          : _createCommentVNode("", true),
                        (_ctx.componentOptions.itemTemplate)
                          ? (_openBlock(), _createBlock(_component_VRuntimeTemplate, {
                              key: 1,
                              template: _ctx.componentOptions.itemTemplate,
                              "template-props": { instance: _ctx.instance, item, emit: _ctx.emit, details: _ctx.details, attrValue: _ctx.attrValue, attrValues: _ctx.attrValues, t: _ctx.t }
                            }, null, 8, ["template", "template-props"]))
                          : _createCommentVNode("", true),
                        (item.replacements?.length && _ctx.componentOptions.showReplacements)
                          ? (_openBlock(), _createBlock(_component_PisReplacements, {
                              key: 2,
                              class: "text-center mt-3",
                              instance: _ctx.instance,
                              replacements: item.replacements,
                              visible: _ctx.replacementsVisible
                            }, null, 8, ["instance", "replacements", "visible"]))
                          : _createCommentVNode("", true)
                      ]),
                      (_ctx.componentOptions.actionTemplate)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                            _createElementVNode("div", _hoisted_9, [
                              _createVNode(_component_VRuntimeTemplate, {
                                template: _ctx.componentOptions.actionTemplate,
                                "template-props": { instance: _ctx.instance, item, emit: _ctx.emit, details: _ctx.details, attrValue: _ctx.attrValue, attrValues: _ctx.attrValues, t: _ctx.t }
                              }, null, 8, ["template", "template-props"])
                            ])
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.componentOptions.selectable)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                            _createElementVNode("input", {
                              type: "checkbox",
                              class: "form-check-input",
                              checked: _ctx.instance?.selection.isSelected(item),
                              onClick: ($event: any) => (_ctx.instance?.selection.toggle(item))
                            }, null, 8, _hoisted_11)
                          ]))
                        : _createCommentVNode("", true)
                    ], 2)
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          }, 16, ["breakpoints", "i18n"])
        ], 64))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}