import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = ["part"]
const _hoisted_2 = { class: "row g-0" }
const _hoisted_3 = { class: "col" }
const _hoisted_4 = { class: "text-muted" }
const _hoisted_5 = { class: "col" }
const _hoisted_6 = { class: "fw-bolder" }
const _hoisted_7 = { class: "col text-end" }
const _hoisted_8 = { key: 2 }
const _hoisted_9 = { class: "row align-items-center" }
const _hoisted_10 = {
  key: 1,
  class: "position-absolute p-2 top-50 start-0 ext-select-checkbox",
  style: {"z-index":"1","width":"auto","margin-top":"-1rem"}
}
const _hoisted_11 = ["checked", "onClick"]
const _hoisted_12 = { class: "position-relative col d-flex justify-content-center align-items-center card" }
const _hoisted_13 = { key: 0 }
const _hoisted_14 = {
  key: 3,
  class: "col-12 g-0"
}
const _hoisted_15 = { class: "position-relative col d-flex justify-content-center align-items-center" }
const _hoisted_16 = { key: 0 }
const _hoisted_17 = {
  key: 0,
  class: "text-muted"
}
const _hoisted_18 = ["onClick"]
const _hoisted_19 = { key: 5 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InjectStyles = _resolveComponent("InjectStyles")!
  const _component_VRuntimeTemplate = _resolveComponent("VRuntimeTemplate")!
  const _component_Card = _resolveComponent("Card")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Clickable = _resolveComponent("Clickable")!
  const _component_Image = _resolveComponent("Image")!
  const _component_Tooltip = _resolveComponent("Tooltip")!
  const _component_PisReplacements = _resolveComponent("PisReplacements")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "root",
    class: _normalizeClass(["pis-component", _ctx.componentName]),
    part: _ctx.componentName
  }, [
    (_ctx.isReady)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.isWebComponent)
            ? (_openBlock(), _createBlock(_component_InjectStyles, {
                key: 0,
                items: _ctx.store?.options.styles
              }, null, 8, ["items"]))
            : _createCommentVNode("", true),
          (!_ctx.hideIfInitial && _ctx.configurators && _ctx.configuratorOptions.visible && _ctx.currentCIDName)
            ? (_openBlock(), _createBlock(_component_Card, {
                key: 1,
                class: "mb-4 bg-light",
                "use-markup": true
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, [
                      _createElementVNode("div", _hoisted_4, _toDisplayString([
                    _ctx.currentCIDName,
                    _ctx.configurators.length > 1 ? _ctx.t('Configurators') : _ctx.t('Configurator'),
                  ]
                    .join(' - ')
                    .concat(':')), 1)
                    ])
                  ]),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.configurators, (conf) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "row g-0",
                      key: conf.code
                    }, [
                      _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("div", _hoisted_6, _toDisplayString(conf.productId), 1)
                      ]),
                      _createElementVNode("div", _hoisted_7, [
                        (_ctx.configuratorOptions.actionTemplate)
                          ? (_openBlock(), _createBlock(_component_VRuntimeTemplate, {
                              key: 0,
                              template: _ctx.configuratorOptions.actionTemplate,
                              "template-props": { t: _ctx.t, item: conf, showConfigurator: _ctx.showConfigurator, emit: _ctx.emit },
                              t: _ctx.t
                            }, null, 8, ["template", "template-props", "t"]))
                          : _createCommentVNode("", true)
                      ])
                    ]))
                  }), 128))
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (!_ctx.hideIfInitial && _ctx.productsItems && _ctx.productsItems.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.productsItems, (item) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: item.productId,
                    class: _normalizeClass(["card mb-3 p-3", {
            'pt-0': !_ctx.widerThanPx(_ctx.componentOptions.mobileBreakpoint),
            'bg-light': item.exactMatch,
            'ext-selected': _ctx.instance?.selection.isSelected(item),
          }])
                  }, [
                    _createElementVNode("div", _hoisted_9, [
                      (_ctx.previewEnabled)
                        ? (_openBlock(), _createBlock(_component_Clickable, {
                            key: 0,
                            class: "position-absolute p-2 top-0 start-0 d-flex ext-icon-preview",
                            style: {"z-index":"1","width":"auto"},
                            title: _ctx.t('Preview', true),
                            disabled: item.inactive,
                            onClick: ($event: any) => (!item.inactive && _ctx.preview(item))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_Icon, {
                                glyph: "preview",
                                lg: ""
                              })
                            ]),
                            _: 2
                          }, 1032, ["title", "disabled", "onClick"]))
                        : _createCommentVNode("", true),
                      (_ctx.componentOptions.selectable)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                            _createElementVNode("input", {
                              type: "checkbox",
                              class: "form-check-input",
                              checked: _ctx.instance?.selection.isSelected(item),
                              onClick: ($event: any) => (_ctx.instance?.selection.toggle(item))
                            }, null, 8, _hoisted_11)
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.widerThanPx(_ctx.componentOptions.mobileBreakpoint))
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 2,
                            class: _normalizeClass([_ctx.previewEnabled || _ctx.componentOptions.selectable ? 'ms-4' : '', 'col']),
                            style: _normalizeStyle({ 'max-width': _ctx.componentOptions.imageSize })
                          }, [
                            _createElementVNode("div", _hoisted_12, [
                              _createVNode(_component_Image, {
                                style: _normalizeStyle({ 'max-width': _ctx.componentOptions.imageSize }),
                                src: (item.images || [])[0]?.url,
                                clickable: _ctx.componentOptions.navigateOnContentClick && !item.inactive,
                                "hide-frame": true,
                                padding: _ctx.componentOptions.imagePadding,
                                onClick: ($event: any) => (_ctx.details(item))
                              }, null, 8, ["style", "src", "clickable", "padding", "onClick"]),
                              (_ctx.componentOptions.imageOverlayTemplate)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                                    _createVNode(_component_VRuntimeTemplate, {
                                      template: _ctx.componentOptions.imageOverlayTemplate,
                                      "template-props": { instance: _ctx.instance, item: item, emit: _ctx.emit, t: _ctx.t }
                                    }, null, 8, ["template", "template-props"])
                                  ]))
                                : _createCommentVNode("", true)
                            ])
                          ], 6))
                        : (_openBlock(), _createElementBlock("div", _hoisted_14, [
                            _createElementVNode("div", _hoisted_15, [
                              _createVNode(_component_Image, {
                                class: "mt-3",
                                style: { 'max-width': '300px' },
                                src: (item.images || [])[0]?.url,
                                clickable: _ctx.componentOptions.navigateOnContentClick && !item.inactive,
                                "hide-frame": true,
                                onClick: ($event: any) => (_ctx.details(item))
                              }, null, 8, ["src", "clickable", "onClick"]),
                              (_ctx.componentOptions.imageOverlayTemplate)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                                    _createVNode(_component_VRuntimeTemplate, {
                                      template: _ctx.componentOptions.imageOverlayTemplate,
                                      "template-props": { instance: _ctx.instance, item: item, emit: _ctx.emit, t: _ctx.t }
                                    }, null, 8, ["template", "template-props"])
                                  ]))
                                : _createCommentVNode("", true)
                            ])
                          ])),
                      _createElementVNode("div", {
                        class: _normalizeClass([_ctx.widerThanPx(_ctx.componentOptions.mobileBreakpoint) ? 'col' : 'col-12 mt-3'])
                      }, [
                        (item.additionalInfo && item.additionalInfo.length)
                          ? (_openBlock(), _createBlock(_component_Tooltip, {
                              key: 0,
                              text: _ctx.additionalInfo(item.additionalInfo)
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_Icon, {
                                  class: "text-warning me-1",
                                  glyph: "warning"
                                })
                              ]),
                              _: 2
                            }, 1032, ["text"]))
                          : _createCommentVNode("", true),
                        (_ctx.componentOptions.titleTemplate)
                          ? (_openBlock(), _createBlock(_component_VRuntimeTemplate, {
                              key: 1,
                              template: _ctx.componentOptions.titleTemplate,
                              "template-props": { instance: _ctx.instance, item, emit: _ctx.emit, details: _ctx.details, attrValue: _ctx.attrValue, t: _ctx.t }
                            }, null, 8, ["template", "template-props"]))
                          : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                              (item.inactive)
                                ? (_openBlock(), _createElementBlock("strong", _hoisted_17, _toDisplayString(_ctx.attrValue(item, '#DisplayName')), 1))
                                : (_openBlock(), _createElementBlock("a", {
                                    key: 1,
                                    href: "#",
                                    onClick: _withModifiers(($event: any) => (_ctx.details(item)), ["prevent"])
                                  }, [
                                    _createElementVNode("strong", null, _toDisplayString(_ctx.attrValue(item, '#DisplayName')), 1)
                                  ], 8, _hoisted_18))
                            ], 64)),
                        (_ctx.componentOptions.showAllIdentifiers)
                          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 3 }, _renderList(_ctx.attrValues(item, '#AllIdentifiers'), (val, index) => {
                              return (_openBlock(), _createElementBlock("div", {
                                class: "small text-muted fw-bold",
                                key: index
                              }, _toDisplayString(val), 1))
                            }), 128))
                          : _createCommentVNode("", true),
                        (_ctx.componentOptions.itemTemplate)
                          ? (_openBlock(), _createBlock(_component_VRuntimeTemplate, {
                              key: 4,
                              template: _ctx.componentOptions.itemTemplate,
                              "template-props": { instance: _ctx.instance, item, emit: _ctx.emit, details: _ctx.details, attrValue: _ctx.attrValue, t: _ctx.t }
                            }, null, 8, ["template", "template-props"]))
                          : (_openBlock(), _createElementBlock("div", _hoisted_19, [
                              _createElementVNode("span", null, _toDisplayString(_ctx.attrValue(item, 'CatalogDescription')), 1)
                            ])),
                        (
                  item.replacements && item.replacements.length && _ctx.componentOptions.showReplacements
                )
                          ? (_openBlock(), _createBlock(_component_PisReplacements, {
                              key: 6,
                              instance: _ctx.instance,
                              replacements: item.replacements,
                              visible: _ctx.replacementsVisible
                            }, null, 8, ["instance", "replacements", "visible"]))
                          : _createCommentVNode("", true)
                      ], 2),
                      (_ctx.componentOptions.additionalColumnTemplate)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 4,
                            class: _normalizeClass([
                _ctx.widerThanPx(_ctx.componentOptions.mobileBreakpoint)
                  ? 'col-' + _ctx.componentOptions.additionalColumnTemplateWidth
                  : 'col-12 mt-3',
              ])
                          }, [
                            _createVNode(_component_VRuntimeTemplate, {
                              template: _ctx.componentOptions.additionalColumnTemplate,
                              "template-props": { instance: _ctx.instance, item, emit: _ctx.emit, details: _ctx.details, attrValue: _ctx.attrValue, t: _ctx.t }
                            }, null, 8, ["template", "template-props"])
                          ], 2))
                        : _createCommentVNode("", true),
                      (!item.inactive && _ctx.componentOptions.actionTemplate)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 5,
                            class: _normalizeClass([
                _ctx.widerThanPx(_ctx.componentOptions.mobileBreakpoint)
                  ? 'col-' + _ctx.componentOptions.actionTemplateWidth
                  : 'col-12 mt-3',
              ])
                          }, [
                            _createVNode(_component_VRuntimeTemplate, {
                              template: _ctx.componentOptions.actionTemplate,
                              "template-props": { instance: _ctx.instance, item, emit: _ctx.emit, details: _ctx.details, attrValue: _ctx.attrValue, t: _ctx.t }
                            }, null, 8, ["template", "template-props"])
                          ], 2))
                        : _createCommentVNode("", true)
                    ])
                  ], 2))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ], 64))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}