
import { defineComponent, onMounted, ref, watch } from 'vue';
import Tooltip from 'bootstrap/js/dist/tooltip';
import { onClickOutside } from '@vueuse/core';

export default defineComponent({
  name: 'Tooltip',

  props: {
    text: String,
    placement: String,
    trigger: String,
    delay: Number,
  },

  setup(props) {
    const tooltip = ref<HTMLElement | null>(null);
    const container = ref(null);
    const wrapper = ref<HTMLElement | null>(null);

    watch(
      () => props.text,
      () => {
        if (!tooltip.value) {
          return;
        }
        const bsTooltipInstance = Tooltip.getInstance(tooltip.value);
        if (bsTooltipInstance) {
          tooltip.value?.setAttribute('data-bs-original-title', props.text || '');
          bsTooltipInstance.setContent();
          bsTooltipInstance.update();
        }
      },
    );

    onMounted(() => {
      new Tooltip(tooltip.value, {
        container: container.value,
        placement: props.placement || 'top',
        trigger: props.trigger || 'hover click',
        title: props.text,
        delay: props.delay || 0,
      });
    });

    const hide = () => {
      const bsTooltipInstance = Tooltip.getInstance(tooltip.value);
      if (bsTooltipInstance) {
        bsTooltipInstance.hide();
      }
    };

    const isMobile = () => {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
      ) {
        return true;
      } else {
        return false;
      }
    };

    onClickOutside(wrapper, () => {
      if (isMobile()) hide();
    });

    return { tooltip, container, wrapper, hide };
  },
});
