
import { computed, defineComponent, PropType, reactive } from 'vue';
import { AttributeValue, ProductAttribute } from '../services/swagger/index.defs';

import AttributeValueTable from './AttributeValueTable.vue';
import InteractiveText from './InteractiveText.vue';
import Icon from './Icon.vue';
import Tooltip from './Tooltip.vue';
import PisLink from './PisLink.vue';
import { TranslateFunc } from '@/products/composables/setupComponent';
import Arrow from '@/common/components/Arrow.vue';
import LinkSecondary from '@/common/components/LinkSecondary.vue';
import { Instance as ProductsInstance } from '@/products/api/Instance';
// import { Instance as CatalogsInstance } from '@/catalogs/api/Instance';
import { IInstance } from '../api/runtime/IInstance';

interface AttributesTableRecord {
  attr: ProductAttribute;
  shouldBeTrimmed: boolean;
  trimmedValues: AttributeValue[];
}

export default defineComponent({
  name: 'AttributesTable',

  props: {
    attributes: {
      type: Object as () => {
        [key: string]: ProductAttribute;
      },
    },
    instance: { type: Object as () => IInstance | undefined, required: true },
    hideDocumentLinks: { type: Boolean, required: true },
    t: { type: Function as PropType<TranslateFunc>, required: true },
  },

  components: {
    Icon,
    Arrow,
    Tooltip,
    PisLink,
    LinkSecondary,
    InteractiveText,
    AttributeValueTable,
  },

  setup(props) {
    const maxAttributeValuesVisible = computed<number>(() => {
      if (props.instance?.instanceType == 'Products') {
        const inst = props.instance as ProductsInstance;
        return inst.store?.options.components?.common?.maxAttributeValuesVisible || Infinity;
      }

      return Infinity;
    });

    const hiddenAttributes = ['#AllIdentifiers', '#DisplayName'];
    const disabledTrimmingMap = reactive({});
    const attrRefs = {};
    const storeAttrRef = (attrCode: string, el: HTMLElement) => {
      attrRefs[attrCode] = el;
    };

    const isDisabledTrimming = (attributeCode: ProductAttribute['attributeCode']) =>
      disabledTrimmingMap[attributeCode];

    const toggleValueTrimming = (attributeCode: ProductAttribute['attributeCode']) => {
      if (disabledTrimmingMap[attributeCode]) {
        delete disabledTrimmingMap[attributeCode];

        if (attrRefs[attributeCode]) {
          attrRefs[attributeCode].scrollIntoView();
        }
      } else {
        disabledTrimmingMap[attributeCode] = true;
      }
    };

    const records = computed(() =>
      Object.values(props.attributes || {}).reduce(
        (allRecords: AttributesTableRecord[], currentAttribute: ProductAttribute) => {
          if (!hiddenAttributes.includes(currentAttribute.attributeCode)) {
            const values = currentAttribute.values || [];
            const shouldBeTrimmed =
              (currentAttribute.values?.length || 0) > maxAttributeValuesVisible.value;
            allRecords.push({
              shouldBeTrimmed,
              attr: currentAttribute,
              trimmedValues: isDisabledTrimming(currentAttribute.attributeCode)
                ? values
                : values.slice(0, maxAttributeValuesVisible.value),
            });
          }
          return allRecords;
        },
        [],
      ),
    );

    return {
      records,
      storeAttrRef,
      isDisabledTrimming,
      toggleValueTrimming,
    };
  },
});
