
import { defineComponent } from 'vue';
import { DynamicScroller, DynamicScrollerItem } from 'vue-virtual-scroller';

export default defineComponent({
  components: {
    DynamicScroller,
    DynamicScrollerItem,
  },

  props: {
    items: { type: Array, required: true },
    height: { type: String, default: '60vh' },
    keyField: { type: String, default: 'name' },
  },
});
