
import { defineComponent, PropType } from 'vue';
import { ClassificationNode } from '../services/swagger/index.defs';
import Removable from '@/common/components/Removable.vue';

export default defineComponent({
  components: {
    Removable,
  },

  props: {
    categories: Array as PropType<ClassificationNode[]>,
  },

  emits: ['click-item', 'remove-item'],
});
