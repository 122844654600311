
import { computed, defineComponent, ref, watch } from 'vue';
import InjectStyles from '@/common/components/InjectStyles.vue';
import { setupComponent } from '@/classifications/composables/setupComponent';
import { ComponentName } from '@/classifications/api/configuration/components/ComponentName';
import { CatalogProductSwitchDisplayModeOption } from '@/catalogs/api/configuration/components/ICatalogProductSwitchOptions';
import {
  ClassificationsBrowserTreeTypeEnum,
  ClassificationsBrowserTreeViewEnum,
} from '@/common/services/swagger/index.defs';
import Icon from '@/common/components/Icon.vue';
import { ChangeTreeTypeCommand } from '@/classifications/api/runtime/CommandExecutor';
import { ITreeTypeTabsOptions } from '@/classifications/api/configuration/components/ITreeTypeTabsOptions';

export default defineComponent({
  name: ComponentName.treeTypeTabs,
  components: {
    Icon,
    InjectStyles,
  },
  props: {
    instanceId: String,
  },

  setup(props) {
    const root = ref<HTMLElement>();
    const { componentName, instance, isReady, isWebComponent, store, t, narrowerThan } =
      setupComponent(root, props.instanceId);

    const translationMap: Record<ClassificationsBrowserTreeViewEnum, string> = {
      [ClassificationsBrowserTreeViewEnum.Offering]: 'OfferingTree',
      [ClassificationsBrowserTreeViewEnum.ProductLine]: 'ProductLineTree',
      [ClassificationsBrowserTreeViewEnum.Favorites]: 'MyFavorites',
    };

    const getCount = (mode: ClassificationsBrowserTreeViewEnum) => {
      switch (mode) {
        case ClassificationsBrowserTreeViewEnum.Offering:
          return (
            instance.value?.selection.selectedItems.filter(
              (a) => a.tree == ClassificationsBrowserTreeTypeEnum.Offering,
            ).length ?? 0
          );
        case ClassificationsBrowserTreeViewEnum.ProductLine:
          return (
            instance.value?.selection.selectedItems.filter(
              (a) => a.tree == ClassificationsBrowserTreeTypeEnum.ProductLine,
            ).length ?? 0
          );
        case ClassificationsBrowserTreeViewEnum.Favorites:
          return instance.value?.favorites.selectedItems.length ?? 0;
      }
    };

    const componentOptions = computed<ITreeTypeTabsOptions | undefined>(
      () => store.value?.options.components?.treeTypeTabs ?? ({} as ITreeTypeTabsOptions),
    );

    const routeView = computed(() => instance.value?.router.routeData.view);

    const allowedModes = computed(() => {
      return componentOptions.value?.displayModes;
    });

    const switchToView = async (mode: ClassificationsBrowserTreeViewEnum) => {
      try {
        await instance.value?.execute(new ChangeTreeTypeCommand({ view: mode }));
      } catch (error) {
        // Ignore error
      }
    };

    const visible = computed(() => componentOptions.value?.visible);

    watch(
      () => componentOptions.value?.defaultDisplayMode,
      async (displayMode: ClassificationsBrowserTreeViewEnum | undefined) => {
        if (displayMode) {
          await switchToView(displayMode);
        }
      },
    );

    return {
      root,
      visible,
      store,
      isReady,
      routeView,
      allowedModes,
      componentName,
      isWebComponent,
      CatalogProductSwitchDisplayModeOption,
      translationMap,
      componentOptions,
      t,
      switchToView,
      narrowerThan,
      getCount,
    };
  },
});
