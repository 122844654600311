
import { computed, defineComponent, ref } from 'vue';
import { ComponentName } from '@/products/api/configuration/components/ComponentName';
import { setupComponent } from '@/products/composables/setupComponent';

import InjectStyles from '@/common/components/InjectStyles.vue';
import VRuntimeTemplate from 'vue3-runtime-template';
import { IDetailsOptions } from '@/products/api/configuration/components/IDetailsOptions';

export default defineComponent({
  name: ComponentName.detailsActions,

  components: {
    InjectStyles,
    VRuntimeTemplate,
  },

  props: {
    instanceId: String,
  },

  setup(props) {
    const root = ref<HTMLElement>();

    const { instance, componentName, isReady, isWebComponent, store, shadowRoot, t, emit } =
      setupComponent(root, props.instanceId, []);
    const item = computed(() => store.value?.data.productDetail?.item);
    const componentOptions = computed(
      () => store.value?.options.components?.details ?? ({} as IDetailsOptions)
    );
    return {
      root,
      componentName,
      isReady,
      isWebComponent,
      store,
      instance,
      item,
      componentOptions,
      shadowRoot,
      emit,
      t,
    };
  },
});
