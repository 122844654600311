import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex-fill"
}
const _hoisted_2 = { class: "position-relative" }
const _hoisted_3 = { class: "col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Image = _resolveComponent("Image")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(_component_Card, {
    class: _normalizeClass([_ctx.useCardMarkup ? 'mb-4' : 'mb-5']),
    "use-markup": _ctx.useCardMarkup
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "before"),
      _createElementVNode("div", {
        class: _normalizeClass(["row align-items-center mt-2", [_ctx.$slots.before ? 'ms-3' : '']])
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["mb-lg-2", { col: _ctx.wide, 'col-12': !_ctx.wide }]),
          style: _normalizeStyle({ 'max-width': _ctx.wide ? _ctx.imageWidth : '100%' })
        }, [
          (!_ctx.hideImage)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_Image, {
                    style: { width: '100%' },
                    class: "flex-fill",
                    src: _ctx.imageSrc,
                    "is-loading": _ctx.imageLoading,
                    clickable: _ctx.imageClickable,
                    "hide-frame": true,
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click-content')))
                  }, null, 8, ["src", "is-loading", "clickable"]),
                  _renderSlot(_ctx.$slots, "imageOverlay")
                ])
              ]))
            : _createCommentVNode("", true)
        ], 6),
        _createElementVNode("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "content")
        ]),
        (_ctx.$slots.additionalColumn)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["mt-lg-2", ['col-lg-' + _ctx.additionalColumnTemplateWidth]])
            }, [
              _createElementVNode("div", {
                class: _normalizeClass([_ctx.wide ? 'ms-auto' : 'mt-3'])
              }, [
                _renderSlot(_ctx.$slots, "additionalColumn")
              ], 2)
            ], 2))
          : _createCommentVNode("", true),
        (!_ctx.inactive && _ctx.$slots.after)
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: _normalizeClass(["mt-lg-2", ['col-lg-' + _ctx.actionTemplateWidth]])
            }, [
              _createElementVNode("div", {
                class: _normalizeClass([_ctx.wide ? 'ms-auto text-end' : 'mt-3'])
              }, [
                _renderSlot(_ctx.$slots, "after")
              ], 2)
            ], 2))
          : _createCommentVNode("", true)
      ], 2)
    ]),
    _: 3
  }, 8, ["class", "use-markup"]))
}