
import { computed, defineComponent, PropType, Ref, ref } from 'vue';
import { TranslateFunc } from '@/catalogs/composables/setupComponent';
import { CatalogProductItem } from '@/common/services/swagger/index.defs';
import DropdownPopper from '@/common/components/DropdownPopper.vue';
import { CatalogProductsCommand } from '@/catalogs/api/runtime/CommandExecutor';
import PIS from '@/common/api/PIS';
import { Instance } from '@/catalogs/api/Instance';
import ItemConfiguredOptions from './ItemConfiguredOptions.vue';
import Icon from '@/common/components/Icon.vue';
import { ComponentName } from '@/catalogs/api/configuration/components/ComponentName';
import useCatalogActions from '@/catalogs/composables/useCatalogActions';
import VRuntimeTemplate from 'vue3-runtime-template';
import { IMenuItem } from '@/catalogs/api/configuration/components/IExportMenuOptions';

export default defineComponent({
  name: 'ItemCatalogs.vue',
  emits: ['navigate'],
  components: { DropdownPopper, ItemConfiguredOptions, Icon, VRuntimeTemplate },
  props: {
    item: { type: Object as PropType<CatalogProductItem>, required: true },
    t: { type: Function as PropType<TranslateFunc>, required: true },
    allCatalogs: { type: Boolean, default: false },
    instanceId: String,
  },
  setup(props, { emit }) {
    const instance = PIS.Catalogs.getInstance(props.instanceId) as Instance;
    const { onProductItemMenuAction } = useCatalogActions(ref(instance) as Ref<Instance>, props.t);

    const details = async (catalogId: string) => {
      if (catalogId) {
        try {
          emit('navigate');
          await instance.execute(new CatalogProductsCommand({ catalogId }));
        } catch (error) {
          // Ignore error
        }
      }
    };

    const catalogProductItemMenu = computed(
      () => instance.store?.options.components?.actions?.productItemMenu,
    );

    const productItemMenuItems = (product: CatalogProductItem): IMenuItem<CatalogProductItem>[] =>
      (catalogProductItemMenu.value?.items || []).filter((item: IMenuItem<CatalogProductItem>) => {
        if (typeof item.visible === 'function') {
          return item.visible(product);
        }
        return item.visible;
      });

    const isDisabled = (item: IMenuItem<CatalogProductItem>, product: CatalogProductItem) =>
      typeof item.disabled === 'function' ? item.disabled(product) : item.disabled;

    const actionsEnabled = computed(
      () =>
        instance.registeredComponents.has(ComponentName.catalogProductsActionModals) &&
        catalogProductItemMenu.value?.visible,
    );

    return {
      instance,
      actionsEnabled,
      catalogProductItemMenu,
      emit,
      details,
      isDisabled,
      productItemMenuItems,
      onProductItemMenuAction,
    };
  },
});
