import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["part"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InjectStyles = _resolveComponent("InjectStyles")!
  const _component_AddToCatalogModal = _resolveComponent("AddToCatalogModal")!
  const _component_ProductActionErrors = _resolveComponent("ProductActionErrors")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "root",
    class: "pis-component position-relative",
    part: _ctx.componentName
  }, [
    (_ctx.isReady)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.isWebComponent)
            ? (_openBlock(), _createBlock(_component_InjectStyles, {
                key: 0,
                items: _ctx.store?.options.styles
              }, null, 8, ["items"]))
            : _createCommentVNode("", true),
          (_ctx.currentActionName === _ctx.ProductAction.addToCatalog)
            ? (_openBlock(), _createBlock(_component_AddToCatalogModal, {
                key: 1,
                t: _ctx.t,
                "instance-id": _ctx.instanceId,
                data: (_ctx.currentActionData as ProductAddToCatalogActionData),
                "onUpdate:show": _ctx.onActionCompleted
              }, null, 8, ["t", "instance-id", "data", "onUpdate:show"]))
            : (_ctx.currentActionName === _ctx.ProductAction.errors)
              ? (_openBlock(), _createBlock(_component_ProductActionErrors, {
                  key: 2,
                  t: _ctx.t,
                  "instance-id": _ctx.instanceId,
                  errors: (_ctx.currentActionData as ProductErrorsActionData).errors,
                  warnings: (_ctx.currentActionData as ProductErrorsActionData).warnings,
                  "onUpdate:show": _ctx.onActionCompleted
                }, null, 8, ["t", "instance-id", "errors", "warnings", "onUpdate:show"]))
              : _createCommentVNode("", true)
        ], 64))
      : _createCommentVNode("", true)
  ], 8, _hoisted_1))
}