
import { computed, defineComponent, PropType } from 'vue';
import Icon from '@/common/components/Icon.vue';
import { INotification } from '@/common/api/runtime/INotification';

enum NotificationTypeToIcon {
  success = 'check-mark',
  info = 'info',
  warning = 'warning-circle',
  danger = 'error-circle',
}

export default defineComponent({
  name: 'Alert',
  components: { Icon },
  props: {
    notification: { type: Object as PropType<INotification>, required: true },
  },
  emits: ['closed'],
  setup(props, { emit }) {
    const closeAlert = () => {
      emit('closed', true);
    };

    const icon = computed(() => {
      return NotificationTypeToIcon[props.notification.type];
    });

    return { alert, closeAlert, icon };
  },
});
