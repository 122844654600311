import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, toHandlers as _toHandlers, mergeProps as _mergeProps, renderList as _renderList, Fragment as _Fragment, vModelSelect as _vModelSelect, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withDirectives as _withDirectives, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = ["part"]
const _hoisted_2 = {
  key: 0,
  class: "mb-4"
}
const _hoisted_3 = {
  key: 0,
  class: "text-center"
}
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 2 }
const _hoisted_6 = ["textContent", "value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InjectStyles = _resolveComponent("InjectStyles")!
  const _component_Clickable = _resolveComponent("Clickable")!
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_FormInputClearable = _resolveComponent("FormInputClearable")!
  const _component_Btn = _resolveComponent("Btn")!
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "root",
    class: _normalizeClass(["pis-component", _ctx.componentName]),
    part: _ctx.componentName
  }, [
    (_ctx.isReady && _ctx.visible)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.isWebComponent)
            ? (_openBlock(), _createBlock(_component_InjectStyles, {
                key: 0,
                items: _ctx.store?.options.styles
              }, null, 8, ["items"]))
            : _createCommentVNode("", true),
          _createVNode(_component_Dropdown, {
            open: _ctx.isAutocompleteVisible,
            "onUpdate:open": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.isAutocompleteVisible) = $event)),
            "min-width": "340px",
            items: _ctx.autocomplete.items
          }, {
            item: _withCtx(({ item }) => [
              _createVNode(_component_Clickable, {
                class: "dropdown-item",
                onClick: ($event: any) => (_ctx.searchText(item.text))
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", null, _toDisplayString(item.text), 1)
                ]),
                _: 2
              }, 1032, ["onClick"])
            ]),
            footer: _withCtx(() => [
              (_ctx.isAutocompleteLoading)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createVNode(_component_Spinner, { sm: "" })
                  ]))
                : (_ctx.autocomplete.totalCount)
                  ? (_openBlock(), _createElementBlock("small", _hoisted_4, _toDisplayString(_ctx.t('ShowingNOfNMatches', false, [
                `${_ctx.autocomplete.visibleMatchCount || 0}`,
                `${_ctx.autocomplete.totalCount || 0}`,
              ])), 1))
                  : (_openBlock(), _createElementBlock("small", _hoisted_5, _toDisplayString(_ctx.t('NoResults')), 1))
            ]),
            activator: _withCtx(({ on }) => [
              _createElementVNode("form", {
                onSubmit: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.onSearch()), ["prevent"])),
                class: _normalizeClass([_ctx.widerThan('sm') && 'input-group flex-nowrap'])
              }, [
                _createVNode(_component_FormInputClearable, _mergeProps({
                  modelValue: _ctx.searchModel.text,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchModel.text) = $event))
                }, _toHandlers(on), {
                  "class-inner": _ctx.widerThan('sm') ? 'border-end-0' : 'mb-1',
                  lg: _ctx.widerThan('sm'),
                  placeholder: _ctx.t('SearchCatalogsInputPlaceholder'),
                  "onUpdate:modelValue": _ctx.onSearchInput,
                  onClear: _ctx.onClear,
                  onFocus: _ctx.openAutocomplete
                }), null, 16, ["modelValue", "class-inner", "lg", "placeholder", "onUpdate:modelValue", "onClear", "onFocus"]),
                _withDirectives(_createElementVNode("select", {
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.searchModel.type) = $event)),
                  class: _normalizeClass(["form-select px-2", [_ctx.narrowerThan('sm') && 'mb-1']]),
                  style: _normalizeStyle({
                'min-width': _ctx.widerThan('sm') ? '200px' : '100%',
                'max-width': _ctx.widerThan('sm') ? '300px' : '100%',
              })
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.searchTypeOptions, (searchType) => {
                    return (_openBlock(), _createElementBlock("option", {
                      textContent: _toDisplayString(_ctx.t(searchType.translate)),
                      key: searchType.value,
                      value: searchType.value
                    }, null, 8, _hoisted_6))
                  }), 128))
                ], 6), [
                  [_vModelSelect, _ctx.searchModel.type]
                ]),
                _createVNode(_component_Btn, {
                  "aria-label": _ctx.t('SearchButtonText'),
                  icon: "search",
                  class: _normalizeClass([_ctx.narrowerThan('sm') && 'w-100 d-flex justify-content-center'])
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString(_ctx.t('SearchButtonText')), 1)
                  ]),
                  _: 1
                }, 8, ["aria-label", "class"])
              ], 34)
            ]),
            _: 1
          }, 8, ["open", "items"])
        ]))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}