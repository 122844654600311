
import { computed, defineComponent, ref } from 'vue';
import { ClassificationItem, TreeTypeEnum } from '@/common/services/swagger/index.defs';
import { ComponentName } from '../api/configuration/components/ComponentName';
import { ClassificationCommand } from '../api/runtime/CommandExecutor';
import { setupComponent } from '../composables/setupComponent';

import InjectStyles from '@/common/components/InjectStyles.vue';
import Btn from '@/common/components/Btn.vue';
import CategoriesList from '@/common/components/CategoriesList.vue';
import Modal from '@/common/components/Modal.vue';
import FiltersNavigatorModal from '@/catalogs/components/common/FiltersNavigatorModal.vue';

import { DataType } from '../api/configuration/application/DataType';

export default defineComponent({
  name: ComponentName.mobileNav,

  components: {
    InjectStyles,
    Btn,
    Modal,
    CategoriesList,
    FiltersNavigatorModal,
  },

  props: {
    instanceId: String,
  },

  setup(props) {
    const root = ref<HTMLElement>();

    const categoriesModalOpen = ref(false);
    const partCategoriesModalOpen = ref(false);
    const filtersModalOpen = ref(false);

    const { componentName, isWebComponent, isReady, instance, store, t, useProductsFilters } =
      setupComponent(root, props.instanceId, [DataType.Breadcrumbs]);

    const {
      filtersAll,
      filtersPromoted,
      filtersAdditional,
      filtersModel,
      filtersFetched,
      filteredResultCount,
      fetchFilterValues,
      applyModelFilters,
      refreshResultCount,
      resetFiltersApplied,
    } = useProductsFilters();

    const categoriesShown = computed(
      () =>
        instance.value?.registeredComponents.has(ComponentName.searchProductCategories) &&
        store.value?.options.components?.searchProductCategories?.visible,
    );

    const partCategoriesShown = computed(
      () =>
        instance.value?.registeredComponents.has(ComponentName.searchPartCategories) &&
        store.value?.options.components?.searchPartCategories?.visible,
    );

    const filtersShown = computed(() =>
      instance.value?.registeredComponents.has(ComponentName.searchFilters),
    );

    const categories = computed(() => store.value?.data.productClassifications?.items ?? []);
    const partCategories = computed(() => store.value?.data.partClassifications?.items ?? []);

    const columnsNumber = computed(() => {
      const itemsNumber = [
        categoriesShown.value,
        partCategoriesShown.value,
        filtersShown.value,
      ].filter(Boolean).length;

      return itemsNumber > 3 ? 3 : itemsNumber; // 3 or less columns
    });

    const applyFilters = () => {
      filtersModalOpen.value = false;
      applyModelFilters();
    };

    const onModalFilterValueChange = ({ attributeCode, filterValueName, value }) => {
      filtersModel.value[attributeCode][filterValueName] = value;
      refreshResultCount();
    };

    const setCategory = async ({ cid }: ClassificationItem) => {
      if (instance.value && cid) {
        categoriesModalOpen.value = false;
        try {
          await instance.value.execute(
            new ClassificationCommand({ cid, clearSearch: false, treeType: TreeTypeEnum.Products }),
          );
        } catch (error) {
          // Ignore Error
        }
      }
    };

    const setPartCategory = async ({ cid }: ClassificationItem) => {
      if (instance.value && cid) {
        partCategoriesModalOpen.value = false;
        try {
          await instance.value.execute(
            new ClassificationCommand({ cid, clearSearch: false, treeType: TreeTypeEnum.Parts }),
          );
        } catch (error) {
          // Ignore Error
        }
      }
    };

    return {
      root,
      componentName,
      isWebComponent,
      isReady,
      store,
      columnsNumber,
      t,
      instance,

      // Categories
      categoriesModalOpen,
      categories,
      categoriesShown,
      setCategory,

      // Part Categories
      partCategoriesModalOpen,
      partCategories,
      partCategoriesShown,
      setPartCategory,

      // Filters
      filtersModalOpen,
      filtersShown,
      filtersAll,
      filtersPromoted,
      filtersAdditional,
      filtersModel,
      filtersFetched,
      filteredResultCount,
      onModalFilterValueChange,
      fetchFilterValues,
      resetFiltersApplied,
      applyFilters,
    };
  },
});
