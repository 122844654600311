import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["part"]
const _hoisted_2 = { class: "mb-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InjectStyles = _resolveComponent("InjectStyles")!
  const _component_CatalogEditModal = _resolveComponent("CatalogEditModal")!
  const _component_CatalogCopyModal = _resolveComponent("CatalogCopyModal")!
  const _component_CatalogImportModal = _resolveComponent("CatalogImportModal")!
  const _component_CatalogExportModal = _resolveComponent("CatalogExportModal")!
  const _component_CatalogCreateNewModal = _resolveComponent("CatalogCreateNewModal")!
  const _component_CatalogHistoryModal = _resolveComponent("CatalogHistoryModal")!
  const _component_Confirmation = _resolveComponent("Confirmation")!
  const _component_CatalogActionErrors = _resolveComponent("CatalogActionErrors")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "root",
    class: "pis-component position-relative",
    part: _ctx.componentName
  }, [
    (_ctx.isReady)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.isWebComponent)
            ? (_openBlock(), _createBlock(_component_InjectStyles, {
                key: 0,
                items: _ctx.store?.options.styles
              }, null, 8, ["items"]))
            : _createCommentVNode("", true),
          (_ctx.currentActionName === _ctx.CatalogAction.edit)
            ? (_openBlock(), _createBlock(_component_CatalogEditModal, {
                key: 1,
                t: _ctx.t,
                "instance-id": _ctx.instanceId,
                data: (_ctx.currentActionData as CatalogEditActionData),
                "onUpdate:show": _ctx.onActionCompleted
              }, null, 8, ["t", "instance-id", "data", "onUpdate:show"]))
            : (_ctx.currentActionName === _ctx.CatalogAction.copy)
              ? (_openBlock(), _createBlock(_component_CatalogCopyModal, {
                  key: 2,
                  t: _ctx.t,
                  "instance-id": _ctx.instanceId,
                  data: (_ctx.currentActionData as CatalogCopyActionData),
                  "onUpdate:show": _ctx.onActionCompleted
                }, null, 8, ["t", "instance-id", "data", "onUpdate:show"]))
              : (_ctx.currentActionName === _ctx.CatalogAction.import)
                ? (_openBlock(), _createBlock(_component_CatalogImportModal, {
                    key: 3,
                    t: _ctx.t,
                    "instance-id": _ctx.instanceId,
                    data: (_ctx.currentActionData as CatalogImportActionData),
                    "onUpdate:show": _ctx.onActionCompleted
                  }, null, 8, ["t", "instance-id", "data", "onUpdate:show"]))
                : (_ctx.currentActionName === _ctx.CatalogAction.export)
                  ? (_openBlock(), _createBlock(_component_CatalogExportModal, {
                      key: 4,
                      t: _ctx.t,
                      "instance-id": _ctx.instanceId,
                      data: (_ctx.currentActionData as CatalogExportActionData),
                      "onUpdate:show": _ctx.onActionCompleted
                    }, null, 8, ["t", "instance-id", "data", "onUpdate:show"]))
                  : (_ctx.currentActionName === _ctx.CatalogAction.createNew)
                    ? (_openBlock(), _createBlock(_component_CatalogCreateNewModal, {
                        key: 5,
                        t: _ctx.t,
                        "instance-id": _ctx.instanceId,
                        "onUpdate:show": _ctx.onActionCompleted
                      }, null, 8, ["t", "instance-id", "onUpdate:show"]))
                    : (_ctx.currentActionName === _ctx.CatalogAction.history)
                      ? (_openBlock(), _createBlock(_component_CatalogHistoryModal, {
                          key: 6,
                          t: _ctx.t,
                          "instance-id": _ctx.instanceId,
                          "onUpdate:show": _ctx.onActionCompleted
                        }, null, 8, ["t", "instance-id", "onUpdate:show"]))
                      : (_ctx.currentActionName === _ctx.CatalogAction.delete)
                        ? (_openBlock(), _createBlock(_component_Confirmation, {
                            key: 7,
                            t: _ctx.t,
                            title: _ctx.t('DeleteCatalog'),
                            item: (_ctx.currentActionData as CatalogDeleteActionData).catalog,
                            "onUpdate:show": _ctx.onActionCompleted,
                            onConfirmationCancelled: _ctx.onActionCompleted,
                            onConfirmationOk: _ctx.onCatalogDelete
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_2, [
                                _createTextVNode(_toDisplayString(_ctx.t('CatalogConfirmDelete')) + ": ", 1),
                                _createElementVNode("strong", null, _toDisplayString((_ctx.currentActionData as CatalogDeleteActionData).catalog.code), 1)
                              ])
                            ]),
                            _: 1
                          }, 8, ["t", "title", "item", "onUpdate:show", "onConfirmationCancelled", "onConfirmationOk"]))
                        : (_ctx.currentActionName === _ctx.CatalogAction.errors)
                          ? (_openBlock(), _createBlock(_component_CatalogActionErrors, {
                              key: 8,
                              t: _ctx.t,
                              "instance-id": _ctx.instanceId,
                              errors: (_ctx.currentActionData as CatalogErrorsActionData).errors,
                              warnings: (_ctx.currentActionData as CatalogErrorsActionData).warnings
                            }, null, 8, ["t", "instance-id", "errors", "warnings"]))
                          : _createCommentVNode("", true)
        ], 64))
      : _createCommentVNode("", true)
  ], 8, _hoisted_1))
}