
import { defineComponent } from 'vue';
import Badge from './Badge.vue';
import Icon from './Icon.vue';
import Spinner from './Spinner.vue';

export default defineComponent({
  name: 'Btn',

  components: {
    Icon,
    Badge,
    Spinner,
  },

  props: {
    link: Boolean,
    href: String,
    lg: Boolean,
    icon: String,
    vertical: Boolean,
    number: Number,
    isLoading: Boolean,
    disabled: { type: Boolean, default: false },
    primary: { type: Boolean, default: false },
  },
});
