
import { defineComponent } from 'vue';
import { Link, LinkTypeEnum, ProductItem, SearchTypeEnum } from '../services/swagger/index.defs';
import {
  ClassificationCommand,
  DetailsCommand,
  DocumentCommand,
  SearchCommand,
} from '@/products/api/runtime/CommandExecutor';
import { IInstance } from '../api/runtime/IInstance';

export default defineComponent({
  name: 'PisLink',

  props: {
    link: { type: Object as () => Link, required: true },
    instance: { type: Object as () => IInstance | undefined, required: true },
    clearSearch: { type: Boolean, default: false },
    onBeforeClick: { type: Function, required: false },
  },

  setup(props) {
    const onLinkClick = async () => {
      if (props.onBeforeClick) {
        try {
          props.onBeforeClick();
        } catch (error) {
          // Ignore error
        }
      }
      const link = props.link;
      switch (link.type) {
        case LinkTypeEnum.None:
          break;
        case LinkTypeEnum.Classification:
          if (link.cid) {
            try {
              await props.instance?.execute(
                new ClassificationCommand({ cid: link.cid, clearSearch: props.clearSearch }),
              );
            } catch (error) {
              // Ignore Error
            }
          }
          break;
        case LinkTypeEnum.Detail:
          if (link.productId) {
            try {
              await props.instance?.execute(
                new DetailsCommand({ productId: link.productId } as ProductItem),
              );
            } catch (error) {
              // Ignore Error
            }
          }
          break;
        case LinkTypeEnum.Document:
          if (link.documentId) {
            try {
              await props.instance?.execute(new DocumentCommand({ documentId: link.documentId }));
            } catch (error) {
              // Ignore Error
            }
          }
          break;
        case LinkTypeEnum.Search:
          if (link.searchText) {
            try {
              await props.instance?.execute(
                new SearchCommand({
                  searchText: link.searchText,
                  searchType: SearchTypeEnum.ExactPhrase,
                }),
              );
            } catch (error) {
              // Ignore Error
            }
          }
          break;
        case LinkTypeEnum.TechnicalInformation:
          break;
      }
    };

    return { onLinkClick };
  },
});
