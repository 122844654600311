import {
  CatalogActionResult,
  CatalogItem,
  CatalogOptionsResult,
  CatalogProductAddItem,
  CatalogTypeEnum,
  FilterValueSearchResult,
  PagerSettings,
  PrmFilterSettings,
  PrmSearchSettings,
  ProductItem,
} from './../../../common/services/swagger/index.defs';
import {
  ClassificationNodesSearchResult,
  ConfiguratorItem,
  FavoriteView,
  FavoriteViewData,
  FeedbackSettings,
  FilterSettings,
  PartTypeEnum,
  SearchTypeEnum,
  SortFieldEnum,
} from '@/common/services/swagger/index.defs';
import { Instance } from '../Instance';
import { DisplayMode, IRouteData } from './IRouteData';
import _cloneDeep from 'lodash/cloneDeep';
import _omit from 'lodash/omit';
import _remove from 'lodash/remove';
import { IExportMenuItem } from '../configuration/components/IExportMenuOptions';
import { defaultRoute } from '../configuration/DefaultRoute';
import { TreeTypeEnum } from '@/common/services/swagger/index.defs';
import {
  SearchFavoriteViewsAppliedData,
  SearchFavoriteViewsDeletedData,
  SearchFavoriteViewsEvent,
  SearchFavoriteViewsLoadedData,
  SearchFavoriteViewsSavedData,
} from '../configuration/events/SearchFavoriteViewsEvents';
import { downloadFile } from '@/common/helpers/downloadFile';
import { ICommand } from '@/common/api/runtime/ICommand';
import { INotification, NotificationType } from '@/common/api/runtime/INotification';
import { clearSavedData } from '@/common/composables/prmFiltersStorage';
import { ProductAction } from './ProductAction';
import {
  ProductAnyActionData,
  ProductAddToCatalogActionData,
  ProductErrorsActionData,
} from './ProductActionData';

export abstract class Command<TArgs> implements ICommand<Instance> {
  isHandled = false;
  constructor(public args: TArgs) {}
  abstract execute: (instance: Instance) => Promise<unknown>;
  abstract name: string;
}

const executePlaceholder = (name: string) => {
  alert(`Command "${name}" should be handled by application`);
  return Promise.resolve();
};

type ProductAnyActionResult = {
  success?: CatalogActionResult['success'];
  message?: CatalogActionResult['message'];
  errors?: CatalogActionResult['errors'];
  warnings?: CatalogActionResult['warnings'];
};

interface ProductAnyActionCommandArgs {
  onSuccessMessage: string;
  onErrorMessage?: string;
}

class HandledError extends Error {
  public isHandled = true;
}

const handleResponse = async <R extends ProductAnyActionResult>(
  instance: Instance,
  args: ProductAnyActionCommandArgs,
  result: Promise<R | undefined> | undefined,
): Promise<R | undefined> | never => {
  if (!result) {
    instance.store.data.isLoadingData = false;
    return;
  }
  try {
    instance.store.data.isLoadingData = true;
    const response: ProductAnyActionResult | undefined = await result;
    instance.store.data.isLoadingData = false;
    if (!response || !response.success) {
      if (response?.errors?.length) {
        await instance.execute(
          new ProductErrorsActionCommand({
            errors: response.errors || [],
            warnings: response.warnings || [],
          }),
        );
        throw new HandledError(response?.message || 'Unknown error');
      }
      throw new Error(response?.message || 'Unknown error');
    }
    await instance.execute(
      new AddNotificationCommand({
        type: NotificationType.success,
        message: args.onSuccessMessage,
      }),
    );
    return response as R;
  } catch (error) {
    instance.store.data.isLoadingData = false;
    if (!(error instanceof HandledError)) {
      await instance.execute(
        new AddNotificationCommand({ type: NotificationType.danger, message: error.message }),
      );
    }
    throw error;
  }
};

export class SearchCommand extends Command<{
  searchText: string;
  searchType: SearchTypeEnum;
  resetCategories?: boolean;
  resetFilters?: boolean;
  cid?: string;
}> {
  name = 'filter-search';

  constructor(args: {
    searchText: string;
    searchType: SearchTypeEnum;
    resetCategories?: boolean;
    resetFilters?: boolean;
    cid?: string;
  }) {
    super(args);
  }

  public execute = async (instance: Instance): Promise<unknown> => {
    const routeData = {
      view: 'search',
      sortField: this.args.searchText ? SortFieldEnum.Relevance : SortFieldEnum.Product,
      searchType: this.args.searchType,
      searchText: this.args.searchText.length == 0 ? undefined : this.args.searchText,
      pagination: { page: 1 },
    } as IRouteData;

    if (this.args.resetCategories) {
      routeData.cid = undefined;
    }

    if (this.args.resetFilters) {
      routeData.filters = undefined;
    }

    if (this.args.cid) {
      routeData.cid = this.args.cid;
    }

    return await instance.router?.setRoute(routeData);
  };
}

export type DetailsCommandArgs = Pick<ProductItem, 'productId'>;

export class DetailsCommand extends Command<DetailsCommandArgs> {
  name = 'details';

  constructor(args: DetailsCommandArgs) {
    super(args);
  }

  public execute = async (instance: Instance): Promise<unknown> => {
    const data = { productId: this.args.productId };
    instance.store.data.productPreview = undefined;
    return await instance.router?.setRoute({
      view: 'detail',
      ...data,
    } as IRouteData);
  };
}

export class PreviewCommand extends Command<{ productId: string }> {
  name = 'preview';

  constructor(args: { productId: string }) {
    super(args);
  }

  public execute = async (instance: Instance): Promise<unknown> => {
    return await instance.httpService?.preview(this.args.productId);
  };
}

export class ScrollToTopCommand extends Command<{ productId: string }> {
  name = 'scroll-to-top';

  constructor(args: { productId: string }) {
    super(args);
  }

  public execute = async (instance: Instance): Promise<unknown> => {
    instance.eventBus.emit('internal:scroll-to-top');
    return Promise.resolve();
  };
}

export class DocumentCommand extends Command<{ documentId: string }> {
  name = 'document';

  constructor(args: { documentId: string }) {
    super(args);
  }

  public execute = async (): Promise<unknown> => executePlaceholder(this.name);
}

export class ConfiguratorCommand extends Command<ConfiguratorItem> {
  name = 'configurator';

  constructor(args: ConfiguratorItem) {
    super(args);
  }

  public execute = async (): Promise<unknown> => executePlaceholder(this.name);
}

export class BackCommand extends Command<{ step: number }> {
  name = 'back';

  constructor(args: { step: number } = { step: 1 }) {
    super(args);
  }

  public execute = async (instance: Instance): Promise<unknown> => {
    if (instance.router?.history.length == 0) {
      instance.router?.setRoute(defaultRoute);
      clearSavedData();
    } else {
      await instance.router?.back(this.args.step);
      if (instance.router?.routeData.view !== 'detail') {
        clearSavedData();
      }
      return;
    }
  };
}

export class SearchViewCommand extends Command<void> {
  name = 'search-view';

  constructor() {
    super();
  }

  public execute = async (instance: Instance): Promise<unknown> =>
    instance.router?.setRoute(defaultRoute);
}

export class DisplayModeCommand extends Command<{ mode: DisplayMode }> {
  name = 'display-mode';

  constructor(args: { mode: DisplayMode }) {
    super(args);
  }

  public execute = async (instance: Instance): Promise<unknown> => {
    return await instance.router?.setRoute({ displayMode: this.args.mode } as never, true);
  };
}

export class ClassificationCommand extends Command<{ cid: string; clearSearch: boolean }> {
  name = 'filter-classification';

  constructor(args: { cid: string; clearSearch: boolean }) {
    super(args);
  }

  public execute = async (instance: Instance): Promise<unknown> => {
    const route = instance.getRouteData();

    route.view = 'search';
    route.sortField = SortFieldEnum.Product;
    if (route.pagination) {
      route.pagination.page = 1;
    }

    route.cid = this.args.cid;

    if (this.args.clearSearch) {
      route.searchText = undefined;
    }

    return await instance.router?.setRoute(route);
  };
}

export class PartsTypeCommand extends Command<{ partsType: PartTypeEnum }> {
  name = 'filter-parts-type';

  constructor(args: { partsType: PartTypeEnum }) {
    super(args);
  }

  public execute = async (instance: Instance): Promise<unknown> => {
    return await instance.router?.setRoute({
      view: 'search',
      sortField: SortFieldEnum.Product,
      pagination: {
        page: 1,
      },
      ...this.args,
    } as IRouteData);
  };
}

export class NarrowByProductCommand extends Command<{
  masterProductId?: string;
  masterProductName?: string;
}> {
  name = 'filter-master-product';

  constructor(args: { masterProductId?: string; masterProductName?: string }) {
    super(args);
  }

  public execute = async (instance: Instance): Promise<unknown> => {
    instance.store.data.searchTitle = this.args.masterProductName;

    return await instance.router?.setRoute({
      view: 'search',
      sortField: SortFieldEnum.Product,
      pagination: {
        page: 1,
      },
      masterProductId: this.args.masterProductId,
    } as IRouteData);
  };
}

export class PaginateCommand extends Command<{ page: number }> {
  name = 'paginate';

  constructor(args: { page: number }) {
    super(args);
  }

  public execute = async (instance: Instance): Promise<unknown> => {
    return await instance.router?.setRoute({
      view: 'search',
      pagination: {
        page: this.args.page,
      },
    } as IRouteData);
  };
}

export class SetPageSizeCommand extends Command<{ pageSize: number }> {
  name = 'page-size';

  constructor(args: { pageSize: number }) {
    super(args);
  }

  public execute = async (instance: Instance): Promise<unknown> => {
    return await instance.router?.setRoute({
      view: 'search',
      pagination: {
        pageSize: this.args.pageSize,
        page: 1,
      },
    } as IRouteData);
  };
}

export class SetSortByCommand extends Command<{ sortField: SortFieldEnum }> {
  name = 'sort-by';

  constructor(args: { sortField: SortFieldEnum }) {
    super(args);
  }

  public execute = async (instance: Instance): Promise<unknown> => {
    return await instance.router?.setRoute({
      view: 'search',
      sortField: this.args.sortField,
    } as IRouteData);
  };
}

export class ApplyFiltersCommand extends Command<{
  filters: FilterSettings[];
}> {
  name = 'filter-parametric-apply';

  constructor(args: { filters: FilterSettings[] }) {
    super(args);
  }

  public execute = async (instance: Instance): Promise<unknown> => {
    return await instance.router?.setRoute({
      view: 'search',
      filters: this.args.filters,
      pagination: {
        page: 1,
        pageSize: instance.router.routeData?.pagination?.pageSize,
      },
    } as IRouteData);
  };
}

export class ExcludeFilterCommand extends Command<{
  attributeCode: string;
  value: string;
}> {
  name = 'filter-parametric-remove';

  constructor(args: { attributeCode: string; value: string }) {
    super(args);
  }

  public execute = async (instance: Instance): Promise<unknown> => {
    const filters = instance.router.routeData?.filters
      ?.map(
        ({ attributeCode, values }): FilterSettings => ({
          attributeCode,
          values: values?.filter((val) => `${val.name}:${val.code}` !== this.args.value),
        }),
      )
      .filter(({ values }) => values?.length);

    return await instance.router?.setRoute({
      filters,
      pagination: {
        page: 1,
        pageSize: instance.router.routeData?.pagination?.pageSize,
      },
    } as IRouteData);
  };
}

export class GetFilterValuesCommand extends Command<{
  attributeCodes: string[];
}> {
  name = 'get-filter-values';

  constructor(args: { attributeCodes: string[] }) {
    super(args);
  }

  public execute = async (instance: Instance): Promise<unknown> => {
    return await instance.httpService?.filterValues(this.args.attributeCodes);
  };
}

export interface PrmLoadArgs {
  search: PrmSearchSettings;
  pager: PagerSettings;
}
export class GetPrmListCommand extends Command<PrmLoadArgs> {
  name = 'get-prm-list';

  constructor(args: PrmLoadArgs) {
    super(args);
  }

  public execute = async (instance: Instance): Promise<unknown> => {
    return await instance.httpService?.loadPrmRelationship(this.args.search, this.args.pager);
  };
}

export class GetPrmFiltersCommand extends Command<PrmFilterSettings> {
  name = 'get-prm-filters';

  public execute = async (instance: Instance): Promise<FilterValueSearchResult | undefined> =>
    await instance.httpService?.loadPrmRelationshipFilters(this.args);
}

export class ExportCommand extends Command<unknown> {
  name = 'export';

  constructor(args: IExportMenuItem) {
    super(args);
  }

  public execute = async (): Promise<unknown> => executePlaceholder(this.name);
}

export class LoadFavoriteViewsCommand extends Command<{
  ids: string[];
}> {
  name = 'favorite-views-load';

  constructor(args: { ids: string[] }) {
    super(args);
  }

  public execute = async (instance: Instance): Promise<void> => {
    const data: SearchFavoriteViewsLoadedData = {
      items: (await instance.httpService?.loadFavoriteViews(this.args.ids)) || [],
    };
    instance.eventBus.emit(SearchFavoriteViewsEvent.loaded, data as SearchFavoriteViewsLoadedData);
  };
}

export class SaveFavoriteViewCommand extends Command<{
  id: string;
  code: string;
  routeData: IRouteData;
}> {
  name = 'favorite-views-save';

  constructor(args: { id: string; code: string; routeData: IRouteData }) {
    super(args);
  }

  public execute = async (instance: Instance): Promise<void> => {
    const item: FavoriteView | undefined = await instance.httpService?.saveFavoriteView(
      this.args.id,
      this.args.code,
      this.args.routeData,
    );
    if (item) {
      instance.eventBus.emit(SearchFavoriteViewsEvent.saved, {
        item,
      } as SearchFavoriteViewsSavedData);
    }
  };
}

export class DeleteFavoriteViewCommand extends Command<{
  id: string;
}> {
  name = 'favorite-views-delete';

  constructor(args: { id: string }) {
    super(args);
  }

  public execute = async (instance: Instance): Promise<void> => {
    const id: string | undefined = await instance.httpService?.deleteFavoriteView(this.args.id);
    if (id) {
      instance.eventBus.emit(SearchFavoriteViewsEvent.deleted, {
        id,
      } as SearchFavoriteViewsDeletedData);
    }
  };
}

export class ApplyFavoriteViewCommand extends Command<{
  favoriteView: FavoriteView;
}> {
  name = 'favorite-views-apply';

  constructor(args: { favoriteView: FavoriteView }) {
    super(args);
  }

  public execute = async (instance: Instance): Promise<void> => {
    const data: FavoriteViewData | undefined = this.args.favoriteView.data;
    if (data) {
      const routeDataPatch: IRouteData = Object.assign({}, instance.router.routeData, {
        searchText: data.search.searchText,
        searchType: data.search.searchType,
        cid: data.search.cid,
        filters: data.search.filterSettings,
        pagination: data.pager,
        sortField: data.sort.field,
        sortDirection: data.sort.direction,
        masterProductId: data.search.masterProductId,
      });
      await instance.router?.setRoute(routeDataPatch);
      instance.eventBus.emit(SearchFavoriteViewsEvent.applied, {
        routeData: routeDataPatch,
      } as SearchFavoriteViewsAppliedData);
    }
  };
}

export class GetClassificationsNodesCommand extends Command<{ cids: string[] }> {
  name = 'get-classifications-nodes';

  constructor(args: { cids: string[] }) {
    super(args);
  }

  public execute = async (
    instance: Instance,
  ): Promise<ClassificationNodesSearchResult | undefined> => {
    if (this.args.cids) return await instance.httpService?.loadClassificationsNodes(this.args.cids);
  };
}

export class AddFavoriteCategoryCommand extends Command<{ cid: string }> {
  name = 'favorite-category-add';

  constructor(args: { cid: string }) {
    super(args);
  }

  public execute = async (instance: Instance): Promise<unknown> => {
    if (this.args.cid) {
      if (
        instance.store.options.application?.treeType === TreeTypeEnum.Products &&
        instance.store.options.components?.favoriteCategories
      ) {
        if (instance.store.options.components.favoriteCategories.productCids === undefined)
          instance.store.options.components.favoriteCategories.productCids = [];
        instance.store.options.components.favoriteCategories.productCids.push(this.args.cid);
      } else if (
        instance.store.options.application?.treeType === TreeTypeEnum.Parts &&
        instance.store.options.components?.favoriteCategories
      ) {
        if (instance.store.options.components.favoriteCategories.partCids === undefined)
          instance.store.options.components.favoriteCategories.partCids = [];
        instance.store.options.components.favoriteCategories.partCids.push(this.args.cid);
      }
    }

    instance.eventBus.emit('favorite-category-added', this.args);
    return Promise.resolve();
  };
}

export class RemoveFavoriteCategoryCommand extends Command<{ cid: string }> {
  name = 'favorite-category-remove';

  constructor(args: { cid: string }) {
    super(args);
  }

  public execute = async (instance: Instance): Promise<unknown> => {
    if (this.args.cid) {
      if (instance.store.options.application?.treeType === TreeTypeEnum.Products) {
        if (instance.store.options.components?.favoriteCategories?.productCids)
          _remove(
            instance.store.options.components?.favoriteCategories.productCids,
            (x) => x === this.args.cid,
          );
        if (instance.store?.data?.favoriteProductCategories?.items)
          _remove(
            instance.store.data.favoriteProductCategories.items,
            (x) => x.cid === this.args.cid,
          );
      } else if (instance.store.options.application?.treeType === TreeTypeEnum.Parts) {
        if (instance.store.options.components?.favoriteCategories?.partCids)
          _remove(
            instance.store.options.components.favoriteCategories.partCids,
            (x) => x === this.args.cid,
          );
        if (instance.store?.data?.favoritePartCategories?.items)
          _remove(instance.store.data.favoritePartCategories.items, (x) => x.cid === this.args.cid);
      }
    }

    instance.eventBus.emit('favorite-category-removed', this.args);
    return Promise.resolve();
  };
}

export class SendFeedbackCommand extends Command<any> {
  name = 'feedback-send';

  constructor(args: FeedbackSettings) {
    super(args);
  }

  public execute = async (instance: Instance): Promise<void> => {
    await instance.httpService?.saveFeedback(this.args);
    instance.eventBus.emit('feedback-send-done', this.args);
  };
}

export class PrintToPdfCommand extends Command<{ productId: string }> {
  name = 'print-to-pdf';

  constructor(args: { productId: string }) {
    super(args);
  }

  public execute = async (instance: Instance): Promise<void> => {
    const url: string | undefined = await instance.httpService?.generatePDFUrl(this.args.productId);
    if (url) {
      const filename = `Product ${this.args.productId}.pdf`;
      await downloadFile(filename, url);
      instance.eventBus.emit('print-to-pdf-done', url);
    }
  };
}

export class ShowPrintViewCommand extends Command<any> {
  name = 'show-print-view';

  constructor(args: { productId: string }) {
    super(args);
  }

  public execute = async (): Promise<unknown> => executePlaceholder(this.name);
}

export class AddNotificationCommand extends Command<INotification> {
  name = 'notification-show';
  public execute = async (instance: Instance): Promise<void> => {
    let showDelay = 0;
    let dismissAfter: number | undefined = this.args.dismissAfter;

    if (instance.store.notifications.length) {
      instance.store.notifications = [];
      showDelay = 1000;
    }

    if (!dismissAfter && dismissAfter !== 0 && this.args.type !== NotificationType.danger) {
      dismissAfter = 6000;
      instance.store.notifications = [];
    }

    if (dismissAfter && dismissAfter !== 0) {
      setTimeout(() => {
        const index = instance.store.notifications.indexOf(this.args);
        if (index !== -1) {
          instance.store.notifications.splice(index, 1);
        }
      }, this.args.dismissAfter);
    }
    setTimeout(() => {
      return instance.store.notifications.push(this.args);
    }, showDelay);
  };
}

export class DeleteNotificationCommand extends Command<INotification> {
  name = 'notification-hide';
  public execute = async (instance: Instance): Promise<void> => {
    const index = instance.store.notifications.indexOf(this.args);
    if (index !== -1) {
      instance.store.notifications.splice(index, 1);
    }
  };
}

export class FilterCompareCommand extends Command<{ cid?: string; diffOnly?: boolean }> {
  name = 'filter-compare';

  constructor(args: { cid?: string; diffOnly?: boolean }) {
    super(args);
  }

  public execute = async (instance: Instance): Promise<unknown> => {
    const routeData = instance.getRouteData();
    if (routeData.compare && this.args.diffOnly != undefined) {
      routeData.compare.differencesOnly = this.args.diffOnly;
    }

    if (routeData.compare && this.args.cid) {
      routeData.compare.cids = [this.args.cid];
    }

    return await instance.router?.setRoute(routeData);
  };
}

export class RemoveCompareItemCommand extends Command<{ item: ProductItem }> {
  name = 'remove-compare-item';

  constructor(args: { item: ProductItem }) {
    super(args);
  }

  public execute = async (instance: Instance): Promise<unknown> => {
    const routeData = instance.getRouteData();
    if (routeData.compare?.productIds) {
      const index = routeData.compare.productIds.indexOf(this.args.item.productId) ?? -1;

      if (index > -1) {
        routeData.compare.productIds.splice(index, 1);
      }
    }
    return await instance.router?.setRoute(routeData);
  };
}

export class ShowComparePrintViewCommand extends Command<any> {
  name = 'show-compare-print-view';

  constructor(args: { el: unknown }) {
    super(args);
  }

  public execute = async (): Promise<unknown> => executePlaceholder(this.name);
}

const setProductAction = async (
  instance: Instance,
  current: ProductAction,
  data: ProductAnyActionData,
) =>
  Object.assign(instance.store.data.actions, {
    current,
    data,
  });

export class ProductActionCompleted extends Command<void> {
  name = 'product-action-completed';
  public execute = async (instance: Instance) =>
    Object.assign(instance.store.data.actions, {
      current: null,
      data: null,
    });
}

export class AddToCatalogCommand extends Command<ProductAddToCatalogActionData> {
  name = 'add-to-catalog';

  public execute = async (instance: Instance): Promise<unknown> =>
    setProductAction(instance, ProductAction.addToCatalog, this.args);
}

export class ProductErrorsActionCommand extends Command<ProductErrorsActionData> {
  name = 'product-errors-action';
  public execute = async (instance: Instance) =>
    setProductAction(instance, ProductAction.errors, this.args);
}

export class CatalogGetOptionsCommand extends Command<void> {
  name = 'catalog-get-options';
  public execute = async (instance: Instance): Promise<CatalogOptionsResult | undefined> => {
    const result: CatalogOptionsResult | undefined =
      await instance.httpService?.getCatalogOptions();
    return result;
  };
}

export class GetAllCatalogsCommand extends Command<void> {
  name = 'catalogs-fetch';
  public execute = async (instance: Instance): Promise<CatalogItem[]> => {
    const result = await instance.httpService?.getAllCatalogs();
    if (!result || !result.items) {
      return [];
    }
    return result.items;
  };
}

export class CatalogCreateNewCommand extends Command<
  {
    code: string;
    type: CatalogTypeEnum;
    description: string;
  } & ProductAnyActionCommandArgs
> {
  name = 'catalog-create-new';
  public execute = async (instance: Instance): Promise<string | undefined> => {
    const result: CatalogActionResult | undefined = await handleResponse(
      instance,
      this.args,
      instance.httpService?.addCatalog(this.args.code, this.args.type, this.args.description),
    );
    return result?.catalog?.id;
  };
}

export class ProductAddToCatalogCommand extends Command<
  {
    id: string;
    products: CatalogProductAddItem[];
  } & ProductAnyActionCommandArgs
> {
  name = 'product-add-to-catalog';
  public execute = async (instance: Instance): Promise<CatalogActionResult | undefined> =>
    handleResponse(
      instance,
      this.args,
      instance.httpService?.addProductToCatalog(this.args.id, this.args.products),
    );
}
export class CommandExecutor {
  constructor(private instance: Instance) {}

  public async execute(command: ICommand<Instance>): Promise<unknown> {
    const commandInterceptor = this.instance.store.options.commandInterceptor;

    if (commandInterceptor) {
      try {
        // we don't want to expose execute function nor let external app to modify args
        // we clone the command exposed externally
        const commandExternal = _cloneDeep(_omit(command, ['execute']));

        await commandInterceptor(commandExternal as any);
        if (commandExternal.isHandled) {
          return Promise.reject('isHandled');
        }

        return command?.execute(this.instance);
      } catch (e) {
        this.instance.logger.log('Command cancelled', command);
      }
    } else {
      return command?.execute(this.instance);
    }
  }
}
