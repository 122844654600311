
import { Instance } from '@/catalogs/api/Instance';
import PIS from '@/common/api/PIS';
import Btn from '@/common/components/Btn.vue';
import Modal from '@/common/components/Modal.vue';
import FormInputClearable from '@/common/components/FormInputClearable.vue';
import FormTextAreaClearable from '@/common/components/FormTextAreaClearable.vue';
import { ValidationType, Validators } from '@/common/helpers/validation';
import { computed, defineComponent, onMounted, PropType, ref } from 'vue';
import {
  CatalogsCommand,
  AddNotificationCommand,
  CatalogCreateNewCommand,
  CatalogGetOptionsCommand,
} from '@/catalogs/api/runtime/CommandExecutor';
import { TranslateFunc } from '@/catalogs/composables/setupComponent';
import { CatalogOptionsResult, CatalogTypeEnum } from '@/common/services/swagger/index.defs';
import { NotificationType } from '@/common/api/runtime/INotification';
import CatalogOptions from '@/catalogs/components/common/CatalogOptions.vue';

export default defineComponent({
  components: {
    Btn,
    Modal,
    FormInputClearable,
    FormTextAreaClearable,
    CatalogOptions,
  },
  props: {
    t: { type: Function as PropType<TranslateFunc>, required: true },
    instanceId: String,
  },
  emits: ['update:show'],
  setup(props, { emit }) {
    const instance = PIS.Catalogs.getInstance(props.instanceId) as Instance;
    const show = ref(false);
    const catalogType: typeof CatalogTypeEnum = CatalogTypeEnum;
    const code = ref<string>('');
    const description = ref<string>('');
    const type = ref<CatalogTypeEnum>(catalogType.Personal);
    const catalogOptions = ref<CatalogOptionsResult['items']>([]);
    const isFormValid = computed<boolean>(
      () => !!code.value && !!type.value && !!description.value,
    );
    const validators: Record<string, Validators> = {
      code: { [ValidationType.required]: {} },
      description: { [ValidationType.required]: {} },
    };
    const close = () => emit('update:show', false);
    const onCodeUpdate = (newCodeValue: string) => (code.value = newCodeValue);
    const onDescriptionUpdate = (newDescriptionValue: string) =>
      (description.value = newDescriptionValue);
    const onTypeUpdated = (newType: CatalogTypeEnum) => (type.value = newType);
    const onSave = async () => {
      try {
        await instance.execute(
          new CatalogCreateNewCommand({
            code: code.value,
            type: type.value,
            description: description.value,
            onSuccessMessage: props.t('CatalogCreateNewSuccess'),
          }),
        );
        await instance.execute(new CatalogsCommand());
      } catch (error) {
        // Ignore error
      }
      close();
    };

    onMounted(async () => {
      try {
        const catalogOptionsResult: CatalogOptionsResult | undefined = (await instance.execute(
          new CatalogGetOptionsCommand(),
        )) as CatalogOptionsResult;
        if (!catalogOptionsResult) {
          throw new Error(props.t('CatalogOptionsLoadingError'));
        }
        catalogOptions.value = catalogOptionsResult.items;
        show.value = true;
      } catch (error) {
        await instance.execute(
          new AddNotificationCommand({ type: NotificationType.danger, message: error.message }),
        );
      }
    });

    return {
      show,
      code,
      type,
      validators,
      description,
      isFormValid,
      catalogType,
      catalogOptions,
      close,
      onSave,
      onCodeUpdate,
      onTypeUpdated,
      onDescriptionUpdate,
    };
  },
});
