
import { defineComponent, ref, reactive, computed, watch } from 'vue';
import _debounce from 'lodash/debounce';
import Image from '@/common/components/Image.vue';
import {
  AutocompleteItem,
  SearchTypeEnum,
  TreeTypeEnum,
} from '@/common/services/swagger/index.defs';
import { ComponentName } from '@/products/api/configuration/components/ComponentName';
import {
  ClassificationCommand,
  DetailsCommand,
  DetailsCommandArgs,
  SearchCommand,
} from '@/products/api/runtime/CommandExecutor';
import { setupComponent } from '@/products/composables/setupComponent';

import InjectStyles from '@/common/components/InjectStyles.vue';
import Btn from '@/common/components/Btn.vue';
import FormInputClearable from '@/common/components/FormInputClearable.vue';
import Dropdown from '@/common/components/DropdownEx.vue';
import Badge from '@/common/components/Badge.vue';
import Clickable from '@/common/components/Clickable.vue';
import Spinner from '@/common/components/Spinner.vue';
import Highlighter from 'vue-highlight-words';
import { ISearchFormOptions } from '@/products/api/configuration/components/ISearchFormOptions';
import {
  Autocomplete,
  AutocompleteModelItem,
  searchTypeAllWords,
  searchTypeExact,
  searchTypeHierarchical,
  searchTypeMasterId,
  searchTypeOneWord,
} from '@/products/api/runtime/Search';

export default defineComponent({
  name: ComponentName.searchForm,

  components: {
    InjectStyles,
    Btn,
    Dropdown,
    FormInputClearable,
    Badge,
    Clickable,
    Spinner,
    Highlighter,
    Image,
  },

  props: {
    instanceId: String,
  },

  setup(props) {
    const root = ref<HTMLElement>();

    const searchModel = reactive({
      text: '',
      type: SearchTypeEnum.WithAllWords,
      isDirty: false,
    });

    const autocomplete = ref<Autocomplete>({
      totalCount: 0,
      visibleMatchCount: 0,
      items: [] as AutocompleteModelItem[],
    });
    const isAutocompleteVisible = ref(false);
    const isAutocompleteLoading = ref(false);

    const {
      componentName,
      isReady,
      isWebComponent,
      instance,
      store,
      routeData,
      widerThan,
      narrowerThan,
      t,
    } = setupComponent(root, props.instanceId);

    const unwatch = watch(
      () => isReady.value,
      (isReady) => {
        if (isReady) {
          searchModel.type = routeData.value?.searchType || SearchTypeEnum.WithAllWords;
          unwatch();
        }
      },
    );

    const componentOptions = computed<ISearchFormOptions | undefined>(
      () => store.value?.options.components?.searchForm,
    );

    const visible = computed<boolean>(() => !!componentOptions.value?.visible);

    const autoDetailsNavigation = computed<boolean>(
      () => !!componentOptions.value?.autoDetailsNavigation,
    );

    const autocompleteEnabled = computed<boolean>(() => !!componentOptions.value?.useAutocomplete);

    const isSparePartsView = computed(
      () => store.value?.options.application?.treeType === TreeTypeEnum.Parts,
    );

    const isSearchTypeDropdownEnabled = computed(() => {
      return routeData.value?.searchType !== SearchTypeEnum.Hierarchical;
    });

    const baseSearchTypeOptions = [searchTypeAllWords, searchTypeOneWord, searchTypeExact];

    const searchTypeOptions = computed(() => {
      return isSparePartsView.value
        ? [searchTypeMasterId, ...baseSearchTypeOptions]
        : baseSearchTypeOptions;
    });

    const searchTypeOptionsForValidation = computed(() => {
      return isSparePartsView.value
        ? [searchTypeMasterId, ...baseSearchTypeOptions]
        : [searchTypeHierarchical, ...baseSearchTypeOptions];
    });

    const categorySearch = computed<boolean>(() => !!componentOptions.value?.categorySearch);

    const currentSearchText = computed(() => routeData.value?.searchText);

    const onSearch = async (resetCategories?: boolean, resetFilters?: boolean) => {
      isAutocompleteVisible.value = false;

      let searchTextValue = searchModel.text;

      let cid = resetCategories ? undefined : autocomplete.value.autoCategoryNavigationCid;

      if (!cid) {
        const productCount = autocomplete.value.items.filter((item) => item.type === 'item').length;
        const categoryCount = autocomplete.value.items.filter(
          (item) => item.type === 'category',
        ).length;

        if (productCount == 0 && categoryCount == 1) {
          cid = autocomplete.value.items.find((item) => item.type === 'category')?.productId;
          searchTextValue = '';
        }

        if (!cid && componentOptions.value?.autoCategoryNavigation) {
          cid = 'ROOT';
        }
      }

      const resetFiltersValue = resetFilters || autocomplete.value.matchesOnlyWithoutFilters;
      const resetCategoriesValue = resetCategories || resetFiltersValue;

      try {
        await instance.value?.execute(
          new SearchCommand({
            searchText: searchTextValue,
            searchType: searchModel.type,
            resetCategories: resetCategoriesValue,
            resetFilters: resetFiltersValue,
            cid: cid,
          }),
        );
        searchModel.text = searchTextValue;
        searchModel.isDirty = false;
      } catch (error) {
        // Ignore Error
      }
    };

    const searchText = (text: string) => {
      searchModel.text = text;
      onSearch();
    };

    const onClear = () => {
      if (!searchModel.isDirty) {
        searchModel.text = '';
        autocomplete.value.items = [];
        autocomplete.value.totalCount = 0;

        onSearch(false);
      }
    };

    const openAutocomplete = () => {
      if (!autocompleteEnabled.value) {
        return;
      }

      if (searchModel.text == routeData.value?.searchText) {
        return;
      }

      if (searchModel.text.length > 2) {
        isAutocompleteVisible.value = true;
        handleAutocompleteSearch(searchModel.text);
      }
    };

    const closeAutocomplete = () => {
      isAutocompleteVisible.value = false;
    };

    const getFirstAttributeValue = (item: AutocompleteItem, attributeCode: string) => {
      if (item.attributes) {
        var attr = item.attributes[attributeCode];

        if (attr && attr.values) {
          return attr.values[0].text;
        }
      }

      return undefined;
    };

    const getAllIds = (item: AutocompleteItem) => {
      if (item.attributes) {
        var attr = item.attributes['#AllIdentifiers'];

        if (attr && attr.values) {
          return attr.values.map((a) => a.text).join(', ');
        }
      }

      return undefined;
    };

    const handleAutocompleteSearch = _debounce(async (text) => {
      if (!autocompleteEnabled.value) {
        return;
      }

      const hasText = text != null && text.length > 2;
      isAutocompleteVisible.value = hasText;

      if (hasText) {
        isAutocompleteLoading.value = true;
        const items: Autocomplete['items'] = [];
        const result = await instance.value?.httpService?.autocomplete(text, false);

        if (result) {
          const matches: Autocomplete['items'] = (result.items || []).map(
            (match: AutocompleteItem) => ({
              ...match,
              text: getFirstAttributeValue(match, '#DisplayName') || '',
              secondaryText: getAllIds(match) || '',
              description: getFirstAttributeValue(match, 'CatalogDescription') || '',
              images: match.images,
              type: 'item',
              location: 'bottom',
            }),
          );

          const autocompleteValue: Autocomplete = {
            totalCount: result.totalCount || 0,
            visibleMatchCount: result.matches?.length || 0,
            items: [],
            autoCategoryNavigationCid: result.autoCategoryNavigationCategory?.cid,
          };

          const isRoot = !routeData.value?.cid || routeData.value?.cid === 'ROOT';

          if (matches.length) {
            items.push({
              attributes: {},
              productId: '',
              count: result.totalCount,
              type: isRoot ? 'root' : 'current',
              location: 'bottom',
              categoryName: result.autoCategoryNavigationCategory?.name,
            });
          }

          if (result.totalCount != result.rootTotalCount) {
            items.push({
              attributes: {},
              productId: '',
              count: result.rootTotalCount,
              type: 'root',
              location: 'bottom',
            });
          }

          if (result.noFiltersTotalCount > 0) {
            items.push({
              attributes: {},
              productId: '',
              count: result.noFiltersTotalCount,
              type: 'no-filters',
              location: 'bottom',
            });
          }

          if (result.categories) {
            result.categories.forEach((c, index) => {
              if (index > (componentOptions.value?.maxCategoryMatches ?? 2) - 1) return;

              let secondaryText = '';

              if (c.breadcrumbs && c.breadcrumbs.length > 0) {
                secondaryText = c.breadcrumbs
                  .slice(0, c.breadcrumbs.length - 1)
                  .map((a) => a.name)
                  .join(' > ');
              }

              items.push({
                attributes: {},
                productId: c.match?.cid ?? '',
                text: c.match?.name,
                secondaryText: secondaryText,
                description: c.keywords?.join(', '),
                // breadcrumbs: c.breadcrumbs,
                images: c.images,
                type: 'category',
                location: 'top',
              });
            });
          }

          autocompleteValue.items = items.concat(matches);
          autocompleteValue.matchesOnlyInAllCatagories =
            result.totalCount == 0 && result.rootTotalCount > 0;
          autocompleteValue.matchesOnlyWithoutFilters = result.noFiltersTotalCount > 0;
          autocomplete.value = autocompleteValue;
        }

        isAutocompleteLoading.value = false;
      }
    }, 300);

    const onSearchInput = _debounce(() => onSearchInputInternal(), 300);

    const onSearchInputInternal = () => {
      searchModel.isDirty =
        searchModel.text?.length > 0 && searchModel.text != routeData.value?.searchText; // searchModel.text.length > 0;
      handleAutocompleteSearch(searchModel.text);
    };

    const details = async (product: DetailsCommandArgs) => {
      if (routeData.value && product) {
        try {
          await instance.value?.execute(new DetailsCommand(product));
        } catch (error) {
          // Ignore Error
        }
      }
    };

    const setClassification = async (cid: string) => {
      if (instance.value && cid) {
        isAutocompleteVisible.value = false;
        searchModel.text = '';
        try {
          await instance.value.execute(new ClassificationCommand({ cid, clearSearch: true }));
        } catch (error) {
          // Ignore Error
        }
      }
    };

    // Sync the search model text with route search text after initializing the app.
    watch(
      () => routeData.value?.searchText,
      (text, oldText) => {
        if (text !== oldText) {
          searchModel.text = text ?? '';
        }
      },
    );

    watch(
      () => routeData.value?.searchType,
      (type, oldType) => {
        if (type && type !== oldType) {
          if (searchTypeOptionsForValidation.value.find((item) => item.value === type)) {
            searchModel.type = type;
          }
        }
      },
    );

    watch(
      () => searchModel.type,
      () => {
        if (routeData.value) {
          routeData.value.searchType = searchModel.type;
        }
      },
    );

    const highlightTerms = computed(() => {
      if (!searchModel.text) {
        return [];
      }

      const arr = searchModel.text
        .trim()
        .toLowerCase()
        .split(/[._-\s/]+/);

      arr.push(searchModel.text.trim());

      // extract numbers
      const numbers = searchModel.text.match(/\d+/g);
      let txt = searchModel.text;

      if (numbers) {
        numbers.forEach((m) => {
          arr.push(m);
          txt = txt.replace(m, '$');
        });
      }

      const arr2 = txt.trim().toLowerCase().split('$');

      arr.push(...arr2);

      return arr;
    });

    const getThumbnailUrl = (item: AutocompleteItem) => {
      if (item.images && item.images.length > 0) {
        return item.images[0].thumbnailUrl;
      }

      return undefined;
    };

    const hasCategories = computed(() => {
      return autocomplete.value.items.some((item) => item.type === 'category');
    });

    return {
      root,
      componentName,
      isReady,
      isWebComponent,
      store,
      searchModel,
      searchTypeOptions,
      autocomplete,
      isAutocompleteVisible,
      isAutocompleteLoading,
      isSparePartsView,
      highlightTerms,
      isSearchTypeDropdownEnabled,
      autoDetailsNavigation,
      categorySearch,
      componentOptions,
      hasCategories,
      currentSearchText,
      details,
      widerThan,
      narrowerThan,
      t,
      onSearch,
      onSearchInput,
      onClear,
      searchText,
      openAutocomplete,
      closeAutocomplete,
      setClassification,
      visible,
      getThumbnailUrl,
    };
  },
});
