
import { defineComponent, PropType } from 'vue';
import { ClassificationItem } from '../services/swagger/index.defs';
import Badge from './Badge.vue';
import Clickable from './Clickable.vue';

export default defineComponent({
  components: {
    Badge,
    Clickable,
  },

  props: {
    categories: Array as PropType<ClassificationItem[]>,
    configuratorsNumberTitle: String,
    configuratorsVisible: Boolean,
  },

  emits: ['click-item'],
});
