import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "position-relative w-100" }
const _hoisted_2 = {
  ref: "ddEl",
  class: "dropdown-menu p-1 dropdown-menu-select position-relative d-block shadow",
  style: {"margin-top":"3rem"}
}
const _hoisted_3 = ["onClick"]
const _hoisted_4 = {
  key: 1,
  class: "dropdown-divider"
}
const _hoisted_5 = { class: "p-2" }
const _hoisted_6 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Popper = _resolveComponent("Popper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Popper, {
      "onOpen:popper": _ctx.open,
      class: "pis-no-transform",
      placement: "bottom-start"
    }, {
      content: _withCtx(({ close }) => [
        _createElementVNode("ul", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fixedItems, (item, index) => {
            return (_openBlock(), _createElementBlock("li", { key: index }, [
              (item.type === 'fixed')
                ? (_openBlock(), _createElementBlock("a", {
                    key: 0,
                    class: "dropdown-item text-wrap w-100",
                    href: "#",
                    onClick: ($event: any) => {
                  close();
                  _ctx.select(item);
                }
                  }, _toDisplayString(item.text), 9, _hoisted_3))
                : (item.type === 'divider')
                  ? (_openBlock(), _createElementBlock("hr", _hoisted_4))
                  : _createCommentVNode("", true)
            ]))
          }), 128)),
          _createElementVNode("li", _hoisted_5, [
            _withDirectives(_createElementVNode("input", {
              type: "text",
              class: "form-control",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filter) = $event)),
              ref: "inputEl"
            }, null, 512), [
              [_vModelText, _ctx.filter]
            ])
          ]),
          _createElementVNode("li", null, [
            _createElementVNode("ul", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredItems, (item, index) => {
                return (_openBlock(), _createElementBlock("li", { key: index }, [
                  _createElementVNode("a", {
                    class: "dropdown-item text-wrap w-100",
                    href: "#",
                    onClick: ($event: any) => {
                    close();
                    _ctx.select(item);
                  }
                  }, _toDisplayString(item.text), 9, _hoisted_6)
                ]))
              }), 128))
            ])
          ])
        ], 512)
      ]),
      default: _withCtx(() => [
        _createElementVNode("button", {
          ref: "toggleEl",
          type: "button",
          class: "btn btn-secondary dropdown-toggle dropdown-toggle-select w-100 text-start form-select"
        }, _toDisplayString(_ctx.innerValue?.text), 513)
      ]),
      _: 1
    }, 8, ["onOpen:popper"])
  ]))
}