import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "list-group" }
const _hoisted_2 = { class: "pis-label text-break" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Clickable = _resolveComponent("Clickable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
      return (_openBlock(), _createBlock(_component_Clickable, {
        key: item[_ctx.iterationKey],
        class: "list-group-item list-group-item-action d-flex align-items-start border-0",
        onClick: _withModifiers(($event: any) => (_ctx.$emit('click-item', item)), ["prevent"])
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_2, _toDisplayString(item[_ctx.namingKey]), 1),
          _createVNode(_component_Clickable, {
            onClick: _withModifiers(($event: any) => (_ctx.$emit('remove-item', item)), ["prevent"]),
            class: "ms-auto ext-remove"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Icon, {
                glyph: 'close',
                sm: ""
              })
            ]),
            _: 2
          }, 1032, ["onClick"])
        ]),
        _: 2
      }, 1032, ["onClick"]))
    }), 128))
  ]))
}