
import { computed, defineComponent, PropType, ref, watch } from 'vue';
import { ImageItem } from '../services/swagger/index.defs';

import Modal from '@/common/components/Modal.vue';
import Image from './Image.vue';
import Arrow from './Arrow.vue';
import Clickable from './Clickable.vue';

export default defineComponent({
  components: {
    Modal,
    Image,
    Arrow,
    Clickable,
  },

  props: {
    images: Array as PropType<ImageItem[]>,
    showPreview: Boolean,
    hideThumbnails: Boolean,
    disableModal: Boolean,
    isLoading: Boolean,
    alwaysShowThumbnails: Boolean,
    showOverlayArrows: Boolean,
    title: String,
  },

  setup(props) {
    const imageModalShown = ref(false);
    const currentIndex = ref<number>(0);

    const currentImage = computed(() =>
      props.images?.length ? props.images[currentIndex.value] : null,
    );

    const openImageModal = () => {
      if (!props.disableModal) {
        imageModalShown.value = true;
      }
    };

    const nextImage = () => {
      if (props.images) {
        const ix = currentIndex.value;
        currentIndex.value = ix < props.images.length - 1 ? ix + 1 : 0;
      }
    };

    const previousImage = () => {
      if (props.images) {
        const ix = currentIndex.value;
        currentIndex.value = ix === 0 ? props.images.length - 1 : ix - 1;
      }
    };

    const onItemClick = (index) => {
      currentIndex.value = index;

      if (!props.showPreview) {
        openImageModal();
      }
    };

    watch(
      () => props.images,
      () => {
        currentIndex.value = 0;
      },
    );

    return {
      imageModalShown,
      currentIndex,
      currentImage,
      openImageModal,
      nextImage,
      previousImage,
      onItemClick,
    };
  },
});
