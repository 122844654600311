import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["part"]
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InjectStyles = _resolveComponent("InjectStyles")!
  const _component_PisReplacements = _resolveComponent("PisReplacements")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "root",
    class: _normalizeClass(["pis-component", _ctx.componentName]),
    part: _ctx.componentName
  }, [
    (_ctx.isReady && _ctx.item)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.isWebComponent)
            ? (_openBlock(), _createBlock(_component_InjectStyles, {
                key: 0,
                items: _ctx.store?.options.styles
              }, null, 8, ["items"]))
            : _createCommentVNode("", true),
          (_ctx.item.replacements && _ctx.item.replacements.length)
            ? (_openBlock(), _createBlock(_component_PisReplacements, {
                key: 1,
                instance: _ctx.instance,
                replacements: _ctx.item.replacements,
                "show-icon": "",
                "is-detail-view": true,
                "shadow-root": _ctx.shadowRoot,
                visible: _ctx.replacementsVisible
              }, null, 8, ["instance", "replacements", "shadow-root", "visible"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}