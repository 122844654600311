
import { computed, defineComponent, ref } from 'vue';
import VRuntimeTemplate from 'vue3-runtime-template';
import InjectStyles from '@/common/components/InjectStyles.vue';
import { ProductItem } from '@/common/services/swagger/index.defs';
import { attrValue, attrValues } from '@/common/helpers/productAttributes';
import { ComponentName } from '@/catalogs/api/configuration/components/ComponentName';
import { DataType } from '@/catalogs/api/configuration/application/DataType';
import { setupComponent } from '@/catalogs/composables/setupComponent';
import { IPreviewOptions } from '@/catalogs/api/configuration/components/IPreviewOptions';
import { DetailsCommand } from '@/catalogs/api/runtime/CommandExecutor';

import Modal from '@/common/components/Modal.vue';
import AttributesTable from '@/common/components/AttributesTable.vue';
import ImageGallery from '@/common/components/ImageGallery.vue';
import Btn from '@/common/components/Btn.vue';

export default defineComponent({
  name: ComponentName.preview,

  components: {
    InjectStyles,
    VRuntimeTemplate,
    Modal,
    AttributesTable,
    ImageGallery,
    Btn,
  },

  props: {
    instanceId: String,
  },

  setup(props) {
    const root = ref<HTMLElement>();

    const { instance, componentName, isReady, isWebComponent, store, routeData, t, emit } =
      setupComponent(root, props.instanceId, [DataType.Products]);

    const componentOptions = computed(
      () => store.value?.options.components?.preview ?? ({} as IPreviewOptions),
    );

    const previewData = computed({
      get: () => store.value?.data.productPreview,
      set: (val) => {
        if (store.value?.data && 'productPreview' in store.value.data) {
          store.value.data.productPreview = val;
        }
      },
    });

    const item = computed<ProductItem | undefined>(() => previewData.value?.item);
    const attributes = computed(() => item.value?.attributes || {});
    const replacements = computed(() => item.value?.replacements || []);
    const title = computed(() => (item.value ? attrValue(item.value, '#DisplayName') : ''));
    const identifiers = computed(() =>
      item.value ? attrValues(item.value, '#AllIdentifiers') : [],
    );

    const hideDocumentLinks = computed(() => true);

    const details = async (product: ProductItem) => {
      if (product?.inactive) {
        return;
      }

      close();

      if (routeData.value && product) {
        try {
          await instance.value?.execute(new DetailsCommand(product));
        } catch (error) {
          // Ignore Error
        }
      }
    };

    const close = () => (previewData.value = undefined);

    return {
      previewData,
      root,
      componentName,
      componentOptions,
      isReady,
      isWebComponent,
      store,
      routeData,
      instance,
      title,
      attributes,
      replacements,
      identifiers,
      item,
      hideDocumentLinks,
      attrValues,
      t,
      emit,
      details,
      attrValue,
      close,
    };
  },
});
