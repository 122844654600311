import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Popper = _resolveComponent("Popper")!

  return (_openBlock(), _createBlock(_component_Popper, _mergeProps({ ref: "popper" }, _ctx.$attrs, { "onOpen:popper": _ctx.open }), {
    content: _withCtx(({ close }) => [
      _renderSlot(_ctx.$slots, "dropdown", { close: close })
    ]),
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "trigger")
    ]),
    _: 3
  }, 16, ["onOpen:popper"]))
}