import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"white-space":"pre-wrap"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VRuntimeTemplate = _resolveComponent("VRuntimeTemplate")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_VRuntimeTemplate, {
      template: _ctx.textParsed,
      "template-props": { onLinkClick: _ctx.onLinkClick }
    }, null, 8, ["template", "template-props"])
  ]))
}