import { CompareResult } from './../../../common/services/swagger/index.defs';
import {
  AttributeGroupSearchResult,
  BreadcrumbsSearchResult,
  ClassificationsSearchResult,
  ConfiguratorItemSearchResult,
  FavoriteViewsSearchResult,
  FilterSearchResult,
  InteractiveGuidesSearchResult,
  MasterProductSearchResult,
  PartTypesSearchResult,
  PrmSearchResult,
  ProductClassificationsSearchResult,
  ProductDetailSearchResult,
  ProductPreview,
  ProductSearchResult,
  RelatedLinksSearchResult,
  ClassificationNodesSearchResult,
  RelatedProductsSearchResult,
} from '@/common/services/swagger/index.defs';
import { attrValue } from '@/common/helpers/productAttributes';
import { reactive } from 'vue';
import { DataType, ProductIdToNameData } from '../configuration/application/DataType';
import { Instance } from '../Instance';
import { ILoadEvent } from './ILoadEvent';

export class StoreService {
  constructor(private instance: Instance) {}

  public async setData(type: DataType, data: unknown): Promise<void> {
    if (data === undefined) {
      const loadEvent: ILoadEvent = {
        type,
        data: undefined,
        instance: this.instance,
      };
      this.setStoreData(loadEvent);
      return;
    }

    const interceptFunc = this.instance.store?.options.dataInterceptor;

    const loadEvent: ILoadEvent = {
      type,
      data: reactive(data as Record<string | number, unknown>),
      instance: this.instance,
    };

    if (interceptFunc) {
      this.setStoreData(await interceptFunc(loadEvent));
    } else {
      this.setStoreData(loadEvent);
    }
  }

  setStoreData({ type, data }: ILoadEvent): void {
    if (!this.instance.store) return;

    const storeData = this.instance.store.data;

    switch (type) {
      case DataType.Products:
        storeData.products = data as ProductSearchResult;
        break;
      case DataType.Classifications:
        storeData.classifications = data as ClassificationsSearchResult;
        break;
      case DataType.Filters:
        storeData.filters = data as FilterSearchResult;
        break;
      case DataType.Breadcrumbs:
        storeData.breadcrumbs = data as BreadcrumbsSearchResult;
        break;
      case DataType.ProductDetails:
        if (data == undefined) {
          storeData.productDetail = undefined;
          storeData.itemNotFound = true;
        } else {
          storeData.productDetail = data as ProductDetailSearchResult;
          storeData.itemNotFound = false;
        }
        break;
      case DataType.ProductClassifications:
        storeData.productClassifications = data as ProductClassificationsSearchResult;
        break;
      case DataType.AttributeGroups:
        storeData.attributeGroups = data as AttributeGroupSearchResult;
        break;
      case DataType.ProductRelationships:
        storeData.prm = data as PrmSearchResult;
        break;
      case DataType.Configurators:
        storeData.configurators = data as ConfiguratorItemSearchResult;
        break;
      case DataType.InteractiveGuides:
        storeData.interactiveGuides = data as InteractiveGuidesSearchResult;
        break;
      case DataType.RelatedLinks:
        storeData.relatedLinks = data as RelatedLinksSearchResult;
        break;
      case DataType.ProductPreview:
        storeData.productPreview = data as ProductPreview;
        break;
      case DataType.PartTypes:
        storeData.partTypes = data as PartTypesSearchResult;
        break;
      case DataType.MasterProducts:
        storeData.sparePartMasterProducts = data as MasterProductSearchResult;
        break;
      case DataType.FavoriteProductCategories:
        storeData.favoriteProductCategories = data as ClassificationNodesSearchResult;
        break;
      case DataType.FavoritePartCategories:
        storeData.favoritePartCategories = data as ClassificationNodesSearchResult;
        break;
      case DataType.FavoriteViews:
        storeData.favoriteViews = data as FavoriteViewsSearchResult;
        break;
      case DataType.RelatedProducts:
        storeData.relatedProducts = data as RelatedProductsSearchResult;
        break;
      case DataType.ProductCompare:
        storeData.compareResult = data as CompareResult;
        break;
      case DataType.ProductIdToName:
        if (!storeData.productIdToDisplayName) {
          storeData.productIdToDisplayName = {};
        }
        if (data.item) {
          const { requestedProductId, item } = data as ProductIdToNameData;
          const displayName = attrValue(item, '#DisplayName');
          /** Save both productIds - requested and returned from api */
          if (displayName) {
            storeData.productIdToDisplayName[item.productId] = displayName;
            storeData.productIdToDisplayName[requestedProductId] = displayName;
          }
        }
        break;
      default:
        this.instance.logger.log('Setting store data not handled', { type, data });
    }
  }
}
