
import { computed, defineComponent, PropType, ref, watch } from 'vue';

import {
  AttributeTypeEnum,
  ProductAttribute,
  ProductItem,
} from '@/common/services/swagger/index.defs';
import Icon from '@/common/components/Icon.vue';
import Tooltip from '@/common/components/Tooltip.vue';
import { ICompareOptions } from '@/products/api/configuration/components/ICompareOptions';

import Image from '@/common/components/Image.vue';
import VRuntimeTemplate from 'vue3-runtime-template';
import { attrValue, attrValues, additionalInfo } from '@/common/helpers/productAttributes';
import { DetailsCommand } from '@/products/api/runtime/CommandExecutor';
import Modal from '@/common/components/Modal.vue';
import { Instance } from '@/products/api/Instance';
import PIS from '@/common/api/PIS';
import { TranslateFunc } from '@/products/composables/setupComponent';
import AttributeValueTable from '@/common/components/AttributeValueTable.vue';
export default defineComponent({
  components: {
    Modal,
    AttributeValueTable,
    VRuntimeTemplate,
    Image,

    Icon,
    Tooltip,
  },

  props: {
    instanceId: String,
    visible: { type: Boolean, default: false },

    items: {
      type: Object as PropType<{ item: ProductItem; attr: ProductAttribute; imageSrc: string }[]>,
      required: true,
    },
    t: { type: Function as PropType<TranslateFunc>, required: true },
  },

  setup(props) {
    const show = ref(props.visible);

    watch(
      () => props.visible,
      (val) => {
        show.value = val;
      },
    );

    const instance = computed(() => PIS.Products.getInstance(props.instanceId) as Instance);

    const componentOptions = computed(
      () => instance.value.store.options.components?.compare ?? ({} as ICompareOptions),
    );

    const details = async (product: ProductItem) => {
      if (product?.inactive) return;

      if (product) {
        try {
          await instance.value.execute(new DetailsCommand(product));
        } catch (error) {
          // Ignore Error
        }
      }
    };

    const replacementsVisible = computed(
      () => instance.value.store.options.components?.common?.hideReplacements != true,
    );

    const emit = (name, data) => {
      instance.value.eventBus.emit(name, data);
    };

    return {
      show,
      componentOptions,
      replacementsVisible,
      AttributeTypeEnum,
      instance,
      emit,
      attrValue,
      attrValues,
      additionalInfo,
      details,
    };
  },
});
