export enum CatalogProductSwitchDisplayModeOption {
  catalog = 'catalog',
  product = 'product',
  alias = 'alias',
}

export interface ICatalogProductSwitchOptions {
  displayModes: CatalogProductSwitchDisplayModeOption[];
  defaultDisplayMode: CatalogProductSwitchDisplayModeOption;
}
