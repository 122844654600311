import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  ref: "root",
  class: "pis-component",
  "aria-label": "Items page navigation"
}
const _hoisted_2 = { class: "my-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InjectStyles = _resolveComponent("InjectStyles")!
  const _component_PaginationAmount = _resolveComponent("PaginationAmount")!
  const _component_Pagination = _resolveComponent("Pagination")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    (_ctx.isReady)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.isWebComponent)
            ? (_openBlock(), _createBlock(_component_InjectStyles, {
                key: 0,
                items: _ctx.store?.options.styles
              }, null, 8, ["items"]))
            : _createCommentVNode("", true),
          (_ctx.totalCount && _ctx.pagination)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: _normalizeClass(["d-flex align-items-center justify-content-between mt-4", [_ctx.widerThan('md') ? 'flex-row' : 'flex-column']])
              }, [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_PaginationAmount, {
                    "total-count": _ctx.totalCount,
                    page: _ctx.pagination.page,
                    "page-size": _ctx.pagination.pageSize,
                    "showing-text": _ctx.t('PaginationShowing', true),
                    "of-text": _ctx.t('PaginationOf', true)
                  }, null, 8, ["total-count", "page", "page-size", "showing-text", "of-text"])
                ]),
                _createVNode(_component_Pagination, {
                  class: _normalizeClass(["my-2", _ctx.componentName]),
                  part: _ctx.componentName,
                  total: Math.ceil(_ctx.totalCount / _ctx.pagination.pageSize),
                  current: _ctx.pagination.page,
                  onChange: _ctx.changePage
                }, null, 8, ["part", "class", "total", "current", "onChange"])
              ], 2))
            : _createCommentVNode("", true)
        ], 64))
      : _createCommentVNode("", true)
  ], 512))
}