import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, renderSlot as _renderSlot, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = { class: "position-relative" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Arrow = _resolveComponent("Arrow")!
  const _component_Clickable = _resolveComponent("Clickable")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["pis-card", [_ctx.useMarkup && 'card']])
  }, [
    (_ctx.useMarkup && (_ctx.header || _ctx.icon))
      ? (_openBlock(), _createBlock(_component_Clickable, {
          key: 0,
          class: _normalizeClass(["card-header d-flex align-items-center text-body", [!_ctx.isOpen && 'border-bottom-0']]),
          onClick: _ctx.toggle
        }, {
          default: _withCtx(() => [
            (_ctx.icon)
              ? (_openBlock(), _createBlock(_component_Icon, {
                  key: 0,
                  glyph: _ctx.icon,
                  class: "me-2"
                }, null, 8, ["glyph"]))
              : _createCommentVNode("", true),
            (_ctx.header)
              ? (_openBlock(), _createElementBlock("strong", _hoisted_1, _toDisplayString(_ctx.header), 1))
              : _createCommentVNode("", true),
            _createVNode(_component_Arrow, {
              class: "d-flex align-items-center ms-auto",
              dir: _ctx.isOpen ? 'up' : 'down'
            }, null, 8, ["dir"])
          ]),
          _: 1
        }, 8, ["class", "onClick"]))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("div", _hoisted_2, [
      (_ctx.$slots.default)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass([_ctx.useMarkup && 'card-body', _ctx.$slots.footer && 'pb-0'])
          }, [
            _renderSlot(_ctx.$slots, "default")
          ], 2))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "list"),
      (_ctx.$slots.footer)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass([
          _ctx.useMarkup && 'card-body',
          _ctx.useMarkup && _ctx.stickyFooter && 'position-sticky bottom-0 bg-body',
        ])
          }, [
            _renderSlot(_ctx.$slots, "footer")
          ], 2))
        : _createCommentVNode("", true)
    ], 512), [
      [_vShow, _ctx.isOpen]
    ])
  ], 2))
}