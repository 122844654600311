import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "card p-3 mb-4 bg-light" }
const _hoisted_2 = { class: "row align-items-center" }
const _hoisted_3 = { class: "text-center m-auto" }
const _hoisted_4 = { class: "position-relative col d-flex justify-content-center align-items-top" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "col" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { key: 5 }
const _hoisted_9 = { class: "col text-end" }
const _hoisted_10 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Image = _resolveComponent("Image")!
  const _component_VRuntimeTemplate = _resolveComponent("VRuntimeTemplate")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Tooltip = _resolveComponent("Tooltip")!
  const _component_AttributeValueTable = _resolveComponent("AttributeValueTable")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    show: _ctx.show,
    "onUpdate:show": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.show) = $event)),
    title: _ctx.t('CompareTableData'),
    xl: ""
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
        return (_openBlock(), _createElementBlock("div", {
          key: item.item.productId,
          class: "mb-4"
        }, [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", {
                class: "col",
                style: _normalizeStyle({ 'max-width': _ctx.componentOptions.imageSize })
              }, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_Image, {
                      style: _normalizeStyle({ 'max-width': _ctx.componentOptions.imageSize }),
                      src: item.imageSrc,
                      "is-loading": false,
                      clickable: true,
                      "hide-frame": true,
                      onClick: ($event: any) => (_ctx.details(item.item))
                    }, null, 8, ["style", "src", "onClick"]),
                    (_ctx.componentOptions.imageOverlayTemplate)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                          _createVNode(_component_VRuntimeTemplate, {
                            template: _ctx.componentOptions.imageOverlayTemplate,
                            "template-props": { instance: _ctx.instance, item: item.item, emit: _ctx.emit, t: _ctx.t }
                          }, null, 8, ["template", "template-props"])
                        ]))
                      : _createCommentVNode("", true)
                  ])
                ])
              ], 4),
              _createElementVNode("div", _hoisted_6, [
                (item.item.additionalInfo && item.item.additionalInfo.length)
                  ? (_openBlock(), _createBlock(_component_Tooltip, {
                      key: 0,
                      text: _ctx.additionalInfo(item.item.additionalInfo)
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Icon, {
                          class: "text-warning me-1",
                          glyph: "warning"
                        })
                      ]),
                      _: 2
                    }, 1032, ["text"]))
                  : _createCommentVNode("", true),
                (_ctx.componentOptions.titleTemplate)
                  ? (_openBlock(), _createBlock(_component_VRuntimeTemplate, {
                      key: 1,
                      template: _ctx.componentOptions.titleTemplate,
                      "template-props": { instance: _ctx.instance, item: item.item, emit: _ctx.emit, details: _ctx.details, attrValue: _ctx.attrValue, t: _ctx.t }
                    }, null, 8, ["template", "template-props"]))
                  : (_openBlock(), _createElementBlock("a", {
                      key: 2,
                      href: "#",
                      class: "fw-bold",
                      onClick: _withModifiers(($event: any) => (_ctx.details(item.item)), ["prevent"])
                    }, _toDisplayString(_ctx.attrValue(item.item, '#DisplayName')), 9, _hoisted_7)),
                (_ctx.componentOptions.showAllIdentifiers)
                  ? (_openBlock(true), _createElementBlock(_Fragment, { key: 3 }, _renderList(_ctx.attrValues(item.item, '#AllIdentifiers'), (val, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "small text-muted fw-bold",
                        key: index
                      }, _toDisplayString(val), 1))
                    }), 128))
                  : _createCommentVNode("", true),
                (_ctx.componentOptions.itemTemplate)
                  ? (_openBlock(), _createBlock(_component_VRuntimeTemplate, {
                      key: 4,
                      template: _ctx.componentOptions.itemTemplate,
                      "template-props": { instance: _ctx.instance, item: item.item, emit: _ctx.emit, details: _ctx.details, attrValue: _ctx.attrValue, t: _ctx.t }
                    }, null, 8, ["template", "template-props"]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.attrValue(item.item, 'CatalogDescription')), 1)
                    ]))
              ]),
              _createElementVNode("div", _hoisted_9, [
                (_ctx.componentOptions.actionTemplate)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                      _createVNode(_component_VRuntimeTemplate, {
                        template: _ctx.componentOptions.actionTemplate,
                        "template-props": { instance: _ctx.instance, item: item.item, emit: _ctx.emit, details: _ctx.details, attrValue: _ctx.attrValue, t: _ctx.t }
                      }, null, 8, ["template", "template-props"])
                    ]))
                  : _createCommentVNode("", true)
              ])
            ])
          ]),
          _createElementVNode("div", {
            class: _normalizeClass({ small: _ctx.componentOptions.compactMode })
          }, [
            _createElementVNode("div", null, [
              _createElementVNode("strong", null, _toDisplayString(item.attr.attributeName) + ":", 1)
            ]),
            _createVNode(_component_AttributeValueTable, {
              table: item.attr.table!
            }, null, 8, ["table"])
          ], 2)
        ]))
      }), 128))
    ]),
    _: 1
  }, 8, ["show", "title"]))
}