import { computed, reactive, Ref, ref, watch } from 'vue';
import _debounce from 'lodash/debounce';
import {
  FilterAttributeValue,
  FilterItem,
  FilterSettings,
  FilterValue,
  FilterValueSearchResult,
} from '@/common/services/swagger/index.defs';
import {
  ApplyFiltersCommand as ProductsApplyFiltersCommand,
  ExcludeFilterCommand as ProductsExcludeFilterCommand,
  GetFilterValuesCommand as ProductsGetFilterValuesCommand,
} from '@/products/api/runtime/CommandExecutor';

import {
  ApplyFiltersCommand as CatalogsApplyFiltersCommand,
  ExcludeFilterCommand as CatalogsExcludeFilterCommand,
  GetFilterValuesCommand as CatalogsGetFilterValuesCommand,
} from '@/catalogs/api/runtime/CommandExecutor';

import { FiltersStorageData } from './FiltersStorageData';
import { IInstance } from '@/common/api/runtime/IInstance';
import { Instance as CatalogsInstance } from '@/catalogs/api/Instance';
import { Instance as ProductsInstance } from '@/products/api/Instance';
export interface FilterSettingsNamed extends FilterSettings {
  attributeName?: string;
}

export type FiltersStorageSetup = (
  instance: Ref<IInstance | undefined>,
) => () => FiltersStorageData;

// TODO need to think about moving this states into store
const filtersModel = ref<Record<string, Record<string, boolean>>>({});
const filtersLoading = reactive<Set<string>>(new Set());
const filtersFetched = reactive<Map<string, FilterValue[]>>(new Map());
const filteredResultCount = ref<number | undefined>();
const filteredResultCountLoading = ref(false);
const filtersOpen = reactive<Set<string>>(new Set());

export const setupFiltersStorage: FiltersStorageSetup = (instance) => () => {
  // List of filters (attributes) available for current category.
  // Displayed in the sidebar.
  const filtersAll = computed(() => {
    if (instance.value?.instanceType == 'Products') {
      return (instance.value as ProductsInstance)?.store.data.filters?.items ?? [];
    } else if (instance.value?.instanceType == 'Catalogs') {
      return (instance.value as CatalogsInstance)?.store.data.filters?.items ?? [];
    } else {
      return [];
    }
  });

  const filtersPromoted = computed(() => filtersAll.value.filter(({ isPromoted }) => !!isPromoted));

  const filtersAdditional = computed(() =>
    filtersAll.value.filter(({ isPromoted }) => !isPromoted),
  );

  const filtersApplied = computed(() => {
    if (instance.value?.instanceType == 'Products') {
      const filters = (instance.value as ProductsInstance)?.router.routeData
        ?.filters as FilterSettingsNamed[];

      return filters ? mapFilters(filters) : [];
    } else if (instance.value?.instanceType == 'Catalogs') {
      const filters = (instance.value as CatalogsInstance)?.router.routeData?.products
        .filters as FilterSettingsNamed[];

      return filters ? mapFilters(filters) : [];
    }

    return [];
  });

  const mapFilters = (filters) => {
    return filters?.map((filter) => {
      if (filter.attributeCode) {
        filter.attributeName = filtersAll.value.find(
          ({ attributeCode }) => attributeCode === filter.attributeCode,
        )?.attributeName;
      }
      return filter;
    });
  };

  const areFiltersApplied = computed(
    () =>
      filtersApplied.value.length > 0 ||
      Object.values(filtersModel.value).some((obj) => Object.values(obj).some(Boolean)),
  );

  const getFiltersModelAsFilterSettings = () =>
    Object.keys(filtersModel.value).reduce(
      (filterSettings: FilterSettings[], filtersModelKey: string) => {
        const filtersModelAttribute = filtersModel.value[filtersModelKey];
        const filtersFetchedByAttribute: FilterValue[] | undefined =
          filtersFetched.get(filtersModelKey);
        const values: FilterAttributeValue[] = Object.keys(filtersModelAttribute).reduce(
          (all: FilterAttributeValue[], filtersModelAttributeKey: string) => {
            if (filtersModelAttribute[filtersModelAttributeKey]) {
              const [name, code] = filtersModelAttributeKey.split(':');
              const filterAttributeValue: FilterAttributeValue = { name };
              if (filtersFetchedByAttribute) {
                const filterFetched: FilterValue | undefined = filtersFetchedByAttribute.find(
                  (filterValue: FilterValue) =>
                    filterValue.name === name && filterValue.code === code,
                );
                if (filterFetched && filterFetched.code) {
                  filterAttributeValue.code = filterFetched.code;
                }
              }
              all.push(filterAttributeValue);
            }
            return all;
          },
          [],
        );
        if (values?.length) {
          filterSettings.push({ attributeCode: filtersModelKey, values });
        }
        return filterSettings;
      },
      [],
    );

  const fetchFilterValues = async (attributeCodes: string[]) => {
    attributeCodes.forEach((attributeCode) => {
      filtersLoading.add(attributeCode);
    });
    try {
      const command =
        instance.value?.instanceType == 'Products'
          ? new ProductsGetFilterValuesCommand({ attributeCodes })
          : new CatalogsGetFilterValuesCommand({ attributeCodes });

      const res = (await instance.value?.execute(command)) as FilterValueSearchResult;

      attributeCodes.forEach((attributeCode) => {
        if (res.items) {
          const attr = res.items.find((item) => item.attributeCode === attributeCode);
          filtersLoading.delete(attributeCode);
          filtersFetched.set(attributeCode, (attr?.values ?? []) as FilterValue[]);
        }
      });
    } catch (error) {
      // Ignore Error
    }
  };

  const expandAllFilters = async (filters: FilterItem[]) => {
    const attrs = filters.map((a) => a.attributeCode);
    await fetchFilterValues(attrs);
    attrs.forEach((a) => {
      filtersOpen.add(a);
    });
  };

  const collapseAllFilters = () => {
    filtersOpen.clear();
  };

  const refreshFetchedFilterValues = () => fetchFilterValues(Array.from(filtersFetched.keys()));

  // Get total count of products that matches current filtersModel.
  const refreshResultCount = _debounce(async () => {
    try {
      filteredResultCountLoading.value = true;
      // If there are no fetched filters
      if (filtersFetched.size === 0) {
        const attributeCodes: string[] = Object.keys(filtersModel.value).filter((name: string) =>
          Object.values(filtersModel.value[name]).find(Boolean),
        );
        // Check if there are some applied filters - fetch filter values if so
        if (attributeCodes.length) {
          await fetchFilterValues(attributeCodes);
        }
      }
      const filterSettings: FilterSettings[] = getFiltersModelAsFilterSettings();
      if (instance.value?.instanceType == 'Products') {
        const res = await (instance.value as ProductsInstance)?.httpService?.loadFiltersResultCount(
          filterSettings,
        );
        filteredResultCount.value = res?.totalCount ?? undefined;
        filteredResultCountLoading.value = false;
      } else if (instance.value?.instanceType == 'Catalogs') {
        const res = await (instance.value as CatalogsInstance)?.httpService?.loadFiltersResultCount(
          filterSettings,
        );
        filteredResultCount.value = res?.totalCount ?? undefined;
        filteredResultCountLoading.value = false;
      }
    } catch (error) {
      filteredResultCount.value = undefined;
      filteredResultCountLoading.value = false;
    }
  }, 700);

  const applyFilters = async (filters: FilterSettings[]): Promise<unknown> => {
    try {
      const command =
        instance.value?.instanceType == 'Products'
          ? new ProductsApplyFiltersCommand({ filters })
          : new CatalogsApplyFiltersCommand({ filters });
      return instance.value?.execute(command) ?? Promise.resolve();
    } catch (error) {
      // Ignore Error
    }
  };

  const applyModelFilters = async () => {
    try {
      await Promise.all([
        applyFilters(getFiltersModelAsFilterSettings()),
        refreshFetchedFilterValues(),
      ]);
    } catch (error) {
      // Ignore Error
    }
  };

  // Uncheck all filter values, reset filtering
  const resetFiltersApplied = () => {
    filtersOpen.clear();
    filtersFetched.clear();

    Object.keys(filtersModel.value).forEach(
      (attributeCode) => (filtersModel.value[attributeCode] = {}),
    );

    const res = Promise.all([applyFilters([])]);
    filteredResultCount.value = undefined;
    refreshResultCount();
    return res;
  };

  // Instead of applying whole filtersModel this method removes one specific filter value.
  const excludeFilter = async ({ attributeCode }: FilterSettings, value: string) => {
    if (attributeCode && value) {
      try {
        filtersModel.value[attributeCode][value] = false;
        const command =
          instance.value?.instanceType == 'Products'
            ? new ProductsExcludeFilterCommand({ attributeCode, value })
            : new CatalogsExcludeFilterCommand({ attributeCode, value });

        await instance.value?.execute(command);
        refreshFetchedFilterValues();
        refreshResultCount();
      } catch (error) {
        // Ignore Error
      }
    }
  };

  // Each time displayed filters are changed we need to fill in the filtersModel
  // with missing attributeCode groups to prevent v-model from accessing undefined.
  watch(filtersAll, (val) => {
    val?.forEach(({ attributeCode }) => {
      if (attributeCode && !filtersModel.value[attributeCode]) {
        filtersModel.value[attributeCode] = {};
      }
    });
  });

  const syncSelectedFilters = () => {
    // need to do this in next tick
    setTimeout(() => {
      const applied =
        instance.value?.instanceType == 'Products'
          ? (instance.value as ProductsInstance)?.router.routeData?.filters
          : (instance.value as CatalogsInstance)?.router.routeData?.products.filters;

      if (filtersModel.value) {
        for (const attr in filtersModel.value) {
          const appliedFilter = applied?.filter((a) => a.attributeCode == attr);
          if (!appliedFilter || appliedFilter.length == 0 || !appliedFilter[0].values?.length) {
            filtersModel.value[attr] = {};
          } else {
            filtersModel.value[attr] = {};
            appliedFilter[0].values.forEach(
              (appliedFilter) =>
                (filtersModel.value[attr][`${appliedFilter.name}:${appliedFilter.code}`] = true),
            );
          }
        }
      }
    }, 1);
  };

  return {
    filtersAll,
    filtersOpen,
    filtersModel,
    filtersApplied,
    filtersLoading,
    filtersFetched,
    filtersPromoted,
    filtersAdditional,
    areFiltersApplied,
    refreshResultCount,
    filteredResultCount,
    filteredResultCountLoading,
    applyFilters,
    excludeFilter,
    fetchFilterValues,
    applyModelFilters,
    resetFiltersApplied,
    syncSelectedFilters,
    refreshFetchedFilterValues,
    expandAllFilters,
    collapseAllFilters,
  };
};
