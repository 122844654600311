
import { computed, defineComponent } from 'vue';
import Arrow from './Arrow.vue';
import Clickable from './Clickable.vue';

export default defineComponent({
  components: {
    Arrow,
    Clickable,
  },

  props: {
    total: {
      type: Number,
      default: 1,
    },
    current: {
      type: Number,
      default: 1,
    },
    trimTo: {
      type: Number,
      default: 5,
    },
  },

  emits: ['change'],

  setup(props, { emit }) {
    const threshold = computed(() => Math.floor(props.trimTo / 2));

    const start = computed(() => {
      const { current, trimTo } = props;
      return current >= trimTo ? current - threshold.value : 1;
    });

    const end = computed(() => {
      const { total, current, trimTo } = props;

      if (total < trimTo || current > total - trimTo) {
        return total;
      } else if (current >= trimTo) {
        return current + threshold.value;
      }

      return trimTo;
    });

    const pagesToDisplay = computed(() =>
      [...Array(end.value - start.value + 1)].map((v, i) => start.value + i)
    );

    const goTo = (index: number) => emit('change', index);

    return {
      threshold,
      start,
      end,
      pagesToDisplay,
      goTo,
    };
  },
});
