import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["part"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 1,
  class: "bg-light p-3"
}
const _hoisted_4 = { class: "fw-bold" }
const _hoisted_5 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InjectStyles = _resolveComponent("InjectStyles")!
  const _component_Clickable = _resolveComponent("Clickable")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "root",
    class: _normalizeClass(["pis-component", _ctx.componentName]),
    part: _ctx.componentName
  }, [
    (_ctx.isReady)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.isWebComponent)
            ? (_openBlock(), _createBlock(_component_InjectStyles, {
                key: 0,
                items: _ctx.store?.options.styles
              }, null, 8, ["items"]))
            : _createCommentVNode("", true),
          (_ctx.links.length > 1)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.t('GoToSection')) + ":", 1),
                _createElementVNode("div", _hoisted_5, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.links, (link) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: link.id,
                      class: _normalizeClass([_ctx.widerThan('lg') ? 'col-6' : 'col-12'])
                    }, [
                      _createVNode(_component_Clickable, {
                        onClick: ($event: any) => (_ctx.scrollToLink(link)),
                        class: "text-body"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(link.text), 1)
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ], 2))
                  }), 128))
                ])
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}