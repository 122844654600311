import { renderSlot as _renderSlot, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass(["d-inline-flex align-items-center badge rounded-pill", [_ctx.highlighted ? 'bg-primary text-light' : 'bg-light text-body']])
  }, [
    _renderSlot(_ctx.$slots, "default"),
    (_ctx.removable)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          class: "btn-close ms-1 ext-remove",
          style: {"font-size":"0.65rem"},
          title: _ctx.removeTitle,
          onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('remove')), ["prevent"]))
        }, null, 8, _hoisted_1))
      : _createCommentVNode("", true)
  ], 2))
}