
import { computed } from 'vue';
import VRuntimeTemplate from 'vue3-runtime-template';

import { DetailsCommand } from '@/products/api/runtime/CommandExecutor';
import { defineComponent } from 'vue';
import { ProductItem } from '../services/swagger/index.defs';
import { IInstance } from '../api/runtime/IInstance';

export default defineComponent({
  name: 'InteractiveText',

  components: {
    VRuntimeTemplate,
  },

  props: {
    text: { type: String, required: true },
    instance: { type: Object as () => IInstance | undefined, required: true },
  },

  setup(props) {
    const textParsed = computed(() => {
      const matches = props.text.match(/{{[^}]*}}/gm);

      let parsed = props.text.replaceAll('\n', '<br>');
      if (matches) {
        matches.forEach((element) => {
          const pid = element.replace('{{', '').replace('}}', '');
          parsed = parsed.replace(
            element,
            `<a href="#" @click.prevent="onLinkClick('${pid}')">${pid}</a>`,
          );
        });
      }
      return parsed;
    });

    const onLinkClick = async (pid: string) => {
      try {
        props.instance?.execute(new DetailsCommand({ productId: pid } as ProductItem));
      } catch (error) {
        // Ignore Error
      }
    };

    return {
      textParsed,
      onLinkClick,
    };
  },
});
