
import { computed, defineComponent, ref } from 'vue';
import { PartTypeEnum } from '@/common/services/swagger/index.defs';
import { ComponentName } from '@/products/api/configuration/components/ComponentName';
import { DataType } from '@/products/api/configuration/application/DataType';
import { setupComponent } from '@/products/composables/setupComponent';
import { PartsTypeCommand } from '@/products/api/runtime/CommandExecutor';

import InjectStyles from '@/common/components/InjectStyles.vue';
import Badge from '@/common/components/Badge.vue';
import LinkSecondary from '@/common/components/LinkSecondary.vue';
import { IBaseComponentOptions } from '@/products/api/configuration/components/IBaseComponentOptions';

const BADGE_TEXT_MAX_LEN = 20;

export default defineComponent({
  name: ComponentName.searchFiltersStatus,

  components: {
    InjectStyles,
    Badge,
    LinkSecondary,
  },

  props: {
    instanceId: String,
  },

  setup(props) {
    const root = ref<HTMLElement>();

    const { componentName, isReady, isWebComponent, store, instance, t, useProductsFilters } =
      setupComponent(root, props.instanceId, [DataType.Filters]);

    const { filtersApplied, excludeFilter, resetFiltersApplied } = useProductsFilters();

    const currentPartType = computed(() => instance.value?.router.routeData?.partsType);

    const isCurrentPartTypeDefault = computed(() => currentPartType.value === PartTypeEnum.All);

    const componentOptions = computed(
      () => store.value?.options.components?.searchFiltersStatus ?? ({} as IBaseComponentOptions),
    );

    const resetPartsType = async () => {
      if (!isCurrentPartTypeDefault.value) {
        try {
          await instance.value?.execute(new PartsTypeCommand({ partsType: PartTypeEnum.All }));
        } catch (error) {
          // Ignore Error
        }
      }
    };

    const resetAll = () => {
      resetPartsType();

      if (filtersApplied.value.length) {
        resetFiltersApplied();
      }
    };

    const truncateText = (text: string) => {
      return text.length > BADGE_TEXT_MAX_LEN
        ? text.substring(0, BADGE_TEXT_MAX_LEN) + '...'
        : text;
    };

    return {
      root,
      componentName,
      isReady,
      isWebComponent,
      store,
      filtersApplied,
      currentPartType,
      isCurrentPartTypeDefault,
      componentOptions,
      t,
      excludeFilter,
      resetPartsType,
      resetAll,
      truncateText,
    };
  },
});
