
import { computed, defineComponent, reactive, ref } from 'vue';
import { ComponentName } from '@/products/api/configuration/components/ComponentName';
import { setupComponent } from '@/products/composables/setupComponent';
import { DataType } from '@/products/api/configuration/application/DataType';
import { attrValue } from '@/common/helpers/productAttributes';

import InjectStyles from '@/common/components/InjectStyles.vue';
import Modal from '@/common/components/Modal.vue';
import NavigationBtns from '../common/NavigationBtns.vue';
import LinkSecondary from '@/common/components/LinkSecondary.vue';
import Icon from '@/common/components/Icon.vue';
import Btn from '@/common/components/Btn.vue';
import { IDetailsHeaderOptions } from '@/products/api/configuration/components/IDetailsHeaderOptions';
import {
  SendFeedbackCommand,
  ShowPrintViewCommand,
  PrintToPdfCommand,
  AddNotificationCommand,
} from '@/products/api/runtime/CommandExecutor';
import FormInputClearable from '@/common/components/FormInputClearable.vue';
import FormTextAreaClearable from '@/common/components/FormTextAreaClearable.vue';
import { FeedbackRatingEnum } from '@/common/services/swagger/index.defs';
import { ValidationErrors, ValidationType, Validators } from '@/common/helpers/validation';
import { NotificationType } from '@/common/api/runtime/INotification';

enum FeedbackFormFieldName {
  name = 'name',
  email = 'email',
  rate = 'rate',
  comments = 'comments',
}

export default defineComponent({
  name: ComponentName.detailsHeader,

  components: {
    Btn,
    Icon,
    Modal,
    InjectStyles,
    LinkSecondary,
    NavigationBtns,
    FormInputClearable,
    FormTextAreaClearable,
  },

  props: {
    instanceId: String,
  },

  setup(props) {
    const root = ref<HTMLElement>();
    const feedbackModalShown = ref(false);

    const getInitialFormValues = (): Record<FeedbackFormFieldName, any> => ({
      [FeedbackFormFieldName.name]: '',
      [FeedbackFormFieldName.email]: '',
      [FeedbackFormFieldName.rate]: FeedbackRatingEnum.Good,
      [FeedbackFormFieldName.comments]: '',
    });
    const feedbackFormModel = reactive<Record<FeedbackFormFieldName, any>>(getInitialFormValues());
    const validators: Partial<Record<FeedbackFormFieldName, Validators>> = {
      [FeedbackFormFieldName.name]: { [ValidationType.required]: {} },
      [FeedbackFormFieldName.email]: { [ValidationType.required]: {}, [ValidationType.email]: {} },
      [FeedbackFormFieldName.comments]: {
        [ValidationType.required]: {},
        [ValidationType.minLength]: { minLength: 3 },
      },
    };
    const feedbackFormValidationErrors = reactive<
      Partial<Record<FeedbackFormFieldName, ValidationErrors>>
    >({
      [FeedbackFormFieldName.name]: {},
      [FeedbackFormFieldName.email]: {},
      [FeedbackFormFieldName.comments]: {},
    });

    const { componentName, isReady, isWebComponent, instance, store, t, widerThan } =
      setupComponent(root, props.instanceId, [DataType.Breadcrumbs, DataType.ProductDetails]);

    const qualityRates = [
      {
        id: FeedbackRatingEnum.Excellent,
        tName: 'RatingExcellent',
        icon: 'face-very-happy',
        color: '#51A34B',
      },
      { id: FeedbackRatingEnum.Good, tName: 'RatingGood', icon: 'face-happy', color: '#3B786E' },
      { id: FeedbackRatingEnum.Poor, tName: 'RatingPoor', icon: 'face-sad', color: '#D93616' },
    ];

    const currentProduct = computed(() => store.value?.data.productDetail?.item);

    const title = computed(() => {
      if (currentProduct.value) {
        return attrValue(currentProduct.value, '#DisplayName');
      }
      return '';
    });

    const isRatingValid = (rating: string): rating is FeedbackRatingEnum =>
      Object.values(FeedbackRatingEnum).includes(rating as FeedbackRatingEnum);

    const feedbackFormValid = computed(
      () =>
        feedbackFormModel.name &&
        feedbackFormModel.email &&
        feedbackFormModel.comments &&
        isRatingValid(feedbackFormModel.rate) &&
        !Object.keys(feedbackFormValidationErrors).find(
          (field: string) => Object.keys(feedbackFormValidationErrors[field]).length,
        ),
    );

    const openFeedbackModal = () => (feedbackModalShown.value = true);

    const closeFeedbackModal = () => (feedbackModalShown.value = false);

    const submitFeedback = async () => {
      const productId = currentProduct.value?.productId;
      const rating = feedbackFormModel.rate;
      const text = feedbackFormModel.comments;
      const userEmail = feedbackFormModel.email;
      const userName = feedbackFormModel.name;
      if (!productId || !feedbackFormValid.value) {
        return;
      }
      try {
        await instance.value?.execute(
          new SendFeedbackCommand({
            productId,
            rating,
            text,
            userEmail,
            userName,
          }),
        );
        closeFeedbackModal();
        Object.assign(feedbackFormModel, getInitialFormValues());

        await instance.value?.execute(
          new AddNotificationCommand({
            type: NotificationType.success,
            message: t('FeedbackAlertSuccess'),
          }),
        );
      } catch (error) {
        // Ignore Error
      }
    };

    const showPrintView = async () => {
      if (currentProduct.value) {
        try {
          await instance.value?.execute(
            new ShowPrintViewCommand({ productId: currentProduct.value.productId }),
          );
        } catch (error) {
          // Ignore Error
        }
      }
    };

    const printToPdf = async () => {
      if (currentProduct.value) {
        try {
          await instance.value?.execute(
            new PrintToPdfCommand({ productId: currentProduct.value.productId }),
          );
        } catch (error) {
          // Ignore Error
        }
      }
    };

    const componentOptions = computed(
      () => store.value?.options.components?.detailsHeader ?? ({} as IDetailsHeaderOptions),
    );

    const onFeedbackFieldUpdate = (name: string, value: number | string) =>
      (feedbackFormModel[name] = value);

    const onFeedbackFieldValidation = (name: string, validationErrors: ValidationErrors) => {
      Object.assign(feedbackFormValidationErrors, { [name]: validationErrors });
    };

    return {
      root,
      title,
      store,
      isReady,
      instance,
      validators,
      qualityRates,
      componentName,
      isWebComponent,
      componentOptions,
      feedbackFormValid,
      feedbackFormModel,
      feedbackModalShown,
      t,
      widerThan,
      printToPdf,
      showPrintView,
      submitFeedback,
      openFeedbackModal,
      closeFeedbackModal,
      onFeedbackFieldUpdate,
      onFeedbackFieldValidation,
    };
  },
});
