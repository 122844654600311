
import { computed, defineComponent, ref, watch } from 'vue';
import { getPathFromEvent } from '@/common/helpers/dom';
import InjectStyles from '@/common/components/InjectStyles.vue';
import { AliasesEnum } from '@/common/services/swagger/index.defs';
import { setupComponent } from '@/catalogs/composables/setupComponent';
import { DataType } from '@/catalogs/api/configuration/application/DataType';
import { AliasesFilterCommand } from '@/catalogs/api/runtime/CommandExecutor';
import { ComponentName } from '@/catalogs/api/configuration/components/ComponentName';
import { ISearchAliasFiltersOptions } from '@/catalogs/api/configuration/components/ISearchAliasFiltersOptions';

export default defineComponent({
  name: ComponentName.searchAliasFilters,

  components: {
    InjectStyles,
  },

  props: {
    instanceId: String,
  },

  setup(props) {
    const root = ref<HTMLElement>();
    const trimList = ref(true);

    const { isReady, isWebComponent, componentName, instance, store, routeData, t } =
      setupComponent(root, props.instanceId, [DataType.ProductClassifications]);

    const componentOptions = computed(
      () =>
        store.value?.options.components?.searchAliasFilters ?? ({} as ISearchAliasFiltersOptions),
    );

    const visible = computed(() => componentOptions.value.visible);

    const currentAliasType = ref<AliasesEnum | undefined>(undefined);

    const isFiltering = ref<boolean>(false);

    const onAliasTypeChange = async (event: Event, type: AliasesEnum): Promise<void> => {
      if (getPathFromEvent<Event, HTMLInputElement>(event)[0].checked) {
        if (instance.value && type) {
          currentAliasType.value = type;
          isFiltering.value = true;
          try {
            await instance.value.execute(new AliasesFilterCommand({ aliasFilter: type }));
          } catch (error) {
            // Ignore error
          }
          isFiltering.value = false;
        }
      }
      //
    };

    watch(
      () => routeData.value?.products.aliases,
      (newAliasType: AliasesEnum | undefined) =>
        (currentAliasType.value = newAliasType || AliasesEnum.All),
    );

    return {
      root,
      store,
      isReady,
      visible,
      trimList,
      isFiltering,
      AliasesEnum,
      componentName,
      isWebComponent,
      componentOptions,
      currentAliasType,
      t,
      onAliasTypeChange,
    };
  },
});
