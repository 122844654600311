
import { defineComponent } from 'vue';
import Clickable from './Clickable.vue';
import Icon from './Icon.vue';

export default defineComponent({
  components: {
    Clickable,
    Icon,
  },

  props: {
    icon: String,
  },
});
