
import { defineComponent, ref, computed } from 'vue';
import { ComponentName } from '@/classifications/api/configuration/components/ComponentName';
import { setupComponent } from '@/classifications/composables/setupComponent';

// Components
import InjectStyles from '@/common/components/InjectStyles.vue';
import ClassificationActions from '@/classifications/components/browser/ClassificationActions.vue';
import ClassificationNote from '@/classifications/components/browser/ClassificationNote.vue';
import LoadingIndicator from './ClassificationsLoadingIndicator.vue';
import ClassificationsSearchForm from './browser/ClassificationsSearchForm.vue';
import ClassificationsTree from './browser/ClassificationsTree.vue';
import ClassificationsSelection from './browser/ClassificationsSelection.vue';
import ClassificationsTreeTabs from './browser/ClassificationsTreeTabs.vue';
import ClassificationsNotifications from './common/ClassificationsNotifications.vue';

export default defineComponent({
  name: ComponentName.layout,

  components: {
    InjectStyles,
    LoadingIndicator,
    ClassificationsSearchForm,
    ClassificationsTree,
    ClassificationsSelection,
    ClassificationNote,
    ClassificationActions,
    ClassificationsTreeTabs,
    ClassificationsNotifications,
  },

  props: {
    instanceId: String,
  },

  setup(props) {
    const root = ref<HTMLElement>();

    const {
      componentName,
      instance,
      isWebComponent,
      isReady,
      translationsReady,
      store,
      routeData,
      widerThan,
      t,
    } = setupComponent(root, props.instanceId);

    const allowMobileView = computed(() => store.value?.options.allowMobileView);

    return {
      root,
      componentName,
      isWebComponent,
      isReady,
      instance,
      translationsReady,
      store,
      routeData,
      allowMobileView,
      widerThan,
      t,
    };
  },
});
