import { computed, ComputedRef, Ref } from 'vue';
import { Instance } from '../api/Instance';
import {
  AttributeGroup,
  PrmRelationship,
  PrmRelationshipTypeEnum,
} from '@/common/services/swagger/index.defs';

export interface PrmAgmData {
  prms: PrmRelationship[];
  attributeGroup: AttributeGroup;
}
export interface PrmData {
  prms: PrmRelationship[];
  agms: PrmAgmData[];
}

export interface PrmDataSetup {
  prmData: ComputedRef<PrmData>;
  visibleAgmData: ComputedRef<PrmAgmData[]>;
  visiblePrmData: ComputedRef<PrmRelationship[]>;
}

export const setupPrmData = (instance: Ref<Instance | undefined>): PrmDataSetup => {
  const hideWhereUsed = computed(
    () =>
      instance.value?.store.options.components?.common?.hideWhereUsed ||
      instance.value?.store.data.productDetail?.item?.hideWhereUsed,
  );
  const prmData = computed<PrmData>(() => {
    const store = instance.value?.store;
    const data: PrmData = { agms: [], prms: [] };
    const attributeGroups = [...(store?.data.attributeGroups?.items || [])];
    const prmItemsLeft = [...(store?.data.prm?.items || [])];
    for (const attributeGroup of attributeGroups) {
      const entry: PrmAgmData = { prms: [], attributeGroup };
      if (attributeGroup.relationshipCode) {
        entry.prms = prmItemsLeft.filter(
          (prm: PrmRelationship) => prm.code === attributeGroup.relationshipCode,
        );
        entry.prms.forEach((prm: PrmRelationship) =>
          prmItemsLeft.splice(prmItemsLeft.indexOf(prm), 1),
        );
      }
      data.agms.push(entry);
    }
    data.prms = prmItemsLeft;
    return data;
  });
  const visibleAgmData = computed<PrmAgmData[]>(() =>
    prmData.value.agms.reduce((result: PrmAgmData[], prmDataItem: PrmAgmData) => {
      if (!prmDataItem.attributeGroup.visible) {
        return result;
      }
      return result.concat([
        {
          attributeGroup: prmDataItem.attributeGroup,
          prms: prmDataItem.prms.filter(
            (prm: PrmRelationship) =>
              !hideWhereUsed.value ||
              (prm.type !== PrmRelationshipTypeEnum.WhereUsed &&
                prm.type !== PrmRelationshipTypeEnum.SparePartUsedOnList),
          ),
        },
      ]);
    }, []),
  );
  const visiblePrmData = computed<PrmRelationship[]>(() =>
    prmData.value.prms.reduce((result: PrmRelationship[], prmDataItem: PrmRelationship) => {
      if (
        !hideWhereUsed.value ||
        (prmDataItem.type !== PrmRelationshipTypeEnum.WhereUsed &&
          prmDataItem.type !== PrmRelationshipTypeEnum.SparePartUsedOnList)
      ) {
        return result.concat([prmDataItem]);
      }
      return result;
    }, []),
  );

  return { prmData, visibleAgmData, visiblePrmData };
};
