import { Instance } from '.././Instance';

import { ISelectionChanged } from './ISelectionChanged';
import { IClassificationSelection } from './IClassificationSelection';
import { ClassificationsBrowserTreeTypeEnum } from '@/common/services/swagger/index.defs';

export class ClassificationSelection implements IClassificationSelection {
  constructor(private instance: Instance) {
    if (!instance.store.data.selectedItems) {
      instance.store.data.selectedItems = new Map<
        string,
        { cid: string; tree?: ClassificationsBrowserTreeTypeEnum }
      >();
    }
  }

  get data() {
    return this.instance.store.data.selectedItems;
  }

  get selectedItems() {
    return this.data ? Array.from(this.data.values()) : [];
  }

  get count() {
    return this.selectedItems.length;
  }

  select = (
    items: { cid: string; tree?: ClassificationsBrowserTreeTypeEnum; selectable: boolean }[],
  ) => {
    if (!this.data || !items?.length) {
      return;
    }

    const added: { cid: string; tree?: ClassificationsBrowserTreeTypeEnum }[] = [];
    items.forEach((item) => {
      if (item && item.cid && !this.isSelected(item)) {
        if (item.selectable) {
          this.data?.set(item.cid + '~' + item.tree, item);
          added.push(item);
        }
      }
    });
    if (added.length) {
      this.instance.eventBus.emit('selection-changed', <ISelectionChanged>{
        added: added,
        removed: [],
        selected: this.selectedItems,
      });
    }
  };

  deselect = (items: { cid: string; tree?: ClassificationsBrowserTreeTypeEnum }[]) => {
    if (!this.data || !items?.length) {
      return;
    }
    const removed: { cid: string }[] = [];
    items.forEach((item) => {
      if (item && item.cid && this.isSelected(item)) {
        this.data?.delete(item.cid + '~' + item.tree);
        removed.push(item);
      }
    });
    if (removed.length) {
      this.instance.eventBus.emit('selection-changed', <ISelectionChanged>{
        added: [],
        removed: removed,
        selected: this.selectedItems,
      });
    }
  };

  toggle = (item: {
    cid: string;
    tree?: ClassificationsBrowserTreeTypeEnum;
    selectable: boolean;
  }) => {
    if (!item.selectable) {
      return;
    }
    if (this.isSelected(item)) {
      this.deselect([item]);
    } else {
      this.select([item]);
    }
  };

  clear = () => {
    if (!this.data) {
      return;
    }

    const removed = this.selectedItems;

    this.data?.clear();
    if (removed.length) {
      this.instance.eventBus.emit('selection-changed', <ISelectionChanged>{
        added: [],
        removed: removed,
        selected: this.selectedItems,
      });
    }
  };

  isSelected = (item: { cid: string; tree?: ClassificationsBrowserTreeTypeEnum }) => {
    if (!this.data) {
      return false;
    }
    return this.data.has(item.cid + '~' + item.tree);
  };
}
