
import { defineComponent, ref } from 'vue';
import Arrow from './Arrow.vue';
import Clickable from './Clickable.vue';
import Icon from './Icon.vue';

export default defineComponent({
  components: { Icon, Arrow, Clickable },

  props: {
    icon: [String, Boolean],
    header: String,
    horizontal: Boolean,
    stickyFooter: Boolean,
    useMarkup: { type: Boolean, default: true },
    collapsed: { type: Boolean, default: false },
  },

  setup(props) {
    const isOpen = ref(!props.collapsed);

    return {
      isOpen,
      toggle: () => (isOpen.value = !isOpen.value),
    };
  },
});
