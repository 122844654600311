
import { computed, defineComponent, ref } from 'vue';
import { attrValue, attrValues, additionalInfo } from '@/common/helpers/productAttributes';
import { DataType } from '@/classifications/api/configuration/application/DataType';
import { ComponentName } from '@/classifications/api/configuration/components/ComponentName';

import { setupComponent } from '@/classifications/composables/setupComponent';

import InjectStyles from '@/common/components/InjectStyles.vue';
import { ISelectionOptions } from '@/classifications/api/configuration/components/ISelectionOptions';
import {
  ClassificationsBrowserNode,
  ClassificationsBrowserTreeTypeEnum,
  ProductGroupValidationStatusEnum,
} from '@/common/services/swagger/index.defs';

import Icon from '@/common/components/Icon.vue';
import Tooltip from '@/common/components/Tooltip.vue';
import { ClearSelectionCommand } from '@/classifications/api/runtime/CommandExecutor';
export default defineComponent({
  name: ComponentName.selection,

  components: {
    InjectStyles,
    Icon,
    Tooltip,
  },

  props: {
    instanceId: String,
  },

  setup(props) {
    const root = ref<HTMLElement>();

    const {
      componentName,
      isReady,
      isWebComponent,
      instance,
      store,
      routeData,
      narrowerThan,
      emit,
      t,
    } = setupComponent(root, props.instanceId, [DataType.Classifications]);

    const componentOptions = computed(
      () => store.value?.options.components?.selection ?? ({} as ISelectionOptions),
    );

    const nodes = computed(
      () => (instance.value?.selection.selectedItems as ClassificationsBrowserNode[]) || [],
    );

    const offeringNodes = computed(() => {
      return nodes.value.filter((a) => a.tree == ClassificationsBrowserTreeTypeEnum.Offering);
    });

    const plNodes = computed(() => {
      return nodes.value.filter((a) => a.tree == ClassificationsBrowserTreeTypeEnum.ProductLine);
    });

    const selection = computed(() => instance.value?.selection);

    const clearAll = () => {
      try {
        instance.value?.execute(new ClearSelectionCommand());
      } catch (error) {
        // ignored
      }
    };

    const height = computed(() =>
      narrowerThan('sm')
        ? componentOptions.value.mobileScrollHeight + 'px'
        : componentOptions.value.scrollHeight + 'px',
    );

    return {
      root,
      store,
      isReady,
      instance,
      routeData,
      componentName,
      isWebComponent,
      componentOptions,
      nodes,
      selection,
      height,
      t,
      emit,
      attrValue,
      attrValues,
      additionalInfo,
      clearAll,
      ProductGroupValidationStatusEnum,
      offeringNodes,
      plNodes,
    };
  },
});
