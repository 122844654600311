
import { computed, defineComponent, ref } from 'vue';
import { DataType } from '@/classifications/api/configuration/application/DataType';
import { ComponentName } from '@/classifications/api/configuration/components/ComponentName';
import { setupComponent } from '@/classifications/composables/setupComponent';
import InjectStyles from '@/common/components/InjectStyles.vue';
import { IActionsOptions } from '@/classifications/api/configuration/components/IActionsOptions';
import { ApplyCommand, CancelCommand } from '@/classifications/api/runtime/CommandExecutor';

export default defineComponent({
  name: ComponentName.actions,

  components: {
    InjectStyles,
  },

  props: {
    instanceId: String,
  },

  setup(props) {
    const root = ref<HTMLElement>();

    const { componentName, isReady, isWebComponent, instance, store, routeData, t } =
      setupComponent(root, props.instanceId, [DataType.Classifications]);

    const componentOptions = computed(
      () => store.value?.options.components?.actions ?? ({} as IActionsOptions),
    );

    const cancel = async () => {
      try {
        await instance.value?.execute(new CancelCommand());
      } catch {
        // ignored
      }
    };

    const apply = async () => {
      try {
        await instance.value?.execute(new ApplyCommand());
      } catch {
        // ignored
      }
    };

    return {
      root,
      store,
      isReady,
      instance,
      routeData,
      componentName,
      isWebComponent,
      componentOptions,
      cancel,
      apply,
      t,
    };
  },
});
