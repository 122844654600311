import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["ratio ratio-1x1 flex-shrink-0 pis-image", [
      _ctx.src || _ctx.isLoading ? 'bg-white' : 'bg-light',
      _ctx.active && 'border-primary',
      !_ctx.hideFrame && 'border',
    ]]),
    style: _normalizeStyle({ cursor: _ctx.clickable ? 'pointer' : 'default' })
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["d-flex align-items-center justify-content-center", 'p-' + _ctx.padding])
    }, [
      (_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_Spinner, {
            key: 0,
            sm: ""
          }))
        : (_ctx.src)
          ? (_openBlock(), _createElementBlock("img", {
              key: 1,
              class: "w-100 h-100",
              style: _normalizeStyle([{"object-fit":"contain"}, [
          _ctx.maxSize && `max-width: ${_ctx.maxSize}px; max-height: ${_ctx.maxSize}px;`,
          _ctx.maxSizeCss && `max-width: ${_ctx.maxSizeCss}; max-height: ${_ctx.maxSizeCss};`,
        ]]),
              src: _ctx.src
            }, null, 12, _hoisted_1))
          : (_openBlock(), _createBlock(_component_Icon, {
              key: 2,
              glyph: "image",
              class: "opacity-25",
              xl: ""
            }))
    ], 2)
  ], 6))
}